<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('organization.label.create_organization') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('organization.message.create_organization') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded' && organization && countries && regions">
        <FormValidator ref="form" class="grid grid-cols-6 gap-4 py-4">
          <OrganizationForm
            :organization="organization"
            :countries="countries"
            :regions="regions"
            :legal-forms="legalForms"
          />
        </FormValidator>
        <ZDialogFooter>
          <Action @proceed="store">{{ $t('organization.command.create_organization') }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import { createOrganization } from '@/api/organization';
import OrganizationData = App.Data.OrganizationData;
import FormValidator from '@ui/FormValidator.vue';
import type { KeyValue } from '@/types/Common';
import { getCountries, getRegions } from '@/api/dictionaries';
import RegionWithTranslationData = App.Data.RegionWithTranslationData;
import CountryWithTranslationData = App.Data.CountryWithTranslationData;
import symbolsStore from '@/stores/symbols';
import IconAlert from '@ui/IconAlert.vue';
import OrganizationForm from '@ui/Organizations/OrganizationForm.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';

export default defineComponent({
  components: {
    OrganizationForm,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      organization: {} as OrganizationData,
      legalForms: {} as KeyValue,
      countries: null as Record<string, string> | null,
      regions: [] as RegionWithTranslationData[],
      isDialogOpen: true,

      state: 'loading' as ComponentLoadState,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      const symbols = symbolsStore();
      this.legalForms = await symbols.get('legal_forms');

      const [countriesResponse, regionsResponse] = await Promise.all([
        getCountries(this.$i18n.locale).execute(),
        getRegions(this.$i18n.locale).execute(),
      ]);

      this.countries = countriesResponse.data.reduce((accumulator: KeyValue, value: CountryWithTranslationData) => {
        return { ...accumulator, [value.id]: value.translations[0].translation };
      }, {});
      this.regions = regionsResponse.data;

      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
    }
  },

  methods: {
    store(action: any) {
      createOrganization(this.organization)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(response => {
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('organization.message.organization_created'),
          });
          this.$router.push({ name: 'organization.details', params: { organizationId: response.data.new_id } });
        });
    },
  },
});
</script>
