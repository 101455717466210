<template>
  <DataLoader ref="loader">
    <router-view></router-view>
    <div class="mb-10">
      <template v-if="asset.category_id">
        <MountedTeleport to="#toolbox">
          <div v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)">
            <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <ZButton class="w-full">
                  <CogIcon class="icon sm"/>
                  <span class="ml-2">
                    {{ $t('asset.command.manage') }}
                  </span>
                  <ChevronDownIcon class="icon xs ml-2"/>
                </ZButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent class="w-56">
                <DropdownMenuGroup>
                  <DropdownMenuItem @click="editAssetData">
                    <span>{{ $t('asset.command.edit_asset_data') }}</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator/>
                  <DropdownMenuItem :disabled="asset.status !== 'draft'" @click="editAssetCategoryAndModel">
                    <span>{{ $t('asset.command.change_category_and_model') }}</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator/>
                  <DropdownMenuItem @click="editAssetAttributes">
                    <span>{{ $t('asset.command.edit_asset_attributes') }}</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator/>
                  <DropdownMenuItem :disabled="asset.has_pending_contracts" @click="updateAssetStatus">
                    <span>{{ $t('asset.command.update_status') }}</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator/>
                  <DropdownMenuItem @click="editAssetImages">
                    <span>{{ $t('asset.command.edit_asset_images') }}</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator/>
                  <DropdownMenuItem @click="assignTagsToAsset">
                    <span>{{ $t('asset.command.assign_tags') }}</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator/>
                  <DropdownMenuItem @click="createNote">
                    <span>{{ $t('asset.command.create_note') }}</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator/>
                  <DropdownMenuItem @click="duplicateAsset">
                    <span>{{ $t('asset.command.duplicate_asset') }}</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator/>
                  <DropdownMenuItem :disabled="!asset.can_be_deleted" @click="requestAssetDelete">
                    <span>{{ $t('asset.command.delete_asset') }}</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </MountedTeleport>
        <IconAlert v-if="asset.status == SYMBOL.ASSET_STATE.DRAFT" class="mt-6" type="warning">
          <div class="flex flex-row justify-between items-center w-full">
            <span>{{ $t('asset.label.asset_is_draft') }}</span>
          </div>
        </IconAlert>

        <IconAlert v-if="asset.has_pending_contracts" class="mt-6" type="warning">
          <div class="flex flex-row justify-between items-center w-full">
            <span>{{ $t('asset.message.has_pending_contracts') }}</span>
          </div>
        </IconAlert>

        <Tabs v-model="currentTab" class="py-5">
          <div class="flex lg:flex-col flex-col-reverse">
            <div class="hidden lg:block">
              <AssetDetailsView :asset="asset" :preview-images="previewImages" :edit-asset-images="editAssetImages"/>
            </div>
            <TabsList
              class="lg:mt-4 no-scrollbar !flex justify-start overflow-x-auto lg:overflow-visible items-center gap-4 lg:gap-0 grid py-5 w-full"
            >
              <TabsTrigger class="w-full" value="details">
                {{ $t('asset.label.navigation_informations') }}
              </TabsTrigger>
              <TabsTrigger class="w-full" value="contracts">
                <div class="relative flex items-center gap-2">
                  {{ $t('asset.label.navigation_contracts') }}
                  <div
                    class="lg:absolute -top-4 -right-5 w-[18px] h-[18px] bg-primary rounded-full text-white text-[9px] flex items-center justify-center"
                  >
                    {{ asset.count.contracts }}
                  </div>
                </div>
              </TabsTrigger>
              <TabsTrigger class="w-full" value="inventories" v-if="access.hasFeature(SYMBOL.FEATURES.INVENTORY)">
                <div class="relative flex items-center gap-2">
                  {{ $t('asset.label.navigation_inventories') }}
                  <div
                    class="lg:absolute -top-4 -right-5 w-[18px] h-[18px] bg-primary rounded-full text-white text-[9px] flex items-center justify-center"
                  >
                    {{ asset.count.inventories }}
                  </div>
                </div>
              </TabsTrigger>
              <TabsTrigger class="w-full" value="notes">
                <div class="relative flex items-center gap-2">
                  {{ $t('asset.label.navigation_notes') }}
                  <div
                    class="lg:absolute -top-4 -right-5 w-[18px] h-[18px] bg-primary rounded-full text-white text-[9px] flex items-center justify-center"
                  >
                    {{ asset.count.notes }}
                  </div>
                </div>
              </TabsTrigger>
              <TabsTrigger class="w-full" value="history">
                <div class="relative flex items-center gap-2">
                  {{ $t('asset.label.navigation_histories') }}
                  <div
                    class="lg:absolute -top-4 -right-5 w-[18px] h-[18px] bg-primary rounded-full text-white text-[9px] flex items-center justify-center"
                  >
                    {{ asset.statusActivities.length }}
                  </div>
                </div>
              </TabsTrigger>
              <TabsTrigger class="w-full" value="files" v-if="access.hasFeature(SYMBOL.FEATURES.DOCUMENTS)">
                <div class="relative flex items-center gap-2">
                  {{ $t('asset.label.navigation_files') }}
                  <div
                    class="lg:absolute -top-4 -right-5 w-[18px] h-[18px] bg-primary rounded-full text-white text-[9px] flex items-center justify-center"
                  >
                    {{ asset.count.files }}
                  </div>
                </div>
              </TabsTrigger>
            </TabsList>
          </div>
          <TabsContent value="details">
            <div class="lg:hidden mt-4">
              <AssetDetailsView :asset="asset" :preview-images="previewImages" :edit-asset-images="editAssetImages"/>
            </div>
            <div class="items-start justify-center gap-6 rounded-lg py-4 md:grid grid-cols-12">
              <div class="col-span-12 lg:col-span-5 xl:col-span-3 grid items-start gap-6">
                <div class="rounded-lg overflow-hidden border bg-card text-card-foreground">
                  <div v-if="asset" class="px-4 pt-4">
                    <div class="i-block">
                      <span class="i-label">{{ $t('asset.label.description') }}</span>
                      <span class="i-value" v-if="asset?.description">{{ asset?.description }}</span>
                      <div class="opacity-30 text-center pt-1 w-full" v-else>{{
                          $t('asset.label.no_description')
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="px-4 pb-4 pt-2 flex gap-2 flex-wrap">
                    <div class="i-block w-full">
                      <span class="i-label">{{ $t('asset.label.tags') }}</span>
                      <div class="mt-2 w-full" v-if="asset.tags.length">
                        <span class="inline-block" v-for="(tag, idx) in asset.tags" :key="idx">
                        <TagLabel :name="tag.name" :color="tag.color"
                                  class="whitespace-nowrap m-1"/>
                        </span>
                      </div>
                      <div class="mt-2 text-center pt-1 w-full" v-else>
                        <span class="opacity-30">{{ $t('asset.label.no_tags') }}</span>
                      </div>

                      <a href="#" v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"
                         @click.prevent="assignTagsToAsset"
                         class="rounded bg-gray-200 w-full flex justify-center p-1 mt-4 text-sm">{{
                          $t('asset.command.add_tag')
                        }}</a>
                    </div>
                  </div>
                </div>
                <div class="rounded-lg overflow-hidden border bg-card text-card-foreground"
                     v-if="access.hasFeature(SYMBOL.FEATURES.CARBON)">
                  <div class="grid grid-cols-1 divide-y">
                    <div class="p-8 bg-primary text-white">
                      <div class="text-center">
                        <div class="text-xl font-bold">{{ readableCarbonValue(asset?.footprintValue) }}</div>
                        <div class="text-sm opacity-80">{{ $t('asset.label.carbon_reduction') }}</div>
                      </div>
                    </div>
                    <div class="overflow-hidden">
                      <AssetCarbonFootprint :asset="asset" @saved="getAssetData"
                                            :editable="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"/>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-span-12 lg:col-span-7 xl:col-span-6 grid items-start gap-6 pt-6 md:pt-0">
                <div class="gap-6 rounded-lg grid xl:grid-cols-1">
                  <div class="col-span-12 xl:col-span-4 grid items-start gap-6 lg:col-span-1">
                    <div class="rounded-xl border bg-card text-card-foreground">
                      <div class="m-5 flex items-center justify-between">
                        <h3 class="font-semibold leading-none tracking-tight">
                          {{ $t('asset.label.category_attributes_header') }}
                        </h3>
                        <div
                          v-if="
                            Object.keys(categoryAttributes).length &&
                            access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)
                          "
                          class="gap-4 flex"
                        >
                          <ZButton v-if="!editMode" class="h-7" variant="outline" @click="setEditMode(true)">
                            {{ $t('asset.command.edit_attributes') }}
                          </ZButton>
                          <ZButton
                            v-if="editMode"
                            class="h-7"
                            :disabled="!canSaveCategoryAttributes"
                            @click="saveCategoryAttributes"
                          >{{ $t('asset.command.save_changes') }}
                          </ZButton>
                          <ZButton v-if="editMode" class="h-7" variant="outline" @click="setEditMode(false, true)"
                          >{{ $t('asset.command.cancel_edit_attributes') }}
                          </ZButton>
                        </div>
                      </div>

                      <div v-if="categoryAttributes.length" class="grid grid-cols-1 divide-y border-t">
                        <div v-for="categoryAttribute in categoryAttributes" :key="'cat_' + categoryAttribute.id">
                          <div class="flex justify-between px-5 py-3">
                            <span class="text-sm text-gray-400 self-center"
                            >{{
                                categoryAttribute.name
                              }}<span v-if="categoryAttribute.unit">({{ categoryAttribute.unit ?? '' }})</span>:
                            </span>
                            <span class="font-normal text-base text-black flex">
                              <AttributeValue
                                v-model="categoryAttributeValues[categoryAttribute.id]"
                                :justify-content="'right'"
                                :definition="categoryAttribute"
                                :on-save="onSave"
                                :edit-mode="editMode"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div v-else class="py-10">
                        <InformationCircleIcon class="icon w-[30px] mb-5 m-auto"/>
                        <h3 class="scroll-m-20 font-semibold text-center">
                          {{ $t('asset.label.attributes_not_found') }}
                        </h3>
                      </div>
                    </div>
                  </div>


                  <div class="col-span-12 xl:col-span-4 grid items-start gap-6 lg:col-span-1">
                    <div class="rounded-xl border bg-card text-card-foreground">
                      <div class="m-5 flex items-center justify-between">
                        <h3 class="font-semibold leading-none tracking-tight">
                          {{ $t('asset.label.attributes_header') }}
                        </h3>
                        <div v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)" class="gap-4 flex">
                          <ZButton class="h-7" variant="outline" @click="editAssetAttributes">
                            {{ $t('asset.command.manage_attributes') }}
                          </ZButton>
                        </div>
                      </div>

                      <div v-if="asset.assetValues!.filter(item => item.category_attribute_id === null).length">
                        <div v-for="attribute in asset.assetValues" :key="attribute.id">
                          <div
                            v-if="attribute?.category_attribute_id === null"
                            class="flex justify-between px-5 py-3 border-t"
                          >
                            <span class="text-sm text-gray-400 self-center">{{ attribute.name }}:</span>
                            <span v-if="attribute.val_int != null" class="font-normal text-base text-black">
                                <span v-if="attribute.val_int * 1 == 1" class="rounded text-white bg-green-600 px-2">{{
                                    $t('common.yes')
                                  }}</span>
                                <span v-else class="rounded bg-gray-300 px-2">{{ $t('common.no') }}</span>
                              </span>
                            <span v-else class="ml-2">{{ displayAttributeValue(attribute) }}</span>
                          </div>
                        </div>
                      </div>

                      <div v-else class="py-10">
                        <InformationCircleIcon class="icon w-[30px] mb-5 m-auto"/>
                        <h3 class="scroll-m-20 font-semibold text-center">
                          {{ $t('asset.label.attributes_not_found') }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <div class="col-span-12 xl:col-span-3 grid items-start gap-6 pt-6 md:pt-0">
                <div class="flex items-center justify-between w-full rounded-xl border bg-card text-card-foreground">
                  <div class="w-full p-4">
                    <p class="font-semibold leading-none tracking-tight">{{ $t('asset.label.scanQr') }}</p>
                    <p class="leading-none mt-2 text-sm tracking-tight">{{ $t('asset.label.scanQrText') }}</p>
                  </div>
                  <div class="p-2 max-w-[100px] max-h-[100px]">
                    <img v-if="asset.qrCodeUrl" class="h-full w-full" :src="asset.qrCodeUrl" alt="qrCode"/>
                  </div>
                </div>
                <div class="rounded-xl border bg-card text-card-foreground overflow-hidden">
                  <div class="m-5 flex justify-between items-center">
                    <h3 class="font-semibold leading-none tracking-tight">{{ $t('asset.label.accounting_data') }}</h3>
                    <PencilSquareIcon class="icon sm cursor-pointer mr-2" @click="editAssetData"/>
                  </div>
                  <div class="m-5">
                    <div
                      v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_FINANCE, true) && access.hasFeature(SYMBOL.FEATURES.FINANCE)">
                      <div class="flex justify-between p-2 border-b">
                        <span class="text-sm text-gray-400 self-center">{{ $t('asset.label.activation_date') }}:</span>
                        <span class="ml-2 break-all">{{
                            asset.activation_date ? formatDate(asset.activation_date) : '-'
                          }}</span>
                      </div>
                      <div class="flex justify-between p-2 border-b">
                        <span class="text-sm text-gray-400 self-center">{{ $t('asset.label.price') }}:</span>
                        <span class="ml-2 break-all"
                        >{{ asset.purchase_price ? formatCurrency(asset.purchase_price) : '-' }}
                          {{ asset.purchase_price && asset.currency ? asset.currency : '' }}</span
                        >
                      </div>
                      <div class="flex justify-between p-2 border-b">
                        <span class="text-sm text-gray-400 self-center">{{ $t('asset.label.purchase_date') }}:</span>
                        <span class="ml-2 break-all">{{
                            asset.purchase_date ? formatDate(asset.purchase_date) : '-'
                          }}</span>
                      </div>
                      <div class="flex justify-between p-2 border-b">
                        <span class="text-sm text-gray-400 self-center">{{ $t('asset.label.invoice_number') }}:</span>
                        <span class="ml-2 break-all">{{ asset.invoice_number ? asset.invoice_number : '-' }}</span>
                      </div>
                      <div class="flex justify-between p-2 border-b">
                        <span class="text-sm text-gray-400 self-center">{{ $t('asset.label.accounting_value') }}:</span>
                        <span class="ml-2 break-all">{{
                            asset.accounting_value ? formatCurrency(asset.accounting_value) : '-'
                          }}</span>
                      </div>
                      <div class="flex justify-between p-2 border-b">
                        <span class="text-sm text-gray-400 self-center"
                        >{{ $t('asset.label.amortization_value') }}:</span
                        >
                        <span class="ml-2 break-all">{{
                            asset.amortization_value ? formatCurrency(asset.amortization_value) : '-'
                          }}</span>
                      </div>
                    </div>
                    <div class="flex justify-between p-2 border-b">
                      <span class="text-sm text-gray-400 self-center">{{ $t('asset.label.warrantator') }}:</span>
                      <span class="ml-2 break-all">{{ asset.warrantator ? asset.warrantator : '-' }}</span>
                    </div>
                    <div class="flex justify-between p-2 border-b">
                      <span class="text-sm text-gray-400 self-center"
                      >{{ $t('asset.label.warranty_expiration_date') }}:</span
                      >
                      <span class="ml-2 break-all">{{
                          asset.warranty_expiration_date ? formatDate(asset.warranty_expiration_date) : '-'
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabsContent>
          <TabsContent value="contracts">
            <div class="border rounded-xl overflow-hidden mt-4">
              <div v-if="assetContracts.data && assetContracts.data.length">
                <ZTable class="w-full">
                  <ZTableHeader>
                    <ZTableRow>
                      <ZTableHead class="font-bold text-left">{{ $t('asset.label.contract_action_name') }}</ZTableHead>
                      <ZTableHead class="font-bold text-left"
                      >{{ $t('asset.label.contract_action_status') }}
                      </ZTableHead>
                      <ZTableHead class="font-bold text-left">{{ $t('asset.label.contract_number') }}</ZTableHead>
                      <ZTableHead class="font-bold text-left">{{ $t('asset.label.contract_status') }}</ZTableHead>
                    </ZTableRow>
                  </ZTableHeader>
                  <ZTableBody>
                    <ZTableRow
                      v-for="(contract, index) in assetContracts.data"
                      :key="index"
                      class="cursor-pointer"
                      @click="contractDetails(contract.contract!.id!)"
                    >
                      <ZTableCell class="text-left">
                        {{ contract.contracted_action!.action!.name }}
                      </ZTableCell>
                      <ZTableCell class="text-left">
                        <PrettyStatus
                          :status="contract.contracted_action!.status!.toString()"
                          :translate="'contract.actionStatus.'"
                        />
                      </ZTableCell>
                      <ZTableCell class="text-left"> #{{ contract.contract!.contract_full_number }}</ZTableCell>
                      <ZTableCell class="text-left">
                        <PrettyStatus :status="contract!.contract!.status!.toString()" :translate="'contract.status.'"/>
                      </ZTableCell>
                    </ZTableRow>
                  </ZTableBody>
                </ZTable>
                <Paginator class="px-3 border-t" :data="assetContracts"/>
              </div>
              <div v-else class="py-10">
                <InformationCircleIcon class="icon w-[30px] mb-5 m-auto"/>
                <h3 class="scroll-m-20 font-semibold text-center">{{ $t('asset.label.contract_not_found') }}</h3>
              </div>
            </div>
          </TabsContent>
          <TabsContent value="inventories">
            <div class="border rounded-xl overflow-hidden mt-4">
              <div v-if="asset.last_inventory_at" class="bg-gray-50 p-3 border-b text-sm">
                {{ $t('asset.label.lastInventoryAt') }}: <b>{{ formatDate(asset.last_inventory_at) }}</b>
              </div>
              <div>
                <div v-if="assetInventories.data && assetInventories.data.length">
                  <ZTable class="w-full border-b">
                    <ZTableHeader>
                      <ZTableRow>
                        <ZTableHead class="font-bold text-left">{{ $t('inventory.label.number') }}</ZTableHead>
                        <ZTableHead class="font-bold text-left"
                        >{{ $t('inventory.inventory_assets.label.status') }}
                        </ZTableHead>
                        <ZTableHead class="font-bold text-left">{{ $t('inventory.label.completed_at') }}</ZTableHead>
                        <ZTableHead class="font-bold text-left">{{ $t('inventory.label.user_name') }}</ZTableHead>
                      </ZTableRow>
                    </ZTableHeader>
                    <ZTableBody>
                      <ZTableRow
                        v-for="inventory in assetInventories.data"
                        :key="inventory.id"
                        class="cursor-pointer"
                        @click="inventoryDetails(inventory!.id!)"
                      >
                        <ZTableCell class="text-left"> #{{ inventory.id }}</ZTableCell>
                        <ZTableCell class="text-left">
                          <PrettyStatus
                            :status="inventory.asset_status!"
                            :translate="'inventory.inventory_assets.states.'"
                          />
                        </ZTableCell>
                        <ZTableCell class="text-left">
                          {{ formatDate(inventory!.completed_at!) }}
                        </ZTableCell>
                        <ZTableCell class="text-left">
                          {{ inventory.user_name }}
                        </ZTableCell>
                      </ZTableRow>
                    </ZTableBody>
                  </ZTable>
                  <Paginator class="px-3" :data="assetInventories"/>
                </div>
                <div v-else class="py-10">
                  <InformationCircleIcon class="icon w-[30px] mb-5 m-auto"/>
                  <h3 class="scroll-m-20 font-semibold text-center">{{ $t('asset.label.inventory_not_found') }}</h3>
                </div>
              </div>
            </div>
          </TabsContent>
          <TabsContent value="notes">
            <div class="border rounded-xl overflow-hidden mt-4">
              <div class="flex p-3 flex-row justify-between items-center border-b bg-muted/30">
                <b>{{ $t('asset.label.notes') }}</b>
                <div v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)" class="gap-4 flex">
                  <ZButton class="h-7" variant="ghost" @click="createNote">
                    {{ $t('asset.command.add_note') }}
                  </ZButton>
                </div>
              </div>
              <div v-if="assetNotes.data && assetNotes.data.length">
                <div v-for="note in assetNotes.data" :key="note.id" class="flex justify-between p-2 border-b">
                  <span>
                    {{ note.note }}
                  </span>
                  <div
                    v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"
                    class="flex justify-between items-center"
                  >
                    <PencilSquareIcon class="icon sm cursor-pointer mr-2" @click="editNote(note.id)"/>
                    <TrashIcon class="h-5 cursor-pointer" @click="deleteNote(note.id)"></TrashIcon>
                  </div>
                </div>
                <Paginator class="px-3" :data="assetNotes"/>
              </div>
              <div v-else class="py-10">
                <InformationCircleIcon class="icon w-[30px] mb-5 m-auto"/>
                <h3 class="scroll-m-20 font-semibold text-center">{{ $t('asset.label.notes_not_found') }}</h3>
              </div>
            </div>
          </TabsContent>
          <TabsContent value="history">
            <div class="border rounded-xl overflow-hidden mt-4">
              <div v-if="asset.statusActivities.length">
                <ZTable class="w-full">
                  <ZTableHeader>
                    <ZTableRow>
                      <ZTableHead class="font-bold text-left">{{ $t('asset.label.old_value') }}</ZTableHead>
                      <ZTableHead class="font-bold text-left">{{ $t('asset.label.new_value') }}</ZTableHead>
                      <ZTableHead class="font-bold text-left">{{ $t('asset.label.causer') }}</ZTableHead>
                      <ZTableHead class="font-bold text-left">{{ $t('asset.label.date') }}</ZTableHead>
                    </ZTableRow>
                  </ZTableHeader>
                  <ZTableBody>
                    <ZTableRow v-for="(statusActivity, index) in asset.statusActivities" :key="index">
                      <ZTableCell class="text-left">
                        <PrettyStatus :status="statusActivity.old_value" :translate="'asset.states.'"/>
                      </ZTableCell>
                      <ZTableCell class="text-left">
                        <PrettyStatus :status="statusActivity.new_value" :translate="'asset.states.'"/>
                      </ZTableCell>
                      <ZTableCell class="text-left">
                        <span>{{ statusActivity.causer.first_name }} {{ statusActivity.causer.last_name }}</span>
                      </ZTableCell>
                      <ZTableCell class="text-left">
                        {{ statusActivity.created_at }}
                      </ZTableCell>
                    </ZTableRow>
                  </ZTableBody>
                </ZTable>
              </div>
              <div v-else class="py-10">
                <InformationCircleIcon class="icon w-[30px] mb-5 m-auto"/>
                <h3 class="scroll-m-20 font-semibold text-center">{{ $t('asset.label.histories_not_found') }}</h3>
              </div>
            </div>
            <h3 class="pt-8 font-bold">{{ $t('asset.label.transfers') }}</h3>
            <tracking :tracking-id="asset.tracking_id"/>
          </TabsContent>
          <TabsContent value="files">
            <FileAttach :id="asset.id" ref="attachFileDialog" :on-update="refreshAssetFiles" type="asset"/>
            <div class="border rounded-xl overflow-hidden mt-4">
              <div>
                <div class="flex p-3 flex-row justify-between items-center border-b bg-muted/30">
                  <b>{{ $t('asset.label.navigation_files') }}</b>
                  <div v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)" class="gap-4 flex">
                    <ZButton class="h-7" variant="ghost" @click="attachFile">
                      {{ $t('asset.command.add_file') }}
                    </ZButton>
                  </div>
                </div>
                <div v-if="assetFiles.data && assetFiles.data.length">
                  <ZTable class="w-full border-b">
                    <ZTableHeader>
                      <ZTableRow>
                        <ZTableHead class="font-bold text-left"
                        ><b>{{ $t('files.label.file') }}</b></ZTableHead
                        >
                        <ZTableHead class="text-center"
                        ><b>{{ $t('files.label.extension') }}</b></ZTableHead
                        >
                        <ZTableHead class="text-center"
                        ><b>{{ $t('files.label.assigned') }}</b></ZTableHead
                        >
                        <ZTableHead class="text-right"></ZTableHead>
                      </ZTableRow>
                    </ZTableHeader>
                    <ZTableBody>
                      <ZTableRow v-for="file in assetFiles.data" :key="file.id">
                        <ZTableCell class="text-left w-full">
                          <div class="flex items-center gap-4 justify-start">
                            <div v-if="isFileImage(file.mime)">
                              <img class="w-[50px] h-[50px] object-cover overflow-hidden" :src="file!.url!"/>
                            </div>
                            <div class="font-medium">{{ file.name }}</div>
                            <div class="text-xs text-gray-400">{{ file.description }}</div>
                          </div>
                        </ZTableCell>
                        <ZTableCell class="text-center text-xs whitespace-nowrap">
                          {{ file.ext }}
                        </ZTableCell>
                        <ZTableCell class="text-center text-xs whitespace-nowrap">
                          <div>{{ formatDate(file!.assigned_at!) }}</div>
                          <div>{{ file.assigner_name }}</div>
                        </ZTableCell>
                        <ZTableCell class="text-right whitespace-nowrap">
                          <DropdownMenu>
                            <DropdownMenuTrigger as-child>
                              <ZButton variant="ghost">
                                <EllipsisHorizontalIcon class="icon sm"/>
                              </ZButton>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                              <DropdownMenuGroup>
                                <DropdownMenuItem @click="getFileDownloadUrl(file!.id!)">
                                  <span>{{ $t('files.command.download') }}</span>
                                </DropdownMenuItem>
                                <DropdownMenuSeparator/>
                                <DropdownMenuItem
                                  :disabled="!access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"
                                  @click="detachFile(file!.id!, file!.pivot_relation_id!)"
                                >
                                  <span>{{ $t('files.command.detach') }}</span>
                                </DropdownMenuItem>
                              </DropdownMenuGroup>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </ZTableCell>
                      </ZTableRow>
                    </ZTableBody>
                  </ZTable>
                  <Paginator class="px-3" :data="assetFiles"/>
                </div>
                <div v-else class="py-10">
                  <InformationCircleIcon class="icon w-[30px] mb-5 m-auto"/>
                  <h3 class="scroll-m-20 font-semibold text-center">{{ $t('files.message.data_not_found') }}</h3>
                </div>
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </template>
    </div>
    <ImagePreview
      v-if="isImageDialogOpen"
      :image-urls="imageURls"
      @image-preview-closed="isImageDialogOpen = false"
    ></ImagePreview>
  </DataLoader>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import {Dialogs} from '@/types/Dialogs';
import Navigation from '@/types/Navigation';
import {
  getAsset,
  deleteAsset,
  saveAssetAttributes,
  getAssetInventories,
  getAssetContracts,
  getAssetFiles,
} from '@/api/asset';
import {deleteAssetNote, getAssetNotes} from '@/api/assetNote';
import AssetData = App.Data.AssetData;
import {ZButton} from '@shadcn/components/ui/button';
import {ChevronDownIcon, CogIcon, InformationCircleIcon} from '@heroicons/vue/24/outline';
import AssetNotesData = App.Data.AssetNotesData;
import ContractRequestData = App.Data.ContractRequestData;
import AssetNotesRequestData = App.Data.AssetNotesRequestData;
import IconAlert from '@ui/IconAlert.vue';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@shadcn/components/ui/dropdown-menu';
import {ROUTE} from '@/router/routeNames';
import {DataSet} from '@/utils/DataSet';
import AttributeValue from '@ui/AttributeValue.vue';
import {getAllCategoryAttributes} from '@/api/category';
import ImagePreview from '@ui/ImagePreview/ImagePreview.vue';
import AssetValueData = App.Data.AssetValueData;
import PrettyStatus from '@ui/PrettyStatus.vue';
import {ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow} from '@shadcn/components/ui/table';
import TagLabel from '@ui/Tags/TagLabel.vue';
import {PencilSquareIcon, TrashIcon} from '@modules/@heroicons/vue/24/outline';
import Paginator from '@ui/Paginator.vue';
import useAccessStore from '@/stores/access';
import AssetInventoryData = App.Data.AssetInventoryData;
import InventoryFilterRequestData = App.Data.InventoryFilterRequestData;
import AssetContractData = App.Data.AssetContractData;
import {Tabs, TabsContent, TabsList, TabsTrigger} from '@shadcn/components/ui/tabs';
import MountedTeleport from '@ui/MountedTeleport.vue';
import AssetDetailsView from '@ui/Assets/AssetDetailsView.vue';
import AssetCarbonFootprint from '@ui/Assets/AssetCarbonFootprint.vue';
import FileAttach from '@ui/File/FileAttach.vue';
import {detachFile, getFileDownloadUrl} from '@/api';
import FileData = App.Data.FileData;
import useUserStore from '@/stores/auth';
import {EllipsisHorizontalIcon} from '@modules/@heroicons/vue/24/solid';
import {isFileImage} from '@/utils/isFileImage';
import useI18n from '@/utils/i18n';
import DataLoader from '@ui/DataLoader.vue';
import AssetFilesRequestData = App.Data.AssetFilesRequestData;
import {formatCurrency} from "../../utils/readableValues";
import Tracking from "@ui/Tracking.vue";

interface ItemType {
  [key: number]: any;
}

export default defineComponent({
  components: {
    Tracking,
    DataLoader,
    EllipsisHorizontalIcon,
    FileAttach,
    AssetCarbonFootprint,
    AssetDetailsView,
    MountedTeleport,
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
    Paginator,
    PencilSquareIcon,
    TrashIcon,
    ZTable,
    ZTableHeader,
    ZTableHead,
    ZTableRow,
    ZTableBody,
    ZTableCell,
    PrettyStatus,
    TagLabel,
    ImagePreview,
    AttributeValue,
    InformationCircleIcon,
    DropdownMenuSeparator,
    DropdownMenuItem,
    DropdownMenuGroup,
    DropdownMenuContent,
    DropdownMenuTrigger,
    IconAlert,
    DropdownMenu,
    CogIcon,
    ChevronDownIcon,
    ZButton,
  },

  props: {
    assetId: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
      navigation: inject('navigation') as Navigation,
    };
  },

  data() {
    return {
      asset: {} as AssetData,
      isImageDialogOpen: false,
      imageURls: [] as string[],
      editMode: false as boolean,
      onSave: false as boolean,
      categoryAttributes: {} as any,
      categoryAttributeValues: {} as ItemType,
      categoryAttributeOldValues: {} as ItemType,
      assetNotes: {} as DataSet<AssetNotesData, AssetNotesRequestData>,
      assetInventories: {} as DataSet<AssetInventoryData, InventoryFilterRequestData>,
      assetContracts: {} as DataSet<AssetContractData, ContractRequestData>,
      assetFiles: {} as DataSet<FileData, AssetFilesRequestData>,
      currentTab: 'details',
      loader: null as any,
    };
  },

  computed: {
    access: () => useAccessStore(),
    user: () => useUserStore(),
    canSaveCategoryAttributes() {
      for (const index in this.categoryAttributes) {
        const attribute = this.categoryAttributes[index];
        if (attribute.validation_rule == 'required') {
          if (
            this.categoryAttributeValues[attribute.id] == undefined ||
            this.categoryAttributeValues[attribute.id] === ''
          )
            return false;
        }
      }
      return true;
    },
  },

  watch: {
    currentTab() {
      this.$router.push({hash: '#' + this.currentTab});
    },

    async $route(to: any) {
      if (to.name == ROUTE.ASSET_DETAILS) {
        try {
          await this.getAssetData();
          await this.getAssetNotesData();
          await this.getAssetInventoriesData();
          await this.getAssetContractsData();
          await this.getAssetFilesData();
          const categoryAttributesResponse = await getAllCategoryAttributes(
            this.asset.category_id!.toString(),
          ).execute();
          this.categoryAttributes = categoryAttributesResponse.data;
        } catch (error: any) {
          throw error;
        }
      }
    },
  },

  async mounted() {
    try {
      this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;
      if (this.$route.hash) {
        this.currentTab = this.$route.hash.replace('#', '');
      }
      await this.getAssetData();
      await this.getAssetNotesData();
      await this.getAssetInventoriesData();
      await this.getAssetContractsData();
      await this.getAssetFilesData();
      const categoryAttributesResponse = await getAllCategoryAttributes(this.asset.category_id!.toString()).execute();
      this.categoryAttributes = categoryAttributesResponse.data;
    } catch (error: any) {
      throw error;
    }
  },

  methods: {
    formatCurrency,
    isFileImage,
    getFileDownloadUrl,
    async getAssetData() {
      this.asset = (await getAsset(this.assetId).withLoader(this.loader).execute()).data;
      (this.navigation as Navigation).setPanelTitle(this.asset?.assetModel?.name + (this.asset?.assetModel?.name && this.asset?.assetModel?.code ? ' / ' : '') + this.asset?.assetModel?.code);

      if (this.asset) {
        this.categoryAttributeValues = {};

        for (let value of this.asset.assetValues!) {
          if (value.category_attribute_id === null) continue;
          this.categoryAttributeValues[value.category_attribute_id] = this.displayAttributeValue(value);
        }
      }

      this.imageURls = [];
      this.asset?.images?.forEach((image: any) => {
        this.imageURls.push(image!.sourceUrl);
      });
      if (this.imageURls.length == 0 && this.asset?.assetModel?.images?.length > 0) {
        this.asset?.assetModel?.images?.forEach((image: any) => {
          this.imageURls.push(image!.sourceUrl);
        });
      }
    },

    async getAssetNotesData() {
      this.assetNotes = new DataSet<AssetNotesData, AssetNotesRequestData>((query: string) =>
        getAssetNotes({perPage: 5}, this.assetId).execute(query),
      ).setParams({perPage: 5});
      await this.assetNotes.load();
    },

    async getAssetInventoriesData() {
      if (!this.access.hasFeature(this.SYMBOL.FEATURES.INVENTORY)) return;

      this.assetInventories = new DataSet<AssetInventoryData, InventoryFilterRequestData>((query: string) =>
        getAssetInventories({perPage: 5}, this.assetId).execute(query),
      ).setParams({perPage: 5});
      await this.assetInventories.load();
    },

    async getAssetContractsData() {
      this.assetContracts = new DataSet<AssetContractData, ContractRequestData>((query: string) =>
        getAssetContracts({perPage: 5}, this.assetId).execute(query),
      ).setParams({perPage: 5});
      await this.assetContracts.load();
    },

    async getAssetFilesData() {
      if (!this.access.hasFeature(this.SYMBOL.FEATURES.DOCUMENTS)) return;

      this.assetFiles = new DataSet<FileData, AssetFilesRequestData>((query: string) =>
        getAssetFiles({perPage: 15}, this.assetId).execute(query),
      ).setParams({perPage: 15});
      await this.assetFiles.load();
    },

    async editAssetData() {
      if (!this.asset) {
        return;
      }

      this.$router.push({name: ROUTE.ASSET_DETAILS_EDIT, params: {assetId: this.asset.id}});
    },

    async editAssetCategoryAndModel() {
      if (!this.asset) {
        return;
      }

      this.$router.push({name: ROUTE.ASSET_CATEGORY_AND_MODEL_EDIT, params: {assetId: this.asset.id}});
    },

    async editAssetImages() {
      if (!this.asset) {
        return;
      }

      this.$router.push({name: ROUTE.ASSET_DETAILS_EDIT_IMAGES, params: {assetId: this.asset.id}});
    },

    async assignTagsToAsset() {
      if (!this.asset) {
        return;
      }

      this.$router.push({name: ROUTE.ASSET_ASSIGN_TAGS, params: {assetId: this.asset.id}});
    },

    async createNote() {
      if (!this.asset) {
        return;
      }

      this.$router.push({name: ROUTE.ASSET_ADD_NOTE, params: {assetId: this.asset.id}});
    },

    async attachFile() {
      if (!this.asset) {
        return;
      }
      (this.$refs['attachFileDialog'] as typeof FileAttach).show();
    },

    async refreshAssetFiles() {
      this.toast({
        title: this.$t('common.success.header'),
        description: this.$t('files.message.added'),
      });
      await this.getAssetData();
      await this.getAssetFilesData();
    },

    async editNote(noteId: number) {
      if (!this.asset) {
        return;
      }

      this.$router.push({name: ROUTE.ASSET_EDIT_NOTE, params: {assetId: this.asset.id, noteId: noteId}});
    },

    async deleteNote(noteId: number) {
      this.dialogs
        .confirm(this.$t('asset.label.deleting_note'), this.$t('asset.message.deleting_note_question'))
        .then(() => {
          if (!this.asset) {
            return;
          }

          deleteAssetNote(this.asset.id!.toString(), noteId.toString())
            .execute()
            .then(() => {
              this.toast({
                title: this.$t('toasts.type.success'),
                description: this.$t('asset.message.asset_deleted'),
              });
              this.getAssetNotesData();
            });
        });
    },

    async duplicateAsset() {
      if (!this.asset) {
        return;
      }

      this.$router.push({name: ROUTE.ASSET_DUPLICATE, params: {assetId: this.asset.id}});
    },

    previewImages() {
      if (this.imageURls.length == 0) return;
      this.isImageDialogOpen = !this.isImageDialogOpen;
    },

    async requestAssetDelete() {
      this.dialogs
        .confirm(this.$t('asset.label.deleting_asset'), this.$t('asset.message.deleting_asset_question'))
        .then(() => {
          if (!this.asset) {
            return;
          }

          deleteAsset(this.asset.id!)
            .execute()
            .then(() => {
              this.toast({
                title: this.$t('toasts.type.success'),
                description: this.$t('asset.message.asset_deleted'),
              });
            });
          this.$router.replace({name: ROUTE.ASSET_LIST});
        });
    },

    displayAttributeValue(attribute: AssetValueData) {
      if (attribute.val_float) return attribute.val_float.toString();
      if (attribute.val_string) return attribute.val_string;
      if (attribute.val_date) return attribute.val_date;
      if (attribute.val_int) return attribute.val_int.toString();
      return '';
    },

    editAssetAttributes() {
      if (!this.asset) {
        return;
      }
      this.$router.push({name: ROUTE.ASSET_DETAILS_EDIT_ATTRIBUTES, params: {assetId: this.asset.id}});
    },

    updateAssetStatus() {
      if (!this.asset) {
        return;
      }
      this.$router.push({name: ROUTE.ASSET_DETAILS_UPDATE_STATUS, params: {assetId: this.asset.id}});
    },

    saveCategoryAttributes(action: any) {
      let fields = [] as AssetValueData[];

      for (const index in this.categoryAttributes) {
        const attribute = this.categoryAttributes[index];
        let val_string = null as string | null;
        let val_int = null as number | null;
        let val_float = null as number | null;
        let val_date = null as string | null;

        const value = this.categoryAttributeValues[attribute.id];

        switch (attribute.type) {
          case 'TEXT':
          case 'SELECT':
          case 'MULTISELECT':
            val_string = value;
            break;

          case 'NUMBER':
            val_float = parseFloat(value);
            break;
          case 'CHECKBOX':
            val_int = parseInt(value);
            break;
          case 'DATE':
            val_date = value;
            break;
        }

        fields.push({
          category_attribute_id: attribute.id,
          name: attribute.name,
          val_string: val_string,
          val_int: val_int,
          val_float: val_float,
          val_date: val_date,
        });
      }

      saveAssetAttributes(this.assetId, fields)
        .withLoader(action.loader)
        .execute('?mode=category')
        .then(() => {
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('asset.message.attributes_saved'),
          });
          this.editMode = false;
          this.getAssetData();
        });
    },

    setEditMode(mode: boolean, cancelChanges: boolean = false) {
      this.editMode = mode;
      if (mode) {
        this.categoryAttributeOldValues = {...this.categoryAttributeValues};
      } else if (cancelChanges) {
        this.categoryAttributeValues = {...this.categoryAttributeOldValues};
      }
    },

    inventoryDetails(id: number) {
      this.$router.push({name: ROUTE.INVENTORY_DETAILS, params: {inventoryId: id}});
    },

    contractDetails(id: number) {
      this.$router.push({name: ROUTE.CONTRACT_DETAILS, params: {contractId: id}});
    },

    detachFile(id: number, relationId: number) {
      this.dialogs
        .confirm(this.$t('files.label.detach_confirm'), this.$t('files.label.detach_confirm_text'))
        .then(() => {
          detachFile({destinationId: this.asset!.id!, destinationType: 'asset', fileId: id, relationId: relationId})
            .execute()
            .then(async () => {
              this.toast({
                title: this.$t('toasts.type.success'),
                description: this.$t('files.message.detached'),
              });
              await this.getAssetData();
              await this.getAssetFilesData();
            })
            .catch(() => {
              this.toast({
                title: useI18n.global.t('common.errors.header'),
                description: useI18n.global.t('common.errors.unexpected'),
                variant: 'destructive',
              });
            });
        });
    },
  },
});
</script>
<style>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
