import { http } from './http';
import { ApiCall } from '@/api/ApiCall';
import {FilterParam, PaginatedResponse} from '@/utils/DataSet';
import { KeyValue } from '@/types/Common';
import NewRecordResponse = App.Data.NewRecordResponse;
import ContractData = App.Data.ContractData;
import ContractRequestData = App.Data.ContractRequestData;
import ContractDataStoreRequest = App.Data.ContractDataStoreRequest;
import SavedRecordResponse = App.Data.SavedRecordResponse;
import ContractDataStatusUpdateRequest = App.Data.ContractDataStatusUpdateRequest;
import CarbonValueRequest = App.Data.CarbonValueRequest;
import ContractChangeData = App.Data.ContractChangeData;
import ContractChangeRequest = App.Data.ContractChangeRequest;
import SuggestedCarbonValueData = App.Data.SuggestedCarbonValueData;

export const getContracts = (fields: ContractRequestData) => {
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<ContractData>>(`contracts` + query);
    },
    {},
    '',
    fields,
  );
};

export const getCuratedOrganizationsContracts = () => {
  return ApiCall.create(
    (options, query) => {
      return http.get(`curatedOrganizationsContracts` + query);
    },
    {},
    '',
  );
};

export const getAllOrganizationsContracts = () => {
  return ApiCall.create(
    (options, query) => {
      return http.get(`allOrganizationsContracts` + query);
    },
    {},
    '',
  );
};

export const getContract = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get<ContractData>(`/contracts/${options.id}`);
  }, options);
};

export const createContract = (fields: ContractRequestData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<NewRecordResponse>('/contracts', fields);
    },
    {},
    '',
    fields,
  );
};

export const updateContract = (id: string, fields: ContractDataStoreRequest) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(`/contracts/${options.id}`, fields);
    },
    options,
    '',
    fields,
  );
};

export const toggleContractStatus = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.patch<SavedRecordResponse>(`/contracts/${options.id}/toggleContractStatus`);
  }, options);
};

export const deleteContract = (id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  return ApiCall.create(options => {
    return http.delete(`/contracts/${options.id}`);
  }, options);
};

// admin

export const getManagedContracts = (fields?: Array<FilterParam>) => {
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<ContractData>>(`managed/contracts` + query);
    },
    {},
    '',
    fields,
  );
};

export const getManagedContract = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get<ContractData>(`/managed/contracts/${options.id}`);
  }, options);
};

export const processContract = (id: string, status: string, fields: ContractDataStatusUpdateRequest) => {
  const options = {} as KeyValue;

  options.id = id;

  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<ContractDataStatusUpdateRequest>(`/managed/contracts/${options.id}`, fields);
    },
    options,
    '',
    fields,
  );
};

export const getContractChanges = (contractId: string) => {
  const options = {} as KeyValue;
  options.contractId = contractId;

  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<ContractChangeData>>(
        `/managed/contracts/${options.contractId}/changes` + query,
      );
    },
    options,
    '',
  );
};

export const createContractChange = (contractId: string, fields: ContractChangeRequest) => {
  const options = {} as KeyValue;
  options.contractId = contractId;

  return ApiCall.create(
    (options, query, fields) => {
      return http.post<ContractChangeRequest>(`/managed/contracts/${options.contractId}/changes`, fields);
    },
    options,
    '',
    fields,
  );
};

export const updateContractChange = (id: string, contractId: string, fields: ContractChangeRequest) => {
  const options = {} as KeyValue;
  options.id = id;
  options.id = contractId;

  return ApiCall.create(
    (options, query, fields) => {
      return http.post<ContractChangeRequest>(`/managed/contracts/${options.contractId}/changes/${options.id}`, fields);
    },
    options,
    '',
    fields,
  );
};

export const deleteContractChange = (id: string, contractId: string) => {
  const options = {} as KeyValue;
  options.id = id;
  options.id = contractId;

  return ApiCall.create(options => {
    return http.delete<ContractChangeRequest>(`/managed/contracts/${options.contractId}/changes/${options.id}`);
  }, options);
};

export const getSuggestedCarbonValue = (contractId: string, contractedAssetId: string) => {
  const options = {} as KeyValue;
  options.contractId = contractId;
  options.contractedAssetId = contractedAssetId;

  return ApiCall.create(options => {
    return http.get<SuggestedCarbonValueData>(
      `/managed/contracts/${options.contractId}/contractedAssets/${options.contractedAssetId}/suggest`,
    );
  }, options);
};

export const createCarbonValueForContract = (contractId: string, contractedAssetId: string, value: number) => {
  const options = {} as KeyValue;
  options.contractId = contractId;
  options.contractedAssetId = contractedAssetId;

  const fields = {
    carbon_value: value,
  } as CarbonValueRequest;

  return ApiCall.create(
    (options, query, fields) => {
      return http.post<ContractChangeRequest>(
        `/managed/contracts/${options.contractId}/contractedAssets/${options.contractedAssetId}/carbon`,
        fields,
      );
    },
    options,
    '',
    fields,
  );
};

export const downloadActionAssetsCSV = (contractId: number, actionId: number) => {
  http
    .get(`/managed/contracts/${contractId}/contractedActions/${actionId}/export.xlsx`, {
      responseType: 'blob',
    })
    .then(response => {
      const blob = new Blob([response.data], {
          type: 'text/xlsx',
        }),
        url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = response.headers['x-filename'];
      downloadLink.click();
    });
};
