import { acceptHMRUpdate, defineStore } from 'pinia';
import Cookies from 'js-cookie';
import { http, getCurrentUser, logoutTemporaryUser, getCurrentUserAccess } from '@/api';
import router from '../router';
import loaderStore from './loader';
import { ROUTE } from '@/router/routeNames';
import { AUTH_COOKIE_KEY } from '@/consts';
import UserData = App.Data.UserData;
import i18n from '../utils/i18n';
import { getOrganizationBranches, getOrganizations } from '@/api/organization';

const useUserStore = defineStore('user', {
  state: () => ({
    user: null as App.Data.UserData | null,
    accountAccess: null as App.Data.AccountAccess | null,
    loggedIn: false,
    savedCredentials: null as string | null,
    chosenBranch: null as number | null,
    chosenOrganization: null as number | null,
  }),
  actions: {
    saveToken(token: string) {
      http.defaults.headers.Authorization = 'Bearer ' + token;
      Cookies.set(AUTH_COOKIE_KEY, token);
    },
    setOrganizationHeader(organization_id: number | null, branch_id: number | null) {
      http.defaults.headers['Organization-Id'] = organization_id;
      http.defaults.headers['Branch-Id'] = branch_id;
    },
    async setDefaultOrganization() {
      try {
        await getOrganizations()
          .execute()
          .then(response => {
            this.chosenOrganization = response.data.data[0].id!;
            localStorage.setItem('chosenOrganizationId', String(this.chosenOrganization));
          });
      } catch (error: any) {}
    },

    async setDefaultBranch() {
      try {
        await getOrganizationBranches(this.chosenOrganization!)
          .execute()
          .then(response => {
            this.chosenBranch =
              response.data?.data && response.data?.data.length && response.data?.data[0].id
                ? response.data?.data[0].id
                : null;
            localStorage.setItem('chosenBranchId', String(this.chosenBranch));
          });
      } catch (error: any) {}
    },

    saveChosenBranch(BranchId: number) {
      this.chosenBranch = BranchId;
      localStorage.setItem('chosenBranchId', String(this.chosenBranch));
      this.setOrganizationHeader(this.chosenOrganization, BranchId);
      router.replace({ name: ROUTE.DASHBOARD }).then(() => router.go(0));
    },

    saveChosenOrganization(organizationId: number) {
      this.chosenOrganization = organizationId;
      localStorage.setItem('chosenOrganizationId', String(this.chosenOrganization));
      this.setOrganizationHeader(organizationId, this.chosenBranch);
    },

    resetDefaults() {
      localStorage.removeItem('chosenOrganizationId');
      localStorage.removeItem('chosenBranchId');
      this.chosenBranch = null;
      this.chosenOrganization = null;
    },

    async loadDefaults(reset: boolean = false) {
      if (!reset) {
        this.chosenBranch = parseInt(localStorage.getItem('chosenBranchId')!);
        this.chosenOrganization = parseInt(localStorage.getItem('chosenOrganizationId')!);
      } else {
        this.chosenBranch = null;
        this.chosenOrganization = null;
      }
      if (!this.chosenOrganization) {
        if ((this.user && !this.user.organizations) || (this.user && this.user.organizations && this.user.organizations.length == 0)) {
          await this.setDefaultOrganization();
        } else {
          this.chosenOrganization = this.user?.organizations[0].id;
        }
      }
      if (!this.chosenBranch) await this.setDefaultBranch();
      this.setOrganizationHeader(this.chosenOrganization, this.chosenBranch);
    },

    async initAuth() {
      const token = Cookies.get(AUTH_COOKIE_KEY);
      if (token) {
        http.defaults.headers.Authorization = 'Bearer ' + token;
        const response: any = await getCurrentUser();
        this.user = response.data;
        await this.loadDefaults();
        await this.loadPermissions();
        this.loggedIn = true;
        i18n.global.locale = response.data.language_code;
        router.push({ name: ROUTE.DASHBOARD });
      } else {
        router.push({ name: ROUTE.LOGIN });
      }

      const savedCredentials = localStorage.getItem('savedCredentials');
      if (savedCredentials) this.savedCredentials = savedCredentials;
    },

    async loadPermissions() {
      try {
        const response: any = await getCurrentUserAccess();
        this.accountAccess = response.data;
      } catch (error: any) {}
    },

    async loginUser(user: App.Data.UserData, token: string) {
      this.saveToken(token);
      this.user = user;
      await this.loadPermissions();
      await this.loadDefaults(true);
      this.loggedIn = true;
      i18n.global.locale = <any>user.language_code;
      if (user.password_reset_required) {
        router.push({ name: ROUTE.CHANGE_PASSWORD });
      } else {
        router.push({ name: ROUTE.DASHBOARD });
      }
    },

    async logoutUser() {
      const { startLoading, stopLoading } = loaderStore();
      startLoading();
      delete http.defaults.headers.Authorization;
      Cookies.remove(AUTH_COOKIE_KEY);
      this.user = null;
      this.loggedIn = false;
      router.push({ name: ROUTE.LOGIN });
      stopLoading();
      localStorage.removeItem('chosenOrganizationId');
      localStorage.removeItem('chosenBranchId');
      localStorage.removeItem('savedCredentials');
    },
    updateUser(user: App.Data.UserData) {
      this.user = user;
    },

    saveOriginalCredentials(user: UserData, token: string) {
      this.savedCredentials = JSON.stringify({ user: user, token: token });
      localStorage.setItem('savedCredentials', String(this.savedCredentials));
    },

    swapToOriginalCredentials() {
      const saved = JSON.parse(this.savedCredentials!) as any;
      const cookie: string | undefined = Cookies.get(AUTH_COOKIE_KEY);
      if (cookie) {
        logoutTemporaryUser(cookie, saved.token).execute();
        this.loginUser(saved.user, saved.token);
        this.savedCredentials = null;
        localStorage.removeItem('savedCredentials');
      }
    },

    hasStoredOriginalCredentials() {
      return this.savedCredentials != null;
    },
  },
});
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
export default useUserStore;
