<script setup lang="ts">
import { ArrowLeftIcon } from '@heroicons/vue/24/outline';
import { useCarousel } from './useCarousel';
import type { WithClassAsProps } from './interface';
import { cn } from '@shadcn/lib/utils';
import { ZButton } from '@shadcn/components/ui/button';

const props = defineProps<WithClassAsProps>();

const { orientation, canScrollPrev, scrollPrev } = useCarousel();
</script>

<template>
  <ZButton
    :disabled="!canScrollPrev"
    :class="
      cn(
        'touch-manipulation absolute h-8 w-8 rounded-full p-0',
        orientation === 'horizontal'
          ? '-left-12 top-1/2 -translate-y-1/2'
          : '-top-12 left-1/2 -translate-x-1/2 rotate-90',
        props.class,
      )
    "
    variant="outline"
    @click="scrollPrev"
  >
    <slot>
      <ArrowLeftIcon class="h-4 w-4 text-current" />
    </slot>
  </ZButton>
</template>
