<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('asset.label.duplicate_asset') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('asset.message.edit_asset_data') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded' && !duplicatedRecords">
        <div>
          <FormValidator ref="form" class="grid grid-cols-4 gap-4 py-4">
            <Field symbol="duplicates" class="col-span-4">
              <ZLabel for="duplicates">
                {{ $t('asset.label.duplicates_count') }}
              </ZLabel>
              <ZInput id="duplicates" v-model="duplicates" min="1" type="number" />
            </Field>
          </FormValidator>
        </div>
        <ZDialogFooter>
          <Action :disabled="!duplicates || duplicates <= 0" @proceed="duplicateAssetRequest">{{
            $t('asset.command.duplicate')
          }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'loaded' && duplicatedRecords">
        <div>
          <ZTable class="w-full">
            <ZTableHeader>
              <ZTableRow>
                <ZTableHead>{{ $t('asset.label.name') }}</ZTableHead>
                <ZTableHead>{{ $t('asset.label.inventory_number') }}</ZTableHead>
                <ZTableHead></ZTableHead>
              </ZTableRow>
            </ZTableHeader>
            <ZTableBody>
              <ZTableRow v-for="(item, index) in duplicatedRecords" :key="index" class="cursor-pointer">
                <ZTableCell>{{ item.name }}</ZTableCell>
                <ZTableCell>{{ item.asset_number }}</ZTableCell>
                <ZTableCell>
                  <router-link :to="{ name: ROUTE.ASSET_DETAILS, params: { assetId: item.id } }">
                    <ZButton variant="outline" class="w-full mt-4 md:mt-0 md:w-auto">{{
                      $t('notification.button')
                    }}</ZButton>
                  </router-link></ZTableCell
                >
              </ZTableRow>
            </ZTableBody>
          </ZTable>
        </div>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import Field from '@ui/FormField.vue';
import { ZInput } from '@shadcn/components/ui/input';
import { ZLabel } from '@shadcn/components/ui/label';
import { duplicateAsset } from '@/api/asset';
import { ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow } from '@shadcn/components/ui/table';
import { ZButton } from '@shadcn/components/ui/button';
import DuplicatedData = App.Data.DuplicatedData;

export default defineComponent({
  components: {
    ZButton,
    ZTableHeader,
    ZTable,
    ZTableRow,
    ZTableHead,
    ZTableBody,
    ZTableCell,
    ZLabel,
    ZInput,
    Field,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    assetId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      state: 'loading' as ComponentLoadState,
      duplicates: 1 as number,
      duplicatedRecords: null as Array<DuplicatedData> | null,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
      throw error;
    }
  },

  methods: {
    async duplicateAssetRequest(action: any) {
      await duplicateAsset(this.assetId, this.duplicates!)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(response => {
          this.duplicatedRecords = response.data.duplicated_records;
        });
    },
  },
});
</script>
