<template>
  <div>
    <div class="flex max-w-[80rem] w-full min-h-[400px] m-auto bg-white md:rounded-xl overflow-hidden mb-20">
      <div class="hidden md:block md:w-1/2 relative">
        <img
          class="absolute object-cover w-full h-full right-0 bottom-0 top-0 left-0"
          src="@pub/images/register-background.jpg"
        />
      </div>
      <div class="w-full md:w-1/2 pt-10 p-8 md:p-16 flex justify-center items-center">
        <div class="w-full max-w-[350px]">
          <div class="flex flex-col gap-4">
            <h3 class="text-2xl font-semibold text-center">{{ $t('auth.resetPasswordForm.header') }}</h3>
            <h3 class="mb-5 text-center">{{ $t('auth.resetPasswordForm.text') }}</h3>
            <FormValidator :errors="errors">
              <div class="flex flex-col gap-4">
                <FormField symbol="email">
                  <ZLabel>{{ $t('auth.login.form.email') }}</ZLabel>
                  <ZInput v-model="form.email" type="email" />
                </FormField>
                <FormField symbol="password">
                  <ZLabel>{{ $t('auth.login.form.password') }}</ZLabel>
                  <ZInput v-model="form.password" type="password" />
                </FormField>
                <FormField symbol="password_confirmation">
                  <ZLabel>{{ $t('auth.registerOrganization.form.password_confirm') }}</ZLabel>
                  <ZInput v-model="form.password_confirmation" type="password" />
                </FormField>
                <ZButton @click.prevent="reset()">{{ $t('auth.resetPasswordForm.button') }}</ZButton>
              </div>
            </FormValidator>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { postResetPassword } from '../../api';
import loaderStore from '../../stores/loader';
import router from '../../router';
import { ZInput } from '@shadcn/components/ui/input';
import { ZButton } from '@shadcn/components/ui/button';
import { ZLabel } from '@shadcn/components/ui/label';
import { useToast } from '@shadcn/components/ui/toast/use-toast';
import FormValidator from '../../components/FormValidator.vue';
import FormField from '../../components/FormField.vue';
import { ROUTE } from '@/router/routeNames';
import { HttpStatusCode } from 'axios';
import { ValidationErrors } from '../../types/ValidationErrors';

export default defineComponent({
  components: {
    ZInput,
    ZButton,
    ZLabel,
    FormValidator,
    FormField,
  },

  props: {
    token: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      errors: null as null | ValidationErrors,
      form: {
        email: '',
        password: '',
        password_confirmation: '',
        token: this.token,
      } as App.Data.ResetPasswordRequestData,
    };
  },

  methods: {
    reset() {
      const { toast } = useToast();
      const { startLoading, stopLoading } = loaderStore();
      startLoading();
      postResetPassword(this.form)
        .then(() => {
          toast({
            title: this.$t('common.success.header'),
            description: this.$t('auth.resetPasswordForm.success'),
          });
          router.push({ name: ROUTE.LOGIN });
        })
        .catch(error => {
          if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
            this.errors = error.response.data.errors;
          }
        })
        .finally(() => stopLoading());
    },
  },
});
</script>
