<template>
  <Field v-if="!withinOrganization" symbol="organization_id" class="pt-4">
    <ZLabel for="organization">
      {{ $t('asset.label.organization') }}
    </ZLabel>
    <SelectField id="organization" v-model="localSelectedOrganizationId" :options="organizations" searchable sorted />
  </Field>
  <div>
    <Field v-if="!areBranchesEmpty" symbol="branch_id" class="pt-4">
      <ZLabel for="branch_id">
        {{ $t('asset.label.branch') }}
      </ZLabel>
      <SelectField
        id="branch_id"
        v-model="localSelectedBranchId"
        :options="branches"
        :disabled="!localSelectedOrganizationId"
        searchable
        sorted
        format="rich"
      />
    </Field>
    <div v-else>
      <IconAlert class="mt-2" type="warning">
        <div class="flex flex-row justify-between items-center">
          <span>{{ $t('asset.message.no_branches_available') }}</span>
        </div>
      </IconAlert>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';
import SelectField from '@ui/SelectField.vue';
import {getOrganizationBranches, getOrganizationsCompact} from '@/api';
import { getBranches } from '@/api/organization';
import type { KeyValue } from '@/types/Common';
import OrganizationCompactData = App.Data.OrganizationCompactData;
import BranchData = App.Data.BranchData;
import IconAlert from '@ui/IconAlert.vue';

export default defineComponent({
  components: {
    IconAlert,
    SelectField,
    ZLabel,
    Field,
  },

  props: {
    selectedBranchId: {
      type: String,
      required: true,
    },

    selectedOrganizationId: {
      type: String,
      required: true,
    },

    withinOrganization: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['update:selectedOrganizationId', 'update:selectedBranchId'],

  data() {
    return {
      organizations: {} as KeyValue,
      branches: {} as KeyValue,
      localSelectedOrganizationId: this.selectedOrganizationId,
      localSelectedBranchId: this.selectedBranchId,
      areBranchesEmpty: false,
    };
  },

  watch: {
    selectedOrganizationId(newVal: string) {
      this.localSelectedOrganizationId = newVal;
      this.getBranches(newVal);
    },

    localSelectedOrganizationId(newVal: string) {
      this.$emit('update:selectedOrganizationId', newVal);
    },

    localSelectedBranchId(newVal: string) {
      this.$emit('update:selectedBranchId', newVal);
    },
  },

  mounted() {
    if (!this.withinOrganization) {
      this.getOrganizations();
    } else {
      this.getBranches(this.selectedOrganizationId);
    }
  },

  methods: {
    async getOrganizations() {
      const organizationsResponse = await getOrganizationsCompact().execute();
      this.organizations = organizationsResponse.data.reduce(
        (accumulator: KeyValue, value: OrganizationCompactData) => {
          return { ...accumulator, [value.id]: value.name };
        },
        {},
      );
    },

    branchPatch(branch: BranchData) {
      let path = '';

      for(let index in branch.parents) {
        path = branch.parents[index].name + ' / ' + path;
      }

      return path;
    },

    async getBranches(selectedOrganizationId: string): Promise<void> {
      const branchesResponse = await getOrganizationBranches(selectedOrganizationId).execute('?_all=true');
      let order = 0 as number;

      this.branches = branchesResponse.data.reduce<KeyValue>((accumulator: KeyValue, branch: BranchData) => {
        return { ...accumulator, [String(branch.id)]: JSON.stringify({
            'keywords': branch.name,
            'label': (order ++).toString(),
            'html': `<div class="flex flex-col">
                        <small>${ this.branchPatch(branch) }</small>
                        <b class="font-bold">${branch.name}</b>
                     </div>
                    `
          }) };

      }, {});
      this.areBranchesEmpty = Object.keys(this.branches).length === 0;
    },
  },
});
</script>
