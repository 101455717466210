<template>
  <DataLoader ref="loader">
    <div class="md:flex md:flex-row">
      <div class="md:w-1/4 mt-4">
        <div class="grid grid-cols-2">
          <div class="i-block">
            <div class="i-label">{{ $t('dictionary.label.country_code') }}</div>
            <div class="i-value">{{ country.code }}</div>
          </div>
          <div class="i-block">
            <div class="i-label">{{ $t('dictionary.label.country_name') }}</div>
            <div class="i-value flex flex-row gap-2">
              {{ country.translations![0]!.translation }}
              <TranslationPopup
                type="country"
                :identifier="country.id!"
                exclude=""
                @updated="translationUpdated"
              ></TranslationPopup>
            </div>
          </div>
          <div class="i-block">
            <div class="i-label">{{ $t('dictionary.label.organization_count') }}</div>
            <div class="i-value flex flex-row gap-2">{{ country.organization_count }}</div>
          </div>
          <div class="i-block">
            <div class="i-label">{{ $t('dictionary.label.region_count') }}</div>
            <div class="i-value flex flex-row gap-2">{{ country.region_count }}</div>
          </div>
        </div>
        <IconAlert v-if="!country.can_be_deleted" class="my-4">
          {{ $t('dictionary.message.country_cant_be_deleted') }}
        </IconAlert>
        <Action
          :disabled="!country.can_be_deleted"
          class="w-full mb-4 flex flex-row gap-2 bg-red-600"
          @click="removeCountry()"
          ><TrashIcon class="icon sm" /> {{ $t('dictionary.command.country_remove') }}</Action
        >
      </div>
      <div class="md:w-3/4 border rounded border-green-600 p-4 mb-4 md:ml-12">
        <div class="flex flex-row gap-2 items-center justify-between pb-6">
          <h3 class="text-xl">{{ $t('dictionary.label.regions') }}</h3>

          <router-link :to="{ name: ROUTE.DICTIONARY.REGION_ADD }">
            <ZButton class="gap-2 w-full md:flex">
              {{ $t('dictionary.command.region_add') }}
              <PlusCircleIcon class="icon md" />
            </ZButton>
          </router-link>
        </div>

        <ZTable class="w-full">
          <ZTableHeader>
            <ZTableRow>
              <ZTableHead>{{ $t('dictionary.label.region_name') }}</ZTableHead>
              <ZTableHead></ZTableHead>
              <ZTableHead class="text-center">{{ $t('dictionary.label.region_code') }}</ZTableHead>
              <ZTableHead class="text-center">{{ $t('dictionary.label.organization_count') }}</ZTableHead>
              <ZTableHead></ZTableHead>
            </ZTableRow>
          </ZTableHeader>
          <ZTableBody>
            <ZTableRow v-for="(item, index) in regionsDataSet.data" :key="index">
              <ZTableCell>
                {{ item!.translations![0]!.translation }}
              </ZTableCell>
              <ZTableCell class="w-[80px]">
                <TranslationPopup
                  type="region"
                  :identifier="item.id!"
                  exclude=""
                  @updated="translationUpdated"
                ></TranslationPopup>
              </ZTableCell>
              <ZTableCell class="text-center">{{ item.code }}</ZTableCell>
              <ZTableCell class="text-center">{{ item.organization_count }}</ZTableCell>
              <ZTableCell>
                <a v-if="item.can_be_deleted" href="#" @click="removeRegion(item)"><TrashIcon class="icon sm" /></a>
              </ZTableCell>
            </ZTableRow>
          </ZTableBody>
        </ZTable>
        <Paginator :data="regionsDataSet"></Paginator>
      </div>
    </div>
    <router-view></router-view>
  </DataLoader>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import IconAlert from '@ui/IconAlert.vue';
import CountryData = App.Data.CountryData;
import { getRegionsIndex, getCountry, deleteRegion, deleteCountry } from '@/api/dictionaries';
import DataLoader from '@ui/DataLoader.vue';
import TranslationPopup from '@ui/TranslationPopup.vue';
import { ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow } from '@shadcn/components/ui/table';
import RegionData = App.Data.RegionData;
import { DataSet } from '@/utils/DataSet';
import Paginator from '@ui/Paginator.vue';
import { Dialogs } from '@/types/Dialogs';
import { PlusCircleIcon, TrashIcon } from '@modules/@heroicons/vue/24/outline';
import { ZButton } from '@shadcn/components/ui/button';
import { ROUTE } from '@/router/routeNames';

export default defineComponent({
  components: {
    ZButton,
    PlusCircleIcon,
    TrashIcon,
    Paginator,
    ZTableCell,
    ZTableBody,
    ZTable,
    ZTableRow,
    ZTableHeader,
    ZTableHead,
    TranslationPopup,
    DataLoader,
    IconAlert,
    Action,
  },

  props: {
    countryId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      country: {} as CountryData,
      regionsDataSet: {} as DataSet<RegionData, null>,
      loader: null as any,
    };
  },

  watch: {
    $route(to: any) {
      if (to.name == ROUTE.DICTIONARY.COUNTRY_EDIT) {
        this.regionsDataSet.resetToFirstPage();
      }
    },
  },

  async mounted() {
    try {
      this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;

      const countryResponse = await getCountry(this.countryId).withLoader(this.loader).execute();
      this.country = countryResponse.data;

      this.regionsDataSet = new DataSet<RegionData, null>((query: string) =>
        getRegionsIndex(this.country!.id!.toString()).execute(query),
      );
      await this.regionsDataSet.resetToFirstPage();
      await this.regionsDataSet.load();
    } catch (error: any) {}
  },

  methods: {
    removeRegion(region: RegionData) {
      this.dialogs
        .confirm(this.$t('dictionary.label.region_delete'), this.$t('dictionary.message.region_delete'))
        .then(() => {
          this.dialogs.authorizeAction().then(() => {
            deleteRegion(region.id!.toString())
              .execute()
              .then(() => {
                this.toast({
                  title: this.$t('toasts.type.saved'),
                  description: this.$t('organization.message.organization_saved'),
                });
                this.regionsDataSet.load();
              });
          });
        });
    },

    removeCountry() {
      this.dialogs
        .confirm(this.$t('dictionary.label.country_delete'), this.$t('dictionary.message.country_delete'))
        .then(() => {
          this.dialogs.authorizeAction().then(() => {
            deleteCountry(this.country!.id!.toString())
              .execute()
              .then(() => {
                this.toast({
                  title: this.$t('toasts.type.saved'),
                  description: this.$t('organization.message.organization_saved'),
                });
                this.$router.push({ name: ROUTE.DICTIONARY.COUNTRIES });
              });
          });
        });
    },

    async translationUpdated() {
      const countryResponse = await getCountry(this.countryId).withLoader(this.loader).execute();
      this.country = countryResponse.data;
      await this.regionsDataSet.load();
    },
  },
});
</script>
