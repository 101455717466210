<template>
  <ZDialog v-model:open="isDialogOpen" class="md:max-h-[90vh]">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('contracts.label.add_note') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('contracts.message.add_note') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <div class="lg:max-h-[70vh] lg:overflow-auto">
          <FormValidator ref="form" :errors="errors" class="grid gap-4 py-4">
            <Field symbol="notes" class="col-span-4">
              <ZLabel for="notes">
                {{ $t('contracts.label.notes') }}
              </ZLabel>
              <textarea
                id="name"
                v-model="asset.notes"
                rows="4"
                class="flex w-full resize-none rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
              />
            </Field>
          </FormValidator>
        </div>
        <ZDialogFooter>
          <Action @proceed="update">{{ $t('contracts.command.save_changes') }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import Action from '@ui/Action.vue';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import { inject } from '@modules/vue';
import { HttpStatusCode } from '@modules/axios';
import { ValidationErrors } from '@/types/ValidationErrors';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';
import { addNoteToContractedAsset, getContractedAsset } from '@/api/contractAsset';
import MinimalContractAssetData = App.Data.MinimalContractAssetData;

export default defineComponent({
  components: {
    Field,
    ZLabel,
    ZDialogContent,
    IconAlert,
    ZDialogFooter,
    ZDialogTitle,
    FormValidator,
    Action,
    ZDialogDescription,
    ZDialog,
    ZDialogHeader,
  },

  props: {
    contractId: {
      type: String,
      required: true,
    },

    assetId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      state: 'loading' as ComponentLoadState,
      errors: null as null | ValidationErrors,
      asset: {} as MinimalContractAssetData,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      const response = await getContractedAsset(this.contractId, this.assetId).execute();
      this.asset = response.data;
      this.state = 'loaded';
    } catch (error) {
      this.state = 'error';
    }
  },

  methods: {
    update() {
      addNoteToContractedAsset(this.contractId, this.assetId, { notes: this.asset.notes ?? '' })
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('assetModels.message.model_saved'),
          });
          this.$emit('saved');
        })
        .catch(error => {
          if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
            this.errors = error.response.data.errors;
          }
        });
    },
  },
});
</script>
