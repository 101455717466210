<template>
  <DataLoader ref="loader">
    <ZDialog v-model:open="isDialogOpen">
      <ZDialogContent
        class="lg:max-w-[625px] lg:overflow-y-hidden max-w-[100vw] overflow-y-scroll h-full lg:h-auto flex flex-col">
        <ZDialogHeader>
          <ZDialogTitle>{{ $t('asset.label.create_asset') }}</ZDialogTitle>
          <ZDialogDescription>
            {{ $t('asset.message.create_asset') }}
          </ZDialogDescription>
        </ZDialogHeader>
        <template v-if="categories">
          <FormValidator ref="form">
            <AssetForm :asset="asset" :filters="filters" :categories="categories" :models="assetModelDataSet"/>
          </FormValidator>
          <ZDialogFooter>
            <Action
              v-if="
                assetModelDataSet &&
                assetModelDataSet.data &&
                assetModelDataSet.data.length &&
                this.$refs.loader.state == 'loaded'
              "
              :disabled="!asset.asset_model_id"
              class="w-full"
              @proceed="store"
            >{{ $t('asset.command.add_asset_with_model') }}
            </Action>
            <Action
              v-else-if="assetModelDataSet && assetModelDataSet.data && this.$refs.loader.state == 'loaded'"
              :disabled="!asset.category_id || (!asset.name && !asset.code)"
              @proceed="store"
              class="w-full"
            >{{ $t('asset.command.add_asset_and_create_model') }}
            </Action>
            <Action
              v-else
              :disabled="true"
              class="w-full"
              @proceed="store"
            >...
            </Action>
          </ZDialogFooter>
        </template>
      </ZDialogContent>
    </ZDialog>
  </DataLoader>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import CategoryCompactData = App.Data.CategoryCompactData;
import {createAsset} from '@/api/asset';
import {getAssetModels} from '@/api/assetModel';
import {getCompactCategories} from '@/api/category';
import AssetForm from '@ui/Assets/AssetForm.vue';
import AssetModelData = App.Data.AssetModelData;
import {DataSet} from '@/utils/DataSet';
import AssetModelRequestData = App.Data.AssetModelRequestData;
import AssetDataStoreRequest = App.Data.AssetDataStoreRequest;
import DataLoader from "@ui/DataLoader.vue";

interface filtersInterface {
  search: string;
  page: number;
  page_size: number;
  type: any;
  category_id: number;
  local: number;
}

export default defineComponent({
  components: {
    DataLoader,
    AssetForm,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      loader: null as any,
      asset: {} as AssetDataStoreRequest,
      categories: {} as CategoryCompactData,
      assetModelDataSet: {} as DataSet<AssetModelData, AssetModelRequestData>,
      filters: {
        search: '',
        page: 1,
        page_size: 5,
        category_id: null as null | string,
        type: 'all',
        local: 1,
      },
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },

    'asset.category_id'() {
      if (this.asset.category_id) {
        this.filters.category_id = this.asset.category_id.toString();
        this.getModels();
      }
    },

    'filters.search'() {
      this.getModels();
    },
  },

  mounted() {
    try {
      this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;
      this.getCategories();
      this.assetModelDataSet = new DataSet<AssetModelData, AssetModelRequestData>((query: string) =>
        getAssetModels().withLoader(this.loader).execute(query),
      );
      this.assetModelDataSet.setParams(this.filters);
      this.assetModelDataSet.loadDebounced();
    } catch (error: any) {
      throw error;
    }
  },

  methods: {
    store(action: any) {
      createAsset(this.asset)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(response => {
          this.$router.push({name: this.ROUTE.ASSET_DETAILS, params: {assetId: response.data.new_id}});

          this.toast({
            title: this.$t('toasts.type.success'),
            description: this.$t('asset.message.asset_created'),
          });
          this.$emit('saved');
        });
    },

    async getCategories() {
      const categoriesResponse = await getCompactCategories().withLoader(this.loader).execute();
      this.categories = categoriesResponse.data;
    },

    getModels() {
      const finalFilters: filtersInterface = {
        ...this.filters,
        category_id: isNaN(parseInt(this.filters!.category_id!, 10)) ? '-1': parseInt(this.filters!.category_id!, 10),
      };
      this.assetModelDataSet.setParams(finalFilters);
      this.assetModelDataSet.loadDebounced();
    },
  },
});
</script>
