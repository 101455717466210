<template>
  <ZDialog v-model:open="isDialogOpen" class="md:max-h-[90vh]">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('contracts.label.edit_contract_action') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('contracts.message.edit_contract_action') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <div class="lg:max-h-[70vh] lg:overflow-auto">
          <FormValidator ref="form" :errors="errors" class="grid gap-4 py-4">
            <ManageActionsForm :action="action" :full="full" />
          </FormValidator>
        </div>
        <ZDialogFooter>
          <Action @proceed="update">{{ $t('contracts.command.save_changes') }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import router from '@/router';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import Action from '@ui/Action.vue';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import ManageActionsForm from '@ui/Contracts/ManageActionsForm.vue';
import ContractActionStoreRequest = App.Data.ContractActionStoreRequest;
import ActionData = App.Data.ActionData;
import { getContractedAction, updateContractAction } from '@/api/contractAction';
import { inject } from '@modules/vue';
import { HttpStatusCode } from '@modules/axios';
import { ValidationErrors } from '@/types/ValidationErrors';
import symbolsStore from '@/stores/symbols';

export default defineComponent({
  components: {
    ManageActionsForm,
    ZDialogContent,
    IconAlert,
    ZDialogFooter,
    ZDialogTitle,
    FormValidator,
    Action,
    ZDialogDescription,
    ZDialog,
    ZDialogHeader,
  },

  props: {
    contractId: {
      type: String,
      required: true,
    },

    actionId: {
      type: String,
      required: true,
    },

    full: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      state: 'loading' as ComponentLoadState,
      action: {} as ContractActionStoreRequest,
      actions: [] as Array<ActionData>,
      errors: null as null | ValidationErrors,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },

    'action.action_id'() {
      this.errors = {};
    },
  },

  async mounted() {
    try {
      const symbols = symbolsStore();
      const rawContractActions = await symbols.get('contract_actions');
      this.actions = Object.values(rawContractActions);
      const response = await getContractedAction(this.contractId, this.actionId).execute();
      this.action = {
        id: response.data.id,
        action_id: response.data.action!.id!,
        notes: response.data.notes,
        contractor: response.data.contractor,
      };

      const selectedAction: ActionData =
        this.actions.find((action: ActionData) => action.id == this.action.action_id) ?? ({} as ActionData);
      if (selectedAction) {
        if (selectedAction!.has_location) this.action.location_value = response.data.location_value;
        if (selectedAction!.has_amount) this.action.amount_value = response.data.amount_value;
        if (selectedAction!.has_deadline) this.action.deadline_value = response.data.deadline_value;
        if (selectedAction!.has_checkbox && response.data.checkbox_value !== null)
          this.action.checkbox_value = response.data.checkbox_value;
        if (selectedAction!.has_checkbox && response.data.checkbox_value == null) this.action.checkbox_value = false;
      }
      this.state = 'loaded';
    } catch (error) {
      this.state = 'error';
    }
  },

  methods: {
    update() {
      updateContractAction(this.contractId, this.actionId, this.action)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('assetModels.message.model_saved'),
          });
          this.$emit('saved');
        })
        .catch(error => {
          if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
            this.errors = error.response.data.errors;
          }
        });
    },
  },
});
</script>
