import { ApiCall } from '@/api/ApiCall';
import { http } from '@/api/http';
import { PaginatedResponse } from '@/utils/DataSet';
import AssetModelData = App.Data.AssetModelData;
import AssetModelRequestData = App.Data.AssetModelRequestData;
import { KeyValue } from '@/types/Common';
import NewRecordResponse = App.Data.NewRecordResponse;
import SavedRecordResponse = App.Data.SavedRecordResponse;
import AssetModelStatsData = App.Data.AssetModelStatsData;

export const getAssetModels = (fields: AssetModelRequestData) => {
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<AssetModelData>>(`/assetModels` + query);
    },
    {},
    '',
    fields,
  );
};

export const getAssetModel = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get<AssetModelData>(`/assetModels/${options.id}`);
  }, options);
};

export const createAssetModel = (fields: AssetModelData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<NewRecordResponse>('/assetModels', fields);
    },
    {},
    '',
    fields,
  );
};

export const updateAssetModel = (id: string, fields: AssetModelData) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(`/assetModels/${options.id}`, fields);
    },
    options,
    '',
    fields,
  );
};

export const updateAssetModelImages = (id: string, fields: { primary_image_id: number }) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(`/assetModels/images/${options.id}`, fields);
    },
    options,
    '',
    fields,
  );
};

export const deleteAssetModel = (id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  return ApiCall.create(options => {
    return http.delete(`/assetModels/${options.id}`);
  }, options);
};

export const saveAssetModelAttributes = (id: string, fields: Array<App.Data.AssetModelValueData>) => {
  const options = {} as KeyValue;
  options.id = String(id);
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(`/assetModels/attributes/${options.id}` + query, fields);
    },
    options,
    '',
    fields,
  );
};

export const getDuplicatedModels = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get<AssetModelData[]>(`/assetModels/${options.id}/duplicated-models`);
  }, options);
};
export const setAssetModelAsGlobal = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.patch<SavedRecordResponse>(`/assetModels/${options.id}/setAsGlobal`, {});
  }, options);
};
export const mergeDuplicates = (id: string, duplicated_id: any) => {
  const options = {} as KeyValue;
  options.id = id;
  options.duplicated_id = duplicated_id;
  return ApiCall.create(
    options => {
      return http.patch<SavedRecordResponse>(
        `/assetModels/${options.id}/mergeDuplicates/${options.duplicated_id}`,
        null,
      );
    },
    options,
    '',
    null,
  );
};
export const getModelStats = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get<AssetModelStatsData>(`/assetModels/${options.id}/stats`);
  }, options);
};

export const deleteAssetModelImage = (id: string, image_id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  options.image_id = image_id.toString();
  return ApiCall.create(options => {
    return http.delete(`/assetModels/${options.id}/images/${options.image_id}/delete`);
  }, options);
};
export const storeAssetModelImage = (id: string, fields: FormData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post(`/assetModels/${id}/images`, fields);
    },
    {},
    '',
    fields,
  );
};
