<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('contracts.label.set_carbon_value') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('contracts.message.set_carbon_value') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <table>
          <tr class="border-b-4">
            <th class="text-right pr-4 py-1">{{ $t('contracts.label.suggested_from_column') }}</th>
            <th class="text-left">{{ $t('contracts.label.suggested_from_name') }}</th>
            <th class="text-right">{{ $t('contracts.label.suggested_from_value') }}</th>
          </tr>
          <tr class="border-b">
            <td class="text-right pr-4 py-1">{{ $t('contracts.label.suggested_from_category') }}</td>
            <td>{{ suggestion.category_name }}</td>
            <td class="text-right">{{ suggestion.category_value }} g</td>
          </tr>
          <tr class="border-b">
            <td class="text-right pr-4 py-1">{{ $t('contracts.label.suggested_from_model') }}</td>
            <td>{{ suggestion.model_number }}</td>
            <td class="text-right">{{ suggestion.model_value }} g</td>
          </tr>
        </table>
        <FormValidator ref="form">
          <Field symbol="carbon_value" class="pt-4">
            <ZLabel for="estimatedDeadlineAt"> {{ $t('contracts.label.carbon_value') }} (g) </ZLabel>
            <NumberInput v-model="value" :digits="0" align="center" :important="true"></NumberInput>
          </Field>
        </FormValidator>
        <ZDialogFooter>
          <Action :disabled="false" @proceed="update">{{ $t('contracts.command.set_carbon_value') }} </Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import { createCarbonValueForContract, getSuggestedCarbonValue } from '@/api/contract';
import { ZLabel } from '@shadcn/components/ui/label';
import { ZInput } from '@shadcn/components/ui/input';
import Field from '@ui/FormField.vue';
import SuggestedCarbonValueData = App.Data.SuggestedCarbonValueData;
import NumberInput from "@ui/NumberInput.vue";

export default defineComponent({
  components: {
    NumberInput,
    Field,
    ZLabel,
    ZInput,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    contractId: {
      type: String,
      required: true,
    },

    contractedAssetId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      state: 'loading' as ComponentLoadState,
      suggestion: {} as SuggestedCarbonValueData,
      value: 0 as number,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      const suggestionRequest = await getSuggestedCarbonValue(this.contractId, this.contractedAssetId).execute();
      this.suggestion = suggestionRequest.data;
      this.value = this.suggestion.registered_value ?? this.suggestion.suggested_value ?? 0;
      this.state = 'loaded';
    } catch (error: any) {
      this.state = error;
    }
  },

  methods: {
    update(action: any) {
      createCarbonValueForContract(this.contractId, this.contractedAssetId, this.value)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('contracts.message.contract_saved'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
