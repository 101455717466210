import { defineStore } from 'pinia';
import useUserStore from './auth';
import UserData = App.Data.UserData;
import PermissionData = App.Data.PermissionData;
import { SYMBOL } from '@/types/symbols';
import OrganizationStructureData = App.Data.OrganizationStructureData;
import OrganizationData = App.Data.OrganizationData;

const useAccessStore = defineStore('access', {
  state: () => ({
    relation: null as Map<string, string> | null,
    organization: null as OrganizationData | null,
    twoFactorWarning: false,
  }),
  actions: {
    setOrganization(organization: OrganizationData) {
      this.organization = organization;
    },

    hasFeature(feature: string) {
      let hasFeatureEnabled = false;
      if (this.organization == null) return false;

      if (feature == SYMBOL.FEATURES.CARBON) hasFeatureEnabled = this.organization.feature_carbonfootprint == '1';
      if (feature == SYMBOL.FEATURES.FINANCE) hasFeatureEnabled = this.organization.feature_finance == '1';
      if (feature == SYMBOL.FEATURES.DOCUMENTS) hasFeatureEnabled = this.organization.feature_documents == '1';
      if (feature == SYMBOL.FEATURES.INVENTORY) hasFeatureEnabled = this.organization.feature_inventory == '1';

      return hasFeatureEnabled;
    },

    hasBranchSelected() {
      const { chosenBranch } = useUserStore();
      return chosenBranch != null && !isNaN(chosenBranch);
    },

    branchWithin(descendant: string, root: string) {
      if (descendant == root) return true;

      const { accountAccess } = useUserStore();
      if (accountAccess?.structure) {
        if (this.relation == null) {
          this.relation = new Map<string, string>();
          for (const rowNo in accountAccess?.structure) {
            const entry = accountAccess?.structure[rowNo] as OrganizationStructureData;
            this.relation.set(entry.branch_id?.toString(), entry.parent_id?.toString());
          }
        }

        let parent = descendant;
        while (parent != null) {
          parent = this.relation.get(parent);
          if (parent == root) return true;
        }

        return false;
      }
    },

    hasPermission(permissionSymbol: string, branchId: string | null = null) {
      let permissions = [] as PermissionData[];
      const { accountAccess } = useUserStore();
      permissions = accountAccess?.permissions as PermissionData[];

      if (permissions != null) {
        let permission = null as PermissionData | null;
        for (permission of permissions) {
          if (permission.symbol === permissionSymbol) {
            if (branchId == null || this.branchWithin(branchId, permission.branch_id.toString())) {
              return true;
            }
          }
        }
      }

      return false;
    },

    allowed(permissionSymbol: string, isBranchArea: boolean = false) {
      const { chosenBranch } = useUserStore();
      let branchId = null as string | null;

      if (this.operator()) return true;

      if (isBranchArea) branchId = chosenBranch ? chosenBranch.toString() : null;
      if (this.hasPermission(SYMBOL.PERMISSION.ORG_ADMIN) || this.hasPermission(permissionSymbol, branchId))
        return true;

      return false;
    },

    operator(permission: string = '') {
      const { user } = useUserStore();
      if (user == null) return false;

      if (user.account_type == 'operator') {
        if (permission == '') return true;
        if (this.hasPermission(SYMBOL.PERMISSION.SYS_ADMIN) || this.hasPermission(permission, null)) return true;
      }
      return false;
    },

    organization(permission: string = '', organizationId: number = -1) {
      const { chosenOrganization } = useUserStore();

      if (organizationId == -1) organizationId = chosenOrganization ?? 0;
      if (this.curator(organizationId)) return true;
      if (permission == '' && this.currentUser()?.organization_id == organizationId) return false;
      if (this.currentUser()?.organization_id != organizationId) return false;

      //TODO - check organization permission

      return false;
    },

    currentUser(): UserData | null {
      const { user } = useUserStore();
      return user;
    },

    currentOrganizationId(): number | null {
      const { chosenOrganization } = useUserStore();
      return chosenOrganization;
    },

    async reloadPermissions(): UserData | null {
      const { loadPermissions } = useUserStore();
      return await loadPermissions();
    },
  },
});

export default useAccessStore;
