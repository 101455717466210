<template>
  <Field symbol="branch_type" class="col-span-4">
    <ZLabel for="branch_type">
      {{ $t('branch.form.branch_type') }}
    </ZLabel>
    <div class="col-span-3">
      <SelectField
        id="branch_type"
        v-model="branch.branch_type"
        :placeholder="$t('branch.form.select_branch_type')"
        :options="branchTypes"
        translate="symbols."
      />
    </div>
  </Field>
  <Field v-if="branch.branch_type != undefined" symbol="name" class="col-span-4">
    <ZLabel for="name">
      {{ $t('branch.form.branch_name') }}
    </ZLabel>
    <ZInput id="name" v-model="branch.name" />
  </Field>
  <Field v-if="branch.branch_type == 'location'" symbol="tax_number" class="col-span-4">
    <ZLabel for="tax_number">
      {{ $t('branch.form.tax_number') }}
    </ZLabel>
    <ZInput id="tax_number" v-model="branch.tax_number" />
  </Field>
  <Field v-if="branch.branch_type == 'location'" symbol="city" class="col-span-3">
    <ZLabel for="city">
      {{ $t('branch.form.city') }}
    </ZLabel>
    <ZInput id="city" v-model="branch.city" />
  </Field>
  <Field v-if="branch.branch_type == 'location'" symbol="postal" class="col-span-1">
    <ZLabel for="postal">
      {{ $t('branch.form.postal') }}
    </ZLabel>
    <ZInput id="postal" v-model="branch.postal" />
  </Field>
  <Field v-if="branch.branch_type == 'location'" symbol="street" class="col-span-2">
    <ZLabel for="street">
      {{ $t('branch.form.street') }}
    </ZLabel>
    <ZInput id="street" v-model="branch.street" />
  </Field>
  <Field v-if="branch.branch_type == 'location'" symbol="building_number">
    <ZLabel for="building_number">
      {{ $t('branch.form.building_number') }}
    </ZLabel>
    <ZInput id="building_number" v-model="branch.building_number" />
  </Field>
  <Field v-if="branch.branch_type == 'location'" symbol="apartment_number">
    <ZLabel for="apartment_number">
      {{ $t('branch.form.apartment_number') }}
    </ZLabel>
    <ZInput id="apartment_number" v-model="branch.apartment_number" />
  </Field>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ZInput } from '@shadcn/components/ui/input';
import { ZLabel } from '@shadcn/components/ui/label';
import SelectField from '@ui/SelectField.vue';
import Field from '@ui/FormField.vue';
import type { KeyValue } from '@/types/Common';
import BranchData = App.Data.BranchData;

export default defineComponent({
  components: {
    ZInput,
    ZLabel,
    SelectField,
    Field,
  },

  props: {
    branch: {
      type: Object as PropType<BranchData>,
      required: true,
    },

    branchTypes: {
      type: Object as PropType<KeyValue>,
      required: true,
    },
  },
});
</script>
