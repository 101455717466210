import {http} from './http';
import OrganizationData = App.Data.OrganizationData;
import CuratedOrganizationsData = App.Data.CuratedOrganizationsData;
import OrganizationCompactData = App.Data.OrganizationCompactData;
import BranchData = App.Data.BranchData;
import NewRecordResponse = App.Data.NewRecordResponse;
import SavedRecordResponse = App.Data.SavedRecordResponse;
import {ApiCall} from '@/api/ApiCall';
import {KeyValue} from '@/types/Common';
import {PaginatedResponse} from '@/utils/DataSet';
import OrganizationBranchData = App.Data.OrganizationBranchData;
import CuratedOrganizationsCompactData = App.Data.CuratedOrganizationsCompactData;
import GeneralStatsData = App.Data.GeneralStatsData;
import MenuCounterData = App.Data.MenuCounterData;
import OrganizationFeaturesData = App.Data.OrganizationFeaturesData;

export const createOrganization = (fields: OrganizationData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<NewRecordResponse>('/organizations', fields);
    },
    {},
    '',
    fields,
  );
};

export const saveOrganization = (id: string, fields: OrganizationData) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(`/organizations/${options.id}`, fields);
    },
    options,
    '',
    fields,
  );
};

export const getOrganizations = () => {
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<OrganizationData>>(`organizations` + query);
    },
    {},
    '',
  );
};

export const getOrganizationsCompact = () => {
  return ApiCall.create(
    (options, query) => {
      return http.get<OrganizationCompactData[]>(`organizations/compact` + query);
    },
    {},
    '',
  );
};

export const getCuratedOrganizationsCompact = () => {
  return ApiCall.create(
    (options, query) => {
      return http.get<CuratedOrganizationsCompactData[]>(`curatedOrganizationsCompact` + query);
    },
    {},
    ''
  );
};

export const getOrganization = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get<OrganizationData>(`/organizations/${options.id}`);
  }, options);
};

export const getOrganizationBranches = (id: number, searchQuery?: string) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<OrganizationBranchData>>(
        `/organizations/${options.id}/organizationBranches` + query,
        {
          params: {searchQuery},
        },
      );
    },
    options,
    '',
  );
};

export const createBranch = (organization: string, parent: string | undefined, fields: BranchData) => {
  const options = {} as KeyValue;
  options.organization = organization;
  options.parent = parent ?? '';
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<NewRecordResponse>(`/organizations/${options.organization}/branches/${options.parent}`, fields);
    },
    options,
    '',
    fields,
  );
};

export const saveBranch = (organization: string, branch: string, fields: BranchData) => {
  const options = {} as KeyValue;
  options.organization = organization;
  options.branch = branch;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(
        `/organizations/${options.organization}/branches/${options.branch}`,
        fields,
      );
    },
    options,
    '',
    fields,
  );
};

export const getBranches = (organization: string) => {
  const options = {} as KeyValue;
  options.organization = organization;
  return ApiCall.create((options, query) => {
    return http.get<BranchData[]>(`organizations/${options.organization}/branches` + query);
  }, options);
};

export const getBranch = (id: string, organization_id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  options.organization_id = organization_id;
  return ApiCall.create(options => {
    return http.get<BranchData>(`/organizations/${options.organization_id}/branches/${options.id}`);
  }, options);
};

export const updateBranchParent = (id: number, destination: number, organization_id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  options.organization_id = organization_id.toString();
  options.destination = destination.toString();
  return ApiCall.create(options => {
    return http.get<SavedRecordResponse>(
      `/organizations/${options.organization_id}/branches/${options.id}/transfer/${options.destination}`,
    );
  }, options);
};

export const changeBranchParentToRoot = (id: number, organization_id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  options.organization_id = organization_id.toString();
  return ApiCall.create(options => {
    return http.get<SavedRecordResponse>(
      `/organizations/${options.organization_id}/branches/${options.id}/transfer-to-root`,
    );
  }, options);
};

export const updateBranchOrder = (id: number, direction: string, organization_id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  options.organization_id = organization_id.toString();
  options.direction = direction.toString();
  return ApiCall.create(options => {
    return http.get<SavedRecordResponse>(
      `/organizations/${options.organization_id}/branches/${options.id}/move/${options.direction}`,
    );
  }, options);
};

export const deleteOrganization = (id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  return ApiCall.create(options => {
    return http.delete(`/organizations/${options.id}`);
  }, options);
};

export const deleteBranch = (id: number, organization_id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  options.organization_id = organization_id.toString();
  return ApiCall.create(options => {
    return http.delete(`/organizations/${options.organization_id}/branches/${id}`);
  }, options);
};

export const addCurator = (organizationId: string, curatorId: string) => {
  const options = {} as KeyValue;
  options.organization_id = organizationId;
  const fields = {
    user_id: curatorId,
  };

  return ApiCall.create(
    (options, query, fields) => {
      return http.post<SavedRecordResponse>(`organizations/${options.organization_id}/curators`, fields);
    },
    options,
    '',
    fields,
  );
};

export const removeCurator = (organizationId: string, curatorId: string) => {
  const options = {} as KeyValue;
  options.organization_id = organizationId;
  options.user_id = curatorId;

  return ApiCall.create(options => {
    return http.delete(`organizations/${options.organization_id}/curators/${options.user_id}`);
  }, options);
};

export const getCuratedOrganizations = () => {
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<CuratedOrganizationsData>>(`curatedOrganizations` + query);
    },
    {},
    '',
  );
};

export const getOrganizationStats = (organizationId: string) => {
  const options = {} as KeyValue;
  options.organizationId = organizationId;

  return ApiCall.create(options => {
    return http.get<GeneralStatsData>(`organizations/${options.organizationId}/stats`);
  }, options);
};

export const getBranchStats = (organizationId: string, branchId: string) => {
  const options = {} as KeyValue;
  options.organizationId = organizationId;
  options.branchId = branchId;

  return ApiCall.create(options => {
    return http.get<GeneralStatsData>(`organizations/${options.organizationId}/branches/${options.branchId}/stats`);
  }, options);
};

export const getClientDashboardStats = () => {
  const options = {} as KeyValue;

  return ApiCall.create(() => {
    return http.get<GeneralStatsData>(`dashboard/stats`);
  }, options);
};

export const getAdminDashboardStats = () => {
  const options = {} as KeyValue;

  return ApiCall.create(
    (options, query) => {
      return http.get<GeneralStatsData>(`adminDashboard/stats` + query);
    },
    options,
    '',
  );
};

export const getMenuStats = () => {
  const options = {} as KeyValue;

  return ApiCall.create(options => {
    return http.get<MenuCounterData>(`menu/stats`);
  }, options);
};

export const getOrganizationFeatures = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get<OrganizationFeaturesData>(`/organizations/${options.id}/features`);
  }, options);
};

export const saveOrganizationFeatures = (id: string, fields: OrganizationFeaturesData) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(`/organizations/${options.id}/features`, fields);
    },
    options,
    '',
    fields,
  );
};
