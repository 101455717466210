<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { cn } from '@shadcn/lib/utils';

const props = defineProps<{ class?: HTMLAttributes['class'] }>();
</script>

<template>
  <div :class="cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:gap-x-2', props.class)">
    <slot />
  </div>
</template>
