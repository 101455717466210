<template>
  <div class="border rounded-xl overflow-hidden mt-4">
    <div v-if="events.data && events.data.length">
      <ZTable class="w-full">
        <ZTableHeader>
          <ZTableRow>
            <ZTableHead class="font-bold text-left" v-if="access.operator()">{{ $t('tracking.label.organization') }}</ZTableHead>
            <ZTableHead class="font-bold text-left">{{ $t('tracking.label.event_type') }}</ZTableHead>
            <ZTableHead class="font-bold text-left">{{ $t('tracking.label.message') }}</ZTableHead>
            <ZTableHead class="font-bold text-left">{{ $t('tracking.label.causer') }}</ZTableHead>
            <ZTableHead class="font-bold text-left">{{ $t('tracking.label.date') }}</ZTableHead>
          </ZTableRow>
        </ZTableHeader>
        <ZTableBody>
          <ZTableRow v-for="(event, index) in events.data" :key="index">
            <ZTableCell class="text-left" v-if="access.operator()">
              {{ event.organization_name }}
            </ZTableCell>
            <ZTableCell class="text-left">
              {{ $t('tracking.event.' + event.event_type + '.short') }}
            </ZTableCell>
            <ZTableCell class="text-left">
              {{ explain(event) }}
            </ZTableCell>
            <ZTableCell class="text-left">
              {{ event.user_name }}
            </ZTableCell>
            <ZTableCell class="text-left">
              {{ event.created_at }}
            </ZTableCell>
          </ZTableRow>
        </ZTableBody>
      </ZTable>
    </div>
    <div v-else class="py-10">
      <InformationCircleIcon class="icon w-[30px] mb-5 m-auto"/>
      <h3 class="scroll-m-20 font-semibold text-center">{{ $t('tracking.label.not_found') }}</h3>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZButton } from '@shadcn/components/ui/button';
import {ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow} from "@shadcn/components/ui/table";
import PrettyStatus from "@ui/PrettyStatus.vue";
import {InformationCircleIcon} from "@modules/@heroicons/vue/24/outline";
import {DataSet} from "@/utils/DataSet";
import {getTracking} from "@/api/commonApis";
import TrackingData = App.Data.TrackingData;
import useAccessStore from "@/stores/access";

export default defineComponent({
  components: {
    InformationCircleIcon, ZTableHeader, PrettyStatus, ZTable, ZTableRow, ZTableHead, ZTableBody, ZTableCell,
    // icons
    // shadcn components
    ZButton,
  },

  props: {
    trackingId: {
      required: true,
      type: [null, String],
    }
  },

  data() {
    return {
      events: {} as DataSet<TrackingData, null>,
    };
  },

  computed: {
    access: () => useAccessStore(),
  },

  async mounted() {
    if (this.trackingId != null) {
      this.events = new DataSet<TrackingData, null>((query: string) =>
        getTracking(this.trackingId).execute(query),
      ).setParams({perPage: 15});
      await this.events.load();
    }
  },

  methods: {
    flattenArray(ob: any) {
      let toReturn = {};

      for (let i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) == 'object' && ob[i] !== null) {
          let flatObject = this.flattenArray(ob[i]);
          for (let x in flatObject) {
            if (!flatObject.hasOwnProperty(x)) continue;

            toReturn[i + '_' + x] = flatObject[x];
          }
        } else {
          toReturn[i] = ob[i];
        }
      }
      return toReturn;
    },

    explain(event) {
      try {
        let json = JSON.parse(event.data);
        return this.$t('tracking.event.' + event.event_type + '.full', this.flattenArray(json));
      } catch(e: any) {
        return e.toString();
      }
    }
  },
});
</script>
