<template>
  <div class="flex max-w-[80rem] w-full min-h-[400px] m-auto bg-white md:rounded-xl overflow-hidden mb-20">
    <div class="hidden md:block md:w-1/2 relative">
      <img
        class="absolute object-cover w-full h-full right-0 bottom-0 top-0 left-0"
        src="@pub/images/register-background.jpg"
      />
    </div>
    <div class="w-full md:w-1/2 pt-10 p-8 md:p-16 flex justify-center items-center">
      <div class="w-full max-w-[350px]">
        <div class="flex flex-col gap-4">
          <div v-if="!success" class="flex flex-col gap-4">
            <div>
              <h3 class="mb-0 text-xl font-bold text-center">{{ organization.name }}</h3>
              <p class="mb-0 scroll-m-20 text-sm text-center">{{ organization.city }}</p>
              <p class="mb-5 mt-10 text-2xl scroll-m-20 font-semibold text-center">
                {{ $t('auth.registerUser.header') }}
              </p>
            </div>
            <FormValidator :errors="errors" validation-translations="auth.registerOrganization.validationError">
              <div class="flex flex-col gap-4">
                <div class="flex gap-5">
                  <FormField symbol="user.email">
                    <ZLabel>{{ $t('auth.registerOrganization.form.email') }}</ZLabel>
                    <ZInput v-model="form.email" type="email" />
                  </FormField>
                  <FormField symbol="user.phone">
                    <ZLabel>{{ $t('auth.registerOrganization.form.phone') }}</ZLabel>
                    <ZInput v-model="form.phone" type="text" />
                  </FormField>
                </div>
                <div class="flex gap-5">
                  <FormField symbol="user.first_name">
                    <ZLabel>{{ $t('auth.registerOrganization.form.firstname') }}</ZLabel>
                    <ZInput v-model="form.first_name" type="text" />
                  </FormField>
                  <FormField symbol="user.last_name">
                    <ZLabel>{{ $t('auth.registerOrganization.form.lastname') }}</ZLabel>
                    <ZInput v-model="form.last_name" type="text" />
                  </FormField>
                </div>
                <FormField symbol="user.password">
                  <ZLabel>{{ $t('auth.registerOrganization.form.password') }}</ZLabel>
                  <ZInput v-model="form.password" type="password" />
                </FormField>
                <FormField symbol="user.password_confirmation">
                  <ZLabel>{{ $t('auth.registerOrganization.form.password_confirm') }}</ZLabel>
                  <ZInput v-model="form.password_confirmation" type="password" />
                </FormField>
                <FormField symbol="user.consent">
                  <div class="flex items-center space-x-2">
                    <Checkbox id="consent" :checked="form.consent" @update:checked="form.consent = !form.consent" />
                    <ZLabel
                      for="consent"
                      class="text-sm pl-3 leading-4 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      <Translation keypath="auth.registerOrganization.form.consent.message">
                        <template #policy>
                          <a href="" target="_blank"
                            ><b>{{ $t('auth.registerOrganization.form.consent.policy') }}</b></a
                          >
                        </template>
                        <template #rules>
                          <a href="" target="_blank"
                            ><b>{{ $t('auth.registerOrganization.form.consent.rules') }}</b></a
                          >
                        </template>
                      </Translation>
                    </ZLabel>
                  </div>
                </FormField>
                <ZButton @click.prevent="register()">{{ $t('auth.registerUser.form.button') }}</ZButton>
              </div>
            </FormValidator>
          </div>
          <div v-else>
            <CheckCircleIcon class="icon lg m-auto mb-8" />
            <h3 class="mb-5 scroll-m-20 text-xl font-semibold text-center">
              <Translation keypath="auth.registerUser.success.header" />
            </h3>
            <p class="mb-5 scroll-m-20 text-center">
              <Translation keypath="auth.registerOrganization.success.body">
                <template #email>
                  <b>{{ form.email }}</b>
                </template>
              </Translation>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { getOrganizationByToken, postRegisterEmployee } from '../../api';
import loaderStore from '../../stores/loader';
import { ZInput } from '@shadcn/components/ui/input';
import { ZButton } from '@shadcn/components/ui/button';
import { ZLabel } from '@shadcn/components/ui/label';
import { Checkbox } from '@shadcn/components/ui/checkbox';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import { Translation } from 'vue-i18n';
import FormValidator from '../../components/FormValidator.vue';
import FormField from '../../components/FormField.vue';
import { HttpStatusCode } from 'axios';
import { ValidationErrors } from '../../types/ValidationErrors';

export default defineComponent({
  components: {
    ZInput,
    ZLabel,
    ZButton,
    CheckCircleIcon,
    Checkbox,
    Translation,
    FormValidator,
    FormField,
  },

  props: {
    token: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      errors: null as null | ValidationErrors,
      success: false,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        password_confirmation: '',
        direct_registration_token: '',
        consent: false,
      } as App.Data.RegisterEmployeeRequestData,

      organization: {
        name: '',
        city: '',
      },
    };
  },

  mounted() {
    this.checkToken();
  },

  methods: {
    async checkToken() {
      const { startLoading, stopLoading } = loaderStore();
      startLoading();
      const response = await getOrganizationByToken(this.token);
      this.organization = response.data;
      stopLoading();
    },

    register() {
      const { startLoading, stopLoading } = loaderStore();
      startLoading();
      postRegisterEmployee({ user: { ...this.form, direct_registration_token: this.token } })
        .then(() => {
          this.success = true;
        })
        .catch(error => {
          if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
            this.errors = error.response.data.errors;
          }
        })
        .finally(() => stopLoading());
    },
  },
});
</script>
