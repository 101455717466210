<template>
  <div class="flex gap-1">
    <Popover v-model:open="showDatePicker">
      <PopoverTrigger as-child>
        <ZButton variant="outline" class="w-full font-normal flex items-center justify-start">
          <div class="flex items-center w-full">
            <CalendarIcon class="w-[20px] mr-2" />
            <span v-if="dateRange.start && dateRange.end" class="leading-3 text-xs">
              Od: {{ formatDate(dateRange.start) }} <br />
              Do: {{ formatDate(dateRange.end) }}
            </span>
            <span v-else>{{ $t('notification.form.date.button') }}</span>
          </div>
          <ChevronDownIcon class="w-[18px] ml-2 text-gray-400" />
        </ZButton>
      </PopoverTrigger>
      <PopoverContent class="w-auto p-0" align="start">
        <Calendar v-model.range="dateRange" timezone="UTC" :columns="1" />
      </PopoverContent>
    </Popover>
    <ZButton v-if="dateRange.start && dateRange.end" variant="ghost" @click.prevent="clearDate()">
      <XCircleIcon class="w-[23px]" />
    </ZButton>
  </div>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import {CalendarIcon, ChevronDownIcon, XCircleIcon} from "@modules/@heroicons/vue/24/outline";
import {ZButton} from "@shadcn/components/ui/button";
import {Popover, PopoverContent, PopoverTrigger} from "@shadcn/components/ui/popover";
import {Calendar} from "@shadcn/components/ui/calendar";
import {datePartISO} from "@/utils";

export default defineComponent({
  components: {
    ChevronDownIcon, XCircleIcon, ZButton, CalendarIcon,
    Popover,
    PopoverTrigger,
    Calendar,
    PopoverContent,
  },

  props: {
    start: {
      required: false,
      default: null,
      type: String
    },

    end: {
      required: false,
      default: null,
      type: String
    }
  },

  data() {
    return {
      showDatePicker: false,
      dateRange: {
        start: '',
        end: '',
      }
    }
  },

    watch: {
      dateRange: {
        deep: true,
        handler() {
          if (this.dateRange) {
            try {
              if (this.dateRange.start) this.$emit('update:start', datePartISO(new Date(this.dateRange.start)));
              else this.$emit('update:start', '');
              if (this.dateRange.end) this.$emit('update:end', datePartISO(new Date(this.dateRange.end)));
              else this.$emit('update:end', '');
            } catch(e) {
              this.$emit('update:start', '');
              this.$emit('update:end', '');
            }
          }
        }
      }
    },

    mounted() {
      this.dateRange = {
        start: this.start,
        end: this.end
      };
      this.showDatePicker = false;
    },

  methods: {
    clearDate() {
      this.dateRange = {
        start: '',
        end: '',
      };
    },
  }
})
</script>
