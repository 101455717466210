import type { RouteRecordRaw } from 'vue-router';
import AppLayout from '../views/AppLayout.vue';
import About from '../views/About.vue';
import Dashboard from '../views/Dashboard.vue';
import Messages from '../views/Messages.vue';
import GuestLayout from '../views/GuestLayout.vue';
import { ROUTE } from './routeNames';
import { organizationRoutes } from '@/router/organization';
import { userAccountRoutes } from '@/router/userAccount';
import { accountRoutes } from '@/router/account';
import { notificationsRoutes } from '@/router/notifications';
import { tagsRoutes } from '@/router/tags';
import useUserStore from '../stores/auth';
import { categoryRoutes } from '@/router/category';
import { model_routes } from '@/router/model';
import { assetRoutes } from '@/router/asset';
import { reportRoutes } from '@/router/report';
import { contractRoutes } from '@/router/contract';
import { dictionaryRoutes } from '@/router/dictionary';
import { inventoryRoutes } from '@/router/inventory';
import { filesRoutes } from '@/router/files';
import { importsRoutes } from '@/router/imports';
import PageNotFound from "@views/PageNotFound.vue";

export const appRoutes: RouteRecordRaw[] = [
  {
    path: '/messages/:type',
    component: GuestLayout,
    children: [
      {
        path: '',
        component: Messages,
        props: true,
      },
    ],
  },
  {
    path: '/app',
    component: AppLayout,
    name: ROUTE.ROOT,
    redirect: () => {
      return { name: ROUTE.DASHBOARD };
    },
    meta: {
      breadcrumbs: true,
      title: 'app.label.home',
    },
    children: [
      {
        path: 'dashboard',
        component: Dashboard,
        name: ROUTE.DASHBOARD,
        meta: {
          breadcrumbs: true,
          title: 'app.label.dashboard',
        },
      },
      {
        path: 'about',
        component: About,
        name: ROUTE.ABOUT,
        meta: {
          breadcrumbs: true,
          title: 'app.label.about',
        },
      },
      ...categoryRoutes,
      ...notificationsRoutes,
      ...accountRoutes,
      ...organizationRoutes,
      ...userAccountRoutes,
      ...model_routes,
      ...tagsRoutes,
      ...assetRoutes,
      ...contractRoutes,
      ...dictionaryRoutes,
      ...inventoryRoutes,
      ...reportRoutes,
      ...filesRoutes,
      ...importsRoutes,
      {
        path: "404",
        component: PageNotFound,
        name: ROUTE.PAGE_NOT_FOUND,
        meta: {
          breadcrumbs: true,
          title: 'app.label.page_not_found',
        },
      },
      {
        path: ":pathMatch(.*)*",
        redirect: () => {
          return { name: ROUTE.PAGE_NOT_FOUND };
        },
      }
    ],
    beforeEnter(to, from, next) {
      const { loggedIn } = useUserStore();
      if (loggedIn) {
        next();
      } else {
        next('/login');
      }
    },
  },
];
