<template>
  <div v-if="stats && stats.usage" class="grid grid-cols-1 gap-4 lg:grid-cols-9 mt-8">
    <div class="border rounded-xl p-6 shadow-md lg:col-span-2" v-if="access.hasFeature(SYMBOL.FEATURES.CARBON) || access.operator()">
      <p class="text-md font-medium">{{ $t('dashboard.label.carbon_footprint_reduction') }}</p>
      <p class="text-3xl font-bold pt-4">{{ readableCarbonValue(stats.usage[8].value) }}</p>
      <p class="text-sm font-medium pb-4">{{ $t('dashboard.label.carbon_summary') }}</p>
      <ZButton v-if="shortcuts" class="w-full" :disabled="!access.allowed(SYMBOL.PERMISSION.BRANCH_REPORT, true)">{{
        $t('dashboard.label.details_and_reports')
      }}</ZButton>
    </div>
    <div class="border rounded-xl p-6 shadow-md lg:col-span-4" :class="{'lg:col-span-5': !access.hasFeature(SYMBOL.FEATURES.CARBON) && !access.operator()}">
      <div class="flex justify-between">
        <p class="text-md font-medium">{{ $t('dashboard.label.assets') }}</p>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-4 pt-4 pb-4 gap-2">
        <div class="text-center">
          <p class="text-3xl font-bold">{{ stats.usage[0].value }}</p>
          <p class="text-sm">{{ $t('dashboard.label.in_use') }}</p>
        </div>
        <div class="text-center">
          <p class="text-3xl font-bold">{{ stats.usage[1].value }}</p>
          <p class="text-sm">{{ $t('dashboard.label.stored') }}</p>
        </div>
        <div class="text-center">
          <p class="text-3xl font-bold">{{ stats.usage[2].value }}</p>
          <p class="text-sm">{{ $t('dashboard.label.disposed') }}</p>
        </div>
        <div class="text-center">
          <p class="text-3xl font-bold">{{ stats.usage[3].value }}</p>
          <p class="text-sm">{{ $t('dashboard.label.missing') }}</p>
        </div>
      </div>
      <div class="flex">
        <ZButton v-if="shortcuts" variant="outline" class="w-1/2 mr-2" @click="$router.push({ name: ROUTE.ASSET_LIST })"
          >{{ $t('dashboard.command.see_all') }}
        </ZButton>
        <ZButton
          v-if="shortcuts"
          class="w-1/2"
          :disabled="!access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"
          @click="$router.push({ name: ROUTE.ASSET_ADD })"
          >{{ $t('dashboard.command.add_asset') }}
        </ZButton>
      </div>
    </div>
    <div class="border rounded-xl p-6 shadow-md lg:col-span-3" :class="{'lg:col-span-4': !access.hasFeature(SYMBOL.FEATURES.CARBON) && !access.operator()}">
      <div class="flex justify-between">
        <p class="text-md font-medium">{{ $t('dashboard.label.orders') }}</p>
      </div>
      <div class="grid grid-cols-3 pt-4 pb-4 gap-2">
        <div class="text-center">
          <p class="text-3xl font-bold">{{ stats.usage[4].value }}</p>
          <p class="text-sm">{{ $t('dashboard.label.pending') }}</p>
        </div>
        <div class="text-center">
          <p class="text-3xl font-bold">{{ stats.usage[5].value }}</p>
          <p class="text-sm">{{ $t('dashboard.label.in_progress') }}</p>
        </div>
        <div class="text-center">
          <p class="text-3xl font-bold">{{ stats.usage[7].value }}</p>
          <p class="text-sm">{{ $t('dashboard.label.delayed') }}</p>
        </div>
      </div>
      <div class="flex">
        <ZButton
          v-if="shortcuts"
          variant="outline"
          class="w-1/2 mr-2"
          @click="$router.push({ name: ROUTE.CONTRACTS_LIST })"
          >{{ $t('dashboard.command.see_all') }}</ZButton
        >
        <ZButton
          v-if="shortcuts"
          class="w-1/2"
          :disabled="!access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"
          @click="$router.push({ name: ROUTE.CONTRACTS_ADD })"
          >{{ $t('dashboard.command.create_order') }}</ZButton
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ZButton } from '@shadcn/components/ui/button';
import {defineComponent, PropType} from 'vue';
import GeneralStatsData = App.Data.GeneralStatsData;
import useAccessStore from '@/stores/access';

export default defineComponent({
  components: {
    ZButton,
  },

  props: {
    dataset: {
      required: true,
      type: Object as PropType<GeneralStatsData>,
    },

    shortcuts: {
      required: false,
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      stats: {} as GeneralStatsData,
    };
  },

  computed: {
    access: () => useAccessStore(),
  },

  watch: {
    dataset() {
      this.stats = this.dataset;
    },
  },

  mounted() {
    this.stats = this.dataset;
  },
});
</script>
