<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('asset.label.edit_asset_category_and_model') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('asset.message.edit_asset_category_and_model') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <div>
          <FormValidator ref="form">
            <AssetForm
              :new-asset="false"
              :asset="asset"
              :categories="categories"
              :filters="filters"
              :models="assetModelDataSet"
            />
          </FormValidator>
        </div>
        <ZDialogFooter>
          <Action @proceed="update">{{
              $t('asset.command.save_changes')
            }}
          </Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import {ComponentLoadState} from '@/types/ComponentLoadState';
import {getAssetModels} from '@/api/assetModel';
import AssetModelData = App.Data.AssetModelData;
import AssetForm from '@ui/Assets/AssetForm.vue';
import AssetData = App.Data.AssetData;
import {getAsset, updateAssetCategory} from '@/api/asset';
import CategoryCompactData = App.Data.CategoryCompactData;
import {getCompactCategories} from '@/api/category';
import {DataSet} from '@/utils/DataSet';
import AssetModelRequestData = App.Data.AssetModelRequestData;
import {Dialogs} from '@/types/Dialogs';
import useI18n from "@/utils/i18n";

interface filtersInterface {
  search: string;
  page: number;
  perPage: number;
  type: any;
  category_id?: number;
  local: number;
}

export default defineComponent({
  components: {
    AssetForm,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    assetId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
      dialogs: inject('dialogs') as Dialogs,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      categories: {} as CategoryCompactData,
      asset: {} as AssetData,
      state: 'loading' as ComponentLoadState,
      assetModelDataSet: {} as DataSet<AssetModelData, AssetModelRequestData>,
      filters: {
        search: '',
        page: 1,
        page_size: 5,
        category_id: null as null | number,
        type: 'all',
        local: 1,
      },
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },

    'asset.category_id'() {
      if (this.asset.category_id) {
        this.filters.category_id = this.asset.category_id;
        this.getModels();
      }
    },

    'filters.search'() {
      this.getModels();
    },
  },

  async mounted() {
    try {
      this.asset = (await getAsset(this.assetId).execute()).data;
      await this.getCategories();

      this.assetModelDataSet = new DataSet<AssetModelData, AssetModelRequestData>((query: string) =>
        getAssetModels().execute(query),
      ).setParams(this.filters);
      await this.assetModelDataSet.loadDebounced();

      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
      throw error;
    }
  },

  methods: {
    update(action: any) {
      this.dialogs
        .confirm(
          this.$t('asset.label.changing_category_and_model'),
          this.$t('asset.message.changing_category_and_model'),
        )
        .then(() => {
          updateAssetCategory(this.assetId, this.asset)
            .withForm(this.$refs.form as typeof FormValidator)
            .withLoader(action.loader)
            .execute()
            .then(() => {
              this.isDialogOpen = false;
              this.toast({
                title: this.$t('toasts.type.saved'),
                description: this.$t('asset.message.asset_saved'),
              });
              this.$emit('saved');
            }).catch(() => {
            this.toast({
              title: useI18n.global.t('common.errors.header'),
              description: useI18n.global.t('common.errors.unexpected'),
              variant: 'destructive',
            });
          });
        });
    },

    async getCategories() {
      const categoriesResponse = await getCompactCategories().execute();
      this.categories = categoriesResponse.data;
    },

    async getModels() {
      const finalFilters: filtersInterface = {
        ...this.filters,
        category_id: parseInt(this.filters!.category_id!, 10),
      };
      this.assetModelDataSet.setParams(finalFilters);
      await this.assetModelDataSet.loadDebounced();
    },
  },
});
</script>
