<template>
  <div class="flex items-center border border-custom-color border-opacity-30 rounded-md px-2 py-1">
    <div v-if="color" class="w-[8px] h-[8px] rounded-full mr-2 bg-color"></div>
    <div class="font-semibold text-xs">{{ name }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },

    color: {
      type: null as unknown as PropType<string | null>,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      colorOpacity: '',
    };
  },

  watch: {
    color() {
      this.setColorOpacity();
    },
  },

  mounted() {
    this.setColorOpacity();
  },

  methods: {
    setColorOpacity() {
      this.colorOpacity = this.color ? this.color + '66'.toString() : '#e4e4e7';
    },
  },
});
</script>
<style scoped>
.bg-color {
  background: v-bind('color');
}
.border-custom-color {
  border-color: v-bind('colorOpacity');
}
</style>
