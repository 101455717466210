import { http } from './http';
import { ApiCall } from '@/api/ApiCall';
import { PaginatedResponse } from '@/utils/DataSet';
import { KeyValue } from '@/types/Common';
import NewRecordResponse = App.Data.NewRecordResponse;
import ContractData = App.Data.ContractData;
import ContractActionStoreRequest = App.Data.ContractActionStoreRequest;
import SavedRecordResponse = App.Data.SavedRecordResponse;
import ContractActionUpdateRequest = App.Data.ContractActionUpdateRequest;
import ContractActionData = App.Data.ContractActionData;

export const getContractedActions = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get<PaginatedResponse<ContractData>>(`contracts/${options.id}/actions`);
  }, options);
};

export const getContractedAction = (contractId: string, actionId: string) => {
  const options = {} as KeyValue;
  options.contractId = contractId;
  options.actionId = actionId;
  return ApiCall.create(options => {
    return http.get<ContractActionData>(`contracts/${options.contractId}/contractedActions/${options.actionId}`);
  }, options);
};

export const storeContractAction = (id: string, fields: ContractActionStoreRequest) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<NewRecordResponse>(`/contracts/${options.id}/contractedActions`, fields);
    },
    options,
    '',
    fields,
  );
};
export const updateContractAction = (contractId: string, actionId: string, fields: ContractActionUpdateRequest) => {
  const options = {} as KeyValue;
  options.contractId = contractId;
  options.actionId = actionId;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(
        `/contracts/${options.contractId}/contractedActions/${options.actionId}`,
        fields,
      );
    },
    options,
    '',
    fields,
  );
};

export const deleteContractedAction = (contractId: string, actionId: string) => {
  const options = {} as KeyValue;
  options.contractId = contractId;
  options.actionId = actionId;
  return ApiCall.create(options => {
    return http.delete(`contracts/${options.contractId}/contractedActions/${options.actionId}`);
  }, options);
};
