<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('organization.label.change_organization_data') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('organization.message.change_organization_data') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded' && organization && countries && regions">
        <FormValidator ref="form" class="grid grid-cols-6 gap-4 py-4">
          <OrganizationForm
            :organization="organization"
            :countries="countries"
            :regions="regions"
            :legal-forms="legalForms"
          />
          <Field v-if="access().operator()" symbol="operator_notes" class="col-span-4">
            <ZLabel for="operator_notes">
              {{ $t('userAccount.label.operator_notes') }}
            </ZLabel>
            <textarea
              id="operator_notes"
              v-model="organization.operator_notes"
              class="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
            />
          </Field>
        </FormValidator>
        <ZDialogFooter>
          <Action @proceed="update">{{ $t('organization.command.save_changes') }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogHeader,
  ZDialogTitle,
  ZDialogFooter,
} from '@shadcn/components/ui/dialog';
import { getOrganization, saveOrganization } from '@/api/organization';
import OrganizationData = App.Data.OrganizationData;
import FormValidator from '@ui/FormValidator.vue';
import type { KeyValue } from '@/types/Common';
import { getCountries, getRegions } from '@/api/dictionaries';
import RegionWithTranslationData = App.Data.RegionWithTranslationData;
import CountryWithTranslationData = App.Data.CountryWithTranslationData;
import symbolsStore from '@/stores/symbols';
import IconAlert from '@ui/IconAlert.vue';
import OrganizationForm from '@ui/Organizations/OrganizationForm.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import Field from '@ui/FormField.vue';
import { ZLabel } from '@shadcn/components/ui/label';
import access from '@/stores/access';

export default defineComponent({
  components: {
    ZLabel,
    Field,
    FormValidator,
    OrganizationForm,
    IconAlert,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogHeader,
    ZDialogTitle,
    ZDialogFooter,
  },

  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      organization: null as OrganizationData | null,
      legalForms: {} as KeyValue,
      countries: null as Record<string, string> | null,
      regions: null as RegionWithTranslationData[] | null,
      isDialogOpen: true,

      state: 'loading' as ComponentLoadState,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      const symbols = symbolsStore();
      this.legalForms = await symbols.get('legal_forms');

      const [countriesResponse, regionsResponse, organizationResponse] = await Promise.all([
        getCountries(this.$i18n.locale).execute(),
        getRegions(this.$i18n.locale).execute(),
        getOrganization(this.organizationId).execute(),
      ]);

      this.countries = countriesResponse.data.reduce((accumulator: KeyValue, value: CountryWithTranslationData) => {
        return { ...accumulator, [value.id]: value.translations[0].translation };
      }, {});
      this.regions = regionsResponse.data;
      this.organization = organizationResponse.data;

      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
    }
  },

  methods: {
    access,
    update(action: any) {
      if (!this.organization) {
        return;
      }

      // organization comes with additional data like curators list, we don't want to send the whole object back, so we clean up the payload
      const payload = {
        id: this.organization.id,
        name: this.organization.name,
        city: this.organization.city,
        postal: this.organization.postal,
        street: this.organization.street,
        apartment_number: this.organization.apartment_number,
        building_number: this.organization.building_number,
        country_id: this.organization.country_id,
        region_id: this.organization.region_id,
        legal_form: this.organization.legal_form,
        tax_number: this.organization.tax_number,
        registration_number: this.organization.registration_number,
        contact_person: this.organization.contact_person,
        contact_email: this.organization.contact_email,
        contact_phone: this.organization.contact_phone,
      } as OrganizationData;

      if (this.access().operator()) {
        payload.operator_notes = this.organization.operator_notes;
      }

      saveOrganization(this.organizationId, payload)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('organization.message.organization_saved'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
