<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('contracts.label.create_contract') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('contracts.message.create_contract') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <FormValidator ref="form">
          <ContractForm :contract="contract" :priorities="priorities" />
        </FormValidator>
        <ZDialogFooter>
          <Action :disabled="false" @proceed="store">{{ $t('contracts.command.create_contract') }} </Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import ContractForm from '@ui/Contracts/ContractForm.vue';
import { PRIORITY } from '@/consts';
import ContractDataStoreRequest = App.Data.ContractDataStoreRequest;
import { createContract } from '@/api/contract';
import { KeyValue } from '@/types/Common';
import {SYMBOL} from "@/types/symbols";

export default defineComponent({
  components: {
    ContractForm,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      contract: {
        priority: 4,
      } as ContractDataStoreRequest,
      state: 'loaded' as ComponentLoadState,
      priorities: {} as KeyValue,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  mounted() {
    for (const [key, value] of Object.entries(PRIORITY)) {
      this.priorities[key] = this.$t('contracts.priority.' + value);
    }
  },

  methods: {
    store(action: any) {
      createContract(this.contract)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(response => {
          this.$router.push({ name: this.ROUTE.CONTRACT_DETAILS, params: { contractId: response.data.new_id } });

          this.toast({
            title: this.$t('toasts.type.success'),
            description: this.$t('contracts.message.asset_created'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
