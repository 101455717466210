<template>
  <Field symbol="technical_condition" class="col-span-2">
    <ZLabel for="technical_condition">
      {{ $t('asset.label.technical_condition') }}
    </ZLabel>
    <ZSelect v-model="asset.technical_condition!" class="w-full">
      <ZSelectTrigger class="w-full">
        <div class="flex flex-row gap-2 whitespace-nowrap">
          <ZSelectValue :placeholder="$t('asset.label.category')" />
        </div>
      </ZSelectTrigger>
      <ZSelectContent>
        <ZSelectGroup>
          <ZSelectItem value="good">
            {{ $t('asset.technical_conditions.good') }}
          </ZSelectItem>
          <ZSelectItem value="damaged">
            {{ $t('asset.technical_conditions.damaged') }}
          </ZSelectItem>
        </ZSelectGroup>
      </ZSelectContent>
    </ZSelect>
  </Field>
  <Field symbol="serial_number" class="col-span-2">
    <ZLabel for="serial_number">
      {{ $t('asset.label.serial_number') }}
    </ZLabel>
    <ZInput id="serial_number" v-model="asset.serial_number" />
  </Field>
  <Field symbol="warranty_expiration_date" class="col-span-2">
    <ZLabel for="warranty_expiration_date">
      {{ $t('asset.label.activation_date') }}
    </ZLabel>
    <div class="flex w-full items-center">
      <Popover v-model:open="showActivationDatePicker">
        <PopoverTrigger as-child>
          <ZButton variant="outline" class="w-full font-normal flex items-center justify-start">
            <div class="flex items-center w-full">
              <CalendarIcon class="w-[20px] mr-2" />
              <span v-if="asset.activation_date" class="leading-3">
                {{ formatDate(asset.activation_date) }}
              </span>
              <span v-else>{{ $t('asset.form.date.button') }}</span>
            </div>
            <ChevronDownIcon class="w-[18px] ml-2 text-gray-400" />
          </ZButton>
        </PopoverTrigger>
        <PopoverContent class="w-auto p-0" align="start">
          <Calendar v-model="asset.activation_date" timezone="UTC" :columns="1" />
        </PopoverContent>
      </Popover>
      <XCircleIcon v-if="asset.activation_date" class="w-[25px] ml-2" @click.prevent="asset.activation_date = ''" />
    </div>
  </Field>
  <Field symbol="warrantator" class="col-span-2">
    <ZLabel for="warrantator">
      {{ $t('asset.label.warrantator') }}
    </ZLabel>
    <ZInput id="warrantator" v-model="asset.warrantator" />
  </Field>
  <Field symbol="warranty_expiration_date" class="col-span-2">
    <ZLabel for="warranty_expiration_date">
      {{ $t('asset.label.warranty_expiration_date') }}
    </ZLabel>
    <div class="flex w-full items-center">
      <Popover v-model:open="showWarrantyDatePicker">
        <PopoverTrigger as-child>
          <ZButton variant="outline" class="w-full font-normal flex items-center justify-start">
            <div class="flex items-center w-full">
              <CalendarIcon class="w-[20px] mr-2" />
              <span v-if="asset.warranty_expiration_date" class="leading-3">
                {{ formatDate(asset.warranty_expiration_date) }}
              </span>
              <span v-else>{{ $t('asset.form.date.button') }}</span>
            </div>
            <ChevronDownIcon class="w-[18px] ml-2 text-gray-400" />
          </ZButton>
        </PopoverTrigger>
        <PopoverContent class="w-auto p-0" align="start">
          <Calendar v-model="asset.warranty_expiration_date" timezone="UTC" :columns="1" />
        </PopoverContent>
      </Popover>
      <XCircleIcon
        v-if="asset.warranty_expiration_date"
        class="w-[25px] ml-2"
        @click.prevent="asset.warranty_expiration_date = ''"
      />
    </div>
  </Field>
  <Field symbol="location" class="col-span-4">
    <ZLabel for="location">
      {{ $t('asset.label.location') }}
    </ZLabel>
    <ZInput id="location" v-model="asset.location"/>
  </Field>
  <div v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_FINANCE, true) && access.hasFeature(SYMBOL.FEATURES.FINANCE)" class="col-span-4 gap-4 grid grid-cols-4">
    <div class="col-span-4 text-center text-xs py-3">{{ $t('asset.label.accounting_data') }}</div>
    <Field symbol="purchase_price" class="col-span-2">
      <ZLabel for="purchase_price">
        {{ $t('asset.label.price') }}
      </ZLabel>
      <NumberInput id="purchase_price" v-model="asset.purchase_price"/>
    </Field>
    <Field symbol="currency" class="col-span-2">
      <ZLabel for="currency">
        {{ $t('asset.label.currency') }}
      </ZLabel>
      <ZInput id="currency" v-model="asset.currency" />
    </Field>
    <Field symbol="invoice_number" class="col-span-2">
      <ZLabel for="invoice_number">
        {{ $t('asset.label.invoice_number') }}
      </ZLabel>
      <ZInput id="invoice_number" v-model="asset.invoice_number" />
    </Field>
    <Field symbol="purchase_date" class="col-span-2">
      <ZLabel for="purchase_date">
        {{ $t('asset.label.purchase_date') }}
      </ZLabel>
      <div class="flex w-full items-center">
        <Popover v-model:open="showPurchaseDatePicker">
          <PopoverTrigger as-child>
            <ZButton variant="outline" class="w-full font-normal flex items-center justify-start">
              <div class="flex items-center w-full">
                <CalendarIcon class="w-[20px] mr-2" />
                <span v-if="asset.purchase_date" class="leading-3">
                  {{ formatDate(asset.purchase_date) }}
                </span>
                <span v-else>{{ $t('asset.form.date.button') }}</span>
              </div>
              <ChevronDownIcon class="w-[18px] ml-2 text-gray-400" />
            </ZButton>
          </PopoverTrigger>
          <PopoverContent class="w-auto p-0" align="start">
            <Calendar v-model="asset.purchase_date" timezone="UTC" :columns="1" />
          </PopoverContent>
        </Popover>
        <XCircleIcon v-if="asset.purchase_date" class="w-[25px] ml-2" @click.prevent="asset.purchase_date = ''" />
      </div>
    </Field>
    <Field symbol="amortization_value" class="col-span-2">
      <ZLabel for="amortization_value">
        {{ $t('asset.label.amortization_value') }}
      </ZLabel>
      <NumberInput id="amortization_value" v-model="asset.amortization_value" type="number" />
    </Field>
    <Field symbol="accounting_value" class="col-span-2">
      <ZLabel for="accounting_value">
        {{ $t('asset.label.accounting_value') }}
      </ZLabel>
      <NumberInput id="accounting_value" v-model="asset.accounting_value" type="number" />
    </Field>
  </div>
  <div class="col-span-4 text-center text-xs py-3">
    {{ $t('asset.label.description') }}
  </div>
  <Field symbol="description" class="col-span-4">
    <textarea
      id="name"
      v-model="asset.description"
      rows="4"
      class="flex w-full resize-none rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
    />
  </Field>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ZInput } from '@shadcn/components/ui/input';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';
import AssetData = App.Data.AssetData;
import { CalendarIcon, ChevronDownIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { ZButton } from '@shadcn/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/components/ui/popover';
import { Calendar } from '@shadcn/components/ui/calendar';
import {
  ZSelect,
  ZSelectContent,
  ZSelectGroup,
  ZSelectItem,
  ZSelectTrigger,
  ZSelectValue,
} from '@shadcn/components/ui/select';
import useAccessStore from '@/stores/access';
import NumberInput from "@ui/NumberInput.vue";

export default defineComponent({
  components: {
    NumberInput,
    ZSelectContent,
    ZSelectItem,
    ZSelectGroup,
    ZSelect,
    ZSelectTrigger,
    ZSelectValue,
    Popover,
    PopoverTrigger,
    Calendar,
    PopoverContent,
    ZButton,
    XCircleIcon,
    ChevronDownIcon,
    CalendarIcon,
    ZInput,
    ZLabel,
    Field,
  },

  props: {
    asset: {
      type: Object as PropType<AssetData>,
      required: true,
    },

    filters: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedCategory: '' as string,
      selectedModel: '' as string,
      showPurchaseDatePicker: false,
      showWarrantyDatePicker: false,
      showActivationDatePicker: false,
    };
  },

  computed: {
    access: () => useAccessStore(),
  },

  watch: {
    'asset.purchase_date'() {
      this.showPurchaseDatePicker = false;
    },

    'asset.warranty_expiration_date'() {
      this.showWarrantyDatePicker = false;
    },

    'asset.activation_date'() {
      this.showActivationDatePicker = false;
    },
  },
});
</script>
