<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('contracts.label.add_action_status') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('contracts.message.add_action_status') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <FormValidator ref="form">
          <Field symbol="status" class="pt-4">
            <ZLabel for="status">
              {{ $t('contracts.label.change_status') }}
            </ZLabel>
            <SelectField v-model="status" :options="statuses"></SelectField>
          </Field>
          <Field symbol="auto_status" class="pt-4 pb-4">
            <div class="flex flex-row gap-2 items-center">
              <ZSwitch :checked="autoStatus" @update:checked="autoStatus = !autoStatus" />
              <span class="flex-1">{{ $t('contracts.label.auto_status') }}</span>
            </div>
          </Field>
          <Field symbol="note" class="pt-4">
            <ZLabel for="note">
              {{ $t('contracts.label.note_content') }}
            </ZLabel>
            <textarea
              v-model="note"
              class="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
            ></textarea>
          </Field>
        </FormValidator>
        <ZDialogFooter>
          <Action :disabled="status == ''" @proceed="update">{{ $t('contracts.command.add_action_status') }} </Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import {ZSwitch} from "@shadcn/components/ui/switch";
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import { createContractChange } from '@/api/contract';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';
import ContractChangeRequest = App.Data.ContractChangeRequest;
import SelectField from "@ui/SelectField.vue";
import {KeyValue} from "@/types/Common";

export default defineComponent({
  components: {
    ZSwitch,
    SelectField,
    Field,
    ZLabel,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      contractId: '' as string,
      contractedActionId: null as string | null,
      contractedAssetId: null as string | null,
      state: 'loading' as ComponentLoadState,
      status: '' as string,
      autoStatus: true as boolean,
      note: '' as string,
      statuses: {} as KeyValue,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    this.statuses[this.SYMBOL.CONTRACT_STATUS.IN_PROGRESS] = this.$t('contract.status.in_progress');
    this.statuses[this.SYMBOL.CONTRACT_STATUS.DELAYED_EXECUTION] = this.$t('contract.status.delayed_execution');
    this.statuses[this.SYMBOL.CONTRACT_STATUS.BLOCKED_EXECUTION] = this.$t('contract.status.blocked_execution');
    this.statuses[this.SYMBOL.CONTRACT_STATUS.COMPLETED_EXECUTION] = this.$t('contract.status.completed_execution');

    try {
      this.contractId = this.$route.params.contractId.toString();
      this.contractedActionId = this.$route.params.contractedActionId ? this.$route.params.contractedActionId.toString() : null;
      this.contractedAssetId = this.$route.params.contractedAssetId ? this.$route.params.contractedAssetId.toString() : null;
      this.state = 'loaded';
    } catch (error: any) {
      this.state = error;
    }
  },

  methods: {
    update(action: any) {
      let fields = {
        note: this.note,
        changed_status: this.status,
        auto_status: this.autoStatus,
      } as ContractChangeRequest;

      if (this.contractedActionId != null) fields.contracted_action_id = parseInt(this.contractedActionId);
      if (this.contractedAssetId != null) fields.contracted_asset_id = parseInt(this.contractedAssetId);

      createContractChange(this.contractId, fields)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('contracts.message.contract_saved'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
