<template>
  <BaseAlert :variant="baseAlertVariant">
    <AlertTitle>
      <div class="flex items-center">
        <InformationCircleIcon v-if="type == 'info'" class="icon md text-blue-400" />
        <ExclamationTriangleIcon v-else-if="type == 'warning'" class="icon md text-yellow-400" />
        <QuestionMarkCircleIcon v-else-if="type == 'default'" class="icon md text-gray-400" />
        <XCircleIcon v-else-if="type == 'error'" class="icon md text-red-400" />
        <Cog8ToothIcon v-else-if="type == 'loading'" class="icon md animate-spin text-gray-400" />

        <div class="ml-2 leading-6 w-full">
          <slot></slot>
        </div>
      </div>
    </AlertTitle>
  </BaseAlert>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
  Cog8ToothIcon,
} from '@heroicons/vue/24/outline';
import { Alert as BaseAlert, AlertTitle } from '@shadcn/components/ui/alert';

type AlertType = 'info' | 'warning' | 'default' | 'error' | 'loading';

export default defineComponent({
  components: {
    // icons
    InformationCircleIcon,
    ExclamationTriangleIcon,
    QuestionMarkCircleIcon,
    XCircleIcon,
    Cog8ToothIcon,
    // shadcn components
    BaseAlert,
    AlertTitle,
  },

  props: {
    type: {
      type: String as PropType<AlertType>,
      default: 'info',
    },
  },

  computed: {
    baseAlertVariant() {
      switch (this.type) {
        case 'info':
          return 'info';
        case 'warning':
          return 'warning';
        case 'error':
          return 'destructive';
        default:
          return 'default';
      }
    },
  },
});
</script>
<style>
.alert {
  @apply p-2 rounded border items-center text-sm;

  &.default {
    @apply bg-white border-gray-100;
    .icon {
      @apply text-gray-400;
    }
  }

  &.info {
    @apply bg-blue-50 border-blue-400;
    .icon {
      @apply text-blue-400;
    }
  }

  &.warning {
    @apply bg-yellow-50 border-yellow-400;
    .icon {
      @apply text-yellow-400;
    }
  }

  &.error {
    @apply bg-red-50 border-red-400 text-red-700;
    .icon {
      @apply text-red-400;
    }
  }
}
</style>
