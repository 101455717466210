<template>
  <div class="mt-4 space-y-4">
    <h3 class="font-bold text-lg flex flex-row gap-2 border rounded border-green-500 p-4">
      <Square3Stack3DIcon class="icon md" />
      {{ category.name }}
    </h3>
    <FormValidator v-if="state == 'loaded'" ref="form">
      <CategoryAttributeForm
        :attribute-types="attributeTypes"
        :category-attribute="categoryAttribute"
      ></CategoryAttributeForm>
    </FormValidator>
    <div class="flex flex-row gap-2 pb-4">
      <ZButton variant="outline" @click="cancel()">{{ $t('common.cancel') }}</ZButton>
      <Action @proceed="update">
        {{ $t('category.command.save_changes') }}
      </Action>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import CategoryAttributeForm from '@ui/Categories/CategoryAttributeForm.vue';
import FormValidator from '@ui/FormValidator.vue';
import Action from '@ui/Action.vue';
import { ZButton } from '@shadcn/components/ui/button';
import { Square3Stack3DIcon } from '@heroicons/vue/24/outline';
import type { GroupedKeyValue, KeyValue } from '@/types/Common';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import attributeTypesStore from '@/stores/attributeTypes';
import { getCategoryAttribute, saveCategoryAttribute } from '@/api/category';
import CategoryData = App.Data.CategoryData;
import CategoryAttributeData = App.Data.CategoryAttributeData;

export default defineComponent({
  components: { Square3Stack3DIcon, Action, FormValidator, CategoryAttributeForm, ZButton },

  props: {
    categoryAttributeId: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      category: {} as CategoryData,
      categoryAttribute: {} as CategoryAttributeData,
      attributeTypes: {} as KeyValue,
      translations: {} as GroupedKeyValue,
      isDialogOpen: true,

      state: 'loading' as ComponentLoadState,
    };
  },

  async mounted() {
    try {
      const attributeTypes = attributeTypesStore();
      this.attributeTypes = await attributeTypes.get();
      const response = await getCategoryAttribute(this.categoryAttributeId).execute();
      this.categoryAttribute = response.data;
      this.category = response.data.category;

      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
    }
  },

  methods: {
    update(action: any) {
      if (!this.categoryAttribute) {
        return;
      }

      if (this.categoryAttribute.type != 'NUMBER') this.categoryAttribute.unit = '';

      let data = JSON.parse(JSON.stringify(this.categoryAttribute));

      saveCategoryAttribute(data, this.categoryAttribute.id!)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('category.message.category_attribute_saved'),
          });
          this.$router.back();
        });
    },

    cancel() {
      this.$router.back();
    },
  },
});
</script>
