<template>
  <Field symbol="name" class="col-span-3">
    <ZLabel for="name">
      {{ $t('organization.form.name') }}
    </ZLabel>
    <ZInput id="name" v-model="organization.name" />
  </Field>
  <Field symbol="legal_form" class="col-span-3">
    <ZLabel for="legal_form">
      {{ $t('organization.form.legal_form') }}
    </ZLabel>
    <div>
      <SelectField
        id="legal_form"
        v-model="organization.legal_form"
        :placeholder="$t('organization.form.select_legal_form')"
        :options="legalForms"
        translate="symbols."
      />
    </div>
  </Field>
  <Field symbol="tax_number" class="col-span-3">
    <ZLabel for="tax_number">
      {{ $t('organization.form.tax_number') }}
    </ZLabel>
    <ZInput id="tax_number" v-model="organization.tax_number" />
  </Field>
  <Field symbol="registration_number" class="col-span-3">
    <ZLabel for="registration_number">
      {{ $t('organization.form.registration_number') }}
    </ZLabel>
    <ZInput id="registration_number" v-model="organization.registration_number" />
  </Field>
  <Field symbol="country_id" class="col-span-3">
    <ZLabel for="country_id">
      {{ $t('organization.form.country') }}
    </ZLabel>
    <div>
      <SelectField
        id="country_id"
        v-model="organization.country_id"
        :placeholder="$t('organization.label.select_country')"
        :options="countries"
      />
    </div>
  </Field>
  <Field symbol="region_id" class="col-span-3">
    <ZLabel for="region_id">
      {{ $t('organization.form.region') }}
    </ZLabel>
    <div>
      <SelectField
        id="region_id"
        v-model="organization.region_id"
        :placeholder="$t('organization.label.select_region')"
        :options="regionsForCountry"
        :disabled="!organization.country_id"
      />
    </div>
  </Field>
  <Field symbol="city" class="col-span-4">
    <ZLabel for="city">
      {{ $t('organization.form.city') }}
    </ZLabel>
    <ZInput id="city" v-model="organization.city" />
  </Field>
  <Field symbol="postal" class="col-span-2">
    <ZLabel for="postal">
      {{ $t('organization.form.postal') }}
    </ZLabel>
    <ZInput id="postal" v-model="organization.postal" />
  </Field>
  <Field symbol="street" class="col-span-6">
    <ZLabel for="street">
      {{ $t('organization.form.street') }}
    </ZLabel>
    <ZInput id="street" v-model="organization.street" />
  </Field>
  <Field symbol="building_number" class="col-span-3">
    <ZLabel for="building_number">
      {{ $t('organization.form.building_number') }}
    </ZLabel>
    <ZInput id="building_number" v-model="organization.building_number" />
  </Field>
  <Field symbol="apartment_number" class="col-span-3">
    <ZLabel for="apartment_number">
      {{ $t('organization.form.apartment_number') }}
    </ZLabel>
    <ZInput id="street_no" v-model="organization.apartment_number" />
  </Field>
  <Field symbol="contact_person" class="col-span-6">
    <ZLabel for="contact_person">
      {{ $t('organization.label.contact_person') }}
    </ZLabel>
    <ZInput id="contact_person" v-model="organization.contact_person" />
  </Field>
  <Field symbol="contact_email" class="col-span-3">
    <ZLabel for="contact_email">
      {{ $t('organization.form.email') }}
    </ZLabel>
    <ZInput id="contact_email" v-model="organization.contact_email" />
  </Field>
  <Field symbol="contact_phone" class="col-span-3">
    <ZLabel for="contact_phone">
      {{ $t('organization.label.contact_phone') }}
    </ZLabel>
    <ZInput id="contact_phone" v-model="organization.contact_phone" />
  </Field>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ZInput } from '@shadcn/components/ui/input';
import { ZLabel } from '@shadcn/components/ui/label';
import SelectField from '@ui/SelectField.vue';
import Field from '@ui/FormField.vue';
import type { KeyValue } from '@/types/Common';
import OrganizationData = App.Data.OrganizationData;
import RegionWithTranslationData = App.Data.RegionWithTranslationData;

export default defineComponent({
  components: {
    ZInput,
    ZLabel,
    SelectField,
    Field,
  },

  props: {
    legalForms: {
      type: Object as PropType<KeyValue>,
      required: true,
    },

    organization: {
      type: Object as PropType<OrganizationData>,
      required: true,
    },

    countries: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },

    regions: {
      type: Object as PropType<RegionWithTranslationData[]>,
      required: true,
    },
  },

  computed: {
    regionsForCountry() {
      if (!this.organization || !this.regions) {
        return {};
      }

      let options = {} as KeyValue;
      for (let index in this.regions) {
        if (this.regions[index].country_id == this.organization.country_id)
          options[this.regions[index].id] = this.regions[index].translations[0].translation;
      }
      return options;
    },
  },
});
</script>
