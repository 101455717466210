<template>
  <div v-if="user.changes && user.changes[field as keyof UserAccountChangesData]" class="flex flex-col gap-2">
    <small>{{ $t('userAccount.label.changed_to') }}:</small>
    <div class="flex flex-row gap-2 border border-yellow-400 p-1 px-2 justify-between items-center rounded">
      <span class="tex-sm">{{ user.changes[field as keyof UserAccountChangesData] }}</span>
      <div class="flex gap-2 items-center">
        <a href="#" @click.prevent="accept(field as keyof UserAccountChangesData)"
          ><HandThumbUpIcon class="icon sm opacity-50 hover:opacity-100"
        /></a>
        <a href="#" @click.prevent="decline(field as keyof UserAccountChangesData)"
          ><HandThumbDownIcon class="icon sm opacity-50 hover:opacity-100"
        /></a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/vue/24/outline';
import UserAccountData = App.Data.UserAccountData;
import UserAccountChangesData = App.Data.UserAccountChangesData;

export default defineComponent({
  components: {
    HandThumbUpIcon,
    HandThumbDownIcon,
  },

  props: {
    user: {
      type: Object as PropType<UserAccountData>,
      required: true,
    },

    field: {
      type: String,
      required: true,
    },
  },

  methods: {
    accept(field: keyof UserAccountChangesData) {
      if (!this.user.changes) return;

      this.user[field as keyof UserAccountChangesData] = this.user.changes[field as keyof UserAccountChangesData]!;
      delete this.user.changes[field as keyof UserAccountChangesData];
    },

    decline(field: keyof UserAccountChangesData) {
      if (this.user.changes) delete this.user.changes[field as keyof UserAccountChangesData];
    },
  },
});
</script>
