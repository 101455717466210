import { http } from './http';
import { ApiCall } from '@/api/ApiCall';
import { PaginatedResponse } from '@/utils/DataSet';
import AssetData = App.Data.AssetData;
import { KeyValue } from '@/types/Common';
import AssetRequestData = App.Data.AssetRequestData;
import SavedRecordResponse = App.Data.SavedRecordResponse;
import NewRecordResponse = App.Data.NewRecordResponse;
import AssetDataStoreRequest = App.Data.AssetDataStoreRequest;
import MinimalAssetData = App.Data.MinimalAssetData;
import AssetDataUpdateCategoryRequest = App.Data.AssetDataUpdateCategoryRequest;
import AssetTagData = App.Data.AssetTagData;
import ContractFilterRequestData = App.Data.ContractFilterRequestData;
import InventoryFilterRequestData = App.Data.InventoryFilterRequestData;
import AssetInventoryData = App.Data.AssetInventoryData;
import AssetContractData = App.Data.AssetContractData;
import FileData = App.Data.FileData;
import AssetsOfflineCompareRequestData = App.Data.AssetsOfflineCompareRequestData;
import AssetFilesRequestData = App.Data.AssetFilesRequestData;
import AssetDuplicateRequest = App.Data.AssetDuplicateRequest;

export const getAssets = (fields: AssetRequestData) => {
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<MinimalAssetData>>(`assets` + query);
    },
    {},
    '',
    fields,
  );
};

export const getExistingAssets = (fields: AssetsOfflineCompareRequestData) => {
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<MinimalAssetData>>(`assetsOfflineCompare` + query);
    },
    {},
    '',
    fields,
  );
};

export const getAsset = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get<AssetData>(`/assets/${options.id}`);
  }, options);
};

export const createAsset = (fields: AssetDataStoreRequest) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<NewRecordResponse>('/assets', fields);
    },
    {},
    '',
    fields,
  );
};

export const saveAssetAttributes = (id: string, fields: Array<App.Data.AssetValueData>) => {
  const options = {} as KeyValue;
  options.id = String(id);
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(`/assets/${options.id}/attributes` + query, fields);
    },
    options,
    '',
    fields,
  );
};

export const updateAsset = (id: string, fields: AssetData) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(`/assets/${options.id}`, fields);
    },
    options,
    '',
    fields,
  );
};

export const updateAssetCategory = (id: string, fields: AssetDataUpdateCategoryRequest) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(`/assets/${options.id}/updateCategory`, fields);
    },
    options,
    '',
    fields,
  );
};

export const duplicateAsset = (id: string, duplicates: number) => {
  let fields = {
    duplicates: duplicates
  } as AssetDuplicateRequest;

  return ApiCall.create(
    (options, query, fields) => {
      return http.post<AssetDuplicateRequest>(`/assets/${id}/duplicateAsset`, fields);
    },
    {},
    '',
    fields,
  );
};

export const deleteAsset = (id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  return ApiCall.create(options => {
    return http.delete(`/assets/${options.id}`);
  }, options);
};
export const updatePrimaryImage = (id: string, primary_image_id: number) => {
  const options = {} as KeyValue;
  options.id = id;
  const fields = {
    primary_image_id: primary_image_id,
  };
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(`/assets/${options.id}/updatePrimaryImage`, fields);
    },
    options,
    '',
    fields,
  );
};
export const deleteAssetImage = (id: string, image_id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  options.image_id = image_id.toString();
  return ApiCall.create(options => {
    return http.delete(`/assets/${options.id}/images/${options.image_id}/delete`);
  }, options);
};
export const storeAssetImage = (id: string, fields: FormData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post(`/assets/${id}/images`, fields);
    },
    {},
    '',
    fields,
  );
};

export const updateAssetStatus = (id: string, status: string) => {
  const fields = {
    status: status,
  };
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch(`/assets/${id}/updateStatus`, fields);
    },
    {},
    '',
    fields,
  );
};

export const updateAssetStatuses = (asset_ids: Array<string | undefined>, status: string) => {
  const fields = {
    asset_ids: asset_ids,
    status: status,
  };
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch(`/assets/updateMultipleStatuses`, fields);
    },
    {},
    '',
    fields,
  );
};

export const getAssetTags = (id: string) => {
  return ApiCall.create(
    (options, query) => {
      return http.get<AssetTagData[]>(`/assets/${id}/tags` + query);
    },
    {},
    '',
  );
};

export const manageAssetTags = (id: string, tags: Array<number>) => {
  const fields = {
    tags: tags,
  };
  return ApiCall.create(
    (options, query, fields) => {
      return http.post(`/assets/${id}/tags`, fields);
    },
    {},
    '',
    fields,
  );
};

export const massTagsAssign = (asset_ids: Array<string | undefined>, tags: Array<number>) => {
  const fields = {
    tags: tags,
    asset_ids: asset_ids,
  };
  return ApiCall.create(
    (options, query, fields) => {
      return http.post(`/assets/massTagsAssign`, fields);
    },
    {},
    '',
    fields,
  );
};

export const massTagsRemove = (asset_ids: Array<string | undefined>, tags: Array<number>) => {
  const fields = {
    tags: tags,
    asset_ids: asset_ids,
  };
  return ApiCall.create(
    (options, query, fields) => {
      return http.post(`/assets/massTagsRemove`, fields);
    },
    {},
    '',
    fields,
  );
};
export const transferAssets = (fields: {
  asset_ids: any[];
  branch_id: string;
  organization_id: string;
  within_organization: boolean;
}) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post(`/assets/transferAssets`, fields);
    },
    {},
    '',
    fields,
  );
};
export const getAssetInventories = (fields: InventoryFilterRequestData, id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<AssetInventoryData>>(`/assets/${options.id}/inventory` + query);
    },
    options,
    '',
    fields,
  );
};

export const getAssetContracts = (fields: ContractFilterRequestData, id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<AssetContractData>>(`/assets/${options.id}/contracts` + query);
    },
    options,
    '',
    fields,
  );
};

export const getAssetFiles = (fields: AssetFilesRequestData, id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<FileData>>(`/assets/${options.id}/files` + query);
    },
    options,
    '',
    fields,
  );
};

export const setCarbonAmortizationValue = (
  id: number,
  method_type: string,
  starts_at: Date,
  value: number,
  months: number | null,
) => {
  const fields = {
    method_type: method_type,
    starts_at: starts_at,
    value: value,
    months: months,
  };

  return ApiCall.create(
    (options, query, fields) => {
      return http.post(`/assets/${id}/setCarbonFootprint`, fields);
    },
    {},
    '',
    fields,
  );
};
