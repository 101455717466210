<template>
  <div>
    <ZSelect v-model="language_code">
      <ZSelectTrigger class="border-0 gap-1 outline-none">
        <ZSelectValue :placeholder="$t('notification.placeholder')" />
      </ZSelectTrigger>
      <ZSelectContent>
        <ZSelectGroup>
          <ZSelectItem v-for="(lang, index) in languages" :key="index" :value="lang.code">{{ lang.code }}</ZSelectItem>
        </ZSelectGroup>
      </ZSelectContent>
    </ZSelect>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { ZButton } from '@shadcn/components/ui/button';
import { useToast } from '@shadcn/components/ui/toast/use-toast';
import { getCurrentUser, postChangeLanguage } from '@/api';
import loaderStore from '../stores/loader';
import { HttpStatusCode } from 'axios';
import i18n from '../utils/i18n';
import {
  ZSelect,
  ZSelectContent,
  ZSelectGroup,
  ZSelectItem,
  ZSelectTrigger,
  ZSelectValue,
} from '@shadcn/components/ui/select';
import useUserStore from '@/stores/auth';
import { getLanguages } from '@/api/dictionaries';

export default defineComponent({
  components: {
    ZSelectValue,
    ZSelectItem,
    ZSelectContent,
    ZSelectGroup,
    ZSelectTrigger,
    ZSelect,
    ZButton,
  },

  data() {
    return {
      loader: loaderStore(),
      firstLoad: true,
      languages: [] as App.Data.LanguageData[],
      language_code: '',
    };
  },

  watch: {
    language_code(val: string) {
      this.update();
    },
  },

  async mounted() {
    this.loader.startLoading();
    const { user } = useUserStore();
    this.language_code = user?.language_code as string;
    const response: any = await getLanguages();
    this.languages = response.data;
    this.loader.stopLoading();
  },

  methods: {
    update() {
      const { toast } = useToast();
      this.loader.startLoading();
      postChangeLanguage({ language_code: this.language_code })
        .then(async () => {
          if (!this.firstLoad) {
            toast({
              title: this.$t('common.success.header'),
              description: this.$t('common.success.saved'),
            });
          } else {
            this.firstLoad = false;
          }
          const { data } = await getCurrentUser();
          i18n.global.locale = <any>data.language_code;
          const { updateUser } = useUserStore();
          updateUser(data);
        })
        .catch(error => {
          if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
            toast({
              title: this.$t('common.errors.header'),
              description: this.$t(error.response.data.message),
              variant: 'destructive',
            });
          }
        })
        .finally(() => this.loader.stopLoading());
    },
  },
});
</script>
