<template>
  <div class="border overflow-hidden">
    <div class="flex p-3 flex-row justify-between items-center border-b border-b-green-500 bg-green-50" v-if="editable">
      <span class="font-semibold">{{ $t('asset.label.carbon_footprint_amortization') }}</span>
      <PencilSquareIcon class="icon sm cursor-pointer mr-2" @click="isCarbonDialogOpen = true" />
    </div>
    <div class="flex flex-col">
      <div v-if="!asset.carbon_footprint_balance" class="m-4 p-4 text-center text-sm text-gray-600">
        {{ $t('asset.label.no_carbon_value') }}
      </div>
      <div v-else class="m-4 p-4 text-center flex flex-col">
        <span class="i-label">
          {{ $t('asset.message.carbon_amortization_value') }}
          <b>{{ readableCarbonValue(asset.carbon_footprint_balance) }}</b></span
        >
        <span v-if="asset.carbon_amortization_months && asset.carbon_amortization_months > 0" class="i-label text-sm">
          <span
            v-html="
              $t('asset.message.carbon_amortization_value_split', {
                months: `<b>${asset.carbon_amortization_months}</b>`,
                value: `<b>${readableCarbonValue(asset.single_month_footprint_value)}</b>`,
              })
            "
          ></span>
        </span>
      </div>
    </div>
  </div>
  <AssetCarbonDialog v-if="isCarbonDialogOpen" :asset="asset" @saved="saveCarbonValue" />
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import { inject } from '@modules/vue';
import { Dialogs } from '@/types/Dialogs';
import { PencilSquareIcon } from '@heroicons/vue/24/outline';
import AssetCarbonDialog from '@views/Assets/AssetCarbonDialog.vue';
import AssetData = App.Data.AssetData;

export default defineComponent({
  components: {
    AssetCarbonDialog,
    //icons
    PencilSquareIcon,
  },

  props: {
    asset: {
      type: {} as PropType<AssetData>,
      required: true,
    },

    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      edit: null as number | null,
      error: false as boolean,
      isCarbonDialogOpen: false as boolean,
    };
  },

  methods: {
    saveCarbonValue() {
      this.isCarbonDialogOpen = false;
      this.$emit('saved');
    },
  },
});
</script>
