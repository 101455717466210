import { defineStore } from 'pinia';
import { getLanguages } from '@/api/commonApis';
import { KeyValue } from '@/types/Common';
import LanguageData = App.Data.LanguageData;

const languagesStore = defineStore('languagesStore', {
  state: () => ({
    languages: [] as LanguageData[],
    loaded: false as boolean,
  }),

  actions: {
    async get(exclude: string | null = null) {
      if (this.loaded) return this.keys(exclude);
      await this.load();
      return this.keys(exclude);
    },

    keys(exclude: string | null = null) {
      const result = {} as KeyValue;

      for (let index = 0; index < this.languages.length; index++) {
        if (exclude != null && exclude == this.languages[index].code) continue;
        result[this.languages[index].code] = this.languages[index].code;
      }

      return result;
    },

    async load() {
      const response = await getLanguages().execute();
      this.languages = response.data;
      this.loaded = true;
    },
  },
});
export default languagesStore;
