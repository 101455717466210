<template>
  <div>
    <template v-if="assetDataSet && filters">
      <div class="grid sm:grid-cols-2 lg:grid-cols-1 lg:flex sm:gap-4 mt-5">
        <div class="w-full grid gap-2 mb-4">
          <ZLabel>{{ $t('asset.label.asset_search') }}</ZLabel>
          <ZInput v-model="filters.search" type="search" :placeholder="$t('asset.label.asset_search_placeholder')" />
        </div>
        <div class="w-full grid gap-2 mb-4">
          <ZLabel>{{ $t('asset.label.filter_category') }}</ZLabel>
          <ZSelect v-model="filters.category">
            <ZSelectTrigger class="w-full">
              <div class="flex flex-row gap-2 whitespace-nowrap">
                <ZSelectValue :placeholder="$t('asset.label.placeholder')" />
              </div>
            </ZSelectTrigger>
            <ZSelectContent>
              <SelectItem value="0">Wszystkie</SelectItem>
              <ZSelectItem v-for="item in categoriesTree" :key="item[0]" :value="item[0]">
                {{ item[1] }}
              </ZSelectItem>
            </ZSelectContent>
          </ZSelect>
        </div>
        <div class="w-full grid gap-2 mb-4">
          <ZLabel>{{ $t('asset.label.filter_tag') }}</ZLabel>
          <SelectMultipleField v-model="filters.tags" :placeholder="$t('asset.label.all_tags')" :options="tags" />
        </div>
        <div class="w-full grid gap-2 mb-4">
          <ZLabel for="technical_condition">
            {{ $t('asset.label.technical_condition') }}
          </ZLabel>
          <ZSelect v-model="filters.technical_condition!" class="w-full">
            <ZSelectTrigger class="w-full">
              <div class="flex flex-row gap-2 whitespace-nowrap">
                <ZSelectValue :placeholder="$t('asset.label.category')" />
              </div>
            </ZSelectTrigger>
            <ZSelectContent>
              <ZSelectGroup>
                <ZSelectItem value="all">{{ $t('asset.form.all') }}</ZSelectItem>
                <ZSelectItem value="good">
                  {{ $t('asset.technical_conditions.good') }}
                </ZSelectItem>
                <ZSelectItem value="damaged">
                  {{ $t('asset.technical_conditions.damaged') }}
                </ZSelectItem>
              </ZSelectGroup>
            </ZSelectContent>
          </ZSelect>
        </div>
      </div>
      <div class="border rounded">
        <ZTable class="w-full">
          <ZTableHeader>
            <ZTableRow>
              <ZTableHead>
                <Checkbox
                  id="requires_all"
                  class="h-6 w-6"
                  :checked="selectedAll"
                  @update:checked="selectAll"
                />

              </ZTableHead>
              <ZTableHead class="min-w-[80px]"></ZTableHead>
              <ZTableHead>{{ $t('asset.label.category') }}</ZTableHead>
              <ZTableHead>{{ $t('asset.label.asset_model') }}</ZTableHead>
              <ZTableHead>{{ $t('asset.label.manufacturer') }}</ZTableHead>
              <ZTableHead>{{ $t('asset.label.inventory_number') }}</ZTableHead>
              <ZTableHead>{{ $t('asset.label.location') }}</ZTableHead>
            </ZTableRow>
          </ZTableHeader>
          <ZTableBody>
            <ZTableRow
              v-for="(item, index) in assetDataSet.data"
              :key="index"
              class="cursor-pointer"
              :class="{ 'bg-accent': item.selected }"
            >
              <ZTableCell>
                <Checkbox
                  id="is_required"
                  class="h-6 w-6"
                  :checked="chosenAssets.includes(item.id)"
                  :disabled="item.selected"
                  @update:checked="toggleChosenAsset(item)"
                />
              </ZTableCell>
              <ZTableCell>
                <img
                  v-if="item.thumb"
                  class="w-[50px] h-[50px] object-cover rounded-xl"
                  :src="item.thumb"
                  alt="primaryImage"
                />
              </ZTableCell>
              <ZTableCell>{{ item.category ? item.category.name : $t('asset.label.lack') }}</ZTableCell>
              <ZTableCell>
                {{ item.asset_model ? item.asset_model?.name : $t('asset.label.lack') }}
              </ZTableCell>
              <ZTableCell>
                {{ item.asset_model?.manufacturer ?? '-' }}
              </ZTableCell>
              <ZTableCell>
                {{ item.asset_number }}
              </ZTableCell>
              <ZTableCell>
                <div class="flex flex-col gap-1">
                  <small>{{ item.branch?.address }}</small>
                  {{ item.branch?.path }} /
                  {{ item.location ?? '-' }}
                </div>
              </ZTableCell>
            </ZTableRow>
          </ZTableBody>
        </ZTable>
        <div v-if="assetDataSet.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
          <ExclamationTriangleIcon class="icon lg" />
          {{ $t('asset.message.data_not_found') }}
        </div>
      </div>
      <Paginator :data="assetDataSet" />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, PropType } from 'vue';
import { DataSet } from '@/utils/DataSet';
import MinimalAssetData = App.Data.MinimalAssetData;
import { ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow } from '@shadcn/components/ui/table';
import { ZInput } from '@shadcn/components/ui/input';
import { ZLabel } from '@shadcn/components/ui/label';
import Paginator from '@ui/Paginator.vue';
import { Checkbox } from '@shadcn/components/ui/checkbox';
import SelectMultipleField from '@ui/SelectMultipleField.vue';
import {
  ZSelect,
  ZSelectContent,
  ZSelectGroup,
  ZSelectItem,
  ZSelectTrigger,
  ZSelectValue,
} from '@shadcn/components/ui/select';
import SelectItem from '@shadcn/components/ui/select/SelectItem.vue';
import { ExclamationTriangleIcon } from '@modules/@heroicons/vue/24/outline';

export default defineComponent({
  components: {
    ExclamationTriangleIcon,
    ZSelectGroup,
    ZSelectItem,
    ZSelectValue,
    ZSelectContent,
    ZSelectTrigger,
    SelectItem,
    ZSelect,
    SelectMultipleField,
    Checkbox,
    Paginator,
    ZLabel,
    ZInput,
    ZTableCell,
    ZTableHeader,
    ZTableRow,
    ZTable,
    ZTableHead,
    ZTableBody,
  },

  props: {
    assetDataSet: {
      type: {} as PropType<DataSet<any, any>>,
      required: true,
    },

    filters: {
      type: Object,
      required: true,
    },

    categories: {
      type: Object,
      required: true,
    },

    tags: {
      type: Object,
      required: true,
    },
  },

  emits: ['change'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      chosenAssets: [] as Array<number>,
      selectedAll: false,
    };
  },

  computed: {
    categoriesTree() {
      let tree = new Map<string, string>();

      const indentation = '-----------------------------';

      for (let index in this.categories) {
        const category = this.categories[index];
        const treeKey = category.id.toString();
        tree.set(treeKey, indentation.substring(0, (category.depth - 1) * 2) + ' ' + category.name);
      }

      return tree;
    },
  },

  watch: {},

  methods: {
    toggleChosenAsset(item: MinimalAssetData) {
      if (!item) {
        return;
      }
      const assetChosen = this.chosenAssets.find(asset => asset == item!.id!);
      if (assetChosen) {
        this.chosenAssets.splice(this.chosenAssets.indexOf(assetChosen), 1);
      } else {
        this.chosenAssets.push(item!.id!);
      }
      this.$emit('change', this.chosenAssets);
    },

    selectAll() {
      this.selectedAll = !this.selectedAll;
      if (this.selectedAll) {
        for (let item of this.assetDataSet.data) {
          const assetChosen = this.chosenAssets.find(asset => asset == item!.id!);
          if (!assetChosen && !item.selected) {
            this.chosenAssets.push(item!.id!);
          }
        }
      } else {
        for (let item of this.assetDataSet.data) {
          const assetChosen = this.chosenAssets.find(asset => asset == item!.id!);
          if (assetChosen && !item.selected) {
            this.chosenAssets.splice(this.chosenAssets.indexOf(assetChosen), 1);
          }
        }
      }
      this.$emit('change', this.chosenAssets);
    }
  },
});
</script>
