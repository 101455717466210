<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('userAccount.label.change_user_password') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('userAccount.message.change_user_password') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <FormValidator ref="form" class="grid grid-cols-4 gap-4 py-4" default-action="($refs.update as any).proceed()">
        <div class="border-b pb-4 col-span-4">
          <ZButton type="button" variant="outline" class="w-full" @click.prevent="sendPasswordReset">
            {{ $t('userAccount.command.send_new_password_link_reset') }}
          </ZButton>
        </div>
        <div class="col-span-4">
          <IconAlert>
            {{ $t('userAccount.message.set_new_password_manually') }}
          </IconAlert>
        </div>
        <Field symbol="password" class="col-span-4">
          <ZLabel for="password">
            {{ $t('userAccount.label.new_password') }}
          </ZLabel>
          <ZInput id="password" v-model="fields.password" type="password" />
        </Field>
        <Field symbol="password_confirmation" class="col-span-4">
          <ZLabel for="password">
            {{ $t('userAccount.label.new_password_confirmation') }}
          </ZLabel>
          <ZInput id="password_confirm" v-model="fields.password_confirmation" type="password" />
        </Field>
      </FormValidator>
      <ZDialogFooter>
        <Action ref="update" @proceed="update">{{ $t('userAccount.command.save_changes') }}</Action>
      </ZDialogFooter>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogHeader,
  ZDialogTitle,
  ZDialogFooter,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import { sendPasswordResetLink, updateUserPassword } from '@/api/userAccount';
import Field from '@ui/FormField.vue';
import { ZInput } from '@shadcn/components/ui/input';
import { ZLabel } from '@shadcn/components/ui/label';
import { ZButton } from '@shadcn/components/ui/button';
import AdminUpdatesUserPasswordRequestData = App.Data.AdminUpdatesUserPasswordRequestData;
import IconAlert from '@ui/IconAlert.vue';
import { Dialogs } from '@/types/Dialogs';
import { toast } from '@shadcn/components/ui/toast';

export default defineComponent({
  components: {
    IconAlert,
    ZLabel,
    ZInput,
    ZButton,
    Field,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogHeader,
    ZDialogTitle,
    ZDialogFooter,
  },

  props: {
    userId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as typeof toast,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      fields: {} as AdminUpdatesUserPasswordRequestData,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {},

  methods: {
    async update(action: any) {
      try {
        await this.dialogs.authorizeAction();
        updateUserPassword(this.userId, this.fields)
          .withForm(this.$refs.form as typeof FormValidator)
          .withLoader(action.loader)
          .execute()
          .then(() => {
            this.isDialogOpen = false;
            this.toast({
              title: this.$t('toasts.type.saved'),
              description: this.$t('userAccount.message.user_account_password_updated'),
            });
            this.$emit('saved');
          });
      } catch (error: any) {}
    },

    async sendPasswordReset() {
      this.dialogs
        .confirm(this.$t('userAccount.label.sending_reset_link'), this.$t('userAccount.message.sending_reset_link'))
        .then(async () => {
          await sendPasswordResetLink(this.userId).execute();
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('userAccount.label.password_reset_link_sent'),
            description: this.$t('userAccount.message.password_reset_link_sent'),
          });
        });
    },
  },
});
</script>
