import type { RouteRecordRaw } from 'vue-router';
import { ROUTE } from './routeNames';
import Contracts from '@views/Contracts/Contracts.vue';
import NewContractDialog from '@views/Contracts/NewContractDialog.vue';
import ContractDetails from '@views/Contracts/ContractDetails.vue';
import AddContractActionsDialog from '@views/Contracts/AddContractActionsDialog.vue';
import ContractActionAssignAssetDialog from '@views/Contracts/ContractActionAssignAssetDialog.vue';
import EditContractDataDialog from '@views/Contracts/EditContractDataDialog.vue';
import EditContractActionDialog from '@views/Contracts/EditContractActionDialog.vue';
import ContractActionAssetAddNoteDialog from '@views/Contracts/ContractActionAssetAddNoteDialog.vue';
import ManagedContracts from '@views/Contracts/ManagedContracts.vue';
import ManagedContractDetails from '@views/Contracts/ManagedContractDetails.vue';
import ManagedContractStatusUpdateDialog from '@views/Contracts/ManagedContractStatusUpdateDialog.vue';
import ManagedContractAddNoteDialog from '@views/Contracts/ManagedContractAddNoteDialog.vue';
import ManagedContractedAssetCarbonValueDialog from '@views/Contracts/ManagedContractedAssetCarbonValueDialog.vue';
import ManagedContractAddActionStatusDialog from "@views/Contracts/ManagedContractAddActionStatusDialog.vue";

export const contractRoutes: RouteRecordRaw[] = [
  {
    path: 'managedContracts',
    name: 'managed.contracts.layout',
    meta: {
      breadcrumbs: true,
      title: 'contracts.label.managed_contracts_title',
      permission: [{operator: true}],
    },
    redirect: () => {
      return { name: ROUTE.MANAGED_CONTRACTS_LIST };
    },
    children: [
      {
        path: '',
        component: ManagedContracts,
        name: ROUTE.MANAGED_CONTRACTS_LIST,
        meta: {
          breadcrumbs: true,
          title: 'contracts.label.managed_contracts_index',
        },
      },
      {
        path: ':contractId',
        component: ManagedContractDetails,
        name: ROUTE.MANAGED_CONTRACT_DETAILS,
        props: true,
        meta: {
          breadcrumbs: true,
          title: 'contracts.label.details_title',
        },
        children: [
          {
            path: 'process',
            props: true,
            component: ManagedContractStatusUpdateDialog,
            name: ROUTE.MANAGED_CONTRACT_STATUS_UPDATE,
          },
          {
            path: 'carbon/:contractedAssetId',
            props: true,
            component: ManagedContractedAssetCarbonValueDialog,
            name: ROUTE.MANAGED_CONTRACT_ASSET_CARBON,
          },
          {
            path: 'contracted-action/:actionId',
            component: EditContractActionDialog,
            props: route => ({
              contractId: route.params.contractId,
              actionId: route.params.actionId,
              full: true,
            }),
            name: ROUTE.MANAGED_CONTRACT_ACTION_EDIT,
          },
          {
            path: 'change-status/:contractedActionId',
            component: ManagedContractAddActionStatusDialog,
            name: ROUTE.MANAGED_CONTRACT_ACTION_STATUS,
          },
          {
            path: 'add-note',
            component: ManagedContractAddNoteDialog,
            name: ROUTE.MANAGED_CONTRACT_ADD_NOTE,
          },
          {
            path: 'add-note-to-asset/:contractedAssetId',
            component: ManagedContractAddNoteDialog,
            name: ROUTE.MANAGED_CONTRACT_ADD_NOTE_TO_ASSET,
          },
          {
            path: 'add-note-to-action/:contractedActionId',
            component: ManagedContractAddNoteDialog,
            name: ROUTE.MANAGED_CONTRACT_ADD_NOTE_TO_ACTION,
          },
        ],
      },
    ],
  },
  {
    path: 'contracts',
    name: 'contracts.layout',
    redirect: () => {
      return { name: ROUTE.CONTRACTS_LIST };
    },
    meta: {
      breadcrumbs: true,
      title: 'contracts.label.list_title',
    },
    children: [
      {
        path: '',
        component: Contracts,
        name: ROUTE.CONTRACTS_LIST,
        children: [
          {
            path: 'add',
            component: NewContractDialog,
            name: ROUTE.CONTRACTS_ADD,
          },
        ],
      },
      {
        path: ':contractId',
        component: ContractDetails,
        name: ROUTE.CONTRACT_DETAILS,
        meta: {
          breadcrumbs: true,
          title: 'contracts.label.details_title',
        },
        props: true,
        children: [
          {
            path: 'editDetails',
            component: EditContractDataDialog,
            name: ROUTE.CONTRACT_EDIT,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'addActions',
            component: AddContractActionsDialog,
            name: ROUTE.CONTRACT_ADD_ACTIONS,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'actions/:actionId/assignAssets',
            component: ContractActionAssignAssetDialog,
            name: ROUTE.CONTRACT_ACTION_ASSIGN_ASSETS,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'actions/:actionId/editAction',
            component: EditContractActionDialog,
            name: ROUTE.CONTRACT_ACTION_EDIT,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'assets/:assetId/addNote',
            component: ContractActionAssetAddNoteDialog,
            name: ROUTE.CONTRACT_ACTION_ASSET_ADD_NOTE,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
        ],
      },
    ],
  },
];
