import type { RouteRecordRaw } from 'vue-router';

import { ROUTE } from './routeNames';
import AccountPersonalInformation from '@views/Account/PersonalInformation.vue';
import AccountSecurity from '@views/Account/Security.vue';
import AccountPassword from '@views/Account/Password.vue';
import AccountLayout from '@views/Account/Layout.vue';
import AccountSettings from '@views/Account/Settings.vue';

export const accountRoutes: RouteRecordRaw[] = [
  {
    path: 'account',
    component: AccountLayout,
    redirect: () => {
      return { name: ROUTE.ACCOUNT_PERSONAL_INFORMATION };
    },
    meta: {
      breadcrumbs: true,
      title: 'account.title',
    },
    children: [
      {
        path: 'personal',
        name: ROUTE.ACCOUNT_PERSONAL_INFORMATION,
        component: AccountPersonalInformation,
      },
      {
        path: 'security',
        name: ROUTE.ACCOUNT_SECURITY,
        component: AccountSecurity,
      },
      {
        path: 'password',
        name: ROUTE.ACCOUNT_PASSWORD,
        component: AccountPassword,
      },
      {
        path: 'settings',
        name: ROUTE.ACCOUNT_SETTINGS,
        component: AccountSettings,
      },
    ],
  },
];
