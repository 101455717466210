<template>
  <Avatar>
    <AvatarFallback>{{ displayName }}</AvatarFallback>
  </Avatar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Avatar, AvatarFallback } from '@shadcn/components/ui/avatar';

export default defineComponent({
  components: {
    Avatar,
    AvatarFallback,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      displayName: '',
    };
  },

  mounted() {
    this.displayName = this.name
      ?.split(' ')
      .map(x => x[0])
      .join('');
  },
});
</script>
