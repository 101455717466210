import { http } from './http';

export const postUpdatePersonalInformation = (data: App.Data.UserUpdatePersonalInformationRequestData) => {
  return http.post('/user/updatePersonalInformation', data);
};
export const postUpdatePassword = (data: App.Data.UserUpdatePasswordRequestData) => {
  return http.post('/user/updatePassword', data);
};
export const postEnable2FA = () => {
  return http.post('/user/twoFactorAuthentication');
};
export const postDisable2FA = () => {
  return http.delete('/user/twoFactorAuthentication');
};
export const postVerify2FA = (code) => {
  return http.post('/user/confirmTwoFactorAuthentication', {'code': code});
};
export const getQR2FA = () => {
  return http.get('/user/twoFactorQrCode');
};
export const getRecoveryCodes2FA = () => {
  return http.get('/user/twoFactorRecoveryCodes');
};
export const postRecoveryCodes2FA = () => {
  return http.post('/user/twoFactorRecoveryCodes');
};
export const postUpdateSettings = (data: App.Data.UserUpdateSettingsRequestData) => {
  return http.post('/user/updateSettings', data);
};
export const postChangeLanguage = (data: App.Data.UserChangeLanguageRequestData) => {
  return http.post('/user/changeLanguage', data);
};
