<template>
  <DataLoader ref="loader">
    <div class="py-4 md:flex">
      <div class="flex flex-col gap-1 w-full md:w-1/5">
        <SelectField
          v-if="access.operator()"
          v-model="filter.organizationId"
          :placeholder="$t('userAccount.label.all_organizations')"
          :options="organizations"
          class="flex-1"
          :disabled="filter.preset == 'operators'"
          unselectable
          searchable
          sorted
        ></SelectField>

        <ZButton variant="ghost" class="justify-start" as-child>
          <a
            href="#"
            :class="{ 'bg-muted': filter.preset == 'active' }"
            class="flex flex-row gap-2 justify-between"
            @click="preset('active')"
          >
            {{ $t('userAccount.label.active_users') }}
            <span class="bg-gray-100 px-2 rounded p-1">{{ actionableStats.active }}</span></a
          >
        </ZButton>
        <ZButton variant="ghost" class="justify-start" as-child>
          <a
            href="#"
            :class="{ 'bg-muted': filter.preset == 'blocked' }"
            class="flex flex-row gap-2 justify-between"
            @click="preset('blocked')"
          >
            {{ $t('userAccount.label.blocked_users') }}
            <span class="bg-gray-100 px-2 rounded p-1">{{ actionableStats.blocked }}</span></a
          >
        </ZButton>
        <ZButton variant="ghost" class="justify-start" as-child>
          <a
            href="#"
            :class="{ 'bg-muted': filter.preset == 'not_activated' }"
            class="flex flex-row gap-2 justify-between"
            @click="preset('not_activated')"
          >
            {{ $t('userAccount.label.not_activated_users') }}
            <span
              class="bg-gray-100 px-2 rounded p-1"
              :class="{ 'bg-red-600 text-white': actionableStats.not_activated > 0 }"
              >{{ actionableStats.not_activated }}</span
            ></a
          >
        </ZButton>
        <ZButton variant="ghost" class="justify-start" as-child>
          <a
            href="#"
            :class="{ 'bg-muted': filter.preset == 'changes' }"
            class="flex flex-row gap-2 justify-between"
            @click="preset('changes')"
          >
            {{ $t('userAccount.label.changes_users') }}
            <span
              class="bg-gray-100 px-2 rounded p-1"
              :class="{ 'bg-red-600 text-white': actionableStats.changes > 0 }"
              >{{ actionableStats.changes }}</span
            ></a
          >
        </ZButton>
        <ZButton variant="ghost" class="justify-start" as-child>
          <a
            href="#"
            :class="{ 'bg-muted': filter.preset == 'not_verified' }"
            class="flex flex-row gap-2 justify-between"
            @click="preset('not_verified')"
          >
            {{ $t('userAccount.label.not_verified_users') }}
            <span class="bg-gray-100 px-2 rounded p-1">{{ actionableStats.not_verified }}</span></a
          >
        </ZButton>

        <ZButton
          v-if="access.operator(SYMBOL.PERMISSION.SYS_ADMIN)"
          variant="ghost"
          class="justify-start mt-6 pt-2 border-t"
          as-child
        >
          <a
            href="#"
            :class="{ 'bg-muted': filter.preset == 'operators' }"
            class="flex flex-row gap-2 justify-between"
            @click="preset('operators')"
          >
            {{ $t('userAccount.label.operators') }}
            <span class="bg-gray-100 px-2 rounded p-1">{{ actionableStats.operators }}</span></a
          >
        </ZButton>
        <ZButton v-if="access.operator(SYMBOL.PERMISSION.SYS_ADMIN)" variant="ghost" class="justify-start" as-child>
          <a
            href="#"
            :class="{ 'bg-muted': filter.preset == 'blocked_operators' }"
            class="flex flex-row gap-2 justify-between"
            @click="preset('blocked_operators')"
          >
            {{ $t('userAccount.label.blocked_operators') }}
            <span class="bg-gray-100 px-2 rounded p-1">{{ actionableStats.blocked_operators }}</span></a
          >
        </ZButton>
      </div>
      <div class="w-full pt-4 md:pt-0 md:pl-4">
        <ZInput v-model="filter.search" :placeholder="$t('common.label.search')" class="mb-2" />
        <Paginator :data="usersDataSet" page-size />
        <div class="border rounded">
          <ZTable class="w-full">
            <ZTableHeader>
              <ZTableRow>
                <ZTableHead>#</ZTableHead>
                <ZTableHead class="text-right">{{ $t('userAccount.label.admin') }}</ZTableHead>
                <ZTableHead>{{ $t('userAccount.label.email') }}</ZTableHead>
                <ZTableHead>{{ $t('userAccount.label.name') }}</ZTableHead>
                <ZTableHead>{{ $t('userAccount.label.organization') }}</ZTableHead>
                <ZTableHead>{{ $t('userAccount.label.position') }}</ZTableHead>
                <ZTableHead>{{ $t('userAccount.label.phone') }}</ZTableHead>
              </ZTableRow>
            </ZTableHeader>
            <ZTableBody>
              <ZTableRow
                v-for="(item, index) in usersDataSet.data"
                :key="index"
                class="cursor-pointer"
                @click="details(item.id ?? 0)"
              >
                <ZTableCell class="w-6">#{{ item.id }}</ZTableCell>
                <ZTableCell class="text-right">
                  <span
                    v-if="
                      item.account_type == SYMBOL.ACCOUNT_TYPE.OPERATOR && item!.access == SYMBOL.PERMISSION.SYS_ADMIN
                    "
                    class="rounded text-xs py-1 px-2 uppercase font-bold bg-yellow-200"
                    >superadmin</span
                  >
                  <span
                    v-else-if="item.account_type == SYMBOL.ACCOUNT_TYPE.OPERATOR"
                    class="rounded text-xs py-1 px-2 uppercase font-bold bg-green-200"
                    >Operator</span
                  >
                  <div v-else>
                    <span
                      v-if="item.access == SYMBOL.PERMISSION.ORG_ADMIN"
                      class="rounded text-xs py-1 px-2 uppercase font-bold bg-blue-200"
                      >{{ $t('userAccount.label.org_admin') }}</span
                    >
                    <span v-else class="rounded text-xs py-1 px-2 uppercase font-bold bg-gray-200">{{
                      $t('userAccount.label.employee')
                    }}</span>
                    <span v-else class="rounded text-xs py-1 px-2 uppercase font-bold bg-gray-200">{{
                      $t('userAccount.label.employee')
                    }}</span>
                  </div>
                </ZTableCell>
                <ZTableCell>{{ item.email }}</ZTableCell>
                <ZTableCell>{{ item.first_name + ' ' + item.last_name }}</ZTableCell>
                <ZTableCell>{{ item.organization_name }}</ZTableCell>
                <ZTableCell>{{ item.position }}</ZTableCell>
                <ZTableCell>{{ item.phone }}</ZTableCell>
              </ZTableRow>
            </ZTableBody>
          </ZTable>
          <div v-if="usersDataSet.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
            <ExclamationTriangleIcon class="icon lg" /> {{ $t('userAccount.message.data_not_found') }}
          </div>
        </div>
        <Paginator :data="usersDataSet" page-size />
      </div>
    </div>

    <router-view></router-view>

    <MountedTeleport to="#toolbox">
      <div class="flex flex-row gap-2">
        <DropdownMenu v-if="access.operator(SYMBOL.PERMISSION.SYS_ADMIN)">
          <DropdownMenuTrigger as-child>
            <ZButton class="w-full">
              <span class="ml-2">
                {{ $t('userAccount.command.add_user') }}
              </span>
              <ChevronDownIcon class="icon xs ml-2" />
            </ZButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent class="w-56">
            <DropdownMenuGroup>
              <DropdownMenuItem @click="newAccount(SYMBOL.ACCOUNT_TYPE.OPERATOR)">
                <span>{{ $t('userAccount.command.add_operator') }}</span>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem @click="newAccount(SYMBOL.ACCOUNT_TYPE.CUSTOMER)">
                <span>{{ $t('userAccount.command.add_customer') }}</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <ZButton v-else class="w-full" @click="newAccount(SYMBOL.ACCOUNT_TYPE.CUSTOMER)">
          <span>
            {{ $t('userAccount.command.add_user') }}
          </span>
        </ZButton>
      </div>
    </MountedTeleport>
  </DataLoader>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import UserAccountData = App.Data.UserAccountData;
import { getActionableAccountsStats, getUserAccounts } from '@/api';
import { DataSet } from '@/utils/DataSet';
import Paginator from '@ui/Paginator.vue';
import { ZInput } from '@shadcn/components/ui/input';
import { ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow } from '@shadcn/components/ui/table';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@shadcn/components/ui/dropdown-menu';
import { ChevronDownIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { ZButton } from '@shadcn/components/ui/button';
import { ROUTE } from '@/router/routeNames';
import SelectField from '@ui/SelectField.vue';
import { getOrganizationsCompact } from '@/api/organization';
import { KeyValue } from '@/types/Common';
import symbolsStore from '@/stores/symbols';
import MountedTeleport from '@ui/MountedTeleport.vue';
import ActionableAccountsStatsData = App.Data.ActionableAccountsStatsData;
import OrganizationCompactData = App.Data.OrganizationCompactData;
import SimpleSearchData = App.Data.SimpleSearchData;
import UserFilterData = App.Data.UserFilterData;
import DataLoader from '@ui/DataLoader.vue';
import useAccessStore from '@/stores/access';

export default defineComponent({
  components: {
    DataLoader,
    SelectField,
    ChevronDownIcon,
    MountedTeleport,
    Paginator,
    // icons
    ExclamationTriangleIcon,
    // shadcn components
    ZButton,
    ZInput,
    ZTable,
    ZTableRow,
    ZTableBody,
    ZTableCell,
    ZTableHead,
    ZTableHeader,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
    DropdownMenuSeparator
  },

  data() {
    return {
      states: {},
      filter: {
        search: '',
        preset: 'active',
      } as UserFilterData,

      usersDataSet: {} as DataSet<UserAccountData, SimpleSearchData>,
      organizations: {} as KeyValue,
      actionableStats: {} as ActionableAccountsStatsData,
      currentPreset: '' as string,
      loader: null as any,
    };
  },

  computed: {
    access: () => useAccessStore(),
  },

  watch: {
    filter: {
      async handler() {
        if (this.usersDataSet.setParams != undefined) {
          this.usersDataSet.setParams(this.filter);
          this.usersDataSet.resetToFirstPage();
          this.usersDataSet.loadDebounced();
        }

        if (this.filter.organizationId != undefined) {
          const actionableStatsResponse = await getActionableAccountsStats().execute(
            '?organizationId=' + this.filter.organizationId,
          );
          this.actionableStats = actionableStatsResponse.data;
        } else if (this.access.operator()) {
          const actionableStatsResponse = await getActionableAccountsStats().execute();
          this.actionableStats = actionableStatsResponse.data;
        }
      },

      deep: true,
    },

    $route(to: any) {
      if (to.name == ROUTE.USER_ACCOUNT_LIST) {
        this.usersDataSet.resetToFirstPage();
        this.usersDataSet.loadDebounced();
      }
    },
  },

  async mounted() {
    try {
      this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;

      if (this.access.currentUser() == null) return;

      if (!this.access.operator()) {
        this.filter.organizationId = this.access.currentOrganizationId();
      } else {
        const organizationsResponse = await getOrganizationsCompact().withLoader(this.loader).execute();
        this.organizations = organizationsResponse.data.reduce(
          (accumulator: KeyValue, value: OrganizationCompactData) => {
            return { ...accumulator, [value.id]: value.name };
          },
          {},
        );
      }

      if (this.access.operator()) {
        const actionableStatsResponse = await getActionableAccountsStats().withLoader(this.loader).execute();
        this.actionableStats = actionableStatsResponse.data;
      }
    } catch (error: any) {}

    const symbols = symbolsStore();
    this.states = await symbols.get('user_states');

    this.usersDataSet = new DataSet<UserAccountData, UserFilterData>((query: string) =>
      getUserAccounts().withLoader(this.loader).execute(query),
    );

    this.usersDataSet.setParams(this.filter);
    await this.usersDataSet.load();
  },

  methods: {
    details(id: number) {
      this.$router.push({ name: ROUTE.USER_ACCOUNT_DETAILS, params: { userId: id } });
    },

    newAccount(type: string) {
      this.$router.push({ name: ROUTE.USER_ACCOUNT_ADD, params: { type: type } });
    },

    preset(name: string) {
      this.filter.search = '';

      this.currentPreset = name;

      switch (name) {
        case 'operators':
          this.filter.preset = 'operators';
          this.filter.organizationId = null;
          break;

        case 'blocked_operators':
          this.filter.preset = 'blocked_operators';
          this.filter.organizationId = null;
          break;

        case 'not_activated':
          this.filter.preset = 'not_activated';
          break;

        case 'not_verified':
          this.filter.preset = 'not_verified';
          break;

        case 'changes':
          this.filter.preset = 'changes';
          break;

        case 'blocked':
          this.filter.preset = 'blocked';
          break;

        case 'active':
        default:
          this.filter.preset = 'active';
          break;
      }
    },
  },
});
</script>
