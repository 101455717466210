import type { RouteRecordRaw } from 'vue-router';
import { ROUTE } from './routeNames';
import EditFileDialog from '@views/Files/EditFileDialog.vue';
import Files from '@views/Files/Files.vue';
import {SYMBOL} from "@/types/symbols";

export const filesRoutes: RouteRecordRaw[] = [
  {
    path: 'files',
    name: ROUTE.FILE_LIST,
    component: Files,
    meta: {
      breadcrumbs: true,
      title: 'files.title',
      feature: SYMBOL.FEATURES.DOCUMENTS,
    },
    children: [
      {
        path: ':fileId',
        component: EditFileDialog,
        name: ROUTE.FILE_EDIT,
        props: true,
        meta: {
          breadcrumbs: false,
        },
      },
    ],
  },
];
