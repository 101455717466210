<template>
  <div>
    <h3 class="text-2xl font-semibold mb-5">{{ $t('account.personal.header') }}</h3>
    <div class="max-w-[500px]">
      <Alert v-if="!access.operator() && changesStatus" class="mb-4">
        <AlertTitle>{{ $t('account.personal.requestChanges.header') }}</AlertTitle>
        <AlertDescription>{{ $t('account.personal.requestChanges.message') }}</AlertDescription>
      </Alert>
      <Alert v-else-if="changesStatus" class="mb-4">
        <AlertTitle>{{ $t('account.personal.saveChanges.header') }}</AlertTitle>
        <AlertDescription>{{ $t('account.personal.saveChanges.message') }}</AlertDescription>
      </Alert>
      <FormValidator :errors="errors">
        <div class="grid gap-4 grid-cols-2">
          <FormField symbol="first_name">
            <ZLabel>{{ $t('account.personal.form.firstName') }}</ZLabel>
            <ZInput v-model="form.first_name" type="email" />
          </FormField>
          <FormField symbol="last_name">
            <ZLabel>{{ $t('account.personal.form.lastName') }}</ZLabel>
            <ZInput v-model="form.last_name" type="email" />
          </FormField>
          <FormField symbol="phone">
            <ZLabel>{{ $t('account.personal.form.phone') }}</ZLabel>
            <ZInput v-model="form.phone" type="email" />
          </FormField>
          <FormField symbol="email">
            <ZLabel>{{ $t('account.personal.form.email') }}</ZLabel>
            <ZInput v-model="form.email" type="email" />
          </FormField>
        </div>
        <div class="flex justify-end">
          <ZButton class="mt-5" @click.prevent="update">{{ $t('common.save') }}</ZButton>
        </div>
      </FormValidator>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { ZButton } from '@shadcn/components/ui/button';
import { ZInput } from '@shadcn/components/ui/input';
import { ZLabel } from '@shadcn/components/ui/label';
import { useToast } from '@shadcn/components/ui/toast/use-toast';
import { Alert, AlertTitle, AlertDescription } from '@shadcn/components/ui/alert';
import { postUpdatePersonalInformation, getCurrentUser } from '../../api';
import loaderStore from '../../stores/loader';
import FormValidator from '../../components/FormValidator.vue';
import FormField from '../../components/FormField.vue';
import { ValidationErrors } from '../../types/ValidationErrors';
import { HttpStatusCode } from 'axios';
import useAccessStore from '@/stores/access';

export default defineComponent({
  components: {
    ZButton,
    ZInput,
    ZLabel,
    FormValidator,
    FormField,
    Alert,
    AlertTitle,
    AlertDescription,
  },

  data() {
    return {
      loader: loaderStore(),
      changesStatus: false as boolean,
      errorMessage: '' as string,
      errors: null as null | ValidationErrors,
      form: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
      } as App.Data.UserUpdatePersonalInformationRequestData,
    };
  },

  computed: {
    access: () => useAccessStore(),
  },

  async mounted() {
    this.loader.startLoading();
    const { data } = await getCurrentUser();
    this.changesStatus = data.changes_requested_status;
    this.form = {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      email: data.email,
    };
    this.loader.stopLoading();
  },

  methods: {
    update() {
      const { toast } = useToast();

      this.loader.startLoading();
      postUpdatePersonalInformation(this.form)
        .then(() => {
          if (this.access.operator()) {
            toast({
              title: this.$t('account.personal.saveChanges.header'),
              description: this.$t('account.personal.saveChanges.message'),
            });
          } else {
            toast({
              title: this.$t('account.personal.requestChanges.header'),
              description: this.$t('account.personal.requestChanges.message'),
            });
          }
          this.errors = null;
          this.changesStatus = true;
        })
        .catch(error => {
          if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
            if (error.response.data.errors) {
              this.errors = error.response.data.errors;
            } else {
              toast({
                title: this.$t('common.errors.header'),
                description: this.$t(error.response.data.message),
                variant: 'destructive',
              });
            }
          }
        })
        .finally(() => this.loader.stopLoading());
    },
  },
});
</script>
