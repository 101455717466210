<template>
  <div v-if="category.attributes">
    <div class="flex">
      <h3 class="font-bold text-lg flex flex-row gap-2">
        {{ $t('category.label.category_attributes') }}
      </h3>
      <router-link
        v-if="category.attributes.length"
        class="ml-auto"
        :to="{ name: ROUTE.CATEGORY_ATTRIBUTE_ADD, params: { categoryId: category.id } }"
      >
        <ZButton class="flex gap-2">
          <span class="hidden lg:block">{{ $t('category.command.add_category_attribute') }}</span>
          <PlusCircleIcon class="icon md" />
        </ZButton>
      </router-link>
    </div>
    <ZTable v-if="category.attributes.length">
      <ZTableHeader>
        <ZTableRow>
          <ZTableHead>{{ $t('category.label.attribute_name') }}</ZTableHead>
          <ZTableHead>{{ $t('category.label.attribute_unit') }}</ZTableHead>
          <ZTableHead>{{ $t('category.label.attribute_type.title') }}</ZTableHead>
          <ZTableHead>{{ $t('category.label.attribute_is_required') }}</ZTableHead>
          <ZTableHead>{{ $t('category.label.action') }}</ZTableHead>
        </ZTableRow>
      </ZTableHeader>
      <ZTableBody>
        <ZTableRow v-for="(item, index) in category.attributes" :key="index">
          <ZTableCell>{{ item.name }}</ZTableCell>
          <ZTableCell>{{ item.unit }}</ZTableCell>
          <ZTableCell
            ><span class="rounded bg-gray-100 px-2">{{
              $t('category.label.attribute_type.' + item.type.toLowerCase())
            }}</span></ZTableCell
          >
          <ZTableCell class="text-center">{{ item.validation_rule == 'required' ? 'Tak' : 'Nie' }}</ZTableCell>
          <ZTableCell class="text-center space-x-1">
            <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <a href="#" @click.prevent="">
                  <EllipsisHorizontalIcon class="icon sm" />
                </a>
              </DropdownMenuTrigger>
              <DropdownMenuContent class="w-56">
                <DropdownMenuGroup>
                  <DropdownMenuItem>
                    <router-link
                      :to="{ name: ROUTE.CATEGORY_ATTRIBUTE_EDIT, params: { categoryAttributeId: item.id } }"
                      class="flex gap-2"
                    >
                      <PencilSquareIcon class="icon sm" />
                      <span>{{ $t('category.command.edit_category_attribute') }}</span>
                    </router-link>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    class="flex gap-2"
                    :disabled="!item.can_be_deleted"
                    @click="requestCategoryDelete(item.id!)"
                  >
                    <TrashIcon class="icon sm" /> <span>{{ $t('category.command.remove_category_attribute') }}</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </ZTableCell>
        </ZTableRow>
      </ZTableBody>
    </ZTable>
    <IconAlert v-else type="info" class="my-2">
      {{ $t('category.message.category_no_attributes') }}
      <router-link class="link" :to="{ name: ROUTE.CATEGORY_ATTRIBUTE_ADD, params: { categoryId: category!.id } }">
        {{ $t('category.command.add_category_attribute') }}
      </router-link>
    </IconAlert>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { PencilSquareIcon, TrashIcon, EllipsisHorizontalIcon } from '@heroicons/vue/24/outline';
import { ZTableBody } from '@shadcn/components/ui/table';
import { ZTableCell } from '@shadcn/components/ui/table';
import { ZTable } from '@shadcn/components/ui/table';
import { ZTableHeader } from '@shadcn/components/ui/table';
import { ZTableRow } from '@shadcn/components/ui/table';
import { ZTableHead } from '@shadcn/components/ui/table';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@shadcn/components/ui/dropdown-menu';
import IconAlert from '@ui/IconAlert.vue';
import CategoryData = App.Data.CategoryData;
import { PlusCircleIcon } from '@modules/@heroicons/vue/24/outline';
import { ZButton } from '@shadcn/components/ui/button';

export default defineComponent({
  components: {
    ZButton,
    PlusCircleIcon,
    ZTableCell,
    ZTableBody,
    ZTableHead,
    ZTableRow,
    ZTableHeader,
    ZTable,
    TrashIcon,
    PencilSquareIcon,
    IconAlert,
    EllipsisHorizontalIcon,
    // shadcn components
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  },

  props: {
    category: {
      type: Object as PropType<CategoryData>,
      required: true,
    },
  },

  emits: ['deleteAttribute'],

  methods: {
    requestCategoryDelete(attributeId: number) {
      this.$emit('deleteAttribute', attributeId);
    },
  },
});
</script>
