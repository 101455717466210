<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[425px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('asset.label.update_carbon_amortization') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('asset.message.update_carbon_amortization') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <div>
          <FormValidator ref="form" class="my-4">
            <Field symbol="method_type" class="pt-4">
              <ZLabel>{{ $t('asset.label.method') }}</ZLabel>
              <SelectField v-model="method" :options="methods" :translate="'asset.'"/>
            </Field>
            <Field symbol="starts_at" class="pt-4">
              <ZLabel>{{ $t('asset.label.carbon_balance_date') }}</ZLabel>
              <Popover v-model:open="showStartAtDatePicker">
                <PopoverTrigger as-child>
                  <ZButton variant="outline" class="w-full font-normal flex items-center justify-start">
                    <div class="flex items-center w-full">
                      <CalendarIcon class="w-[20px] mr-2"/>
                      <span v-if="starts_at" class="leading-3">
                        {{ formatDate(starts_at as string) }}
                      </span>
                      <span v-else>{{ $t('asset.form.date.button') }}</span>
                    </div>
                    <ChevronDownIcon class="w-[18px] ml-2 text-gray-400"/>
                  </ZButton>
                </PopoverTrigger>
                <PopoverContent class="w-auto p-0" align="start">
                  <Calendar v-model="starts_at as string" timezone="UTC" :columns="1"/>
                </PopoverContent>
              </Popover>
            </Field>
            <Field symbol="value" class="pt-4">
              <ZLabel>{{ $t('asset.label.carbon_value') }}</ZLabel>
              <NumberInput v-model="carbonValue" :disabled="!method" :digits="0" align="center" :important="true"/>
            </Field>
            <div v-if="method == SYMBOL.ASSET_CARBON_FOOTPRINT_METHOD.LINEAR">
              <Field symbol="months" class="pt-4">
                <ZLabel class="mt-2">{{ $t('asset.label.months') }}</ZLabel>
                <ZInput v-model="months" :type="'number'"/>
              </Field>
            </div>
          </FormValidator>
        </div>
        <ZDialogFooter>
          <div class="flex justify-between w-full">
            <Action variant="destructive" @proceed="removeFootprint">{{ $t('asset.command.remove_footprint') }}</Action>
            <Action :disabled="!canSubmit" @proceed="setCarbonFootprint">{{ $t('asset.command.save_changes') }}</Action>
          </div>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {ZInput} from '@shadcn/components/ui/input';
import {ZLabel} from '@shadcn/components/ui/label';
import {inject} from '@modules/vue';
import SelectField from '@ui/SelectField.vue';
import IconAlert from '@ui/IconAlert.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import Action from '@ui/Action.vue';
import FormValidator from '@ui/FormValidator.vue';
import Field from '@ui/FormField.vue';
import {setCarbonAmortizationValue} from '@/api/asset';
import {ComponentLoadState} from '@/types/ComponentLoadState';
import {KeyValue} from '@/types/Common';
import symbolsStore from '@/stores/symbols';
import CarbonFootprintMethodType = App.Enums.CarbonFootprintMethodType;
import {SYMBOL} from '@/types/symbols';
import {Popover, PopoverContent, PopoverTrigger} from '@shadcn/components/ui/popover';
import {CalendarIcon, ChevronDownIcon} from '@modules/@heroicons/vue/24/outline';
import {Calendar} from '@shadcn/components/ui/calendar';
import {ZButton} from '@shadcn/components/ui/button';
import {datePartISO} from '@/utils';
import AssetData = App.Data.AssetData;
import NumberInput from "@ui/NumberInput.vue";
import {Dialogs} from "@/types/Dialogs";
import {setUserState} from "@/api";

export default defineComponent({
  components: {
    NumberInput,
    CalendarIcon,
    PopoverContent,
    ZButton,
    Calendar,
    PopoverTrigger,
    ChevronDownIcon,
    Popover,
    ZDialog,
    ZDialogHeader,
    ZDialogDescription,
    ZDialogContent,
    Field,
    FormValidator,
    Action,
    ZDialogTitle,
    ZDialogFooter,
    IconAlert,
    //shadcn
    SelectField,
    ZInput,
    ZLabel,
  },

  props: {
    asset: {
      type: {} as PropType<AssetData>,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
      dialogs: inject('dialogs') as Dialogs,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      showStartAtDatePicker: false,
      state: 'loading' as ComponentLoadState,
      method: '' as string | CarbonFootprintMethodType,
      months: null as number | null,
      carbonValue: null as number | null,
      methods: {} as KeyValue,
      starts_at: null as Date | unknown,
    };
  },

  computed: {
    canSubmit() {
      if (this.method == SYMBOL.ASSET_CARBON_FOOTPRINT_METHOD.SINGLE) {
        return this.method && this.carbonValue;
      } else {
        return this.method && this.carbonValue && this.months;
      }
    },
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) {
        this.$emit('saved')
      }
    },
  },

  async mounted() {
    try {
      const symbols = symbolsStore();
      const methods = await symbols.get('carbon_footprint_method');
      this.methods = Object.fromEntries(Object.entries(methods));
      this.carbonValue = this.asset.carbon_footprint_balance ?? 0;
      this.months = this.asset.carbon_amortization_months;
      if (this.months && this.months > 1) this.method = this.SYMBOL.ASSET_CARBON_FOOTPRINT_METHOD.LINEAR;
      else this.method = this.SYMBOL.ASSET_CARBON_FOOTPRINT_METHOD.LINEAR;

      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
      throw error;
    }
  },

  methods: {
    async setCarbonFootprint(action: any) {
      let start_date = '' as unknown;
      if (this.starts_at != null) start_date = datePartISO(this.starts_at as Date);
      await setCarbonAmortizationValue(this.asset!.id!, this.method, start_date as Date, this.carbonValue!, this.months)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.$emit('saved');
        });
    },

    removeFootprint(action: any) {
      this.dialogs
        .confirm(this.$t('asset.label.confirm_remove_footprint'), this.$t('asset.message.confirm_remove_footprint'))
        .then(() => {
          setCarbonAmortizationValue(this.asset!.id!, this.method, '0001-01-01', 0, 0)
            .withForm(this.$refs.form as typeof FormValidator)
            .withLoader(action.loader)
            .execute()
            .then(() => {
              this.$emit('saved');
            });
        });
    },
  },
});
</script>
