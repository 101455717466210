import { http } from './http';
import { ApiCall } from '@/api/ApiCall';
import { PaginatedResponse } from '@/utils/DataSet';
import TagData = App.Data.TagData;
import { KeyValue } from '@/types/Common';
import TagListData = App.Data.TagListData;
import TagsCompactData = App.Data.TagsCompactData;

export const getTags = () => {
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<TagListData>>('/tags' + query);
    },
    {},
    '',
  );
};
export const getTagsCompact = () => {
  return ApiCall.create(
    (options, query) => {
      return http.get<TagsCompactData[]>(`/tags/compact` + query);
    },
    {},
    '',
  );
};
export const getTag = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get<TagData>(`/tags/${options.id}`);
  }, options);
};
export const saveTag = (tag: string, fields: TagData) => {
  const options = {} as KeyValue;
  options.tag = tag;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<TagData>(`/tags/${options.tag}`, fields);
    },
    options,
    '',
    fields,
  );
};
export const createTag = (fields: TagData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<TagListData>('/tags', fields);
    },
    {},
    '',
    fields,
  );
};
export const deleteTag = (id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  return ApiCall.create(options => {
    return http.delete(`/tags/${options.id}`);
  }, options);
};
