<template>
  <ZDialog v-model:open="isDialogOpen" class="md:max-h-[90vh]">
    <ZDialogContent class="lg:max-w-[70vw] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('contracts.label.assign_assets') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('contracts.message.assign_assets') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <div class="lg:max-h-[70vh] lg:overflow-auto">
          <FormValidator ref="form" class="grid gap-4 py-4">
            <ManageAssetsForm
              :asset-data-set="assetDataSet"
              :filters="filters"
              :categories="categories"
              :tags="tags"
              @change="chosenAssets = $event"
            />
          </FormValidator>
        </div>
        <ZDialogFooter>
          <Action @proceed="update">{{ $t('contracts.command.save_changes') }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import Action from '@ui/Action.vue';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import ContractActionStoreRequest = App.Data.ContractActionStoreRequest;
import { assignAssetsToAction } from '@/api/contractAsset';
import ManageAssetsForm from '@ui/Contracts/ManageAssetsForm.vue';
import { HttpStatusCode } from '@modules/axios';
import { toast } from '@shadcn/components/ui/toast';
import { inject } from '@modules/vue';
import { DataSet } from '@/utils/DataSet';
import MinimalAssetData = App.Data.MinimalAssetData;
import { getExistingAssets } from '@/api/asset';
import { getCompactCategories } from '@/api/category';
import CategoryCompactData = App.Data.CategoryCompactData;
import { getTagsCompact } from '@/api';
import { KeyValue } from '@/types/Common';
import TagsCompactData = App.Data.TagsCompactData;
import AssetsOfflineCompareRequestData = App.Data.AssetsOfflineCompareRequestData;

interface filtersInterface {
  search: string;
  page: number;
  perPage: number;
  technical_condition?: string;
  category?: number;
  tags?: number[];
  contracted_action_id: number;
}

export default defineComponent({
  components: {
    ManageAssetsForm,
    ZDialogContent,
    IconAlert,
    ZDialogFooter,
    ZDialogTitle,
    FormValidator,
    Action,
    ZDialogDescription,
    ZDialog,
    ZDialogHeader,
  },

  props: {
    contractId: {
      type: String,
      required: true,
    },

    actionId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      assetDataSet: {} as DataSet<MinimalAssetData, AssetsOfflineCompareRequestData>,
      isDialogOpen: true,
      state: 'loading' as ComponentLoadState,
      action: {} as ContractActionStoreRequest,
      chosenAssets: [] as Array<string>,
      categories: {} as CategoryCompactData,
      tags: {},
      filters: {
        search: '',
        page: 1,
        perPage: 15,
        technical_condition: 'all',
        category: '0',
        tags: [],
        contracted_action_id: parseInt(this.actionId),
      },
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },

    filters: {
      deep: true,
      handler() {
        const finalFilters: filtersInterface = {
          ...this.filters,
          category: parseInt(this.filters!.category!, 10),
          tags: this.filters!.tags,
        };
        if (this.filters.technical_condition === 'all') {
          delete finalFilters['technical_condition'];
        }
        if (this.filters.category === '0') {
          delete finalFilters['category'];
        }
        this.assetDataSet.setParams(finalFilters).resetToFirstPage();
      },
    },
  },

  async mounted() {
    try {
      const finalFilters: filtersInterface = {
        ...this.filters,
        category: parseInt(this.filters!.category!, 10),
        tags: this.filters!.tags,
      };
      if (this.filters.technical_condition === 'all') {
        delete finalFilters['technical_condition'];
      }
      if (this.filters.category === '0') {
        delete finalFilters['category'];
      }
      this.assetDataSet = new DataSet<MinimalAssetData, AssetsOfflineCompareRequestData>((query: string) =>
        getExistingAssets(finalFilters).execute(query),
      ).setParams(finalFilters);
      await this.getCategories();
      const tagsResponse = await getTagsCompact().execute();
      this.tags = tagsResponse.data.reduce((accumulator: KeyValue, value: TagsCompactData) => {
        return { ...accumulator, [value.id]: value.name };
      }, {});
      await this.assetDataSet.load();
      this.state = 'loaded';
    } catch (error) {
      this.state = 'error';
    }
  },

  methods: {
    async getCategories() {
      const categoriesResponse = await getCompactCategories().execute();
      this.categories = categoriesResponse.data;
    },

    update() {
      assignAssetsToAction(this.contractId, {
        contracted_action_id: parseInt(this.actionId),
        asset_ids: this.chosenAssets,
      })
        .execute()
        .then(async () => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('organization.message.organization_saved'),
          });
          this.$emit('saved');
        })
        .catch(error => {
          if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
            toast({
              title: this.$t('common.errors.header'),
              description: this.$t(error.response.data.message),
              variant: 'destructive',
            });
          }
        });
    },
  },
});
</script>
