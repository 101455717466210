import type { RouteRecordRaw } from 'vue-router';
import Assets from '@views/Assets/Assets.vue';
import { ROUTE } from './routeNames';
import AssetDetails from '@views/Assets/AssetDetails.vue';
import NewAssetDialog from '@views/Assets/NewAssetDialog.vue';
import EditAssetDataDialog from '@views/Assets/EditAssetDataDialog.vue';
import AssignAssetTags from '@views/Assets/AssignAssetTagsDialog.vue';
import EditAssetAttributesDialog from '@views/Assets/EditAssetAttributesDialog.vue';
import EditAssetCategoryAndModelDialog from '@views/Assets/EditAssetCategoryAndModelDialog.vue';
import EditAssetImagesDialog from '@views/Assets/EditAssetImagesDialog.vue';
import DuplicateAssetDialog from '@views/Assets/DuplicateAssetDialog.vue';
import UpdateAssetStatusDialog from '@views/Assets/UpdateAssetStatusDialog.vue';
import TransferAssetsDialog from '@views/Assets/TransferAssetsDialog.vue';
import CreateAssetNoteDialog from '@views/Assets/CreateAssetNoteDialog.vue';
import EditAssetNoteDialog from '@views/Assets/EditAssetNoteDialog.vue';
import AssetCarbonDialog from '@views/Assets/AssetCarbonDialog.vue';

export const assetRoutes: RouteRecordRaw[] = [
  {
    path: 'assets',
    name: 'asset.layout',
    redirect: () => {
      return { name: ROUTE.ASSET_LIST };
    },
    meta: {
      breadcrumbs: true,
      title: 'asset.label.list_title',
    },
    children: [
      {
        path: '',
        component: Assets,
        name: ROUTE.ASSET_LIST,
        children: [
          {
            path: 'add',
            component: NewAssetDialog,
            name: ROUTE.ASSET_ADD,
          },
          {
            path: 'transfer',
            component: TransferAssetsDialog,
            name: ROUTE.ASSET_TRANSFER,
            props: true,
          },
        ],
      },
      {
        path: ':assetId',
        component: AssetDetails,
        name: ROUTE.ASSET_DETAILS,
        meta: {
          breadcrumbs: true,
          title: 'asset.label.details_title',
        },
        props: true,
        children: [
          {
            path: 'editDetails',
            component: EditAssetDataDialog,
            name: ROUTE.ASSET_DETAILS_EDIT,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'assignAssetTags',
            component: AssignAssetTags,
            name: ROUTE.ASSET_ASSIGN_TAGS,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'editCategoryAndModel',
            component: EditAssetCategoryAndModelDialog,
            name: ROUTE.ASSET_CATEGORY_AND_MODEL_EDIT,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'editImages',
            component: EditAssetImagesDialog,
            name: ROUTE.ASSET_DETAILS_EDIT_IMAGES,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'editAttributes',
            component: EditAssetAttributesDialog,
            name: ROUTE.ASSET_DETAILS_EDIT_ATTRIBUTES,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'updateStatus',
            component: UpdateAssetStatusDialog,
            name: ROUTE.ASSET_DETAILS_UPDATE_STATUS,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'duplicateAsset',
            component: DuplicateAssetDialog,
            name: ROUTE.ASSET_DUPLICATE,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'addNote',
            component: CreateAssetNoteDialog,
            name: ROUTE.ASSET_ADD_NOTE,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'editNote/:noteId',
            component: EditAssetNoteDialog,
            name: ROUTE.ASSET_EDIT_NOTE,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
        ],
      },
    ],
  },
];
