<template>
  <Popover v-model:open="show">
    <PopoverTrigger as-child>
      <div class="flex items-center gap-4 ml-2 p-2 rounded-sm cursor-pointer">
        <div>
          <UAvatar v-model="user"></UAvatar>
        </div>
        <div class="hidden md:flex flex-col whitespace-nowrap">
          <b>{{ user?.first_name }} {{ user?.last_name }}</b>
          <span>{{ user?.position }}</span>
        </div>
        <div>
          <ChevronDownIcon class="icon w-[15px]" />
        </div>
      </div>
    </PopoverTrigger>
    <PopoverContent class="w-60">
      <div class="v-menu">
        <div v-if="canSwapCredentials">
          <a class="menu-item cursor-pointer" @click.prevent="swapToOriginal()">
            <KeyIcon class="icon sm" /> {{ $t('userAccount.message.back_to_admin_account') }}
          </a>
          <div class="border my-2"></div>
        </div>
        <router-link
          :to="{ name: ROUTE.ACCOUNT_PERSONAL_INFORMATION }"
          class="menu-item"
          @click.prevent="closePopover()"
          ><UserCircleIcon class="icon sm" /> {{ $t('account.personal.header') }}</router-link
        >
        <router-link :to="{ name: ROUTE.ACCOUNT_SECURITY }" class="menu-item" @click.prevent="closePopover()"
          ><ShieldCheckIcon class="icon sm" /> {{ $t('account.security.header') }}</router-link
        >
        <router-link :to="{ name: ROUTE.ACCOUNT_SETTINGS }" class="menu-item" @click.prevent="closePopover()"
          ><Cog6ToothIcon class="icon sm" /> {{ $t('account.settings.header') }}</router-link
        >
        <router-link :to="{ name: ROUTE.ACCOUNT_PASSWORD }" class="menu-item" @click.prevent="closePopover()"
          ><LockClosedIcon class="icon sm" /> {{ $t('account.password.header') }}</router-link
        >
        <div class="border my-2"></div>
        <a class="menu-item cursor-pointer" @click.prevent="logout()"><KeyIcon class="icon sm" /> Wyloguj</a>
      </div>
    </PopoverContent>
  </Popover>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  ChevronDownIcon,
  UserCircleIcon,
  LockClosedIcon,
  Cog6ToothIcon,
  ShieldCheckIcon,
  KeyIcon,
} from '@heroicons/vue/24/outline';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/components/ui/popover';
import useUserStore from '../stores/auth';
import { mapState } from 'pinia';
import UAvatar from '@ui/UAvatar.vue';

export default defineComponent({
  components: {
    Cog6ToothIcon,
    UAvatar,
    ChevronDownIcon,
    UserCircleIcon,
    LockClosedIcon,
    ShieldCheckIcon,
    KeyIcon,
    Popover,
    PopoverContent,
    PopoverTrigger,
  },

  data() {
    return {
      show: false,
      canSwapCredentials: false,
    };
  },

  computed: {
    ...mapState(useUserStore, ['user']),
  },

  watch: {
    show() {
      const { hasStoredOriginalCredentials } = useUserStore();
      this.canSwapCredentials = hasStoredOriginalCredentials();
    },
  },

  methods: {
    logout() {
      const { logoutUser } = useUserStore();
      logoutUser();
    },

    swapToOriginal() {
      const { swapToOriginalCredentials } = useUserStore();
      swapToOriginalCredentials();
      this.closePopover();
    },

    closePopover() {
      this.show = !this.show;
    },
  },
});
</script>
