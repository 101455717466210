<template>
  <div>
    <AdminDashboard
      v-if="user && user.id && user?.account_type === 'operator'"
      :user-id="user!.id!.toString()"
    ></AdminDashboard>
    <UserDashboard v-else></UserDashboard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import UserDashboard from '@ui/Dashboard/UserDashboard.vue';
import AdminDashboard from '@ui/Dashboard/AdminDashboard.vue';
import useUserStore from '@/stores/auth';
import { mapState } from 'pinia';

export default defineComponent({
  components: {
    AdminDashboard,
    UserDashboard,
    // icons
    // shadcn components
  },

  inject: {
    navigation: { from: 'navigation' },
  },

  data() {
    return {};
  },

  computed: {
    ...mapState(useUserStore, ['user']),
  },

  methods: {},
});
</script>
