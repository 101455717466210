import type { RouteRecordRaw } from 'vue-router';
import { ROUTE } from './routeNames';
import Tags from '@views/Tags/Tags.vue';
import EditTagDialog from '@views/Tags/EditTagDialog.vue';
import NewTagDialog from '@views/Tags/NewTagDialog.vue';
import {SYMBOL} from "@/types/symbols";

export const tagsRoutes: RouteRecordRaw[] = [
  {
    path: 'tags',
    name: ROUTE.TAGS,
    component: Tags,
    meta: {
      breadcrumbs: true,
      title: 'tag.title',
      permission: [{type: SYMBOL.PERMISSION.SYS_ADMIN}]
    },
    children: [
      {
        path: 'edit/:tagId',
        component: EditTagDialog,
        name: ROUTE.TAGS_EDIT,
        props: true,
        meta: {
          breadcrumbs: false,
        },
      },
      {
        path: 'add',
        component: NewTagDialog,
        name: ROUTE.TAGS_ADD,
        props: true,
        meta: {
          breadcrumbs: false,
        },
      },
    ],
  },
];
