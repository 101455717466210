<template>
  <div class="flex flex-col justify-between">
    <div class="mb-4 gap-2 grid grid-cols-4 border rounded-md p-2 md:p-4">
      <Field symbol="name" class="col-span-3">
        <ZLabel for="name" class="flex justify-between items-center">
          {{ $t('category.label.attribute_name') }}
          <TranslationPopup v-if="categoryAttribute.id" type="category_attribute" :identifier="categoryAttribute.id!"></TranslationPopup>
        </ZLabel>
        <ZInput id="name" v-model="categoryAttribute.name" />
      </Field>
      <Field symbol="unit">
        <ZLabel for="unit" class="flex justify-between items-center">
          {{ $t('category.label.attribute_unit') }}
          <TranslationPopup
            v-if="categoryAttribute.id"
            :disabled="categoryAttribute.type != 'NUMBER' ? 'true' : ''"
            type="category_attribute"
            :identifier="categoryAttribute.id!"
            symbol="unit"
          ></TranslationPopup>
        </ZLabel>
        <ZInput id="unit" v-model="categoryAttribute.unit" :disabled="categoryAttribute.type != 'NUMBER'" />
      </Field>
      <Field symbol="type" class="col-span-2">
        <ZLabel for="type">
          {{ $t('category.label.attribute_type.title') }}
        </ZLabel>
        <div>
          <SelectField
            id="type"
            v-model="categoryAttribute.type"
            :placeholder="$t('category.label.select_type')"
            :options="attributeTypes"
            translate="category.label."
          />
        </div>
      </Field>
      <Field symbol="validation_rule" class="pt-8">
        <div class="flex gap-2 items-center">
          <Checkbox
            id="is_required"
            class="h-6 w-6"
            :checked="categoryAttribute.validation_rule == 'required'"
            @update:checked="toggleRequired()"
          />
          <ZLabel for="is_required" class="peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >{{ $t('category.label.attribute_is_required') }}
          </ZLabel>
        </div>
      </Field>
      <Field symbol="sync_model" class="pt-8">
        <div class="flex gap-2 items-center">
          <Checkbox
            id="sync_model"
            class="h-6 w-6"
            :checked="categoryAttribute.sync_model"
            @update:checked="toggleSync()"
          />
          <ZLabel for="is_required" class="peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >{{ $t('category.label.sync_model') }}
          </ZLabel>
        </div>
      </Field>
    </div>

    <div
      v-if="
        categoryAttribute.type &&
        (categoryAttribute.type.includes('SELECT') || categoryAttribute.type.includes('MULTISELECT'))
      "
      class="mb-4 border rounded-md md:p-4"
    >
      <Field symbol="parameters" class="h-full">
        <ZLabel for="name">
          {{ $t('category.label.attribute_parameters') }}
        </ZLabel>
        <div class="flex flex-col gap-2">
          <div v-for="(_, index) in options" :key="index" class="flex flex-row gap-2">
            <ZInput id="value" v-model="options[index]" @blur="syncOptions" />
            <TranslationPopup
              v-if="categoryAttribute.id"
              type="category_attribute"
              :identifier="categoryAttribute.id!"
              :symbol="options[index]"
            ></TranslationPopup>
            <ZButton variant="destructive" :disabled="!categoryAttribute.can_be_deleted && initialOptions.hasOwnProperty(index)" @click.prevent="removeOption(index)"><TrashIcon class="icon sm" /></ZButton>
          </div>
          <div>
            <ZLabel>{{ $t('category.label.new_option') }}</ZLabel>
            <div class="flex flex-row gap-2">
              <ZInput id="value" v-model="newOption" class="flex-1" />
              <Action @proceed="addOption">{{ $t('category.command.add_option') }}</Action>
            </div>
          </div>
        </div>
      </Field>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, PropType } from 'vue';
import { ZInput } from '@shadcn/components/ui/input';
import { ZLabel } from '@shadcn/components/ui/label';
import { ZButton } from '@shadcn/components/ui/button';
import SelectField from '@ui/SelectField.vue';
import Field from '@ui/FormField.vue';
import type { KeyValue } from '@/types/Common';
import { TrashIcon } from '@heroicons/vue/24/solid';
import { Checkbox } from '@shadcn/components/ui/checkbox';
import CategoryAttributeData = App.Data.CategoryAttributeData;
import Action from '@ui/Action.vue';
import { Dialogs } from '@/types/Dialogs';
import TranslationPopup from '@ui/TranslationPopup.vue';
import categories from '@views/Categories/Categories.vue';

export default defineComponent({
  components: {
    TranslationPopup,
    Action,
    Checkbox,
    ZInput,
    ZLabel,
    ZButton,
    SelectField,
    Field,
    TrashIcon,
  },

  props: {
    categoryAttribute: {
      type: Object as PropType<CategoryAttributeData>,
      required: true,
    },

    attributeTypes: {
      type: Object as PropType<KeyValue>,
      required: true,
    },
  },

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
    };
  },

  data() {
    return {
      newOption: '' as string,
      options: {} as KeyValue,
      initialOptions: {} as KeyValue,
    };
  },

  computed: {
    categories() {
      return categories;
    },
  },

  mounted() {
    this.options = {};
    this.initialOptions = {};
    for (let index in this.categoryAttribute.parameters) {
      this.options[index.toString()] = this.categoryAttribute.parameters[index];
      this.initialOptions[index.toString()] = this.categoryAttribute.parameters[index];
    }
  },

  methods: {
    generateUIDWithCollisionChecking() {
      while (true) {
        var uid = ('0000' + ((Math.random() * Math.pow(36, 4)) | 0).toString(36)).slice(-4);
        if (!this.options.hasOwnProperty(uid)) {
          return uid;
        }
      }
    },

    toggleRequired() {
      if (this.categoryAttribute.validation_rule == 'required') this.categoryAttribute.validation_rule = '';
      else this.categoryAttribute.validation_rule = 'required';
    },

    toggleSync() {
      this.categoryAttribute.sync_model = !this.categoryAttribute.sync_model;
    },

    addOption() {
      let uid = '' as string;
      uid = this.generateUIDWithCollisionChecking();
      this.options[uid] = this.newOption;
      this.newOption = '';
      this.syncOptions();
    },

    removeOption(index: string) {
      this.dialogs
        .confirm(
          this.$t('category.label.deleting_parameter_option'),
          this.$t('category.message.deleting_parameter_option'),
        )
        .then(() => {
          delete this.options[index];
          this.syncOptions();
        });
    },

    syncOptions() {
      this.categoryAttribute.parameters = {};

      for (const index in this.options) {
        this.categoryAttribute.parameters[index] = this.options[index];
      }
    },
  },
});
</script>
