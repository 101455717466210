<template>
  <DataLoader ref="loader">
    <div class="pt-4">
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <div v-else-if="state == 'loaded'">
        <div v-if="access.hasPermission(SYMBOL.PERMISSION.SYS_ADMIN)" class="md:flex justify-center mt-2">
          <ZButton
            :variant="!displayMineOnly ? 'outline' : 'secondary'"
            class="w-full my-2 md:m-2"
            @click="displayMineOnly = true"
            >{{ $t('dashboard.label.organizations_im_curating') }}
          </ZButton>
          <ZButton
            :variant="displayMineOnly ? 'outline' : 'secondary'"
            class="w-full my-2 md:m-2"
            @click="displayMineOnly = false"
            >{{ $t('dashboard.label.all_organizations') }}
          </ZButton>
        </div>

        <StatWidget :dataset="stats" :shortcuts="false" />
        <EfficiencyWidget :dataset="stats" :dates="dates" />

        <div class="text-lg font-medium pt-12 pb-6">{{ $t('dashboard.label.open_orders') }}</div>
        <div class="grid sm:grid-cols-2 lg:flex sm:gap-4">
          <div class="w-full grid gap-2 mb-4">
            <ZLabel>{{ $t('dashboard.label.order_search') }}</ZLabel>
            <ZInput
              v-model="filters.search"
              type="search"
              :placeholder="$t('dashboard.label.order_search_placeholder')"
            />
          </div>
          <div class="w-full grid gap-2 mb-4">
            <ZLabel>{{ $t('dashboard.label.organization') }}</ZLabel>
            <SelectField v-model="filters.organization_id" :options="organizationsReduced" unselectable></SelectField>
          </div>
          <div class="w-full grid gap-2 mb-4">
            <ZLabel>{{ $t('dashboard.label.filter_status') }}</ZLabel>
            <SelectField v-model="filters.status" unselectable :options="contract_states"></SelectField>
          </div>
        </div>
        <div class="border rounded">
          <ZTable class="w-full">
            <ZTableHeader>
              <ZTableRow>
                <ZTableHead>{{ $t('contracts.label.status') }}</ZTableHead>
                <ZTableHead>{{ $t('contracts.label.contract_number') }}</ZTableHead>
                <ZTableHead>{{ $t('contracts.label.priority') }}</ZTableHead>
                <ZTableHead>{{ $t('contracts.label.assets_count') }}</ZTableHead>
                <ZTableHead>{{ $t('contracts.label.actions_count') }}</ZTableHead>
                <ZTableHead>{{ $t('contracts.label.author') }}</ZTableHead>
                <ZTableHead>{{ $t('contracts.label.created_at') }}</ZTableHead>
              </ZTableRow>
            </ZTableHeader>
            <ZTableBody>
              <ZTableRow
                v-for="(item, index) in curatedOrganizationsContractData.data"
                :key="index"
                class="cursor-pointer"
                @click="details(item.id ?? 0)"
              >
                <ZTableCell>
                  <PrettyStatus :status="item.status!" :translate="'contract.status.'" />
                </ZTableCell>
                <ZTableCell
                  ><span>{{ item.contract_full_number }}</span></ZTableCell
                >
                <ZTableCell
                  ><span>{{ item!.priority! }}</span></ZTableCell
                >
                <ZTableCell
                  ><span>{{ item.contracted_assets_count }}</span></ZTableCell
                >
                <ZTableCell
                  ><span>{{ item.contracted_actions_count }}</span></ZTableCell
                >
                <ZTableCell
                  ><span>{{ item.user_name }}</span></ZTableCell
                >
                <ZTableCell
                  ><span>{{ formatDate(item.created_at!) }}</span></ZTableCell
                >
              </ZTableRow>
            </ZTableBody>
          </ZTable>
          <div
            v-if="curatedOrganizationsContractData.data?.length == 0"
            class="p-8 text-center flex flex-col gap-2 items-center"
          >
            <ExclamationTriangleIcon class="icon lg" />
            {{ $t('contracts.message.data_not_found') }}
          </div>
        </div>
        <Paginator :data="curatedOrganizationsContractData" />
        <div v-if="displayMineOnly">
          <div class="text-lg font-medium pt-12 pb-6">{{ $t('dashboard.label.my_organizations') }}</div>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div
              v-for="(organization, index) in organizations.data"
              :key="`organization-${index}`"
              class="border rounded-xl p-6 shadow-md"
            >
              <div class="flex items-center justify-between">
                <p class="text-md font-medium">{{ organization.name }}</p>
                <router-link
                  :to="{ name: ROUTE.ORGANIZATION_DETAILS, params: { organizationId: organization.id } }"
                  class="text-sm font-medium underline cursor-pointer"
                  >{{ $t('dashboard.command.view') }}
                </router-link>
              </div>
              <p>
                <span class="i-value">
                  {{ organization.postal }} {{ organization.city }}, {{ organization.street }}
                  {{ organization.building_number }} {{ organization.apartment_number ?? '' }}
                </span>
              </p>
              <div class="text-sm pb-2 pt-4 flex justify-between border-b">
                <p>Użytkownicy</p>
                <p>{{ organization.users_count }}</p>
              </div>
              <div class="text-sm py-2 flex justify-between border-b">
                <p>Zasoby</p>
                <p>{{ organization.assets_count }}</p>
              </div>
              <div class="text-sm py-2 flex justify-between border-b">
                <p>Zlecenia</p>
                <p>{{ organization.contracts_count }}</p>
              </div>
            </div>
          </div>
          <Paginator :data="organizations"></Paginator>
        </div>
      </div>
    </div>
  </DataLoader>
</template>

<script lang="ts">
import CuratedOrganizationsCompactData = App.Data.CuratedOrganizationsCompactData;

interface DatesInterface {
  month: string,
  year: number
}
import { defineComponent } from 'vue';
import { ZButton } from '@shadcn/components/ui/button';
import { ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow } from '@shadcn/components/ui/table';
import { ZLabel } from '@shadcn/components/ui/label';
import { ZInput } from '@shadcn/components/ui/input';
import { getAdminDashboardStats, getCuratedOrganizations, getCuratedOrganizationsCompact } from '@/api';
import CuratedOrganizationsData = App.Data.CuratedOrganizationsData;
import { DataSet } from '@/utils/DataSet';
import SimpleSearchData = App.Data.SimpleSearchData;
import Paginator from '@ui/Paginator.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import IconAlert from '@ui/IconAlert.vue';
import { KeyValue } from '@/types/Common';
import SelectField from '@ui/SelectField.vue';
import OrganizationCompactData = App.Data.OrganizationCompactData;
import DataLoader from '@ui/DataLoader.vue';
import { getAllOrganizationsContracts, getCuratedOrganizationsContracts } from '@/api/contract';
import CuratedOrganizationsContractsRequestData = App.Data.CuratedOrganizationsContractsRequestData;
import ContractData = App.Data.ContractData;
import PrettyStatus from '@ui/PrettyStatus.vue';
import { ExclamationTriangleIcon } from '@modules/@heroicons/vue/24/outline';
import symbolsStore from '@/stores/symbols';
import { ROUTE } from '@/router/routeNames';
import GeneralStatsData = App.Data.GeneralStatsData;
import StatWidget from '@ui/Dashboard/StatWidget.vue';
import ContractStatus = App.Enums.ContractStatus;
import EfficiencyWidget from '@ui/Dashboard/EfficiencyWidget.vue';
import useAccessStore from '@/stores/access';

export default defineComponent({
  components: {
    EfficiencyWidget,
    StatWidget,
    ZTableCell,
    ExclamationTriangleIcon,
    PrettyStatus,
    DataLoader,
    SelectField,
    IconAlert,
    Paginator,
    ZInput,
    ZLabel,
    ZTable,
    ZTableHead,
    ZTableBody,
    ZTableHeader,
    ZTableRow,
    ZButton,
    // icons
    // shadcn components
  },

  inject: {
    navigation: { from: 'navigation' },
  },

  props: {
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      organizations: {} as DataSet<CuratedOrganizationsData, SimpleSearchData>,
      curatedOrganizationsContractData: {} as DataSet<ContractData, CuratedOrganizationsContractsRequestData>,
      displayMineOnly: true as boolean,
      organizationsReduced: {} as KeyValue,
      state: 'loading' as ComponentLoadState,
      loader: null as any,
      dates: [] as DatesInterface[],
      contract_states: {} as KeyValue,
      filters: {
        search: '',
        page: 1,
        status: '' as ContractStatus,
        organization_id: 0,
      },

      stats: {} as GeneralStatsData,
    };
  },

  computed: {
    access: () => useAccessStore(),
  },

  watch: {
    displayMineOnly() {
      if (!this.displayMineOnly) {
        this.getAllOrganizationsContracts();
        this.getStats(true);
        return;
      }
      this.getMyOrganizationsContracts();
      this.getStats();
    },

    filters: {
      deep: true,
      handler() {
        this.curatedOrganizationsContractData.setParams(this.filters).resetToFirstPage();
      },
    },
  },

  async mounted() {
    try {
      this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;
      const organizationsResponse = await getCuratedOrganizationsCompact().withLoader(this.loader).execute();
      this.organizationsReduced = organizationsResponse.data.reduce<KeyValue>(
        (accumulator: KeyValue, value: CuratedOrganizationsCompactData) => {
          return { ...accumulator, [value.id]: value.name };
        },
        {},
      );
      await this.getMyOrganizations();
      await this.getStates();
      await this.getMyOrganizationsContracts();
      await this.getStats();
      this.getDates();

      this.state = 'loaded';
    } catch (error) {
      this.state = 'error';
      throw error;
    }
  },

  methods: {
    getDates() {
      const currentDate = new Date();

      for (let i = 0; i < 6; i++) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
        const month = date.toLocaleString('en-US', { month: 'long' }).toLowerCase();
        const year = date.getFullYear();
        this.dates.push({ month, year });
      }
      this.dates = this.dates.reverse();
    },

    async getMyOrganizations() {
      this.organizations = new DataSet<CuratedOrganizationsData, SimpleSearchData>(query =>
        getCuratedOrganizations().execute(query),
      );
      await this.organizations.load();
    },

    async getStats(all?: boolean) {
      const statsResponse = await getAdminDashboardStats().execute(all ? '?all=1' : '');
      this.stats = statsResponse.data;
    },

    async getStates() {
      const symbols = symbolsStore();
      const states = await symbols.get('contract_states');
      for (const [key, value] of Object.entries(states)) {
        this.contract_states[key] = this.$t(value);
      }
      delete this.contract_states.draft;
    },

    async getMyOrganizationsContracts() {
      this.curatedOrganizationsContractData = new DataSet<ContractData, CuratedOrganizationsContractsRequestData>(
        query => getCuratedOrganizationsContracts().execute(query),
      ).setParams(this.filters);
      await this.curatedOrganizationsContractData.load();
    },

    async getAllOrganizationsContracts() {
      this.curatedOrganizationsContractData = new DataSet<ContractData, CuratedOrganizationsContractsRequestData>(
        query => getAllOrganizationsContracts().execute(query),
      ).setParams(this.filters);
      await this.curatedOrganizationsContractData.load();
    },

    details(id: number) {
      this.$router.push({ name: ROUTE.CONTRACT_DETAILS, params: { contractId: id } });
    },
  },
});
</script>
