<template>
  <AlertDialog v-if="isDialogOpen" v-model:open="isDialogOpen" :force-mount="true">
    <AlertDialogContent @escape-key-down="cancel">
      <AlertDialogHeader>
        <AlertDialogTitle>{{ dialogTitle }}</AlertDialogTitle>
        <AlertDialogDescription>
          {{ message }}
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel @click="cancel">{{ $t('common.label.cancel') }}</AlertDialogCancel>
        <AlertDialogAction @click="confirm">{{ $t('common.label.confirm') }}</AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@shadcn/components/ui/alert-dialog';

export default defineComponent({
  components: {
    // icons
    // shadcn components
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
  },

  promiseResolve: null,
  promiseReject: null,

  data() {
    return {
      isDialogOpen: false,
      dialogTitle: '' as string,
      message: '' as string,
      promiseResolve: null as any,
      promiseReject: null as any,
      promise: null as null | Promise<void>,
    };
  },

  methods: {
    open(title: string, message: string): Promise<void> {
      this.dialogTitle = title;
      this.message = message;
      this.isDialogOpen = true;
      this.promise = new Promise((resolve, reject) => {
        this.promiseResolve = resolve;
        this.promiseReject = reject;
      });
      return this.promise;
    },

    cancel() {
      this.isDialogOpen = false;
      if (this.promiseReject != null) this.promiseReject();
    },

    confirm() {
      this.isDialogOpen = false;
      if (this.promiseResolve != null) this.promiseResolve();
    },
  },
});
</script>
<style></style>
