<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent
      class="lg:max-w-[1300px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto flex flex-col"
    >
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('asset.label.update_status') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('asset.message.update_status') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <h2>
          {{ $t('asset.message.assets_amount') }} <b>{{ assets.length }}</b>
        </h2>
        <div class="flex lg:flex-row gap-6 flex-col mt-0">
          <div class="flex-1 max-h-[50vh] max-w-[100vw] overflow-auto">
            <ZTable class="w-full">
              <ZTableHeader>
                <ZTableRow>
                  <ZTableHead></ZTableHead>
                  <ZTableHead>{{ $t('asset.label.name') }}</ZTableHead>
                  <ZTableHead>{{ $t('asset.label.status') }}</ZTableHead>
                  <ZTableHead>{{ $t('asset.label.asset_model') }}</ZTableHead>
                  <ZTableHead>{{ $t('asset.label.inventory_number') }}</ZTableHead>
                  <ZTableHead>{{ $t('asset.label.category') }}</ZTableHead>
                </ZTableRow>
              </ZTableHeader>
              <ZTableBody>
                <ZTableRow v-for="(item, index) in assets" :key="index">
                  <ZTableCell>
                    <img
                      v-if="item.thumb"
                      class="min-w-[50px] w-[50px] h-[50px] object-cover rounded-xl"
                      :src="item.thumb"
                      alt="primaryImage"
                    />
                  </ZTableCell>
                  <ZTableCell>
                    <b>{{ item.name }}</b>
                  </ZTableCell>
                  <ZTableCell>
                    <PrettyStatus :status="item.status!" :translate="'asset.states.'" />
                  </ZTableCell>
                  <ZTableCell>
                    {{ item.asset_model?.name }}
                  </ZTableCell>
                  <ZTableCell>
                    {{ item.asset_number }}
                  </ZTableCell>
                  <ZTableCell>{{ item.category ? item.category.name : $t('asset.label.lack') }}</ZTableCell>
                </ZTableRow>
              </ZTableBody>
            </ZTable>
          </div>
          <div class="md:px-4 py-2 flex flex-col">
            <div>
              <FormValidator ref="form" class="min-w-64">
                <UpdateAssetsStatusForm @update:new-status="updateSelectedStatus" />
              </FormValidator>
            </div>
            <ZDialogFooter class="py-4">
              <Action class="w-full" :disabled="!canSubmit" @proceed="updateAssetStatuses">{{
                $t('asset.command.update_status')
              }}</Action>
            </ZDialogFooter>
          </div>
        </div>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import MinimalAssetData = App.Data.MinimalAssetData;
import { ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow } from '@shadcn/components/ui/table';
import { ValidationErrors } from '@/types/ValidationErrors';
import FormValidator from '@ui/FormValidator.vue';
import UpdateAssetsStatusForm from '@ui/Assets/UpdateAssetStatusesForm.vue';
import { updateAssetStatuses } from '@/api/asset';
import PrettyStatus from '@ui/PrettyStatus.vue';

export default defineComponent({
  components: {
    PrettyStatus,
    UpdateAssetsStatusForm,
    FormValidator,
    ZTableHeader,
    ZTableCell,
    ZTableHead,
    ZTableBody,
    ZTable,
    ZTableRow,
    IconAlert,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    assets: {
      type: Array<MinimalAssetData>,
      required: true,
    },
  },

  emits: ['updateStatusesDialogClosed', 'saved'],

  setup() {
    const currentTab = ref('withinOrganization');

    return {
      currentTab,
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      status: '',
      state: 'loaded' as ComponentLoadState,
      errors: null as null | ValidationErrors,
    };
  },

  computed: {
    canSubmit() {
      return this.status && this.status.length > 0;
    },
  },

  watch: {
    isDialogOpen() {
      this.$emit('updateStatusesDialogClosed', true);
    },
  },

  methods: {
    updateSelectedStatus(newStatus: string) {
      this.status = newStatus;
    },

    async updateAssetStatuses() {
      this.state = 'loading';
      const assetIds = this.assets.map(asset => asset.id?.toString());
      await updateAssetStatuses(assetIds, this.status!)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('asset.message.status_update_success'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
