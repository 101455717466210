<template>
  <ZButton type="submit" :disabled="processing" @click.prevent="proceed">
    <div v-if="processing" class="flex">
      <svg
        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      {{ $t('common.action.processing') }}
    </div>
    <slot v-if="!processing" />
  </ZButton>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZButton } from '@shadcn/components/ui/button';
import type { Loader } from '@/types/Loader';

export default defineComponent({
  components: {
    // icons
    // shadcn components
    ZButton,
  },

  props: [],
  events: ['proceed'],
  emits: ['proceed'],

  data() {
    return {
      processing: false as boolean,
      loader: null as Loader | null,
    };
  },

  computed: {},

  watch: {},

  mounted() {
    this.loader = {
      setLoading(b: boolean) {
        this.component.processing = b;
        return true;
      },

      setError() {
        this.component.processing = false;
      },
    } as Loader;

    this.loader.component = this;
  },

  methods: {
    proceed() {
      this.$emit('proceed', this);
    },
  },
});
</script>
