<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>
          {{
            $route.name == ROUTE.CATEGORY_ADD
              ? $t('category.label.create_category')
              : $t('category.label.create_sub_category')
          }}
        </ZDialogTitle>
        <ZDialogDescription>
          {{ $t('category.message.create_category') }}
        </ZDialogDescription>
      </ZDialogHeader>

      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded' && category && attributeTypes">
        <FormValidator ref="form" class="grid grid-cols-7 gap-4 py-4">
          <CategoryForm :category="category" :attributeTypes="attributeTypes"></CategoryForm>
        </FormValidator>
        <ZDialogFooter>
          <Action v-if="$route.name == ROUTE.CATEGORY_ADD" @proceed="store">
            {{ $t('category.command.add_category') }}
          </Action>
          <Action v-else @proceed="storeChildren">
            {{ $t('category.command.add_category') }}
          </Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import type { KeyValue } from '@/types/Common';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import CategoryData = App.Data.CategoryData;
import CategoryForm from '@ui/Categories/CategoryForm.vue';
import { createCategory, createSubCategory } from '@/api/category';
import { ROUTE } from '@/router/routeNames';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';

export default defineComponent({
  components: {
    ZDialogFooter,
    ZDialogTitle,
    ZDialogDescription,
    ZDialogHeader,
    ZDialogContent,
    ZDialog,
    CategoryForm,
    IconAlert,
    Action,
    FormValidator,
  },

  props: {
    parentCategoryId: {
      type: String,
      default: undefined,
      required: false,
    },
  },

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      category: {} as CategoryData,
      attributeTypes: {} as KeyValue,
      isDialogOpen: true,

      state: 'loading' as ComponentLoadState,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      this.category = { name: '' } as CategoryData;
      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
    }
  },

  methods: {
    store(action: any) {
      createCategory(this.category)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(response => {
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('category.message.category_saved'),
          });
          this.$router.push({ name: ROUTE.CATEGORY_LIST, query: { categoryId: response.data.new_id } });
        });
    },

    storeChildren(action: any) {
      createSubCategory(this.category, this.parentCategoryId)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(response => {
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('category.message.category_saved'),
          });
          this.$router.push({ name: ROUTE.CATEGORY_LIST, query: { categoryId: response.data.new_id } });
        });
    },
  },
});
</script>
