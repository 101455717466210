<template>
  <div v-if="assetMutatedTags?.length" class="border rounded-lg mb-4">
    <div class="px-4 mt-4 text-sm font-semibold">
      {{ $t('asset.label.selected_tags') }} ({{ assetMutatedTags.length }})
    </div>
    <div class="flex gap-2 p-5 flex-wrap">
      <div v-for="(tag, idx) in assetMutatedTags" :key="idx" class="cursor-pointer">
        <TagLabel
          :name="tag.name"
          :color="tag.color"
          class="hover:bg-gray-100 hover:shadow-md whitespace-nowrap"
          @click="manageTag(tag)"
        />
      </div>
    </div>
  </div>

  <div class="border rounded-lg">
    <div v-if="tags.data?.length">
      <div class="px-4 mt-4 text-sm font-semibold">{{ $t('asset.label.all_tags') }}</div>
      <div class="flex gap-4 p-5 flex-wrap">
        <div v-for="(tag, idx) in tags.data" :key="idx" class="cursor-pointer">
          <TagLabel
            :name="tag.name"
            :color="tag.color"
            class="hover:bg-gray-100 hover:shadow-md whitespace-nowrap"
            :class="{ 'bg-gray-200 shadow-md': assetMutatedTags && assetMutatedTags.find(item => item.id === tag.id) }"
            @click="manageTag(tag)"
          />
        </div>
      </div>
      <Paginator class="px-4 border-t" :data="tags"/>
    </div>
    <div v-else class="text-center text-sm p-4">
      {{ $t('asset.message.no_tags') }}
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {PropType} from 'vue';
import {DataSet} from '@/utils/DataSet';
import Paginator from '@ui/Paginator.vue';
import TagLabel from '@ui/Tags/TagLabel.vue';

interface assetTag {
  id: number;
  name: string;
  color: string | null;
}

export default defineComponent({
  components: { TagLabel, Paginator },

  props: {
    tags: {
      type: Object as PropType<DataSet<any, any>>,
      required: true,
    },

    assetTags: {
      type: Array as PropType<assetTag[]>,
      required: false,
      default: () => []
    },
  },

  emits: ['update'],

  data() {
    return {
      assetMutatedTags: [] as assetTag[],
    };
  },

  mounted() {
    this.assetMutatedTags = this.assetTags.reduce((accumulator, value) => {
      return [...accumulator, {id: value.id, name: value.name, color: value.color}];
    }, [] as assetTag[]);
    this.$emit('update', this.assetMutatedTags);
  },

  methods: {
    manageTag(tag: assetTag) {
      const existingTag = this.assetMutatedTags.find(item => item.id === tag.id);
      if (existingTag) {
        this.assetMutatedTags.splice(this.assetMutatedTags.indexOf(existingTag), 1);
      } else {
        this.assetMutatedTags.push({id: tag.id, name: tag.name, color: tag.color});
      }
      this.$emit('update', this.assetMutatedTags);
    },
  },
});
</script>
