<template>
  <div class="border border-green-500 rounded-xl overflow-hidden mt-6">
    <div class="flex p-3 flex-row justify-between items-center border-b border-b-green-500 bg-green-50">
      <b>{{ $t('dictionary.label.carbon_default_values') }}</b>
    </div>
    <div class="flex flex-col">
      <div v-if="defaultValues.length == 0" class="m-4 p-4 text-center text-sm text-gray-600">
        {{ $t('dictionary.label.no_carbon_default_values') }}
      </div>

      <FormValidator :errors="errors">
        <FormField
          v-for="(defaultValue, index) in defaultValues"
          :key="index"
          :symbol="`${defaultValue.id}-carbon_footprint`"
          class="border-b p-1 text-center"
        >
          <div  class="i-block horizontal border-none">
            <div class="i-label">{{ defaultValue.action_name }}: </div>
            <div class="i-value flex gap-2 items-center">
            <span v-if="edit != index" @click.prevent="editIt(index)">{{
                defaultValue.carbon_footprint ? defaultValue.carbon_footprint : $t('dictionary.label.not_applicable')
              }}</span>
              <ZInput v-if="edit == index" v-model="editedValue"/>
              <span v-if="defaultValue.carbon_footprint > 0" @click.prevent="editIt(index)">g</span>
              <a v-if="edit == null" href="#" @click.prevent="editIt(index)"
              ><PencilSquareIcon class="icon sm opacity-10 group-hover:opacity-100"
              /></a>
              <a v-if="edit == index" href="#" @click.prevent="edit = null"
              ><XCircleIcon class="icon md opacity-50 hover:opacity-100"
              /></a>
              <a v-if="edit == index" href="#" @click.prevent="saveCurrent()"
              ><CheckCircleIcon class="icon md opacity-50 hover:opacity-100"
              /></a>
            </div>
          </div>
        </FormField>
      </FormValidator>
      <FormValidator :errors="errors" v-if="edit == -1" class="i-block horizontal border-b p-3 text-sm">
        <div class="w-[50%] flex self-start">
          <FormField symbol="new-action_id">
            <SelectField v-model="newActionId" :options="availableActions" searchable/>
          </FormField>
        </div>
        <div class="flex self-start overflow-hidden w-full">
          <FormField symbol="new-carbon_footprint">
            <div class="i-value flex flex-1 items-center gap-2">
              <ZInput v-model="newDefaultValue" type="text"/>
              g
            </div>
          </FormField>
        </div>
      </FormValidator>
      <ZButton
        v-if="edit != -1"
        class="m-2"
        @click.prevent="add()"
      >
        <PlusCircleIcon class="icon md mr-2"/>
        {{ $t('dictionary.command.add_carbon_value') }}
      </ZButton
      >
      <div v-if="edit == -1" class="flex flex-row gap-4 m-2">
        <ZButton
          variant="outline"
          class="w-full"
          @click.prevent="edit = null">{{
            $t('common.cancel')
          }}
        </ZButton>
        <ZButton
          class="w-full"
          @click.prevent="addDefaultValue()"
        >{{ $t('dictionary.command.save_carbon_value') }}
        </ZButton
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {ZInput} from '@shadcn/components/ui/input';
import {inject} from '@modules/vue';
import {Dialogs} from '@/types/Dialogs';
import ActionData = App.Data.ActionData;
import CarbonFootprintDefaultValueData = App.Data.CarbonFootprintDefaultValueData;
import {
  createDefaultCarbonValue,
  getCompactedActions,
  getDefaultCarbonValuesForAssetModel,
  getDefaultCarbonValuesForCategory,
  saveDefaultCarbonValue,
} from '@/api';
import SelectField from '@ui/SelectField.vue';
import {KeyValue} from '@/types/Common';
import {PencilSquareIcon, PlusCircleIcon, XCircleIcon, CheckCircleIcon} from '@heroicons/vue/24/outline';
import {HttpStatusCode} from '@modules/axios';
import {ValidationErrors} from '@/types/ValidationErrors';
import FormValidator from '@ui/FormValidator.vue';
import FormField from '@ui/FormField.vue';
import {ZButton} from "@shadcn/components/ui/button";

export default defineComponent({
  components: {
    FormField,
    FormValidator,
    //icons
    PencilSquareIcon,
    PlusCircleIcon,
    XCircleIcon,
    CheckCircleIcon,
    //shadcn
    SelectField,
    ZButton,
    ZInput,
  },

  props: {
    type: {
      type: String,
      required: true,
    },

    identifier: {
      type: Number,
      required: true,
    },
  },

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      actions: [] as ActionData[],
      defaultValues: [] as CarbonFootprintDefaultValueData[],
      newActionId: 0 as number,
      newDefaultValue: 0 as number,
      edit: null as number | null,
      editedValue: null as number | null,
      error: false as boolean,
      errors: null as null | ValidationErrors,
    };
  },

  computed: {
    availableActions() {
      return this.actions.reduce((accumulator: KeyValue, value: ActionData) => {
        for (let index in this.defaultValues) {
          if (this.defaultValues[index].action_id == value.id) return accumulator;
        }
        return {...accumulator, [value.id as number]: value.name};
      }, {});
    },
  },

  watch: {
    async identifier(val) {
      const defaultValuesResponse =
        this.type == 'category'
          ? await getDefaultCarbonValuesForCategory(val).execute()
          : await getDefaultCarbonValuesForAssetModel(val).execute();
      this.defaultValues = defaultValuesResponse.data;
    },
  },

  async mounted() {
    const defaultValuesResponse =
      this.type == 'category'
        ? await getDefaultCarbonValuesForCategory(this.identifier.toString()).execute()
        : await getDefaultCarbonValuesForAssetModel(this.identifier.toString()).execute();
    this.defaultValues = defaultValuesResponse.data;

    const actionsResponse = await getCompactedActions().execute();
    this.actions = actionsResponse.data;
  },

  methods: {
    async addDefaultValue() {
      let fields = {
        action_id: this.newActionId,
        carbon_footprint: this.newDefaultValue,
      } as CarbonFootprintDefaultValueData;

      if (this.type == 'category') {
        fields.category_id = this.identifier;
      } else {
        fields.asset_model_id = this.identifier;
      }

      try {
        await createDefaultCarbonValue(fields).execute();
        this.edit = null;

        const defaultValuesResponse =
          this.type == 'category'
            ? await getDefaultCarbonValuesForCategory(this.identifier.toString()).execute()
            : await getDefaultCarbonValuesForAssetModel(this.identifier.toString()).execute();
        this.defaultValues = defaultValuesResponse.data;
      } catch (error) {
        if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
          let errors = error.response.data.errors;
          this.updateKeysWithId(errors, 'new')
        }
      }
    },

    editIt(index: number) {
      this.edit = index;
      this.errors = {};
      this.editedValue = this.defaultValues[index].carbon_footprint;
    },

    add() {
      this.errors = {}
      this.newActionId = null;
      this.newDefaultValue = 0;
      this.edit = -1;
    },

    async saveCurrent() {
      if (this.edit != null && this.edit >= 0) {
        let goodValue = this.defaultValues[this.edit].carbon_footprint;

        try {
          this.defaultValues[this.edit].carbon_footprint = this.editedValue!;
          await saveDefaultCarbonValue(this.defaultValues[this.edit].id!.toString(), this.defaultValues[this.edit]).execute();

          this.editedValue = null;
          this.edit = null;
          this.errors = {};
        } catch (error) {
          this.defaultValues[this.edit!].carbon_footprint = goodValue;
          this.error = true;
          if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
            let errors = error.response.data.errors;
            this.updateKeysWithId(errors, this.defaultValues[this.edit].id)
          }
          this.defaultValues[this.edit].carbon_footprint = goodValue;
        }
      }
    },

    updateKeysWithId(object, id) {
      const updatedObject = {};
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          updatedObject[`${id}-${key}`] = object[key];
        }
      }
      this.errors = updatedObject;
    }
  },
})
;
</script>
