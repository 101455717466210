<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('asset.label.edit_asset_model_images') }}</ZDialogTitle>
      </ZDialogHeader>
      <div></div>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <IconAlert v-if="uploadErrors.length > 0" type="error">
          <div v-for="(error, index) in uploadErrors" :key="index">{{ error }}</div>
        </IconAlert>
        <div v-if="imagesToUpload.length" class="grid gap-4 grid-cols-8 border border-green-600 rounded-md p-3">
          <div
            v-for="(img, i) in imagesToUpload"
            :key="i"
            class="relative border rounded h-[50px] w-[50px] cursor-pointer"
          >
            <img class="w-full h-full object-cover rounded" :src="img"/>
          </div>
        </div>
        <div class="flex items-end">
          <div class="grid w-full max-w-sm items-center gap-1.5">
            <ZInput id="images" multiple type="file" accept="image/png, image/jpeg, image/png, image/jpg"
                    @change="handleFileChange"/>
          </div>
          <ZButton class="ml-3 w-[200px]" :disabled="tempFiles.getAll('images[]').length == 0" @click="saveFiles">{{
              $t('asset.label.add_images')
            }}
          </ZButton>
        </div>
        <div v-if="!asset.images?.length" class="my-4">
          <InformationCircleIcon class="icon w-[30px] mb-5 m-auto"/>
          <h3 class="scroll-m-20 font-semibold text-center">{{ $t('asset.label.not_found_images') }}</h3>
        </div>
        <div v-if="asset.images?.length" class="flex flex-1 gap-3 flex-wrap overflow-auto">
          <div
            v-for="(image, i) in asset.images"
            :key="i"
            class="relative w-[134px] h-[134px] rounded-lg overflow-hidden border p-1 cursor-pointer"
            :class="image.id == asset.primary_image_id ? 'border-green-600' : ''"
          >
            <img
              :src="image!.sourceUrl!"
              :alt="image!.name!"
              class="w-full h-full object-cover rounded-lg"
              @click="updatePrimaryImage(image!.id!)"
            />
            <div
              class="absolute rounded-full bg-white text-black hover:bg-black hover:text-white transition bottom-1 right-1 w-8 cursor-pointer p-1"
              @click="deleteImage(image!.id!)"
            >
              <TrashIcon></TrashIcon>
            </div>
            <div
              v-if="image.id == asset.primary_image_id"
              class="absolute bg-green-600 rounded-full top-1 left-1 cursor-pointer text-xs text-white py-1 px-1.5"
            >
              {{ $t('asset.label.primary_image') }}
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import {ZDialog, ZDialogContent, ZDialogHeader, ZDialogTitle} from '@shadcn/components/ui/dialog';
import {InformationCircleIcon, TrashIcon} from '@heroicons/vue/24/outline';
import IconAlert from '@ui/IconAlert.vue';
import {ComponentLoadState} from '@/types/ComponentLoadState';
import {ZButton} from '@shadcn/components/ui/button';
import {ZInput} from '@shadcn/components/ui/input';
import AssetData = App.Data.AssetData;
import {deleteAssetImage, getAsset, storeAssetImage, updatePrimaryImage} from '@/api/asset';

export default defineComponent({
  components: {
    InformationCircleIcon,
    ZInput,
    IconAlert,
    ZDialog,
    ZButton,
    ZDialogContent,
    ZDialogHeader,
    ZDialogTitle,
    TrashIcon,
  },

  props: {
    assetId: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      asset: {} as AssetData,
      state: 'loaded' as ComponentLoadState,
      tempFiles: new FormData(),
      imagesToUpload: [] as string[],
      uploadErrors: [] as string[],
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      await this.getAsset();
    } catch (error: any) {
      this.state = error;
    }
  },

  methods: {
    async getAsset() {
      this.asset = (await getAsset(this.assetId).execute()).data;
    },

    handleFileChange(event: any) {
      this.tempFiles = new FormData();
      this.imagesToUpload = [];
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        this.tempFiles.append('images[]', files[i]);
        this.imagesToUpload.push(URL.createObjectURL(files[i]));
      }
    },

    async saveFiles() {
      try {
        await storeAssetImage(this.assetId, this.tempFiles)
          .execute()
          .then(() => {
            this.getAsset();
          });
        this.tempFiles = new FormData();
        this.imagesToUpload = [];
        this.uploadErrors = [];
      } catch (errors: any) {
        this.uploadErrors = [];
        let errorBag: keyof typeof errors.response.data.errors;
        for (errorBag in errors.response.data.errors) {
          const fileErrors = errors.response.data.errors[errorBag];
          let error: keyof typeof fileErrors;
          for (error in errors.response.data.errors[errorBag]) {
            this.uploadErrors.push(
              this.$t('asset.validation.file') +
              ' ' +
              (this.uploadErrors.length + 1) +
              ': ' +
              this.$t(errors.response.data.errors[errorBag][error]),
            );
          }

          if (this.uploadErrors.length > 2) {
            this.uploadErrors.push('...');
            break;
          }
        }
      }
    },

    deleteImage(imageId: number) {
      deleteAssetImage(this.assetId, imageId)
        .execute()
        .then(() => {
          this.getAsset();
        });
    },

    updatePrimaryImage(imageId: number) {
      updatePrimaryImage(this.assetId, imageId)
        .execute()
        .then(() => {
          this.getAsset();
        });
    },
  },
});
</script>
