<template>
  <div>
    <h3 class="text-2xl font-semibold mb-5">{{ $t('account.settings.header') }}</h3>
    <div class="max-w-[500px]">
      <div class="p-5 flex justify-between items-center border rounded-xl">
        <div class="mr-4">
          <p>{{ $t('account.settings.language.label') }}</p>
        </div>
        <div class="w-full lg:w-[100px]">
          <ZSelect v-model="form.language_code">
            <ZSelectTrigger>
              <ZSelectValue :placeholder="$t('notification.placeholder')" />
            </ZSelectTrigger>
            <ZSelectContent>
              <ZSelectGroup>
                <ZSelectItem v-for="(lang, index) in languages" :key="index" :value="lang.code">{{
                  lang.code
                }}</ZSelectItem>
              </ZSelectGroup>
            </ZSelectContent>
          </ZSelect>
        </div>
      </div>
      <div class="flex justify-end">
        <ZButton class="mt-5" @click.prevent="update">{{ $t('common.save') }}</ZButton>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { ZButton } from '@shadcn/components/ui/button';
import { useToast } from '@shadcn/components/ui/toast/use-toast';
import { postUpdateSettings, getCurrentUser } from '@/api';
import loaderStore from '../../stores/loader';
import { HttpStatusCode } from 'axios';
import i18n from '../../utils/i18n';
import {
  ZSelect,
  ZSelectContent,
  ZSelectGroup,
  ZSelectItem,
  ZSelectTrigger,
  ZSelectValue,
} from '@shadcn/components/ui/select';
import useUserStore from '@/stores/auth';
import { getLanguages } from '@/api/dictionaries';

export default defineComponent({
  components: {
    ZSelectValue,
    ZSelectItem,
    ZSelectContent,
    ZSelectGroup,
    ZSelectTrigger,
    ZSelect,
    ZButton,
  },

  data() {
    return {
      loader: loaderStore(),
      languages: [] as App.Data.LanguageData[],
      form: {
        language_code: '',
      },
    };
  },

  async mounted() {
    this.loader.startLoading();
    const { user } = useUserStore();
    this.form.language_code = user?.language_code as string;
    const response: any = await getLanguages();
    this.languages = response.data;
    this.loader.stopLoading();
  },

  methods: {
    update() {
      const { toast } = useToast();
      this.loader.startLoading();
      postUpdateSettings(this.form)
        .then(async () => {
          toast({
            title: this.$t('common.success.header'),
            description: this.$t('common.success.saved'),
          });
          const { data } = await getCurrentUser();
          i18n.global.locale = <any>data.language_code;
          const { updateUser } = useUserStore();
          updateUser(data);
        })
        .catch(error => {
          if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
            toast({
              title: this.$t('common.errors.header'),
              description: this.$t(error.response.data.message),
              variant: 'destructive',
            });
          }
        })
        .finally(() => this.loader.stopLoading());
    },
  },
});
</script>
