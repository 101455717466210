<template>
  <router-view></router-view>
  <Loader></Loader>
  <Toaster />
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import Loader from './components/Loader.vue';
import Toaster from '@shadcn/components/ui/toast/Toaster.vue';
import { useToast } from '@shadcn/components/ui/toast';

export default defineComponent({
  components: {
    Loader,
    Toaster,
  },

  provide() {
    const { toast } = useToast();

    return {
      toast: toast,
    };
  },
});
</script>
