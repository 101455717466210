import type { RouteRecordRaw } from 'vue-router';
import { ROUTE } from './routeNames';
import Dictionaries from '@views/Dictionaries/Dictionaries.vue';
import Actions from '@views/Dictionaries/Actions.vue';
import Countries from '@views/Dictionaries/Countries.vue';
import EditActionDialog from '@views/Dictionaries/EditActionDialog.vue';
import NewActionDialog from '@views/Dictionaries/NewActionDialog.vue';
import EditCountryDialog from '@views/Dictionaries/EditCountryDialog.vue';
import NewCountryDialog from '@views/Dictionaries/NewCountryDialog.vue';
import NewRegionDialog from '@views/Dictionaries/NewRegionDialog.vue';
import {SYMBOL} from "@/types/symbols";

export const dictionaryRoutes: RouteRecordRaw[] = [
  {
    path: 'dictionary',
    name: ROUTE.DICTIONARY.INDEX,
    component: Dictionaries,
    meta: {
      breadcrumbs: true,
      title: 'dictionary.title',
      permission: [{operator: true, type: SYMBOL.PERMISSION.SYS_DICTIONARY_MANAGER}],
    },
    children: [
      {
        path: 'dictionary_actions',
        name: ROUTE.DICTIONARY.ACTIONS,
        component: Actions,
        meta: {
          breadcrumbs: true,
          title: 'dictionary.actions_title',
        },
        children: [
          {
            path: 'edit/:actionId',
            component: EditActionDialog,
            name: ROUTE.DICTIONARY.ACTION_EDIT,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'add',
            component: NewActionDialog,
            name: ROUTE.DICTIONARY.ACTION_ADD,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
        ],
      },
      {
        path: 'countries',
        name: ROUTE.DICTIONARY.COUNTRIES,
        redirect: () => {
          return { name: ROUTE.DICTIONARY.COUNTRY_INDEX };
        },
        props: true,
        meta: {
          breadcrumbs: true,
          title: 'dictionary.countries_title',
        },
        children: [
          {
            path: 'index',
            name: ROUTE.DICTIONARY.COUNTRY_INDEX,
            component: Countries,
            meta: {
              breadcrumbs: true,
              title: 'dictionary.country_index',
            },
            children: [
              {
                path: 'add',
                component: NewCountryDialog,
                name: ROUTE.DICTIONARY.COUNTRY_ADD,
                props: true,
                meta: {
                  breadcrumbs: true,
                  title: 'dictionary.country_add',
                },
              },
            ],
          },
          {
            path: ':countryId',
            component: EditCountryDialog,
            name: ROUTE.DICTIONARY.COUNTRY_EDIT,
            props: true,
            meta: {
              breadcrumbs: true,
              title: 'dictionary.country_edit',
            },
            children: [
              {
                path: 'add-region',
                component: NewRegionDialog,
                name: ROUTE.DICTIONARY.REGION_ADD,
                props: true,
                meta: {
                  breadcrumbs: true,
                  title: 'dictionary.country_add',
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
