<template>
  <div class="flex flex-row gap-1 items-center" :class="{ 'justify-end': justifyContent == 'right' }">
    <template v-if="editMode">
      <div v-if="definition.type == 'TEXT'" class="w-1/2">
        <ZInput v-model="model" type="text" @change="change" />
      </div>
      <div v-else-if="definition.type == 'CHECKBOX'">
        <ZSwitch :checked="getCheckboxValue()" @update:checked="changeSwitch" />
      </div>
      <div v-else-if="definition.type == 'NUMBER'" class="w-1/2">
        <ZInput v-model="model" type="number" @change="change" />
      </div>
      <div v-else-if="definition.type == 'DATE'">
        <ZInput v-model="model" type="date" @change="change" />
      </div>
      <div v-else-if="definition.type == 'SELECT'" class="min-w-40 w-1/2">
        <SelectField v-model="model" :options="options" @update:model-value="change" unselectable />
      </div>
      <div v-else-if="definition.type == 'MULTISELECT'" class="min-w-40 w-1/2">
        <SelectMultipleField
          v-model="model"
          :placeholder="$t('assetModels.placeholder')"
          :options="options"
          @update:model-value="change"
        />
      </div>
    </template>
    <template v-else-if="definition.type == 'CHECKBOX'">
      <span v-if="model == 1" class="rounded text-white bg-green-600 px-2">{{ $t('common.yes') }}</span>
      <span v-else class="rounded bg-gray-300 px-2">{{ $t('common.no') }}</span>
    </template>
    <template v-else-if="definition.type == 'SELECT'">
      {{ selectedItem() }}
    </template>
    <template v-else-if="definition.type == 'MULTISELECT'">
      {{ multipleSelectItems() }}
    </template>
    <template v-else>
      {{ model ?? '-' }}
    </template>
    <div v-if="editMode" class="text-xs ml-1 text-gray-400 w-2 flex self-start">
      <b class="text-red-600 text-sm">{{ definition.validation_rule == 'required' ? '*' : '' }}</b>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { ZInput } from '@shadcn/components/ui/input';
import SelectField from '@ui/SelectField.vue';
import ZSwitch from '@shadcn/components/ui/switch/Switch.vue';
import SelectMultipleField from '@ui/SelectMultipleField.vue';
import { KeyValue } from '@/types/Common';

interface ItemType {
  [key: number]: any;
}

export default defineComponent({
  components: {
    SelectMultipleField,
    ZSwitch,
    SelectField,
    ZInput,
  },

  props: {
    modelValue: {
      type: [String, Number],
      required: false,
      default: '',
    },

    editMode: {
      type: Boolean,
      required: true,
    },

    onSave: {
      type: Boolean,
      required: true,
    },

    definition: {
      type: Object,
      required: true,
    },

    justifyContent: {
      type: [String, Boolean],
      default: false,
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      model: this.modelValue,
    };
  },

  computed: {
    options() {
      if (
        (this.definition.type == 'SELECT' || this.definition.type == 'MULTISELECT') &&
        this.definition.parameters !== null
      ) {
        const values = {} as KeyValue;
        for (const index in this.definition.parameters) {
          values[index] = this.definition.parameters[index];
        }

        return values;
      }
      return {} as KeyValue;
    },
  },

  watch: {
    modelValue() {
      this.model = this.modelValue;
      if (this.definition.type == 'CHECKBOX') {
        this.changeSwitch(!!this.model);
      }
    },
  },

  mounted() {
    if (this.definition.type == 'CHECKBOX') {
      this.changeSwitch(!!this.model);
    }
    if (
      (this.definition.type == 'SELECT' || this.definition.type == 'MULTISELECT') &&
      this.definition.parameters !== null
    ) {
      const values = {} as KeyValue;
      for (const index in this.definition.parameters) {
        values[index] = this.definition.parameters[index];
      }

      if (Array.isArray(this.model)) {
        let modelKey: keyof typeof this.model;
        let newModel: any;
        newModel = [];
        for (modelKey in this.model) {
          if (values[this.model[modelKey]]) newModel[modelKey] = this.model[modelKey];
        }
        this.model = newModel;
        this.change();
      } else if (!values[this.model]) {
        this.model = '';
        this.change();
      }
    }
  },

  methods: {
    multipleSelectItems() {
      let list = [] as any;
      if (Array.isArray(this.model)) {
        list = this.model;
      }

      let selected = list.length ? list : [];
      const values = [] as string[];
      if (selected) {
        for (let key in selected as ItemType) {
          values[key] = this.definition.parameters[selected[key]];
        }
      }
      return values.length ? values.join(', ') : '-';
    },

    selectedItem() {
      return this.options?.[this.model] ?? '-';
    },

    change() {
      this.$emit('update:modelValue', this.model);
    },

    getCheckboxValue() {
      return !!(this.model && this.model);
    },

    changeSwitch(val: boolean) {
      this.model = val ? 1 : 0;
      this.$emit('update:modelValue', this.model);
    },
  },
});
</script>
