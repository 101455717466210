import { http } from './http';
import { ApiCall } from '@/api/ApiCall';
import { KeyValue } from '@/types/Common';
import AssetNotesData = App.Data.AssetNotesData;
import AssetNotesUpdateRequestData = App.Data.AssetNotesUpdateRequestData;
import AssetNotesRequestData = App.Data.AssetNotesRequestData;
import { PaginatedResponse } from '@/utils/DataSet';

export const getAssetNotes = (fields: AssetNotesRequestData, id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<AssetNotesData>>(`/assets/${options.id}/notes` + query);
    },
    options,
    '',
    fields,
  );
};

export const getAssetNote = (assetId: string, noteId: string) => {
  const options = {} as KeyValue;
  options.assetId = assetId;
  options.noteId = noteId;
  return ApiCall.create(options => {
    return http.get<AssetNotesData>(`/assets/${options.assetId}/notes/${options.noteId}`);
  }, options);
};

export const createAssetNote = (assetId: string, note: string) => {
  const options = {} as KeyValue;
  options.assetId = assetId;
  const fields = {
    note: note,
  } as AssetNotesUpdateRequestData;

  return ApiCall.create(
    (options, query, fields) => {
      return http.post(`/assets/${options.assetId}/notes`, fields);
    },
    options,
    '',
    fields,
  );
};

export const updateAssetNote = (assetId: string, noteId: string, fields: AssetNotesUpdateRequestData) => {
  const options = {} as KeyValue;
  options.assetId = assetId;
  options.noteId = noteId;
  return ApiCall.create(
    (options, query, fields) => {
      return http.post(`/assets/${options.assetId}/notes/${options.noteId}/update`, fields);
    },
    options,
    '',
    fields,
  );
};

export const deleteAssetNote = (assetId: string, noteId: string) => {
  const options = {} as KeyValue;
  options.assetId = assetId.toString();
  options.noteId = noteId.toString();
  return ApiCall.create(options => {
    return http.delete(`/assets/${options.assetId}/notes/${options.noteId}/delete`);
  }, options);
};
