<template>
  <div>
    <div class="border rounded-lg">
      <ZTable class="w-full">
        <ZTableHeader>
          <ZTableRow>
            <ZTableHead>{{ $t('contracts.label.timestamp') }}</ZTableHead>
            <ZTableHead>{{ $t('contracts.label.author') }}</ZTableHead>
            <ZTableHead>{{ $t('contracts.label.subject') }}</ZTableHead>
            <ZTableHead>{{ $t('contracts.label.comment') }}</ZTableHead>
          </ZTableRow>
        </ZTableHeader>
        <ZTableBody>
          <template v-for="(item, index) in changesDataSet.data" :key="index">
            <ZTableRow class="cursor-pointer">
              <ZTableCell>
                <b>{{ formatDateTime(item!.created_at!) }}</b>
              </ZTableCell>
              <ZTableCell>
                {{ item!.user_name }}
              </ZTableCell>
              <ZTableCell>
                <span v-if="item!.contracted_action_id" class="inline-blick pr-2">
                  {{ $t('contracts.label.subject_action') }}
                  <b>[#{{ item!.action_ref_number }}] {{ item!.action_name }}</b>:
                </span>
                <span v-if="item!.changed_status != ''">
                  {{ $t('contracts.label.subject_change_status') }}
                  <PrettyStatus :status="item!.changed_status" :translate="'contract.status.'"></PrettyStatus>
                </span>
                <span v-else-if="item!.contracted_asset_id">
                  {{ $t('contracts.label.subject_asset') }}
                  {{ item!.asset_name }}
                </span>
                <span v-else>{{ $t('contracts.label.subject_generic') }}</span>
              </ZTableCell>
              <ZTableCell>{{ item!.note }} </ZTableCell>
            </ZTableRow>
          </template>
        </ZTableBody>
      </ZTable>
      <div v-if="changesDataSet.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
        <ExclamationTriangleIcon class="icon lg" />
        {{ $t('contracts.message.changes_not_found') }}
      </div>
    </div>
    <Paginator :data="changesDataSet"></Paginator>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import { Dialogs } from '@/types/Dialogs';
import Navigation from '@/types/Navigation';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import { getContractChanges } from '@/api/contract';
import { ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow } from '@shadcn/components/ui/table';
import { DataSet } from '@/utils/DataSet';
import Paginator from '@ui/Paginator.vue';
import PrettyStatus from '@ui/PrettyStatus.vue';
import { ExclamationTriangleIcon } from '@modules/@heroicons/vue/24/outline';
import ContractChangeData = App.Data.ContractChangeData;

export default defineComponent({
  components: {
    ExclamationTriangleIcon,
    PrettyStatus,
    Paginator,
    ZTableCell,
    ZTableHeader,
    ZTableRow,
    ZTable,
    ZTableHead,
    ZTableBody,
    IconAlert,
  },

  props: {
    contractId: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
      navigation: inject('navigation') as Navigation,
    };
  },

  data() {
    return {
      state: 'loading' as ComponentLoadState,
      changesDataSet: {} as DataSet<ContractChangeData, null>,
    };
  },

  async mounted() {
    try {
      await this.getChanges();
      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
      throw error;
    }
  },

  methods: {
    async getChanges() {
      this.changesDataSet = new DataSet<ContractChangeData, null>((query: string) =>
        getContractChanges(this.contractId).execute(query),
      );
      await this.changesDataSet.load();
    },
  },
});
</script>
