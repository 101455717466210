<template>
  <form @keyup.enter.prevent="callDefaultAction" @submit.prevent="callDefaultAction">
    <slot />
  </form>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import { ValidationErrors } from '@/types/ValidationErrors';

export default defineComponent({
  provide() {
    return {
      validationError: computed(() => this.currentErrors),
    };
  },

  props: {
    errors: {
      type: Object as PropType<null | ValidationErrors>,
      required: false,
      default: null,
    },
  },

  emits: ['defaultAction'],

  data() {
    return {
      currentErrors: null as null | ValidationErrors,
    };
  },

  watch: {
    errors() {
      this.currentErrors = this.errors;
    },
  },

  mounted() {},

  methods: {
    setErrors(errors: null | ValidationErrors) {
      this.currentErrors = errors;
    },

    callDefaultAction() {
      this.$emit('defaultAction');
    },
  },
});
</script>
