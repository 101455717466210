<template>
  <span
    class="rounded-[6px] text-[10px] text-gray-800 py-1 px-2 uppercase font-bold text-center"
    :class="style"
    >{{ item }}</span
  >
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { SYMBOL } from '@/types/symbols';

export default defineComponent({
  components: {},

  props: {
    status: {
      type: String,
      required: false,
      default: null,
    },

    translate: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    item() {
      if (this.translate != undefined) {
        return this.$t(this.translate + this.status);
      }
      return this.status;
    },

    style() {
      switch (this.status) {
        case SYMBOL.ASSET_STATE.DRAFT:
          return 'bg-gray-200';
        case SYMBOL.ASSET_STATE.IN_USE:
          return 'bg-green-400';
        case SYMBOL.ASSET_STATE.IN_OPERATION:
          return 'bg-green-200';
        case SYMBOL.ASSET_STATE.IN_STORAGE:
          return 'bg-cyan-200';
        case SYMBOL.ASSET_STATE.IN_MAINTENANCE:
          return 'bg-orange-400';
        case SYMBOL.ASSET_STATE.WORN_OUT:
          return 'bg-gray-400';
        case SYMBOL.ASSET_STATE.DISPOSED:
          return 'bg-red-300';
        case SYMBOL.ASSET_STATE.SOLD:
          return 'bg-orange-200';
        case SYMBOL.ASSET_STATE.IN_TRANSIT:
          return 'bg-purple-200';
        case SYMBOL.ASSET_STATE.MISSING:
          return 'bg-purple-400';

        case SYMBOL.ASSET_TECHNICAL_CONDITION.GOOD:
          return 'bg-green-200';
        case SYMBOL.ASSET_TECHNICAL_CONDITION.DAMAGED:
          return 'bg-red-300';

        case SYMBOL.INVENTORY_STATUS.NEW:
          return 'bg-gray-400';
        case SYMBOL.INVENTORY_STATUS.IN_PREPARATION:
          return 'bg-orange-400';
        case SYMBOL.INVENTORY_STATUS.IN_PROGRESS:
          return 'bg-purple-200';
        case SYMBOL.INVENTORY_STATUS.COMPLETED:
          return 'bg-green-200';
        case SYMBOL.INVENTORY_STATUS.FINISHED:
          return 'bg-green-400';

        case SYMBOL.INVENTORY_ASSET_STATUS.DRAFT:
          return 'bg-gray-400';
        case SYMBOL.INVENTORY_ASSET_STATUS.PRESENT_GOOD:
          return 'bg-green-200';
        case SYMBOL.INVENTORY_ASSET_STATUS.PRESENT_DAMAGED:
          return 'bg-red-400';
        case SYMBOL.INVENTORY_ASSET_STATUS.MISSING:
          return 'bg-yellow-200';

        case SYMBOL.CONTRACT_STATUS.DRAFT:
          return 'bg-gray-400';
        case SYMBOL.CONTRACT_STATUS.WORK_ORDER:
          return 'bg-orange-400';
        case SYMBOL.CONTRACT_STATUS.NEW_ORDER:
          return 'bg-yellow-200';
        case SYMBOL.CONTRACT_STATUS.ACCEPTED:
          return 'bg-green-200';
        case SYMBOL.CONTRACT_STATUS.CANCELED:
          return 'bg-red-400';
        case SYMBOL.CONTRACT_STATUS.IN_PROGRESS:
          return 'bg-purple-200';
        case SYMBOL.CONTRACT_STATUS.DELAYED_EXECUTION:
          return 'bg-yellow-100';
        case SYMBOL.CONTRACT_STATUS.BLOCKED_EXECUTION:
          return 'bg-red-100';
        case SYMBOL.CONTRACT_STATUS.COMPLETED_EXECUTION:
          return 'bg-green-400';

        case SYMBOL.CONTRACT_ACTION_STATUS.DRAFT:
          return 'bg-gray-400';
        case SYMBOL.CONTRACT_ACTION_STATUS.SEND:
          return 'bg-orange-400';
        case SYMBOL.CONTRACT_ACTION_STATUS.IN_PROGRESS:
          return 'bg-purple-200';
        case SYMBOL.CONTRACT_ACTION_STATUS.CANCELLED:
          return 'bg-yellow-100';
        case SYMBOL.CONTRACT_ACTION_STATUS.FINISHED:
          return 'bg-red-100';

        default:
          return '';
      }
    },
  },
});
</script>
