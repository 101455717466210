<template>
  <Login2FaDialog ref="2FaDialog" />
  <div class="flex max-w-[80rem] w-full min-h-[400px] m-auto bg-white md:rounded-xl overflow-hidden mb-20">
    <div class="hidden md:block md:w-1/2 relative">
      <img
        class="absolute object-cover w-full h-full right-0 bottom-0 top-0 left-0"
        src="@pub/images/login-background.jpg"
      />
    </div>
    <div class="w-full md:w-1/2 pt-10 p-8 md:p-16 flex justify-center items-center">
      <div class="w-full max-w-[30rem]">
        <div class="flex flex-col gap-4">
          <h3 class="mb-5 scroll-m-20 text-2xl font-semibold text-center">{{ $t('auth.login.header') }}</h3>
          <FormValidator :errors="errors">
            <div class="flex flex-col gap-4">
              <div class="w-100">
                <FormField symbol="email">
                  <ZLabel>{{ $t('auth.login.form.email') }}</ZLabel>
                  <ZInput v-model="form.email" type="email" />
                </FormField>
              </div>
              <div class="w-100">
                <FormField symbol="password">
                  <ZLabel>{{ $t('auth.login.form.password') }}</ZLabel>
                  <ZInput v-model="form.password" type="password" />
                </FormField>
              </div>
              <ZButton @click.prevent="login()">{{ $t('auth.login.form.button') }}</ZButton>
            </div>
          </FormValidator>
          <div class="flex justify-between items-center">
            <ResetPasswordDialog />
            <div>
              <a
                href="https://outlet.zerowaste.design/pl/page/regulamin"
                class="text-xs mr-3 text-gray-400"
                target="_blank"
                >{{ $t('auth.login.rules') }}</a
              >
              <a
                href="https://outlet.zerowaste.design/pl/page/polityka-prywatnosci"
                target="_blank"
                class="text-xs text-gray-400"
                >{{ $t('auth.login.policy') }}</a
              >
            </div>
          </div>
          <Separator />
          <div>
            <h3 class="font-semibold">{{ $t('auth.login.noAccountHeader') }}</h3>
            <p class="text-sm">{{ $t('auth.login.noAccountText') }}</p>
          </div>
          <ZButton variant="outline" @click.prevent="register()">{{ $t('auth.login.createAccount') }}</ZButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { postLogin } from '../../api';
import useUserStore from '../../stores/auth';
import loaderStore from '../../stores/loader';
import router from '../../router';
import { ZInput } from '@shadcn/components/ui/input';
import { ZButton } from '@shadcn/components/ui/button';
import { ZLabel } from '@shadcn/components/ui/label';
import { Separator } from '@shadcn/components/ui/separator';
import FormValidator from '../../components/FormValidator.vue';
import FormField from '../../components/FormField.vue';
import ResetPasswordDialog from '../../components/ResetPasswordDialog.vue';
import Login2FaDialog from '../../components/Login2FaDialog.vue';
import { ROUTE } from '@/router/routeNames';
import { HttpStatusCode } from 'axios';
import { ValidationErrors } from '../../types/ValidationErrors';
import { useToast } from '@shadcn/components/ui/toast/use-toast';

export default defineComponent({
  components: {
    ZInput,
    ZButton,
    ZLabel,
    Separator,
    FormValidator,
    FormField,
    ResetPasswordDialog,
    Login2FaDialog,
  },

  data() {
    return {
      errors: null as null | ValidationErrors,
      form: {
        email: '',
        password: '',
        recaptcha_token: '',
      } as App.Data.LoginRequestData,
    };
  },

  methods: {
    register() {
      router.push({ name: ROUTE.REGISTER });
    },

    async login() {
      const { startLoading, stopLoading } = loaderStore();
      startLoading();
      const token = await this.$recaptcha('login');
      postLogin({ ...this.form, ...{ recaptcha_token: token as string } })
        .then(response => {
          if (response.data.two_factor) {
            (this.$refs['2FaDialog'] as typeof Login2FaDialog).show();
          } else {
            const { loginUser } = useUserStore();
            const { toast } = useToast();
            loginUser(response.data.user, response.data.token);
            toast({
              title: this.$t('common.success.header'),
              description: this.$t('auth.login.form.success'),
            });
          }
        })
        .catch(error => {
          if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
            this.errors = error.response.data.errors;
          }
        })
        .finally(() => stopLoading());
    },
  },
});
</script>
