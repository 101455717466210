export const SYMBOL = {
  ACCOUNT_TYPE: {
    OPERATOR: 'operator',
    CUSTOMER: 'customer',
  },

  ACCOUNT_STATE: {
    NOT_ACTIVATED: 'not_activated',
    ACTIVE: 'active',
    BLOCKED: 'blocked',
  },

  PERMISSION: {
    SYS_ADMIN: 'sys.admin',
    SYS_CURATOR: 'sys.curator',
    SYS_DICTIONARY_MANAGER: 'sys.dictionary.manager',
    SYS_CATEGORY_MANAGER: 'sys.category.manager',

    ORG_ADMIN: 'org.admin',

    BRANCH_VIEW: 'branch.view',
    BRANCH_MANAGE: 'branch.manage',
    BRANCH_FINANCE: 'branch.finance',
    BRANCH_REPORT: 'branch.report',
  },

  ASSET_MODEL_STATE: {
    DRAFT: 'draft',
    ACTIVE: 'active',
  },

  ASSET_STATE: {
    DRAFT: 'draft',
    IN_USE: 'in_use',
    IN_OPERATION: 'in_operation',
    IN_STORAGE: 'in_storage',
    IN_MAINTENANCE: 'in_maintenance',
    WORN_OUT: 'worn_out',
    DISPOSED: 'disposed',
    SOLD: 'sold',
    IN_TRANSIT: 'in_transit',
    ACCEPTED: 'accepted',
    BLOCKED_EXECUTION: 'blocked_execution',
    CANCELLED: 'canceled',
    COMPLETED_EXECUTION: 'completed_execution',
    DELAYED_EXECUTION: 'delayed_execution',
    IN_PROGRESS: 'in_progress',
    NEW_ORDER: 'new_order',
    WORK_ORDER: 'work_order',
    MISSING: 'missing',
  },

  ASSET_TECHNICAL_CONDITION: {
    GOOD: 'good',
    DAMAGED: 'damaged',
  },

  ASSET_CARBON_FOOTPRINT_METHOD: {
    SINGLE: 'single',
    LINEAR: 'linear',
  },

  INVENTORY_STATUS: {
    NEW: 'new',
    IN_PREPARATION: 'in_preparation',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    FINISHED: 'finished',
  },

  INVENTORY_ASSET_STATUS: {
    DRAFT: 'draft',
    PRESENT_GOOD: 'present_good',
    PRESENT_DAMAGED: 'present_damaged',
    MISSING: 'missing',
  },

  CONTRACT_STATUS: {
    DRAFT: 'draft',
    WORK_ORDER: 'work_order',
    NEW_ORDER: 'new_order',
    ACCEPTED: 'accepted',
    CANCELED: 'canceled',
    IN_PROGRESS: 'in_progress',
    DELAYED_EXECUTION: 'delayed_execution',
    BLOCKED_EXECUTION: 'blocked_execution',
    COMPLETED_EXECUTION: 'completed_execution',
  },

  CONTRACT_ACTION_STATUS: {
    DRAFT: 'draft',
    SEND: 'send',
    IN_PROGRESS: 'in_progress',
    CANCELLED: 'cancelled',
    FINISHED: 'finished',
  },

  MONTHS: {
    1: 'january',
    2: 'february',
    3: 'march',
    4: 'april',
    5: 'may',
    6: 'june',
    7: 'july',
    8: 'august',
    9: 'september',
    10: 'october',
    11: 'november',
    12: 'december',
  },

  FEATURES: {
    CARBON: 'carbon',
    FINANCE: 'finance',
    INVENTORY: 'inventory',
    DOCUMENTS: 'documents'
  }
};
