<template>
  <DataLoader ref="loader">
    <div>
      <StatWidget :dataset="stats" />
      <div class="text-lg font-medium pt-12 pb-6">{{ $t('dashboard.label.orders') }}</div>
      <div class="grid sm:grid-cols-2 lg:flex sm:gap-4">
        <div class="w-full grid gap-2 mb-4">
          <ZLabel>{{ $t('dashboard.label.order_search') }}</ZLabel>
          <ZInput
            v-model="filters.search"
            type="search"
            :placeholder="$t('dashboard.label.order_search_placeholder')"
          />
        </div>
        <div class="w-full grid gap-2 mb-4">
          <ZLabel>{{ $t('dashboard.label.filter_status') }}</ZLabel>
          <SelectField v-model="filters.status" unselectable :options="contract_states"></SelectField>
        </div>
      </div>
      <div class="border rounded">
        <ZTable class="w-full">
          <ZTableHeader>
            <ZTableRow>
              <ZTableHead>{{ $t('contracts.label.status') }}</ZTableHead>
              <ZTableHead>{{ $t('contracts.label.contract_number') }}</ZTableHead>
              <ZTableHead>{{ $t('contracts.label.priority') }}</ZTableHead>
              <ZTableHead>{{ $t('contracts.label.assets_count') }}</ZTableHead>
              <ZTableHead>{{ $t('contracts.label.actions_count') }}</ZTableHead>
              <ZTableHead>{{ $t('contracts.label.author') }}</ZTableHead>
              <ZTableHead>{{ $t('contracts.label.created_at') }}</ZTableHead>
            </ZTableRow>
          </ZTableHeader>
          <ZTableBody>
            <ZTableRow
              v-for="(item, index) in contractDataSet.data"
              :key="index"
              class="cursor-pointer"
              @click="details(item.id ?? 0)"
            >
              <ZTableCell>
                <PrettyStatus :status="item.status!" :translate="'contract.status.'" />
              </ZTableCell>
              <ZTableCell
                ><span>{{ item.contract_full_number }}</span></ZTableCell
              >
              <ZTableCell
                ><span>{{ item!.priority! }}</span></ZTableCell
              >
              <ZTableCell
                ><span>{{ item.contracted_assets_count }}</span></ZTableCell
              >
              <ZTableCell
                ><span>{{ item.contracted_actions_count }}</span></ZTableCell
              >
              <ZTableCell
                ><span>{{ item.user_name }}</span></ZTableCell
              >
              <ZTableCell
                ><span>{{ formatDate(item.created_at!) }}</span></ZTableCell
              >
            </ZTableRow>
          </ZTableBody>
        </ZTable>
        <div
          v-if="!contractDataSet.data || contractDataSet.data?.length == 0"
          class="p-8 text-center flex flex-col gap-2 items-center"
        >
          <ExclamationTriangleIcon class="icon lg" />
          {{ $t('contracts.message.data_not_found') }}
        </div>
      </div>
      <Paginator :data="contractDataSet" />
    </div>
  </DataLoader>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow } from '@shadcn/components/ui/table';
import PrettyStatus from '@ui/PrettyStatus.vue';
import { ExclamationTriangleIcon } from '@modules/@heroicons/vue/24/outline';
import Paginator from '@ui/Paginator.vue';
import { ZLabel } from '@shadcn/components/ui/label';
import { ZInput } from '@shadcn/components/ui/input';
import SelectField from '@ui/SelectField.vue';
import symbolsStore from '@/stores/symbols';
import { DataSet } from '@/utils/DataSet';
import { getContracts } from '@/api/contract';
import DataLoader from '@ui/DataLoader.vue';
import { KeyValue } from '@/types/Common';
import ContractData = App.Data.ContractData;
import ContractRequestData = App.Data.ContractRequestData;
import { ComponentLoadState } from '@/types/ComponentLoadState';
import { ROUTE } from '@/router/routeNames';
import { getClientDashboardStats } from '@/api';
import StatWidget from '@ui/Dashboard/StatWidget.vue';
import useAccessStore from '@/stores/access';
import CuratedOrganizationsContractsRequestData = App.Data.CuratedOrganizationsContractsRequestData;
import ContractStatus = App.Enums.ContractStatus;
import GeneralStatsData = App.Data.GeneralStatsData;

export default defineComponent({
  components: {
    StatWidget,
    SelectField,
    ZInput,
    ZLabel,
    ZTableCell,
    Paginator,
    ExclamationTriangleIcon,
    PrettyStatus,
    ZTable,
    DataLoader,
    ZTableHead,
    ZTableBody,
    ZTableHeader,
    ZTableRow,
    // icons
    // shadcn components
  },

  inject: {
    navigation: { from: 'navigation' },
  },

  data() {
    return {
      contractDataSet: {} as DataSet<ContractData, CuratedOrganizationsContractsRequestData>,
      state: 'loading' as ComponentLoadState,
      loader: null as any,
      contract_states: {} as KeyValue,
      filters: {
        search: '',
        page: 1,
        status: '' as ContractStatus,
        organization_id: 0,
      },

      stats: {} as GeneralStatsData,
    };
  },

  computed: {
    access: () => useAccessStore(),
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        this.contractDataSet.setParams(this.filters).resetToFirstPage();
      },
    },
  },

  async mounted() {
    try {
      this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;
      await this.getContractsData();
      await this.getStates();
      await this.getStats();
      this.state = 'loaded';
    } catch (error) {
      this.state = 'error';
      throw error;
    }
  },

  methods: {
    async getContractsData() {
      if (this.access.hasBranchSelected()) {
        this.contractDataSet = new DataSet<ContractData, ContractRequestData>((query: string) =>
          getContracts(this.filters).withLoader(this.loader).execute(query),
        );
        await this.contractDataSet.load();
      }
    },

    async getStats() {
      const statsResponse = await getClientDashboardStats().withLoader(this.loader).execute();
      this.stats = statsResponse.data;
    },

    async getStates() {
      const symbols = symbolsStore();
      const states = await symbols.get('contract_states');
      for (const [key, value] of Object.entries(states)) {
        this.contract_states[key] = this.$t(value);
      }
      delete this.contract_states.draft;
    },

    details(id: number) {
      this.$router.push({ name: ROUTE.CONTRACT_DETAILS, params: { contractId: id } });
    },
  },
});
</script>
