<template>
  <router-view></router-view>
  <div>
    <template v-if="state == 'loading'">
      <IconAlert type="loading">
        {{ $t('common.action.loading') }}
      </IconAlert>
    </template>
    <template v-else-if="state === 'loaded' && assetModel">
      <MountedTeleport to="#toolbox">
        <div>
          <DropdownMenu>
            <DropdownMenuTrigger as-child>
              <ZButton class="w-full">
                <CogIcon class="icon sm"/>
                <span class="ml-2">
                  {{ $t('assetModels.command.manage') }}
                </span>
                <ChevronDownIcon class="icon xs ml-2"/>
              </ZButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent class="w-56">
              <DropdownMenuGroup>
                <DropdownMenuItem @click="editAssetModelData">
                  <span>{{ $t('assetModels.command.edit_model_data') }}</span>
                </DropdownMenuItem>
                <DropdownMenuSeparator/>
                <DropdownMenuItem @click="editAssetModelAttributes">
                  <span>{{ $t('assetModels.command.edit_model_attributes') }}</span>
                </DropdownMenuItem>
                <DropdownMenuSeparator/>
                <DropdownMenuItem @click="editAssetModelImages">
                  <span>{{ $t('assetModels.command.edit_model_images') }}</span>
                </DropdownMenuItem>
                <DropdownMenuSeparator/>
                <div v-if="assetModel.organization_id">
                  <DropdownMenuItem @click="setAsGlobal">
                    <span>{{ $t('assetModels.command.setAsGlobal') }}</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator/>
                </div>
                <DropdownMenuItem :disabled="!assetModel.can_be_deleted" @click="requestAssetModelDelete">
                  <span>{{ $t('assetModels.command.delete_model') }}</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </MountedTeleport>

      <IconAlert v-if="assetModel.state == SYMBOL.ASSET_MODEL_STATE.DRAFT" class="mt-6" type="warning">
        <div class="flex flex-row justify-between items-center w-full">
          <span>{{ $t('assetModels.label.asset_model_is_draft') }}</span>
        </div>
      </IconAlert>

      <div class="lg:grid grid-cols-6 gap-4 mt-10 mb-10">
        <div class="col-span-4">
          <div class="flex flex-col lg:flex-row gap-4 rounded md:mb-4">
            <div
              class="row-span-2 lg:mr-4 lg:p-2 relative lg:border rounded-md w-full h-[160px] md:h-[220px] lg:max-w-[130px] lg:max-h-[130px] cursor-pointer"
            >
              <img
                class="w-full h-full object-cover rounded-md"
                :src="assetModel.thumb"
                alt="primaryImage"
                @click="previewImages"
              />
              <div
                class="text-xs absolute top-0 left-0 -m-3 bg-muted w-[30px] h-[30px] flex justify-center rounded-full"
                @click="editAssetModelImages"
              >
                <PlusCircleIcon class="icon sm"/>
              </div>
            </div>
            <div class="grid grid-cols-2 lg:grid-cols-3 gap-4 flex-1">
              <div class="i-block">
                <div class="i-label">{{ $t('assetModels.label.code') }}</div>
                <span class="i-value">{{ assetModel.code }}</span>
              </div>
              <div class="i-block">
                <div class="i-label">{{ $t('assetModels.label.category') }}</div>
                <span class="i-value">{{ assetModel.category ? assetModel.category.name : 'Brak' }}</span>
              </div>
              <div class="i-block">
                <div class="i-label">{{ $t('assetModels.label.manufacturer') }}</div>
                <span class="i-value">{{ assetModel.manufacturer ? assetModel.manufacturer : '-' }}</span>
              </div>
              <div class="i-block">
                <div class="i-label">{{ $t('assetModels.label.organization') }}</div>
                <span class="i-value">
                  <span v-if="assetModel.organization">
                    {{ assetModel.organization.name }}
                  </span>
                  <span v-else>
                    {{ $t('assetModels.label.lack') }}
                    <span class="ml-1 rounded text-xs bg-gray-200 py-1 px-1.5">{{
                        $t('assetModels.label.global_model')
                      }}</span>
                  </span>
                </span>
              </div>
              <div class="i-block">
                <div class="i-label">{{ $t('assetModels.label.photos_title') }}</div>
                <span class="i-value">
                  <div class="cursor-pointer">
                    {{ assetModel.images.length }}
                    <ZButton
                      v-if="assetModel.images.length"
                      variant="ghost"
                      class="h-7"
                      @click.prevent="previewImages"
                    >{{ $t('assetModels.label.show_photos') }}</ZButton
                    >
                    <ZButton
                      v-if="!assetModel.images.length"
                      variant="ghost"
                      class="h-7"
                      @click.prevent="editAssetModelImages"
                    >{{ $t('assetModels.label.add_photos') }}</ZButton
                    >
                  </div>
                </span>
              </div>
            </div>
          </div>

          <div class="col-span-12 lg:col-span-7 xl:col-span-6 grid items-start gap-6 pt-6 md:pt-0">
            <div class="gap-6 rounded-lg grid xl:grid-cols-1">
              <div class="col-span-12 xl:col-span-4 grid items-start gap-6 lg:col-span-1">
                <div class="rounded-xl border bg-card text-card-foreground">
                  <div class="m-5 flex items-center justify-between">
                    <h3 class="font-semibold leading-none tracking-tight">
                      {{ $t('assetModels.model_category_attributes_header') }}
                    </h3>
                    <div v-if="Object.keys(categoryAttributes).length" class="gap-4 flex">
                      <ZButton v-if="!editMode" class="h-7" variant="ghost" @click="setEditMode(true)"
                      >{{ $t('assetModels.command.edit_attributes') }}
                      </ZButton>
                      <ZButton
                        v-if="editMode"
                        class="h-7"
                        :disabled="!canSaveCategoryAttributes"
                        @click="saveCategoryAttributes"
                      >{{ $t('assetModels.command.save_attributes') }}
                      </ZButton>
                      <ZButton v-if="editMode" class="h-7" variant="ghost" @click="setEditMode(false, true)"
                      >{{ $t('assetModels.command.cancel_edit_attributes') }}
                      </ZButton>
                    </div>
                  </div>

                  <div v-if="categoryAttributes.length" class="grid grid-cols-1 divide-y border-t">
                    <div v-for="categoryAttribute in categoryAttributes" :key="'cat_' + categoryAttribute.id">
                      <div class="flex justify-between px-5 py-3">
                        <span class="text-sm text-gray-400 self-center"
                        >{{
                            categoryAttribute.name
                          }}<span v-if="categoryAttribute.unit">({{ categoryAttribute.unit ?? '' }})</span>:
                        </span>
                        <span class="font-normal text-base text-black flex">
                          <AttributeValue
                            v-model="categoryAttributeValues[categoryAttribute.id]"
                            :justify-content="'right'"
                            :definition="categoryAttribute"
                            :on-save="onSave"
                            :edit-mode="editMode"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div v-else class="py-10">
                    <InformationCircleIcon class="icon w-[30px] mb-5 m-auto"/>
                    <h3 class="scroll-m-20 font-semibold text-center">
                      {{ $t('asset.label.attributes_not_found') }}
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-span-12 xl:col-span-4 grid items-start gap-6 lg:col-span-1">
                <div class="rounded-xl border bg-card text-card-foreground">
                  <div class="m-5 flex items-center justify-between">
                    <h3 class="font-semibold leading-none tracking-tight">{{
                        $t('assetModels.model_attributes_header')
                      }}</h3>
                    <div class="gap-4 flex">
                      <ZButton class="h-7" variant="ghost" @click="editAssetModelAttributes"
                      >{{ $t('assetModels.command.manage_attributes') }}
                      </ZButton>
                    </div>
                  </div>
                  <div v-if="assetModel.assetModelValues!.filter(item => item.category_attribute_id === null).length">
                    <div v-for="attribute in assetModel.assetModelValues" :key="attribute.id">
                      <div
                        v-if="attribute?.category_attribute_id === null"
                        class="flex justify-between px-5 py-3 border-t"
                      >
                        <span class="text-sm text-gray-400 self-center">{{ attribute.name }}:</span>
                        <span v-if="attribute.val_int != null" class="font-normal text-base text-black">
                                <span v-if="attribute.val_int * 1 == 1" class="rounded text-white bg-green-600 px-2">{{
                                    $t('common.yes')
                                  }}</span>
                                <span v-else class="rounded bg-gray-300 px-2">{{ $t('common.no') }}</span>
                              </span>
                        <span v-else class="ml-2">{{ displayAttributeValue(attribute) }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-else class="py-10">
                    <InformationCircleIcon class="icon w-[30px] mb-5 m-auto"/>
                    <h3 class="scroll-m-20 font-semibold text-center">{{ $t('assetModels.notFound') }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="border rounded-xl overflow-hidden mt-6">
            <div class="flex p-3 flex-row justify-between items-center border-b bg-muted/30">
              <b>{{ $t('assetModels.duplicates_header') }}</b>
            </div>
            <div v-if="[...duplicatedModels].length">
              <p class="bg-muted/30 border-b p-3 text-sm">{{ $t('assetModels.duplicatesText') }}</p>

              <ZTable class="w-full">
                <ZTableHeader>
                  <ZTableRow>
                    <ZTableHead>{{ $t('assetModels.form.duplicated.name') }}</ZTableHead>
                    <ZTableHead>{{ $t('assetModels.form.duplicated.category') }}</ZTableHead>
                    <ZTableHead>{{ $t('assetModels.form.duplicated.organization') }}</ZTableHead>
                    <ZTableHead>{{ $t('assetModels.form.duplicated.date') }}</ZTableHead>
                    <ZTableHead></ZTableHead>
                  </ZTableRow>
                </ZTableHeader>
                <ZTableBody>
                  <ZTableRow v-for="(model, i) in duplicatedModels" :key="i">
                    <ZTableCell>
                      <div class="cursor-pointer" @click="showModelDetails(model.id!)">{{ model.name }}</div>
                    </ZTableCell>
                    <ZTableCell>{{ model.category.name }}</ZTableCell>
                    <ZTableCell>{{ model.organization.name }}</ZTableCell>
                    <ZTableCell>{{ formatDateTime(model.created_at!) }}</ZTableCell>
                    <ZTableCell>
                      <ZButton
                        v-if="!assetModel.organization_id"
                        class="h-7"
                        variant="secondary"
                        @click="requestMergeDuplicates(model.id!.toString())"
                      >{{ $t('assetModels.command.merge_model') }}
                      </ZButton>
                    </ZTableCell>
                  </ZTableRow>
                </ZTableBody>
              </ZTable>
            </div>
            <div v-else class="py-10">
              <InformationCircleIcon class="icon w-[30px] mb-5 m-auto"/>
              <h3 class="scroll-m-20 font-semibold text-center">{{ $t('assetModels.notFoundDuplicates') }}</h3>
            </div>
          </div>
        </div>
        <div class="col-span-2">
          <div class="border rounded-xl overflow-hidden mt-6">
            <div class="flex p-3 flex-row justify-between items-center border-b bg-muted/30">
              <b>{{ $t('assetModels.stats_header') }}</b>
            </div>
            <div class="i-block horizontal border-b p-3 text-sm">
              <span class="i-label">{{ $t('assetModels.stats.resources') }}:</span>
              <span class="i-value">{{ stats!.resources }}</span>
            </div>
            <div class="i-block horizontal border-b p-3 text-sm">
              <span class="i-label">{{ $t('assetModels.stats.used') }}:</span>
              <span class="i-value">{{ stats!.used }}</span>
            </div>
            <div class="i-block horizontal border-b p-3 text-sm">
              <span class="i-label">{{ $t('assetModels.stats.created_at') }}:</span>
              <span class="i-value">{{ formatDateTime(stats!.created_at) }}</span>
            </div>
            <div class="i-block horizontal p-3 text-sm">
              <span class="i-label">{{ $t('assetModels.stats.updated_at') }}:</span>
              <span class="i-value">{{ formatDateTime(stats!.updated_at) }} </span>
            </div>
          </div>

          <CarbonDefaults type="assetModel" :identifier="assetModel.id!"></CarbonDefaults>
        </div>
      </div>
    </template>
    <template v-else-if="state == 'error'">
      <IconAlert type="error">
        {{ $t('common.errors.unexpected') }}
      </IconAlert>
    </template>
  </div>
  <ImagePreview
    v-if="isImageDialogOpen"
    :image-urls="imageURls"
    @image-preview-closed="isImageDialogOpen = false"
  ></ImagePreview>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import {ComponentLoadState} from '@/types/ComponentLoadState';
import AssetModelData = App.Data.AssetModelData;
import IconAlert from '@ui/IconAlert.vue';
import {
  deleteAssetModel,
  getAssetModel,
  getDuplicatedModels,
  getModelStats,
  mergeDuplicates,
  saveAssetModelAttributes,
  setAssetModelAsGlobal,
} from '@/api/assetModel';
import {ChevronDownIcon, InformationCircleIcon, CogIcon, PlusCircleIcon} from '@heroicons/vue/24/outline';
import {ZButton} from '@shadcn/components/ui/button';
import {ROUTE} from '@/router/routeNames';
import {Dialogs} from '@/types/Dialogs';
import Navigation from '@/types/Navigation';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@shadcn/components/ui/dropdown-menu';
import ImagePreview from '@ui/ImagePreview/ImagePreview.vue';
import AssetModelValueData = App.Data.AssetModelValueData;
import {getAllCategoryAttributes} from '@/api/category';
import AttributeValue from '@ui/AttributeValue.vue';
import {ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow} from '@shadcn/components/ui/table';
import AssetModelStatsData = App.Data.AssetModelStatsData;
import CarbonDefaults from '@ui/Dictionaries/CarbonDefaults.vue';
import MountedTeleport from '@ui/MountedTeleport.vue';

interface ItemType {
  [key: number]: any;
}

export default defineComponent({
  components: {
    MountedTeleport,
    CarbonDefaults,
    ZTable,
    ZTableRow,
    ZTableHeader,
    ZTableCell,
    ZTableBody,
    ZTableHead,
    CogIcon,
    PlusCircleIcon,
    InformationCircleIcon,
    AttributeValue,
    ImagePreview,
    ChevronDownIcon,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    ZButton,
    IconAlert,
  },

  props: {
    modelId: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
      navigation: inject('navigation') as Navigation,
    };
  },

  data() {
    return {
      assetModel: {} as AssetModelData,
      state: 'loading' as ComponentLoadState,
      isImageDialogOpen: false,
      imageURls: [] as string[],
      categoryAttributes: {} as any,
      categoryAttributeValues: {} as ItemType,
      categoryAttributeOldValues: {} as ItemType,
      editMode: false as boolean,
      onSave: false as boolean,
      duplicatedModels: [] as AssetModelData[],
      stats: null as AssetModelStatsData | null,
    };
  },

  computed: {
    canSaveCategoryAttributes() {
      for (const index in this.categoryAttributes) {
        const attribute = this.categoryAttributes[index];
        if (attribute.validation_rule == 'required') {
          if (
            this.categoryAttributeValues[attribute.id] == undefined ||
            this.categoryAttributeValues[attribute.id] === ''
          )
            return false;
        }
      }
      return true;
    },
  },

  watch: {
    async $route(to: any) {
      if (to.name == ROUTE.ASSET_MODELS_DETAILS) {
        this.state = 'loading';
        try {
          await this.getAssetModel();
          const categoryAttributesResponse = await getAllCategoryAttributes(
            this.assetModel.category_id!.toString(),
          ).execute();
          this.categoryAttributes = categoryAttributesResponse.data;
          this.state = 'loaded';
        } catch (error: any) {
          this.state = 'error';
          throw error;
        }
      }
    },
  },

  async mounted() {
    try {
      await this.getAssetModel();
      await this.getAllDuplicatedModels();
      const categoryAttributesResponse = await getAllCategoryAttributes(
        this.assetModel.category_id!.toString(),
      ).execute();
      this.categoryAttributes = categoryAttributesResponse.data;

      const statsResponse = await getModelStats(this.assetModel.id!.toString()).execute();
      this.stats = statsResponse.data;

      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
      throw error;
    }
  },

  methods: {
    async getAllDuplicatedModels() {
      if (!this.assetModel) return;

      const duplicatedModelsReponse = await getDuplicatedModels(this.assetModel.id!.toString()).execute();
      this.duplicatedModels = duplicatedModelsReponse.data;
    },

    async getAssetModel() {
      this.assetModel = (await getAssetModel(this.modelId).execute()).data;
      (this.navigation as Navigation).setPanelTitle(this.assetModel.name != '' ? this.assetModel.name : '#' + this.assetModel.code);

      if (this.assetModel) {
        this.categoryAttributeValues = {};

        for (let value of this.assetModel.assetModelValues!) {
          if (value.category_attribute_id === null) continue;
          this.categoryAttributeValues[value.category_attribute_id] = this.displayAttributeValue(value);
        }
      }

      this.imageURls = [];
      this.assetModel.images.forEach((image: any) => {
        this.imageURls.push(image.sourceUrl);
      });
    },

    requestAssetModelDelete() {
      this.dialogs
        .confirm(this.$t('assetModels.label.deleting_model'), this.$t('assetModels.message.deleting_model_question'))
        .then(() => {
          if (!this.assetModel) {
            return;
          }

          deleteAssetModel(this.assetModel.id!)
            .execute()
            .then(() => {
              this.toast({
                title: this.$t('toasts.type.success'),
                description: this.$t('assetModels.message.model_deleted'),
              });
            });
          this.$router.replace({name: ROUTE.ASSET_MODELS});
        });
    },

    editAssetModelData() {
      if (!this.assetModel) {
        return;
      }

      this.$router.push({name: ROUTE.ASSET_MODELS_DETAILS_EDIT, params: {modelId: this.assetModel.id}});
    },

    editAssetModelAttributes() {
      if (!this.assetModel) {
        return;
      }

      this.$router.push({name: ROUTE.ASSET_MODELS_DETAILS_EDIT_ATTRIBUTES, params: {modelId: this.assetModel.id}});
    },

    editAssetModelImages() {
      if (!this.assetModel) {
        return;
      }

      this.$router.push({name: ROUTE.ASSET_MODELS_DETAILS_EDIT_IMAGES, params: {modelId: this.assetModel.id}});
    },

    previewImages() {
      if (this.imageURls.length == 0) return;
      this.isImageDialogOpen = !this.isImageDialogOpen;
    },

    async setAsGlobal() {
      if (!this.assetModel) return;

      await setAssetModelAsGlobal(this.assetModel.id!.toString()).execute();
      this.assetModel.organization_id = null;
    },

    requestMergeDuplicates(duplicated_id: string) {
      if (!this.assetModel) return;
      this.dialogs
        .confirm(
          this.$t('assetModels.label.merging_asset_models'),
          this.$t('assetModels.message.merge_asset_models_question'),
        )
        .then(() => {
          mergeDuplicates(this.assetModel.id!.toString(), duplicated_id).execute();
          this.getAllDuplicatedModels();
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('assetModels.message.merged_models'),
          });
          this.getAssetModel();
          this.getAllDuplicatedModels();
        });
    },

    displayAttributeValue(attribute: AssetModelValueData) {
      if (attribute.val_float) return attribute.val_float.toString();
      if (attribute.val_string) return attribute.val_string;
      if (attribute.val_date) return attribute.val_date;
      if (attribute.val_int) return attribute.val_int.toString();
      return '';
    },

    showModelDetails(id: number) {
      this.$router.push({name: ROUTE.ASSET_MODELS_DETAILS, params: {modelId: id}});
    },

    saveCategoryAttributes(action: any) {
      let fields = [] as AssetModelValueData[];

      for (const index in this.categoryAttributes) {
        const attribute = this.categoryAttributes[index];
        let val_string = null as string | null;
        let val_int = null as number | null;
        let val_float = null as number | null;
        let val_date = null as string | null;

        const value = this.categoryAttributeValues[attribute.id];

        console.log(this.categoryAttributeValues, attribute, 'value:', value);

        switch (attribute.type) {
          case 'TEXT':
          case 'SELECT':
          case 'MULTISELECT':
            val_string = value;
            break;

          case 'NUMBER':
            val_float = parseFloat(value);
            break;
          case 'CHECKBOX':
            val_int = parseInt(value);
            break;
          case 'DATE':
            val_date = value ?? null;
            break;
        }

        fields.push({
          category_attribute_id: attribute.id,
          name: attribute.name,
          val_string: val_string,
          val_int: val_int,
          val_float: val_float,
          val_date: val_date,
        });
      }

      saveAssetModelAttributes(this.modelId, fields)
        .withLoader(action.loader)
        .execute('?mode=category')
        .then(() => {
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('assetModels.message.attributes_saved'),
          });
          this.editMode = false;
          this.getAssetModel();
        });
    },

    setEditMode(mode: boolean, cancelChanges: boolean = false) {
      this.editMode = mode;
      if (mode) {
        this.categoryAttributeOldValues = {...this.categoryAttributeValues};
      } else if (cancelChanges) {
        this.categoryAttributeValues = {...this.categoryAttributeOldValues};
      }
    },
  },
});
</script>
