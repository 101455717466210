<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent
      class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto gap-0"
    >
      <ZDialogHeader class="gap-2 mb-4">
        <ZDialogTitle v-if="access.operator()">{{
          $t('organization.label.select_organization_and_branch')
        }}</ZDialogTitle>
        <ZDialogTitle v-else>{{ $t('organization.label.select_branch_only') }}</ZDialogTitle>
        <ZDialogDescription v-if="access.operator()">
          {{ $t('organization.label.select_organization_and_branch_description') }}
        </ZDialogDescription>
        <ZDialogDescription v-else>
          {{ $t('organization.label.select_branch_description_only') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <SelectField
        v-if="access.operator() || (access.currentUser() && access.currentUser()!.organizations && access.currentUser()!.organizations!.length > 1)"
        id="organization"
        v-model="selectedOrganizationId"
        :options="organizations"
        searchable
        sorted
        @update:model-value="updatedOrganizationId"
      />
      <ZInput v-model="searchQuery" :placeholder="$t('organization.label.branch_search')" class="my-4" />
      <div v-if="!branchDataSet || !branchDataSet.data || !branchDataSet.data.length" class="pt-6">
        {{ $t('organization.label.no_branches_found') }}
      </div>
      <div class="max-h-[50vh] overflow-auto">
        <div
          v-for="(branch, index) in branchDataSet.data"
          :key="`branch-${index}`"
          :class="{ 'font-medium text-gray-800 bg-gray-100': branch.id === selectedBranchId }"
          class="cursor-pointer min-h-16 py-3 border-b flex justify-between items-center hover:font-medium px-2"
          @click="selectedBranchId = branch.id"
        >
          <div>
            <p>
              {{ branch.name }}
            </p>
            <template v-if="branch.branch_type == 'location'">
              <p class="text-xs">
                {{ branch.postal }} {{ branch.city }}, {{ branch.street }} {{ branch.building_number }}
                {{ branch.apartment_number ?? '' }}
              </p>
            </template>
            <template v-if="branch.parents!.length">
              <div class="text-xs">
              <span v-for="(parent, branchIndex) in branch.parents" :key="`parent-${branchIndex}`">
                {{ branchIndex > 0 ? ' /' : '' }} {{ parent.name }}
              </span>
              </div>
            </template>
          </div>
          <ArrowRightIcon class="icon sm" />
        </div>
      </div>
      <Paginator :data="branchDataSet" />
      <ZDialogFooter v-if="access.operator()" class="mt-5">
        <ZButton class="w-full" :disabled="!selectedOrganizationId" @click="save()">{{
          $t('organization.command.select_organization_and_branch')
        }}</ZButton>
      </ZDialogFooter>
      <ZDialogFooter v-else class="mt-5">
        <ZButton class="w-full" :disabled="!selectedBranchId || !selectedOrganizationId" @click="save()">{{
          $t('organization.command.select_branch')
        }}</ZButton>
      </ZDialogFooter>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import { getOrganizationBranches, getOrganizationsCompact } from '@/api/organization';
import { DataSet } from '@/utils/DataSet';
import { mapState } from 'pinia';
import useUserStore from '@/stores/auth';
import { ZButton } from '@shadcn/components/ui/button';
import { ZInput } from '@shadcn/components/ui/input';
import SelectField from '@ui/SelectField.vue';
import type { KeyValue } from '@/types/Common';
import { ArrowRightIcon } from '@heroicons/vue/24/outline';
import Paginator from '@ui/Paginator.vue';
import OrganizationBranchData = App.Data.OrganizationBranchData;
import SimpleSearchData = App.Data.SimpleSearchData;
import OrganizationCompactData = App.Data.OrganizationCompactData;
import useAccessStore from '@/stores/access';

export default defineComponent({
  components: {
    Paginator,
    ArrowRightIcon,
    ZInput,
    ZButton,
    ZDialogFooter,
    SelectField,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogHeader,
    ZDialogTitle,
  },

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: false,
      selectedBranchId: undefined as number | undefined,
      selectedOrganizationId: undefined as number | undefined,
      branchDataSet: {} as DataSet<OrganizationBranchData, SimpleSearchData>,
      searchQuery: '' as string,
      organizations: {} as KeyValue,
      promiseResolve: null as any,
      promiseReject: null as any,
    };
  },

  computed: {
    ...mapState(useUserStore, ['user']),
    access: () => useAccessStore(),
  },

  watch: {
    searchQuery() {
      this.branchDataSet.loadDebounced();
    },

    isDialogOpen() {
      if (!this.isDialogOpen) {
        this.selectedBranchId = undefined;
        this.selectedOrganizationId = undefined;
        this.searchQuery = '';
      }
    },
  },

  mounted() {},

  methods: {
    open(organization: number | undefined, branch: number | undefined) {
      this.selectedOrganizationId = organization;
      this.selectedBranchId = branch;

      this.getOrganizations();
      this.getBranches();

      const promise = new Promise<number[]>((resolve, reject) => {
        this.promiseResolve = resolve;
        this.promiseReject = reject;
      });

      this.isDialogOpen = true;
      return promise;
    },

    getBranches() {
      if (!this.selectedOrganizationId) return;

      this.branchDataSet = new DataSet<OrganizationBranchData, SimpleSearchData>((query: string) =>
        getOrganizationBranches(this.selectedOrganizationId ?? 0, this.searchQuery).execute(query),
      );
      this.branchDataSet.load();
    },

    async getOrganizations() {
      const organizationsResponse = await getOrganizationsCompact().execute();

      this.organizations = organizationsResponse.data.reduce(
        (accumulator: KeyValue, value: OrganizationCompactData) => {
          return { ...accumulator, [value.id]: value.name };
        },
        {},
      );
    },

    updatedOrganizationId() {
      this.selectedBranchId = undefined;
      this.getBranches();
    },

    async save() {
      this.promiseResolve([this.selectedOrganizationId, this.selectedBranchId]);
      this.isDialogOpen = false;
    },
  },
});
</script>
