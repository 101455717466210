import {ref} from "@modules/vue";

export const readableCarbonValue = function (value: any) {
  const locale = ref(navigator.language || 'en-US').value;
  let formatter =  Intl.NumberFormat(locale, {minimumFractionDigits: 2, maximumFractionDigits: 2});

  if (value < 1000) return formatter.format(value) + ' g';
  return formatter.format(value * 0.001) + ' kg';
};

export const formatCurrency = function (value: any) {
  const locale = ref(navigator.language || 'en-US').value;
  let formatter =  Intl.NumberFormat(locale, {minimumFractionDigits: 2, maximumFractionDigits: 2});
  return formatter.format(value);
};
