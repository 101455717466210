import { http } from './http';

export const getCurrentUser = () => {
  return http.get<App.Data.UserData>('/currentUser');
};
export const getCurrentUserAccess = () => {
  return http.get<App.Data.AccountAccess>('/user/permissions');
};

export const postLogin = (data: App.Data.LoginRequestData) => {
  return http.post('/login', data);
};
export const postRegister = (data: App.Data.RegisterRequestData) => {
  return http.post('/register', data);
};
export const postRegisterEmployee = (data: { user: App.Data.RegisterEmployeeRequestData }) => {
  return http.post('/register', data);
};
export const getOrganizationByToken = (token: string) => {
  return http.get<App.Data.RegisterOrganizationData>('/checkOrganizationToken', { params: { token } });
};
export const postForgotPassword = (data: App.Data.PasswordResetLinkRequestData) => {
  return http.post('/forgotPassword', data);
};
export const postResetPassword = (data: App.Data.ResetPasswordRequestData) => {
  return http.post('/resetPassword', data);
};
export const postChallenge2FA = (data: { code?: string; recovery_code?: string }) => {
  return http.post('/twoFactorChallenge', data);
};
