<template>
  <Field symbol="priority" class="pt-4">
    <ZLabel for="priority">
      {{ $t('contracts.form.priority') }}
    </ZLabel>
    <SelectField id="organization" v-model="contract.priority" :options="priorities" />
  </Field>
  <Field symbol="description" class="pt-4">
    <ZLabel for="description">
      {{ $t('contracts.label.description') }}
    </ZLabel>
    <textarea
      id="description"
      v-model="contract.description"
      rows="4"
      class="flex w-full resize-none rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
    />
  </Field>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';
import SelectField from '@ui/SelectField.vue';

export default defineComponent({
  components: {
    SelectField,
    ZLabel,
    Field,
  },

  props: {
    contract: {
      type: Object,
      required: true,
    },

    priorities: {
      type: Object,
      required: true,
    },
  },

  mounted() {},
});
</script>
