<template>
  <DataLoader ref="loader">
  <teleport v-if="componentMounted" to="#toolbox">
    <router-link :to="{ name: ROUTE.DICTIONARY.ACTION_ADD }">
      <ZButton class="gap-2 w-full md:flex">
        {{ $t('dictionary.command.action_add') }}
        <PlusCircleIcon class="icon md" />
      </ZButton>
    </router-link>
  </teleport>
  <div class="py-4">
    <Paginator :data="actionsDataSet" />
    <div class="border rounded">
      <ZTable class="w-full">
        <ZTableHeader>
          <ZTableRow>
            <ZTableHead>{{ $t('dictionary.label.action') }}</ZTableHead>
            <ZTableHead class="text-center">{{ $t('dictionary.label.requires_assets') }}</ZTableHead>
            <ZTableHead class="text-center">{{ $t('dictionary.label.used') }}</ZTableHead>
            <ZTableHead class="text-center">{{ $t('dictionary.label.defaults') }}</ZTableHead>
            <ZTableHead class="text-center">{{ $t('dictionary.label.date') }}</ZTableHead>
            <ZTableHead></ZTableHead>
          </ZTableRow>
        </ZTableHeader>
        <ZTableBody>
          <ZTableRow v-for="(item, index) in actionsDataSet.data" :key="index">
            <ZTableCell>
              {{ item.name }}
            </ZTableCell>
            <ZTableCell class="w-[80px] text-center">
              <div class="flex items-center justify-center">
                <CheckCircleIcon v-if="item.is_asset_required" class="icon sm text-primary" />
                <NoSymbolIcon v-else class="icon sm opacity-30" />
              </div>
            </ZTableCell>
            <ZTableCell class="w-[80px] text-center">{{ item.usage }}</ZTableCell>
            <ZTableCell class="w-[80px] text-center">{{ item.defaults }}</ZTableCell>
            <ZTableCell class="w-[120px] text-center"
              ><span class="text-xs text-gray-400">{{ formatDate(item.created_at!) }}</span></ZTableCell
            >
            <ZTableCell class="w-[50px] text-right">
              <DropdownMenu>
                <DropdownMenuTrigger as-child>
                  <ZButton variant="ghost">
                    <EllipsisHorizontalIcon class="icon sm" />
                  </ZButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuGroup>
                    <DropdownMenuItem @click="edit(item)">
                      <span>{{ $t('dictionary.command.edit') }}</span>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem :disabled="!item.can_be_deleted" @click="remove(item)">
                      <span>{{ $t('dictionary.command.delete') }}</span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </ZTableCell>
          </ZTableRow>
        </ZTableBody>
      </ZTable>
      <div v-if="actionsDataSet.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
        <ExclamationTriangleIcon class="icon lg" /> {{ $t('organization.message.data_not_found') }}
      </div>
    </div>
  </div>
  <Paginator :data="actionsDataSet" />
  <router-view></router-view>
  </DataLoader>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import { DataSet } from '@/utils/DataSet';
import Paginator from '@ui/Paginator.vue';
import { ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow } from '@shadcn/components/ui/table';
import { ExclamationTriangleIcon, PlusCircleIcon, CheckCircleIcon, NoSymbolIcon } from '@heroicons/vue/24/outline';
import { ZButton } from '@shadcn/components/ui/button';
import { ROUTE } from '@/router/routeNames';
import { deleteAction, getActions } from '@/api';
import { Dialogs } from '@/types/Dialogs';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@shadcn/components/ui/dropdown-menu';
import { EllipsisHorizontalIcon } from '@heroicons/vue/24/solid';
import ActionData = App.Data.ActionData;
import DataLoader from "@ui/DataLoader.vue";

export default defineComponent({
  components: {
    DataLoader,
    EllipsisHorizontalIcon,
    CheckCircleIcon,
    PlusCircleIcon,
    ExclamationTriangleIcon,
    NoSymbolIcon,
    Paginator,
    ZButton,
    ZTable,
    ZTableRow,
    ZTableBody,
    ZTableCell,
    ZTableHead,
    ZTableHeader,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  },

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      componentMounted: false,
      status: 'all',
      actionsDataSet: {} as DataSet<ActionData, null>,
      loader: null as any,
    };
  },

  watch: {
    search() {
      this.load();
    },

    status() {
      this.load();
    },

    $route(to: any) {
      if (to.name == ROUTE.DICTIONARY.ACTIONS) {
        this.actionsDataSet.resetToFirstPage();
      }
    },
  },

  mounted() {
    this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;
    this.componentMounted = true;
    this.actionsDataSet = new DataSet<ActionData, null>((query: string) => getActions().withLoader(this.loader).execute(query));
    this.actionsDataSet.load();
  },

  methods: {
    load() {
      this.actionsDataSet.resetToFirstPage();
    },

    edit(action: ActionData) {
      this.$router.push({ name: ROUTE.DICTIONARY.ACTION_EDIT, params: { actionId: action.id } });
    },

    async remove(action: ActionData) {
      if (action.usage) {
        this.toast({
          variant: 'destructive',
          title: this.$t('dictionary.label.action_cant_be_deleted'),
          description: this.$t('dictionary.message.action_cant_be_deleted'),
        });
        return;
      }

      this.dialogs
        .confirm(this.$t('dictionary.label.action_delete'), this.$t('dictionary.message.action_delete'))
        .then(() => {
          this.dialogs.authorizeAction().then(() => {
            deleteAction(action!.id!.toString())
              .execute()
              .then(() => {
                this.toast({
                  title: this.$t('toasts.type.success'),
                  description: this.$t('action.message.deleted'),
                });
                this.actionsDataSet.load();
              });
          });
        });
    },
  },
});
</script>
