<template>
  <DataLoader ref="loader">
    <div class="py-4">
      <div class="flex py-2 gap-4">
        <ZInput v-model="search" :placeholder="$t('common.label.search')"/>
      </div>
      <Paginator :data="organizationsDataSet" page-size/>
      <div class="border rounded">
        <ZTable class="w-full">
          <ZTableHeader>
            <ZTableRow>
              <ZTableHead>#</ZTableHead>
              <ZTableHead>{{ $t('organization.label.organization') }}</ZTableHead>
              <ZTableHead>{{ $t('organization.label.phone') }}</ZTableHead>
              <ZTableHead>{{ $t('organization.label.tax_number') }}</ZTableHead>
            </ZTableRow>
          </ZTableHeader>
          <ZTableBody>
            <ZTableRow
              v-for="(item, index) in organizationsDataSet.data"
              :key="index"
              class="cursor-pointer"
              @click="details(item.id ?? 0)"
            >
              <ZTableCell class="w-6">#{{ item.id }}</ZTableCell>
              <ZTableCell class="flex flex-row gap-2 items-center">
                <div>
                  <BuildingOfficeIcon class="icon md"/>
                </div>
                <div class="flex flex-col">
                  <b>{{ item.name }}</b>
                  <span>{{ item.postal }} {{ item.city }}, {{ item.street }}, {{ item.building_number }}</span>
                </div>
              </ZTableCell>
              <ZTableCell>{{ item.contact_phone }}</ZTableCell>
              <ZTableCell>{{ item.tax_number }}</ZTableCell>
            </ZTableRow>
          </ZTableBody>
        </ZTable>
        <div v-if="organizationsDataSet.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
          <ExclamationTriangleIcon class="icon lg"/>
          {{ $t('organization.message.data_not_found') }}
        </div>
      </div>
    </div>
    <Paginator :data="organizationsDataSet" page-size/>
    <router-view></router-view>
    <MountedTeleport to="#toolbox">
      <router-link :to="{ name: ROUTE.ORGANIZATION_ADD }">
        <ZButton class="gap-2 w-full md:flex">
         {{ $t('organization.command.add_organization') }}
          <PlusCircleIcon class="icon md"/>
        </ZButton>
      </router-link>
    </MountedTeleport>
  </DataLoader>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import OrganizationData = App.Data.OrganizationData;
import {getOrganizations} from '@/api/organization';
import {DataSet} from '@/utils/DataSet';
import Paginator from '@ui/Paginator.vue';
import {ZInput} from '@shadcn/components/ui/input';
import {ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow} from '@shadcn/components/ui/table';
import {BuildingOfficeIcon, ExclamationTriangleIcon, PlusCircleIcon} from '@heroicons/vue/24/outline';
import {ZButton} from '@shadcn/components/ui/button';
import {ROUTE} from '@/router/routeNames';
import MountedTeleport from '@ui/MountedTeleport.vue';
import SimpleSearchData = App.Data.SimpleSearchData;
import DataLoader from "@ui/DataLoader.vue";

export default defineComponent({
  components: {
    DataLoader,
    MountedTeleport,
    Paginator,
    // icons
    BuildingOfficeIcon,
    PlusCircleIcon,
    ExclamationTriangleIcon,
    // shadcn components
    ZButton,
    ZInput,
    ZTable,
    ZTableRow,
    ZTableBody,
    ZTableCell,
    ZTableHead,
    ZTableHeader,
  },

  data() {
    return {
      search: '' as string,
      loader: null as any,
      organizationsDataSet: {} as DataSet<OrganizationData, SimpleSearchData>,
    };
  },

  watch: {
    search() {
      this.organizationsDataSet.setParams({search: this.search}).resetToFirstPage();
    },

    $route(to: any) {
      if (to.name == ROUTE.ORGANIZATION_LIST) {
        this.organizationsDataSet.resetToFirstPage();
      }
    },
  },

  mounted() {
    this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;
    this.organizationsDataSet = new DataSet<OrganizationData, SimpleSearchData>((query: string) =>
      getOrganizations().withLoader(this.loader).execute(query),
    );
    this.organizationsDataSet.load();
  },

  methods: {
    details(id: number) {
      this.$router.push({name: ROUTE.ORGANIZATION_DETAILS, params: {organizationId: id}});
    },
  },
});
</script>
