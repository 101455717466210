import { defineStore } from 'pinia';
import { GroupedKeyValue } from '@/types/Common';
import { getMenuStats } from '@/api/organization';

const statsStore = defineStore('statsStore', {
  state: () => ({
    stats: {} as GroupedKeyValue,
    loaded: false as boolean,
  }),

  actions: {
    async get(group: string) {
      if (this.loaded) return this.stats[group];
      await this.load();
      return this.stats[group];
    },

    async load() {
      const response = await getMenuStats().execute();
      this.stats = response.data;
      this.loaded = true;
    },
  },
});
export default statsStore;
