<template>
  <div class="w-full flex flex-col overflow-auto max-h-[35rem]">
    <div
      v-if="asset.assetValues?.filter(item => item.category_attribute_id == null).length"
      class="grid grid-cols-12 gap-2 mb-4 md:mx-3"
    >
      <ZLabel class="col-span-3"> Typ </ZLabel>
      <ZLabel class="col-span-4"> {{ $t('asset.label.attribute') }} </ZLabel>
      <ZLabel class="col-span-4"> {{ $t('asset.label.value') }} </ZLabel>
      <ZLabel></ZLabel>
    </div>
    <div v-else class="text-center my-10">
      <InformationCircleIcon class="icon w-[80px] mb-5 m-auto" />
      <h3 class="scroll-m-20 text-xl font-semibold text-center">{{ $t('asset.label.notFound') }}</h3>
    </div>
    <div v-for="(attribute, i) in asset.assetValues" :key="i">
      <div v-if="!attribute.hidden" class="my-2 md:m-2 grid grid-cols-12 gap-2">
        <div class="col-span-3">
          <Field :symbol="'select:' + i">
            <ZSelect
              v-if="!attribute.category_attribute_id"
              v-model="attribute.type"
              :on-value-change="resetFields(attribute)"
            >
              <ZSelectTrigger>
                <ZSelectValue :placeholder="$t('asset.placeholder')" />
              </ZSelectTrigger>
              <ZSelectContent>
                <ZSelectGroup>
                  <ZSelectItem value="NUMBER">{{ $t('asset.form.number') }}</ZSelectItem>
                  <ZSelectItem value="CHECKBOX">{{ $t('asset.form.checkbox') }}</ZSelectItem>
                  <ZSelectItem value="TEXT">{{ $t('asset.form.text') }}</ZSelectItem>
                  <ZSelectItem value="DATE">{{ $t('asset.form.date.date') }}</ZSelectItem>
                </ZSelectGroup>
              </ZSelectContent>
            </ZSelect>
          </Field>
        </div>

        <div class="col-span-4">
          <Field :symbol="i + '.name'">
            <div v-if="attribute.category_attribute_id" class="text-left my-auto">{{ attribute.name }}</div>
            <ZInput v-else :id="'name:' + i" v-model="attribute.name" />
          </Field>
        </div>

        <div class="col-span-4">
          <div v-if="attribute.type == 'TEXT'">
            <Field :symbol="i + '.val_string'">
              <ZInput :id="'value:' + i" v-model="attribute.val_string as string" type="text" />
            </Field>
          </div>
          <div v-if="attribute.type == 'DATE'">
            <Field :symbol="i + '.val_date'">
              <ZInput :id="'value:' + i" v-model="attribute.val_date" type="date" />
            </Field>
          </div>
          <div v-if="attribute.type == 'NUMBER'">
            <Field :symbol="i + '.val_float'">
              <ZInput :id="'value:' + i" v-model="attribute.val_float" type="number" />
            </Field>
          </div>
          <div v-if="attribute.type == 'CHECKBOX'" class="ml-2 text-xs text-gray-400 flex h-full items-center">
            <Field :symbol="i + '.val_int'">
              <div class="flex gap-2 items-center">
                {{ $t('asset.form.checkbox_no') }}
                <ZSwitch
                  :id="'value:' + i"
                  :default-checked="false"
                  :checked="checkedValueTransform(attribute)"
                  @update:checked="checkedSetValue(attribute)"
                />
                {{ $t('asset.form.checkbox_yes') }}
              </div>
            </Field>
          </div>
        </div>

        <div class="flex justify-center self-center">
          <TrashIcon
            v-if="!attribute.category_attribute_id"
            class="h-5 cursor-pointer"
            @click="deleteField(i)"
          ></TrashIcon>
        </div>
      </div>
    </div>
    <ZButton
      class="flex w-full justify-center h-12 cursor-pointer mt-3"
      variant="secondary"
      @click.prevent="addNewAttribute"
    >
      <div class="flex">
        <span class="my-auto">
          {{ $t('asset.command.add_new_attribute') }}
        </span>
        <PlusCircleIcon class="ml-2 h-6 text-center my-auto"></PlusCircleIcon>
      </div>
    </ZButton>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ZInput } from '@shadcn/components/ui/input';
import { ZLabel } from '@shadcn/components/ui/label';
import { ZButton } from '@shadcn/components/ui/button';
import { ZSwitch } from '@shadcn/components/ui/switch';
import { PlusCircleIcon, InformationCircleIcon, TrashIcon } from '@heroicons/vue/24/outline';
import {
  ZSelect,
  ZSelectContent,
  ZSelectGroup,
  ZSelectItem,
  ZSelectTrigger,
  ZSelectValue,
} from '@shadcn/components/ui/select';
import Field from '@ui/FormField.vue';
import AssetValueData = App.Data.AssetValueData;
import AssetData = App.Data.AssetData;

interface NewAssetValueData extends AssetValueData {
  hidden?: boolean;
  type?: string;
}
interface NewAssetData extends AssetData {
  assetValues?: Array<NewAssetValueData> | null;
}
export default defineComponent({
  components: {
    InformationCircleIcon,
    ZSwitch,
    ZButton,
    Field,
    ZSelectTrigger,
    ZSelectValue,
    ZSelectItem,
    ZSelectContent,
    ZSelectGroup,
    ZSelect,
    ZInput,
    ZLabel,
    PlusCircleIcon,
    TrashIcon,
  },

  props: {
    asset: {
      type: Object as PropType<NewAssetData>,
      required: true,
    },
  },

  mounted() {
    this.asset.assetValues?.forEach(attr => {
      if (attr.type == null && attr.category_attribute_id == null) {
        if (attr.val_int == 0 || attr.val_int == 1) {
          attr.type = 'CHECKBOX';
        } else if (attr.val_float) {
          attr.type = 'NUMBER';
        } else if (attr.val_date) {
          attr.type = 'DATE';
        } else {
          attr.type = 'TEXT';
        }
      } else {
        attr.hidden = true;
      }
    });
  },

  methods: {
    checkedValueTransform(attribute: NewAssetValueData) {
      return !!(attribute.val_int && attribute.val_int);
    },

    checkedSetValue(attribute: any) {
      attribute.val_int = !(attribute.val_int * 1 ? 1 : 0);
    },

    addNewAttribute() {
      let newAttribute = {
        asset_model_id: this.asset.id,
        category_attribute_id: null,
        name: '',
        val_string: null,
        val_int: null,
        val_float: null,
        val_date: null,
        type: 'TEXT',
      };
      this.asset.assetValues?.push(newAttribute);
    },

    deleteField(index: number) {
      this.asset.assetValues?.splice(index, 1);
    },

    resetFields(attribute: any) {
      if (attribute.type == 'CHECKBOX') {
        if (attribute.val_int == null) {
          attribute.val_int = 0;
        } else {
          attribute.val_string = null;
          attribute.val_float = null;
          attribute.val_date = null;
        }
      } else if (attribute.type == 'NUMBER') {
        if (attribute.val_float == null) {
          attribute.val_string = null;
          attribute.val_int = null;
          attribute.val_date = null;
        }
      } else if (attribute.type == 'DATE') {
        if (attribute.val_date == null) {
          attribute.val_string = null;
          attribute.val_float = null;
          attribute.val_int = null;
        }
      } else if (attribute.type == 'TEXT') {
        if (attribute.val_string == null) {
          attribute.val_string = null;
          attribute.val_float = null;
          attribute.val_int = null;
          attribute.val_date = null;
        }
      }
    },
  },
});
</script>
