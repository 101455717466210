import { http } from './http';
import { ApiCall } from '@/api/ApiCall';
import { PaginatedResponse } from '@/utils/DataSet';
import { KeyValue } from '@/types/Common';
import NewRecordResponse = App.Data.NewRecordResponse;
import ContractActionAssetStoreRequest = App.Data.ContractActionAssetStoreRequest;
import ContractActionDeleteRequest = App.Data.ContractActionDeleteRequest;
import ContractAssetData = App.Data.ContractAssetData;
import ContractAssetNoteData = App.Data.ContractAssetNoteData;
import ContractActionAssetRequestData = App.Data.ContractActionAssetRequestData;

export const getContractedActionAssets = (
  contractId: string,
  actionId: string,
  fields: ContractActionAssetRequestData,
) => {
  const options = {} as KeyValue;
  options.contractId = contractId;
  options.actionId = actionId;
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<ContractAssetData>>(
        `contracts/${options.contractId}/actions/${options.actionId}/assets` + query,
      );
    },
    options,
    '',
    fields,
  );
};

export const getContractedAsset = (contractId: string, assetId: string) => {
  const options = {} as KeyValue;
  options.contractId = contractId;
  options.assetId = assetId;
  return ApiCall.create(options => {
    return http.get(`contracts/${options.contractId}/assets/${options.assetId}`);
  }, options);
};

export const assignAssetsToAction = (id: string, fields: ContractActionAssetStoreRequest) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<NewRecordResponse>(`/contracts/${options.id}/assets`, fields);
    },
    options,
    '',
    fields,
  );
};
export const removeAssetsFromAction = (id: string, fields: ContractActionDeleteRequest) => {
  const options = {} as KeyValue;
  options.id = id;
  const config = fields !== null ? fields : undefined;

  return ApiCall.create(
    (options, query, config) => {
      return http.delete(`/contracts/${options.id}/assets`, { data: config });
    },
    options,
    '',
    config,
  );
};

export const addNoteToContractedAsset = (contractId: string, assetId: string, fields: ContractAssetNoteData) => {
  const options = {} as KeyValue;
  options.contractId = contractId;
  options.assetId = assetId;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<ContractAssetData>(
        `contracts/${options.contractId}/contractedAssets/${options.assetId}/note`,
        fields,
      );
    },
    options,
    '',
    fields,
  );
};
