import { defineStore } from 'pinia';
import { KeyValue } from '@/types/Common';
import { getCategoryAttributeTypes } from '@/api/category';

const attributeTypesStore = defineStore('attributeTypesStore', {
  state: () => ({
    attributeTypes: {} as KeyValue,
    loaded: false as boolean,
  }),

  actions: {
    async get() {
      if (this.loaded) return this.attributeTypes;
      await this.load();
      return this.attributeTypes;
    },

    async load() {
      const response = await getCategoryAttributeTypes().execute();
      this.attributeTypes = response.data;
      this.loaded = true;
    },
  },
});
export default attributeTypesStore;
