<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent
      class="lg:max-w-[1300px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto flex flex-col"
    >
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('asset.label.tagging') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('asset.message.tagging') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <h2>
          {{ $t('asset.message.assets_amount') }} <b>{{ assets.length }}</b>
        </h2>
        <div class="flex gap-6 flex-col mt-0">
          <div>
            <FormValidator ref="form">
              <AssetTagsForm :tags="tagsDataSet" @update="modifiedTags = $event" />
            </FormValidator>
          </div>
          <div class="flex-1 max-w-[100vw] overflow-auto max-h-[30vh]">
            <ZTable class="w-full">
              <ZTableHeader>
                <ZTableRow>
                  <ZTableHead></ZTableHead>
                  <ZTableHead>{{ $t('asset.label.name') }}</ZTableHead>
                  <ZTableHead>{{ $t('asset.label.status') }}</ZTableHead>
                  <ZTableHead>{{ $t('asset.label.asset_model') }}</ZTableHead>
                  <ZTableHead>{{ $t('asset.label.inventory_number') }}</ZTableHead>
                  <ZTableHead>{{ $t('asset.label.category') }}</ZTableHead>
                </ZTableRow>
              </ZTableHeader>
              <ZTableBody>
                <ZTableRow v-for="(item, index) in assets" :key="index">
                  <ZTableCell>
                    <img
                      v-if="item.thumb"
                      class="min-w-[50px] w-[50px] h-[50px] object-cover rounded-xl"
                      :src="item.thumb"
                      alt="primaryImage"
                    />
                  </ZTableCell>
                  <ZTableCell>
                    <b>{{ item.name }}</b>
                  </ZTableCell>
                  <ZTableCell>
                    <PrettyStatus :status="item.status!" :translate="'asset.states.'" />
                  </ZTableCell>
                  <ZTableCell>
                    {{ item.asset_model?.name }}
                  </ZTableCell>
                  <ZTableCell>
                    {{ item.asset_number }}
                  </ZTableCell>
                  <ZTableCell>{{ item.category ? item.category.name : $t('asset.label.lack') }}</ZTableCell>
                </ZTableRow>
              </ZTableBody>
            </ZTable>
          </div>
          <div class="md:px-4 py-2 flex justify-end">
            <ZDialogFooter class="py-4">
              <Action variant="outline" class="w-full" :disabled="!canSubmit" @proceed="remove">{{
                  $t('asset.command.mass_remove_tags')
                }}</Action>
              <Action class="w-full" :disabled="!canSubmit" @proceed="assign">{{
                $t('asset.command.mass_assign_tags')
                }}</Action>
            </ZDialogFooter>
          </div>
        </div>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import MinimalAssetData = App.Data.MinimalAssetData;
import { ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow } from '@shadcn/components/ui/table';
import { ValidationErrors } from '@/types/ValidationErrors';
import FormValidator from '@ui/FormValidator.vue';
import {massTagsAssign, massTagsRemove} from '@/api/asset';
import PrettyStatus from '@ui/PrettyStatus.vue';
import AssetTagsForm from '@ui/Assets/AssetTagsForm.vue';
import { DataSet } from '@/utils/DataSet';
import TagListData = App.Data.TagListData;
import TagRequestData = App.Data.TagRequestData;
import { getTags } from '@/api';

export default defineComponent({
  components: {
    AssetTagsForm,
    PrettyStatus,
    FormValidator,
    ZTableHeader,
    ZTableCell,
    ZTableHead,
    ZTableBody,
    ZTable,
    ZTableRow,
    IconAlert,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    assets: {
      type: Array<MinimalAssetData>,
      required: true,
    },
  },

  emits: ['taggingDialogClosed', 'saved'],

  setup() {
    const currentTab = ref('withinOrganization');

    return {
      currentTab,
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      state: 'loaded' as ComponentLoadState,
      errors: null as null | ValidationErrors,
      tagsDataSet: {} as DataSet<TagListData, TagRequestData>,
      modifiedTags: [],
    };
  },

  computed: {
    canSubmit() {
      return this.modifiedTags && this.modifiedTags.length > 0;
    },
  },

  watch: {
    isDialogOpen() {
      this.$emit('taggingDialogClosed', true);
    },
  },

  mounted() {
    this.tagsDataSet = new DataSet<TagListData, TagRequestData>((query: string) => getTags().execute(query)).setParams({
      search: '',
      page: 1,
      status: 'visible',
      perPage: 30,
    });
    this.tagsDataSet.load();
  },

  methods: {
    assign(action: any) {
      const assetIds = this.assets.map(asset => asset.id?.toString());
      massTagsAssign(assetIds, this.modifiedTags)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('assetModels.message.attributes_saved'),
          });
          this.$emit('saved');
        });
    },

    remove(action: any) {
      const assetIds = this.assets.map(asset => asset.id?.toString());
      massTagsRemove(assetIds, this.modifiedTags)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('assetModels.message.attributes_saved'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
