<template>
  <DataLoader ref="loader">
    <div class="py-4">
      <div class="grid sm:grid-cols-2 lg:grid-cols-1 lg:flex gap-4 mt-5">
        <div class="w-full">
          <ZLabel>{{ $t('inventory.label.inventory_search') }}</ZLabel>
          <ZInput
            v-model="filters.search"
            type="search"
            :placeholder="$t('inventory.label.inventory_search_placeholder')"
          />
        </div>
        <div class="w-full lg:max-w-[200px]">
          <ZLabel for="technical_condition">
            {{ $t('inventory.label.status') }}
          </ZLabel>
          <ZSelect v-model="filters.status!" class="w-full">
            <ZSelectTrigger class="w-full">
              <div class="flex flex-row gap-2 whitespace-nowrap">
                <ZSelectValue :placeholder="$t('inventory.label.status')"/>
              </div>
            </ZSelectTrigger>
            <ZSelectContent>
              <ZSelectGroup>
                <ZSelectItem :key="'all'" :value="'all'">{{ $t('inventory.status.all') }}</ZSelectItem>
                <ZSelectItem
                  v-for="(translation, inventoryStatus) in allStatuses"
                  :key="inventoryStatus"
                  :value="inventoryStatus"
                >{{ $t(translation) }}
                </ZSelectItem
                >
              </ZSelectGroup>
            </ZSelectContent>
          </ZSelect>
        </div>
        <div class="w-full lg:max-w-[250px]">
          <ZLabel>
            {{ $t('inventory.label.started_at') }}
          </ZLabel>
          <div class="flex items-center gap-1">
            <Popover v-model:open="showStartedAtDateRangePicker">
              <PopoverTrigger as-child>
                <ZButton variant="outline" class="w-full font-normal flex items-center justify-start">
                  <div class="flex items-center w-full">
                    <CalendarIcon class="w-[20px] mr-2"/>
                    <span v-if="startedAtDateRange.start && startedAtDateRange.end" class="leading-3 text-xs">
                    Od: {{ formatDate(startedAtDateRange.start) }} <br/>
                    Do: {{ formatDate(startedAtDateRange.end) }}
                  </span>
                    <span v-else>{{ $t('inventory.form.date.button') }}</span>
                  </div>
                  <ChevronDownIcon class="w-[18px] ml-2 text-gray-400"/>
                </ZButton>
              </PopoverTrigger>
              <PopoverContent class="w-auto p-0" align="start">
                <Calendar v-model.range="startedAtDateRange" timezone="UTC" :columns="1"/>
              </PopoverContent>
            </Popover>
            <ZButton
              v-if="startedAtDateRange.start && startedAtDateRange.end"
              variant="ghost"
              @click.prevent="clearStartedAtDateRange()"
            >
              <XCircleIcon class="w-[23px]"/>
            </ZButton>
          </div>
        </div>

        <div class="w-full lg:max-w-[250px]">
          <ZLabel>
            {{ $t('inventory.label.completed_at') }}
          </ZLabel>
          <div class="flex items-center gap-1">
            <Popover v-model:open="showCompletedAtDateRangePicker">
              <PopoverTrigger as-child>
                <ZButton variant="outline" class="w-full font-normal flex items-center justify-start">
                  <div class="flex items-center w-full">
                    <CalendarIcon class="w-[20px] mr-2"/>
                    <span v-if="completedAtDateRange.start && completedAtDateRange.end" class="leading-3 text-xs">
                    Od: {{ formatDate(completedAtDateRange.start) }} <br/>
                    Do: {{ formatDate(completedAtDateRange.end) }}
                  </span>
                    <span v-else>{{ $t('inventory.form.date.button') }}</span>
                  </div>
                  <ChevronDownIcon class="w-[18px] ml-2 text-gray-400"/>
                </ZButton>
              </PopoverTrigger>
              <PopoverContent class="w-auto p-0" align="start">
                <Calendar v-model.range="completedAtDateRange" timezone="UTC" :columns="1"/>
              </PopoverContent>
            </Popover>
            <ZButton
              v-if="completedAtDateRange.start && completedAtDateRange.end"
              variant="ghost"
              @click.prevent="clearCompletedAtDateRange()"
            >
              <XCircleIcon class="w-[23px]"/>
            </ZButton>
          </div>
        </div>
      </div>
    </div>
    <Paginator :data="inventoryDataSet"/>
    <div class="border rounded">
      <ZTable class="w-full">
        <ZTableHeader>
          <ZTableRow>
            <ZTableHead>{{ $t('inventory.label.number') }}</ZTableHead>
            <ZTableHead>{{ $t('inventory.label.status') }}</ZTableHead>
            <ZTableHead>{{ $t('inventory.label.started_at') }}</ZTableHead>
            <ZTableHead>{{ $t('inventory.label.completed_at') }}</ZTableHead>
            <ZTableHead class="w-48 text-center">{{ $t('inventory.label.assets_count') }}</ZTableHead>
            <ZTableHead class="w-48 text-center">{{ $t('inventory.label.assets_good_count') }}</ZTableHead>
            <ZTableHead class="w-48 text-center">{{ $t('inventory.label.assets_damaged_count') }}</ZTableHead>
            <ZTableHead class="w-48 text-center">{{ $t('inventory.label.assets_missing_count') }}</ZTableHead>
          </ZTableRow>
        </ZTableHeader>
        <ZTableBody>
          <ZTableRow
            v-for="(item, index) in inventoryDataSet.data"
            :key="index"
            class="cursor-pointer"
            @click="details(item.id ?? 0)"
          >
            <ZTableCell class="">
              <b>#{{ item.id }}</b>
            </ZTableCell>
            <ZTableCell class="">
              <PrettyStatus :status="item.status!" :translate="'inventory.status.'"/>
            </ZTableCell>
            <ZTableCell>
              {{ item.started_at ? formatDate(item.started_at) : '-' }}
            </ZTableCell>
            <ZTableCell>
              {{ item.completed_at ? formatDate(item.completed_at) : '-' }}
            </ZTableCell>
            <ZTableCell class="text-center">
              {{ item.inventory_assets_count }}
            </ZTableCell>
            <ZTableCell class="text-center">
              {{ item.inventory_assets_good_count }}
            </ZTableCell>
            <ZTableCell class="text-center">
              {{ item.inventory_assets_damaged_count }}
            </ZTableCell>
            <ZTableCell class="text-center">
              {{ item.inventory_assets_missing_count }}
            </ZTableCell>
          </ZTableRow>
        </ZTableBody>
      </ZTable>
      <div v-if="inventoryDataSet.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
        <ExclamationTriangleIcon class="icon lg"/>
        {{ $t('inventory.message.data_not_found') }}
      </div>
    </div>
    <Paginator :data="inventoryDataSet"/>
    <router-view></router-view>
    <MountedTeleport to="#toolbox">
      <div class="flex flex-row gap-3">
        <ZButton
          class="flex gap-2 w-full"
          :disabled="!access.hasBranchSelected()"
          @click="router().push({ name: ROUTE.INVENTORY_ADD })"
          v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"
        >
          {{ $t('inventory.command.create_inventory') }}
          <PlusCircleIcon class="icon md"/>
        </ZButton>
      </div>
    </MountedTeleport>
  </DataLoader>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {ZButton} from '@shadcn/components/ui/button';
import MountedTeleport from '@ui/MountedTeleport.vue';
import {ExclamationTriangleIcon, PlusCircleIcon} from '@heroicons/vue/24/outline';
import {
  ZSelect,
  ZSelectContent,
  ZSelectGroup,
  ZSelectItem,
  ZSelectTrigger,
  ZSelectValue,
} from '@shadcn/components/ui/select';
import {ZLabel} from '@shadcn/components/ui/label';
import {ZInput} from '@shadcn/components/ui/input';
import {ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow} from '@shadcn/components/ui/table';
import Paginator from '@ui/Paginator.vue';
import {ROUTE} from '@/router/routeNames';
import router from '@/router';
import PrettyStatus from '@ui/PrettyStatus.vue';
import InventoryData = App.Data.InventoryData;
import InventoryFilterRequestData = App.Data.InventoryFilterRequestData;
import {DataSet} from '@/utils/DataSet';
import {getInventories} from '@/api/inventory';
import symbolsStore from '@/stores/symbols';
import {CalendarIcon, ChevronDownIcon, XCircleIcon} from '@modules/@heroicons/vue/24/outline';
import {Popover, PopoverContent, PopoverTrigger} from '@shadcn/components/ui/popover';
import {Calendar} from '@shadcn/components/ui/calendar';
import useAccessStore from '@/stores/access';
import DataLoader from "@ui/DataLoader.vue";

interface DateRangeInterface {
  start: string,
  end: string,
};

interface InventoryFilterInterface {
  search?: string | null;
  page?: number | null;
  perPage?: number | null;
  status?: string | null;
  startedAtDateRange?: DateRangeInterface;
  completedAtDateRange?: DateRangeInterface;
};

export default defineComponent({
  components: {
    DataLoader,
    CalendarIcon,
    XCircleIcon,
    ChevronDownIcon,
    PrettyStatus,
    ZTableHead,
    ZTableBody,
    ZTable,
    Paginator,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Calendar,
    ZTableHeader,
    ZTableRow,
    ZTableCell,
    ExclamationTriangleIcon,
    PlusCircleIcon,
    MountedTeleport,
    ZButton,
    ZSelect,
    ZSelectContent,
    ZSelectGroup,
    ZSelectItem,
    ZSelectTrigger,
    ZSelectValue,
    ZLabel,
    ZInput,
  },

  data() {
    return {
      inventoryDataSet: {} as DataSet<InventoryData, InventoryFilterRequestData>,
      allStatuses: {},
      loader: null as any,
      showStartedAtDateRangePicker: false,
      showCompletedAtDateRangePicker: false,
      startedAtDateRange: {
        start: '',
        end: '',
      },

      completedAtDateRange: {
        start: '',
        end: '',
      },

      filters: {
        search: '',
        page: 1,
        perPage: 15,
        status: 'all',
        started_at: null,
        completed_at: null,
        startedAtDateRange: {
          start: '',
          end: '',
        },

        completedAtDateRange: {
          start: '',
          end: '',
        }
      } as InventoryFilterInterface,
    };
  },

  computed: {
    access: () => useAccessStore(),
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        this.inventoryDataSet.setParams(this.filters as InventoryFilterRequestData, true).resetToFirstPage();
      },
    },

    startedAtDateRange: {
      deep: true,
      handler() {
        this.showStartedAtDateRangePicker = false;
        this.filters.startedAtDateRange!.start = this.startedAtDateRange.start
          ? new Date(this.startedAtDateRange.start).toISOString().split('T')[0]
          : '';
        this.filters.startedAtDateRange!.end = this.startedAtDateRange.end
          ? new Date(this.startedAtDateRange.end).toISOString().split('T')[0]
          : '';
        this.inventoryDataSet.setParams(this.filters as InventoryFilterRequestData, true).resetToFirstPage();
      },
    },

    completedAtDateRange: {
      deep: true,
      handler() {
        this.showCompletedAtDateRangePicker = false;
        this.filters.completedAtDateRange!.start = this.completedAtDateRange.start
          ? new Date(this.completedAtDateRange.start).toISOString().split('T')[0]
          : '';
        this.filters.completedAtDateRange!.end = this.completedAtDateRange.end
          ? new Date(this.completedAtDateRange.end).toISOString().split('T')[0]
          : '';
        this.inventoryDataSet.setParams(this.filters as InventoryFilterRequestData, true).resetToFirstPage();
      },
    },
  },

  async mounted() {
    try {
      this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;
      const symbols = symbolsStore();
      this.allStatuses = await symbols.get('inventory_states');
      await this.fetchData();
    } catch (error: any) {
    }
  },

  methods: {
    router() {
      return router;
    },

    async fetchData() {
      try {
        this.inventoryDataSet = new DataSet<InventoryData, InventoryFilterRequestData>((query: string) =>
          getInventories(this.filters as InventoryFilterRequestData).withLoader(this.loader).execute(query),
        ).setParams(this.filters as InventoryFilterRequestData);
        await this.inventoryDataSet.load();
      } catch (e) {
      }
    },

    details(id: number) {
      this.$router.push({name: ROUTE.INVENTORY_DETAILS, params: {inventoryId: id}});
    },

    clearStartedAtDateRange() {
      this.filters.startedAtDateRange = {
        start: '',
        end: '',
      };
      this.startedAtDateRange = {
        start: '',
        end: '',
      };
    },

    clearCompletedAtDateRange() {
      this.filters.completedAtDateRange = {
        start: '',
        end: '',
      };
      this.completedAtDateRange = {
        start: '',
        end: '',
      };
    },
  },
});
</script>
