<template>
  <div class="mt-4 space-y-4">
    <h3 class="font-bold text-lg flex flex-row gap-2 border rounded border-green-500 p-4">
      <Square3Stack3DIcon class="icon md" />
      {{ category.name }}
    </h3>
    <FormValidator ref="form">
      <CategoryAttributeForm
        :attribute-types="attributeTypes"
        :category-attribute="categoryAttribute"
      ></CategoryAttributeForm>
    </FormValidator>
    <div class="flex flex-row gap-2">
      <ZButton variant="outline" @click="cancel()">{{ $t('common.cancel') }}</ZButton>
      <Action @proceed="store">
        {{ $t('category.command.add_category_attribute') }}
      </Action>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import CategoryAttributeForm from '@ui/Categories/CategoryAttributeForm.vue';
import attributeTypesStore from '@/stores/attributeTypes';
import type { GroupedKeyValue, KeyValue } from '@/types/Common';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import { createCategoryAttribute, getCategory } from '@/api/category';
import Action from '@ui/Action.vue';
import { Square3Stack3DIcon } from '@heroicons/vue/24/outline';
import FormValidator from '@ui/FormValidator.vue';
import { ROUTE } from '@/router/routeNames';
import CategoryData = App.Data.CategoryData;
import CategoryAttributeData = App.Data.CategoryAttributeData;
import { ZButton } from '@shadcn/components/ui/button';

export default defineComponent({
  components: {
    ZButton,
    FormValidator,
    Square3Stack3DIcon,
    Action,
    CategoryAttributeForm,
  },

  props: {
    categoryId: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      category: {} as CategoryData,
      categoryAttribute: {} as CategoryAttributeData,
      attributeTypes: {} as KeyValue,
      translations: {} as GroupedKeyValue,
      isDialogOpen: true,

      state: 'loading' as ComponentLoadState,
    };
  },

  async mounted() {
    try {
      const attributeTypes = attributeTypesStore();
      this.attributeTypes = await attributeTypes.get();
      const response = await getCategory(this.categoryId).execute();
      this.category = response.data;
      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
    }
  },

  methods: {
    store(action: any) {
      if (this.categoryAttribute.type != 'NUMBER') this.categoryAttribute.unit = '';

      let data = JSON.parse(JSON.stringify(this.categoryAttribute));

      createCategoryAttribute(data, this.categoryId)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('category.message.category_attribute_saved'),
          });
          this.$router.replace({ name: ROUTE.CATEGORY_LIST, query: { categoryId: this.categoryId } });
        });
    },

    cancel() {
      this.$router.back();
    },
  },
});
</script>
