import type { RouteRecordRaw } from 'vue-router';
import ImportAsset from '@views/Imports/Assets/ImportAsset.vue';
import {ROUTE} from "@/router/routeNames";

export const importsRoutes: RouteRecordRaw[] = [
  {
    path: 'importAssets',
    name: ROUTE.IMPORT_ASSETS,
    component: ImportAsset,
    meta: {
      breadcrumbs: true,
      title: 'importAsset.title',
      permission: [{operator: true}],
    }
  },
];
