<template>
  <teleport v-if="componentMounted" to="#toolbox">
    <router-link :to="{ name: ROUTE.TAGS_ADD }">
      <ZButton variant="outline" @click.prevent="fullScreenMode = !fullScreenMode" class="gap-2 w-full md:flex">
        Tryb pełnoekranowy
      </ZButton>
    </router-link>
  </teleport>
  <div :class="{'full-screen-mode': fullScreenMode}">
    <div v-if="fullScreenMode">
      <ZButton variant="outline" @click.prevent="fullScreenMode = !fullScreenMode" class="h-8 gap-2 absolute right-3 top-3">
        Wyłącz tryb pełnoekranowy
      </ZButton>
    </div>
    <div class="flex items-center gap-4 mt-4">
      <div class="border w-full rounded-lg p-4">
        <div class="font-semibold">Arkusz</div>
        <div class="text-xs">Wybierz arkusz do importu zasobów</div>
        <FormValidator ref="form" class="mt-3 flex gap-3 items-center">
          <Field symbol="purchase_price" class="col-span-2">
            <ZInput id="file" type="file" @change="addFile" />
          </Field>
          <div class="text-xs whitespace-nowrap flex items-start gap-1 flex-col">
            Usuń aktualne zasoby
            <ZSwitch :checked="getCheckboxValue()" @update:checked="changeSwitch" />
          </div>
        </FormValidator>
        <Action :disabled="!file" class="w-full mt-4" @proceed="uploadFile">Prześlij plik</Action>
      </div>
      <div class="border w-full rounded-lg p-4  relative" >
        <div v-if="!assetsDataSet.data?.length" class="absolute left-0 rounded-lg right-0 top-0 bottom-0 bg-white/90 z-20 flex items-center justify-center">
          <div class="text-sm font-bold">Wgraj zasoby poprzez arkusz, aby móc uzupełnić zdjęcia</div>
        </div>
        <div v-if="uploadImagesInfo.uploading" class="absolute left-0 right-0 top-0 bottom-0 font-bold text-center self-center  mb-12">{{uploadImagesInfo.toUpload}} / {{images.length}}</div>
        <img v-if="uploadImagesInfo.uploading" src="@pub/images/loader.gif" title="Logo ZWD" class="max-w-14 m-auto absolute left-0 right-0 top-12 bottom-0" />
        <div  :class="{'opacity-10': uploadImagesInfo.uploading}" class="flex items-stretch gap-6">
          <div class="w-full flex flex-col">
            <div class="font-semibold">Zdjęcia</div>
            <div class="text-xs">Nazwa zdjęcia musi być taka sama jak w arkuszu (kolumna T) i nie może się powtarzać</div>
            <FormValidator ref="formImages" class="mt-3 flex gap-3 items-center">
              <Field symbol="purchase_price" class="col-span-2">
                <ZInput type="file" accept="image/png, image/jpeg" multiple  @change="addImageFile" />
              </Field>
            </FormValidator>
            <Action :disabled="!images.length || uploadImagesInfo.uploading" class="w-full mt-4" @proceed="uploadImageFile">Prześlij plik</Action>
          </div>
          <div v-if="uploadedImages.length" class="w-full border-l pl-4 max-h-[160px] overflow-y-scroll">
            <div class="">
            <div class="flex gap-3 my-1" v-for="(image, key) in uploadedImages" :key="key">
              <img class="w-[40px] h-[40px] object-cover rounded-sm" :src="image.url"/>
              <div>
                <div class="text-xs font-bold">{{image.name}}</div>
                <div class="text-xs" :class="{'text-red-500': image.isError, 'text-green-500': !image.isError}">{{image.message}}</div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

<br/>
  <div class="border rounded-xl overflow-hidden" v-if="assetsDataSet.data?.length">
    <div class="border-b bg-gray-100">
      <div class="border-b bg-white flex items-center justify-between">
        <div class="px-3 text-xs font-semibold">
          Widoczne:
        </div>
        <div class="divide-x flex items-center">
          <div class="p-3 flex items-center gap-2">
            <ZLabel class="flex items-center gap-3">
              <div class="w-[10px] h-[10px] border rounded-full"></div>
              Dane podstawowe</ZLabel>
            <ZSwitch :checked="tabFilter.basic" @update:checked="tabFilter.basic = !tabFilter.basic"/>
          </div>
          <div class="p-3 flex items-center gap-2">
            <ZLabel class="flex items-center gap-3">
              <div class="w-[10px] h-[10px] bg-yellow-200 rounded-full"></div>
              Atrybuty</ZLabel>
            <ZSwitch :checked="tabFilter.attributes" @update:checked="tabFilter.attributes = !tabFilter.attributes"/>
          </div>
          <div class="p-3 flex items-center gap-2">
            <ZLabel class="flex items-center gap-3">
              <div class="w-[10px] h-[10px] bg-blue-200 rounded-full"></div>
              Dane księgowe</ZLabel>
            <ZSwitch :checked="tabFilter.accounting" @update:checked="tabFilter.accounting = !tabFilter.accounting"/>
          </div>
        </div>
      </div>
      <Paginator class="px-3" :data="assetsDataSet" />
    </div>
    <ZTable class="w-full">
      <ZTableHeader>
        <ZTableRow class="divide-x">
          <ZTableHead></ZTableHead>
          <ZTableHead v-if="tabFilter.basic"></ZTableHead>
          <ZTableHead v-if="tabFilter.basic">Nazwa</ZTableHead>
          <ZTableHead v-if="tabFilter.basic">Oddział</ZTableHead>
          <ZTableHead v-if="tabFilter.basic">Lokalizacja</ZTableHead>
          <ZTableHead v-if="tabFilter.basic">Status</ZTableHead>
          <ZTableHead v-if="tabFilter.basic">Stan techniczny</ZTableHead>
          <ZTableHead v-if="tabFilter.basic">Ślad węglowy</ZTableHead>
          <ZTableHead v-if="tabFilter.basic">Kategoria</ZTableHead>
          <ZTableHead v-if="tabFilter.basic">Model</ZTableHead>
          <ZTableHead v-if="tabFilter.basic">Opis</ZTableHead>
          <ZTableHead v-if="tabFilter.basic">Nr. seryjny</ZTableHead>
          <ZTableHead v-if="tabFilter.basic">Gwarant</ZTableHead>
          <ZTableHead v-if="tabFilter.basic">Gwarancja</ZTableHead>
          <ZTableHead v-if="tabFilter.accounting" class="bg-blue-100">Cena zakupu</ZTableHead>
          <ZTableHead v-if="tabFilter.accounting" class="bg-blue-100">Waluta</ZTableHead>
          <ZTableHead v-if="tabFilter.accounting" class="bg-blue-100">Data zakupu</ZTableHead>
          <ZTableHead v-if="tabFilter.accounting" class="bg-blue-100">Nr. fakt.</ZTableHead>
          <ZTableHead v-if="tabFilter.accounting" class="bg-blue-100">Wartość księgowa</ZTableHead>
          <ZTableHead v-if="tabFilter.accounting" class="bg-blue-100">Data aktywacji</ZTableHead>
          <ZTableHead v-if="tabFilter.accounting" class="bg-blue-100">Amortyzacja</ZTableHead>
          <ZTableHead v-if="tabFilter.attributes" class="bg-yellow-100" v-for="(_, key) in selectedAttributes" :key="key" >
            <ZSelect v-model="selectedAttributes[key]">
              <ZSelectTrigger class="w-full">
                <div class="flex flex-row gap-2 whitespace-nowrap">
                  <ZSelectValue />
                </div>
              </ZSelectTrigger>
              <ZSelectContent>
                <ZSelectGroup>
                  <ZSelectItem v-for="(attribute, key) in config.attributes" :key="key" :value="attribute.id.toString()">
                    {{ attribute.name }}
                  </ZSelectItem>
                </ZSelectGroup>
              </ZSelectContent>
            </ZSelect>
          </ZTableHead>
        </ZTableRow>
      </ZTableHeader>
      <ZTableBody>
        <ZTableRow  class="divide-x" v-for="(item, index) in assetsDataSet.data" :key="index" :style="{'background-color': item.import_status == 'error' ? '#ff000055': (item.import_status == 'warning' ? 'yellow' : 'transparent')}">
          <ZTableCell v-if="tabFilter.basic" class="p-1 text-xs">{{ item.import_status == 'error' ? 'Błąd' : (item.import_status == 'warning' ? 'Ostrzeżenie' : 'Gotowy') }}</ZTableCell>
          <ZTableCell v-if="tabFilter.basic" class="p-1 text-xs">
            <img :src="item.image" class="min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px] object-cover rounded-md">
          </ZTableCell>
          <ZTableCell v-if="tabFilter.basic" class="p-1 text-xs">{{item.name}}</ZTableCell>
          <ZTableCell v-if="tabFilter.basic" class="p-1 text-xs">{{item.branch?.name ?? '???'}}</ZTableCell>
          <ZTableCell v-if="tabFilter.basic" class="p-1 text-xs">{{item.location}}</ZTableCell>
          <ZTableCell v-if="tabFilter.basic" class="p-1 text-xs"><PrettyStatus :status="item.status!" :translate="'asset.states.'" /></ZTableCell>
          <ZTableCell v-if="tabFilter.basic" class="p-1 text-xs">{{ $t('asset.technical_conditions.' + item.technical_condition) }}</ZTableCell>
          <ZTableCell v-if="tabFilter.basic" class="p-1 text-xs">{{item.carbon_footprint_balance}}</ZTableCell>
          <ZTableCell v-if="tabFilter.basic" class="p-1 text-xs">{{item.category?.name ?? '???'}}</ZTableCell>
          <ZTableCell v-if="tabFilter.basic" class="p-1 text-xs">{{item.model?.name ?? item.asset_model_value}}</ZTableCell>
          <ZTableCell v-if="tabFilter.basic" class="p-1 text-xs">{{item.description}}</ZTableCell>
          <ZTableCell v-if="tabFilter.basic" class="p-1 text-xs">{{item.serial_number}}</ZTableCell>
          <ZTableCell v-if="tabFilter.basic" class="p-1 text-xs">{{item.warrantator}}</ZTableCell>
          <ZTableCell v-if="tabFilter.basic" class="p-1 text-xs">{{item.warranty_expiration_date ? formatDate(item.warranty_expiration_date) : ''}}</ZTableCell>
          <ZTableCell v-if="tabFilter.accounting" class="bg-blue-50 p-1 text-xs">{{item.purchase_price}}</ZTableCell>
          <ZTableCell v-if="tabFilter.accounting" class="bg-blue-50 p-1 text-xs">{{item.currency}}</ZTableCell>
          <ZTableCell v-if="tabFilter.accounting" class="bg-blue-50 p-1 text-xs">{{item.purchase_date ? formatDate(item.purchase_date) : ''}}</ZTableCell>
          <ZTableCell v-if="tabFilter.accounting" class="bg-blue-50 p-1 text-xs">{{item.invoice_number}}</ZTableCell>
          <ZTableCell v-if="tabFilter.accounting"  class="bg-blue-50 p-1 text-xs">{{item.accounting_value}}</ZTableCell>
          <ZTableCell v-if="tabFilter.accounting" class="bg-blue-50 p-1 text-xs">{{item.activation_date ? formatDate(item.activation_date) : ''}}</ZTableCell>
          <ZTableCell v-if="tabFilter.accounting" class="bg-blue-50 p-1 text-xs">{{item.amortization_value}}</ZTableCell>
          <ZTableCell class="bg-yellow-50 p-1 text-xs" v-if="tabFilter.attributes" v-for="(item, colIndex) in item.attribute_values">
            {{ translateAttributeToText(item, colIndex) }}
          </ZTableCell>
        </ZTableRow>
        <div v-if="!tabFilter.basic && !tabFilter.accounting && !tabFilter.attributes" class="text-center p-6">Wbierz dane do wyświetlenia</div>
      </ZTableBody>
    </ZTable>
    <div class="px-3 border-t bg-gray-100">
      <Paginator :data="assetsDataSet" />
    </div>
    <div v-if="assetsDataSet.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
      <ExclamationTriangleIcon class="icon lg" /> {{ $t('organization.message.data_not_found') }}
    </div>
  </div>
    <div v-if="assetsDataSet.data?.length" class="flex items-center justify-between bg-green-50 border border-green-500 rounded-xl my-5 p-2">
      <div class="font-semibold text-sm mx-2">
        Sprawdź, czy wszystkie danę są poprawne przed wykonaniem importu
      </div>
      <div class="flex items-center gap-2">
        <ZSwitch :checked="importWarnings" @update:checked="importWarnings = !importWarnings"/>
        Importuj wiersze z ostrzeżeniami
        <Action @proceed="confirmImport">Wykonaj import</Action>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import { ZInput } from '@shadcn/components/ui/input';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';
import { ZButton } from '@shadcn/components/ui/button';
import { Dialogs } from '@/types/Dialogs';
import FormValidator from "@ui/FormValidator.vue";
import Field from "@ui/FormField.vue";
import {ZLabel} from "@shadcn/components/ui/label";
import Action from "@ui/Action.vue";
import {TaskQueue} from 'cwait';

import {
  confirmImportAsset,
  getImportAssetConfig,
  getImportedAssets,
  storeImagesImportedAssets,
  storeImportAsset
} from "@/api";
import ZSwitch from "@shadcn/components/ui/switch/Switch.vue";
import {DataSet} from "@/utils/DataSet";
import Paginator from "@ui/Paginator.vue";
import {ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow} from "@shadcn/components/ui/table";
import {EllipsisHorizontalIcon} from "@modules/@heroicons/vue/24/solid";
import {ExclamationTriangleIcon} from "@modules/@heroicons/vue/24/outline";
import TagLabel from "@ui/Tags/TagLabel.vue";
import {DropdownMenu} from "@shadcn/components/ui/dropdown-menu";
import {
  ZSelect,
  ZSelectContent,
  ZSelectGroup,
  ZSelectItem,
  ZSelectTrigger,
  ZSelectValue
} from "@shadcn/components/ui/select";
import PrettyStatus from "@ui/PrettyStatus.vue";

export default defineComponent({
  components: {
    PrettyStatus,
    ZSelectItem, ZSelectValue, ZSelectContent, ZSelectGroup, ZSelectTrigger, ZSelect,
    ZTableCell,
    ZTableHeader,
    ExclamationTriangleIcon,
    ZTable,
    ZTableRow,
    ZTableBody,
    ZTableHead,
    Paginator,
    ZSwitch,
    Action,
    ZLabel, Field,
    FormValidator,
    ZInput,
    ZButton,
  },

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      componentMounted: false,
      assetsDataSet: {} as DataSet<any, any>,
      file: null,
      images: [],
      removeOldAssets: 1,
      config: null as any,
      selectedAttributes: [],
      tabFilter:{
        basic:true,
        attributes:true,
        accounting:true,
      },
      fullScreenMode:false,
      uploadedImages:[],
      uploadImagesInfo:{
        toUpload:1,
        uploading:false
      },
      importWarnings: false,
    };
  },

  watch: {

  },

  async mounted() {
    this.componentMounted = true;
    await this.loadAssets(false);
    await this.loadConfig();
  },

  methods: {

    addFile(e) {
      this.file = e.target.files[0];
    },
    addImageFile(e) {
      this.images = e.target.files;
    },
    getCheckboxValue() {
      return !!(this.removeOldAssets && this.removeOldAssets);
    },

    changeSwitch(val: boolean) {
      this.removeOldAssets = val ? 1 : 0;
    },

    loadAssets(verify = false) {
      this.assetsDataSet = new DataSet<any, any>((query: string) => {
          if (verify) query += '&verify=1';
          return getImportedAssets().execute(query)
        }
      );
      return this.assetsDataSet.load();
    },
    loadConfig() {
      return getImportAssetConfig()
        .execute()
        .then(response => {
          this.config = response.data;
          this.selectedAttributes = Array(response.data.attributes_columns);
        });
    },
    translateAttributeToText(value, colIndex) {
      let attribute = this.config?.attributes[this.selectedAttributes[colIndex]*1 -1];
      if(attribute?.type === 'MULTISELECT'){
        const currentValues = value?.split?.(',');
        if(currentValues && currentValues.length){
          const values = [];
          Object.entries(attribute.parameters).filter(([key, v]) => {
            if (currentValues.includes(key)){
              values.push(v);
            }
          });
          if(values.length){
            return values.join(', ')
          }else{
            return '[Błąd] Zła wartość: '+value;
          }
        }

      }else if(attribute?.type === 'SELECT'){
        return attribute.parameters[value];
      }else if(attribute?.type === 'CHECKBOX'){
        if(value != null){
          return value ? 'Tak' : 'Nie';
        }
      }else{
        return value;
      }
    },
    async uploadFile(action:any) {
      var tempFiles = new FormData();
      tempFiles.append('file', this.file);
      tempFiles.append('removeOldAssets', this.removeOldAssets);
      await storeImportAsset(tempFiles)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(async () => {
          this.file = null;
          await this.loadAssets(false);
          await this.loadConfig();
        });
    },

    async uploadImageFile(action:any) {
      this.uploadImagesInfo.uploading = true;
      var queue = new TaskQueue(Promise, 1);
      for (const file of this.images) {
        var tempFiles = new FormData();
        tempFiles.append('image', file)
        queue.wrap(await new Promise(resolve => {
          var imageSrc = '';

          if (FileReader) {
            var fr = new FileReader();
            fr.onload = function () {
              imageSrc = fr.result;
            }
            fr.readAsDataURL(file);
          }
            storeImagesImportedAssets(tempFiles).then(() => {
              this.uploadImagesInfo.toUpload = this.uploadImagesInfo.toUpload+1;
              this.uploadedImages.push({isError:false, name:file.name, url: imageSrc, message:this.$t('importAsset.message.successUploadImage')});
              resolve();
            }).catch((error) => {
              this.uploadImagesInfo.toUpload = this.uploadImagesInfo.toUpload+1;
              this.uploadedImages.push({isError:true, name:file.name, url: imageSrc, message:this.$t(error?.response?.data?.message)});
              resolve();
            })
          })
        )
      };

      Promise.all(queue).then(() => {
        this.loadAssets(true);
        this.uploadImagesInfo.toUpload = 1;
        this.uploadImagesInfo.uploading = false;
        this.images = [];
      }).catch((err) => {
        this.loadAssets(true);
        this.uploadImagesInfo.toUpload = 1;
        this.uploadImagesInfo.uploading = false;
        this.images = [];
      });
    },


    async confirmImport(action:any) {
      this.dialogs
        .confirm('Potwierdź import', 'Jeśli dane się zgadzają, możesz je przesłać do bazy danych. Tego procesu nie można cofnąć')
        .then(async () => {
          await confirmImportAsset({selectedAttributes: this.selectedAttributes}, this.importWarnings)
            .withLoader(action.loader)
            .execute()
            .then(() => {
              this.assetsDataSet.load();
            });
        });
    },



  },
});
</script>
<style>
.full-screen-mode{
  position: absolute; left: 0px; top: 0px; bottom: 0px; right: 0; background: white; z-index: 49; max-width: initial !important; padding: 40px; overflow: scroll;
}
</style>
