<template>
  <Field symbol="organization_id" class="pt-4 w-full">
    <ZLabel for="organization">
      {{ $t('asset.label.status') }}
    </ZLabel>
    <SelectField id="status" v-model="status" :options="assetStatuses" :translate="'asset.states.'" />
  </Field>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';
import SelectField from '@ui/SelectField.vue';
import type { KeyValue } from '@/types/Common';
import symbolsStore from '@/stores/symbols';

export default defineComponent({
  components: {
    SelectField,
    ZLabel,
    Field,
  },

  emits: ['update:newStatus'],

  data() {
    return {
      assetStatuses: {} as KeyValue,
      status: String,
    };
  },

  watch: {
    status(newVal: string) {
      this.$emit('update:newStatus', newVal);
    },
  },

  async mounted() {
    const symbols = symbolsStore();
    const allStatuses = await symbols.get('asset_statuses');
    this.assetStatuses = Object.fromEntries(Object.entries(allStatuses).filter(([key]) => key !== 'all'));
  },
});
</script>
