<template>
  <ZSelect v-model="model" v-model:open="opened" class="w-full" @update:model-value="selectOption">
    <ZSelectTrigger class="w-full" :class="{ 'opacity-70 pointer-events-none': isDisabled }">
      <div class="flex flex-row gap-2 truncate">
        <ZSelectValue :placeholder="placeholder" />
      </div>
    </ZSelectTrigger>
    <ZSelectContent>
      <div v-if="searchable" class="flex flex-row border border-gray-200 rounded py-1 m-1">
        <MagnifyingGlassIcon class="icon sm mx-2" />
        <input v-model="filter" class="flex-1 outline-none" @keydown.stop="onkeydown()" />
      </div>
      <a
        v-if="unselectable && model != '' && model != null"
        href="#"
        class="flex flex-row items-center gap-2 text-sm p-1 py-2 hover:bg-gray-100 rounded mx-1 border-b"
        @click.prevent="clearSelection"
      >
        <XCircleIcon class="icon sm" />
        {{ $t('common.clear-selection') }}
      </a>
      <ZSelectGroup>
        <ZSelectItem v-for="item in items" :key="item.value" :value="item.value">
          <span v-if="item.html == null">
            {{ item.label }}
          </span>
          <span v-else v-html="item.html"></span>
        </ZSelectItem>
      </ZSelectGroup>
    </ZSelectContent>
  </ZSelect>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import {
  ZSelect,
  ZSelectContent,
  ZSelectGroup,
  ZSelectItem,
  ZSelectTrigger,
  ZSelectValue,
} from '@shadcn/components/ui/select';
import { MagnifyingGlassIcon } from '@modules/@heroicons/vue/24/solid';
import { XCircleIcon } from '@modules/@heroicons/vue/24/outline';

export default defineComponent({
  components: {
    // icons
    MagnifyingGlassIcon,
    XCircleIcon,
    // shadcn components
    ZSelect,
    ZSelectContent,
    ZSelectGroup,
    ZSelectItem,
    ZSelectTrigger,
    ZSelectValue,
  },

  props: {

    options: {
      type: Object,
      required: true,
    },

    modelValue: {
      type: [String, Number, null] as PropType<string | number | null>,
      required: false,
      default: null,
    },

    format: {
      type: String,
      required: false,
      default: 'simple',
    },

    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    translate: {
      type: String,
      required: false,
      default: null,
    },

    unselectable: {
      type: Boolean,
      required: false,
      default: false,
    },

    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },

    sorted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      model: this.modelValue?.toString(),
      isDisabled: this.disabled,
      filter: '' as string,
      opened: false as boolean,
    };
  },

  computed: {
    items() {
      let result = [];
      let label = '' as string;
      let keywords = '' as string;
      let html = null as string | null;

      for (let key in this.options) {
        if (this.format == 'simple') {
          if (this.translate == undefined) {
            label = this.options[key];
            keywords = label;
          } else {
            label = this.$t(this.translate + this.options[key]);
            keywords = label;
          }
        } else {
          try {
            let json = JSON.parse(this.options[key]);
            keywords = json.keywords;
            label = json.label;
            html = json.html;
          } catch(e: any) {
            keywords = this.options[key];
            label = this.options[key];
            html = this.options[key];
          }
        }

        if (this.filter != '') {
          if (keywords.toUpperCase().indexOf(this.filter.toUpperCase(), 0) == -1) continue;
        }

        result.push({value: key.toString(), label: label, html: html});
      }

      if (this.sorted) {
        result.sort((a, b) =>
          a.label.toUpperCase() > b.label.toUpperCase() ? 1 : a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 0,
        );
      }

      return result;
    },
  },

  watch: {
    modelValue() {
      if (this.modelValue == undefined || this.options[this.modelValue?.toString()] != undefined) {
        this.model = this.modelValue?.toString();
      } else {
        this.model = '';
        this.$emit('update:modelValue', undefined);
      }
    },

    options() {
      if (this.modelValue && this.options[this.modelValue?.toString()] == undefined) {
        this.model = '';
        this.$emit('update:modelValue', undefined);
      }
    },

    disabled(newValue) {
      this.isDisabled = newValue;
    },

    opened(newValue) {
      if (newValue) this.filter = '';
    },
  },

  methods: {
    selectOption(index: string) {
      this.$emit('update:modelValue', index);
    },

    onkeydown() {},

    clearSelection() {
      this.model = '';
      this.opened = false;
      this.$emit('update:modelValue', undefined);
    },
  },
});
</script>
