<template>
  <ZDialog v-model:open="isDialogOpen" class="md:max-h-[90vh]">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('contracts.label.add_contract_actions') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('contracts.message.add_contract_actions') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <div class="lg:max-h-[70vh] lg:overflow-auto">
          <FormValidator ref="form" :errors="errors" class="grid gap-4 py-4">
            <ManageActionsForm :action="action" />
          </FormValidator>
        </div>
        <ZDialogFooter>
          <Action @proceed="update">{{ $t('contracts.command.save_changes') }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import Action from '@ui/Action.vue';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import ManageActionsForm from '@ui/Contracts/ManageActionsForm.vue';
import ContractActionStoreRequest = App.Data.ContractActionStoreRequest;
import { storeContractAction } from '@/api/contractAction';
import { inject } from '@modules/vue';
import { HttpStatusCode } from '@modules/axios';
import { ValidationErrors } from '@/types/ValidationErrors';

export default defineComponent({
  components: {
    ManageActionsForm,
    ZDialogContent,
    IconAlert,
    ZDialogFooter,
    ZDialogTitle,
    FormValidator,
    Action,
    ZDialogDescription,
    ZDialog,
    ZDialogHeader,
  },

  props: {
    contractId: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      state: 'loading' as ComponentLoadState,
      action: {} as ContractActionStoreRequest,
      errors: null as null | ValidationErrors,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) {
        this.$router.back();
      }
    },

    'action.action_id'() {
      this.errors = {};
    },
  },

  async mounted() {
    try {
      this.state = 'loaded';
    } catch (error) {
      this.state = 'error';
    }
  },

  methods: {
    update() {
      storeContractAction(this.contractId, this.action)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('assetModels.message.model_saved'),
          });
          this.$emit('saved');
        })
        .catch(error => {
          if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
            this.errors = error.response.data.errors;
          }
        });
    },
  },
});
</script>
