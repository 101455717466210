import { RouteRecordRaw } from 'vue-router';
import { ROUTE } from '@/router/routeNames';
import Categories from '@views/Categories/Categories.vue';
import EditCategoryDialog from '@views/Categories/EditCategoryDialog.vue';
import NewCategoryDialog from '@views/Categories/NewCategoryDialog.vue';
import NewCategoryAttribute from '@views/Categories/NewCategoryAttribute.vue';
import EditCategoryAttribute from '@views/Categories/EditCategoryAttribute.vue';
import {SYMBOL} from "@/types/symbols";

export const categoryRoutes: RouteRecordRaw[] = [
  {
    path: 'categories',
    name: 'category.layout',
    redirect: () => {
      return { name: ROUTE.CATEGORY_LIST };
    },
    meta: {
      breadcrumbs: true,
      title: 'category.label.list_title',
      permission: [{operator: true, type: SYMBOL.PERMISSION.SYS_CATEGORY_MANAGER}],
    },
    children: [
      {
        path: '',
        component: Categories,
        name: ROUTE.CATEGORY_LIST,
        children: [
          {
            path: 'add',
            component: NewCategoryDialog,
            name: ROUTE.CATEGORY_ADD,
            meta: {
              breadcrumbs: true,
            },
          },
          {
            path: ':categoryId/edit',
            component: EditCategoryDialog,
            name: ROUTE.CATEGORY_EDIT,
            props: true,
            meta: {
              breadcrumbs: true,
            },
          },
          {
            path: 'sub-category-add/:parentCategoryId',
            component: NewCategoryDialog,
            name: ROUTE.CATEGORY_SUB_ADD,
            props: true,
            meta: {
              breadcrumbs: true,
              title: 'category.label.create_category',
            },
          },
        ],
      },
      {
        path: ':categoryId/category-attribute-add',
        component: NewCategoryAttribute,
        name: ROUTE.CATEGORY_ATTRIBUTE_ADD,
        props: true,
        meta: {
          breadcrumbs: true,
          title: 'category.label.create_category_attribute',
        },
      },
      {
        path: ':categoryAttributeId/category-attribute-edit',
        component: EditCategoryAttribute,
        name: ROUTE.CATEGORY_ATTRIBUTE_EDIT,
        props: true,
        meta: {
          breadcrumbs: true,
          title: 'category.label.edit_category_attribute',
        },
      },
    ],
  },
];
