<template>
  <div class="md:bg-gray-100 absolute h-full w-full overflow-auto">
    <div>
      <img src="@pub/images/logo.png" title="Logo ZWD" class="max-w-32 mt-20 md:my-16 m-auto" />
      <router-view></router-view>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
