<template>
  <ZDialog>
    <ZDialogTrigger as-child>
      <a class="text-sm cursor-pointer">{{ $t('auth.login.resetPassword') }}</a>
    </ZDialogTrigger>
    <ZDialogContent class="sm:max-w-[425px]">
      <div v-if="!success" class="grid gap-4">
        <ZDialogHeader>
          <ZDialogTitle>{{ $t('auth.resetPasswordDialog.header') }}</ZDialogTitle>
        </ZDialogHeader>
        <ZDialogDescription>{{ $t('auth.resetPasswordDialog.text') }}</ZDialogDescription>
        <FormValidator :errors="errors">
          <div>
            <FormField symbol="email">
              <ZLabel>{{ $t('auth.resetPasswordDialog.form.email') }}</ZLabel>
              <ZInput v-model="form.email" type="email" />
            </FormField>
            <ZDialogFooter class="mt-5">
              <ZDialogClose as-child>
                <ZButton variant="outline">{{ $t('common.cancel') }}</ZButton>
              </ZDialogClose>
              <ZButton @click.prevent="resetPassword">{{ $t('auth.resetPasswordDialog.form.button') }}</ZButton>
            </ZDialogFooter>
          </div>
        </FormValidator>
      </div>
      <div v-else class="grid gap-4">
        <h3 class="scroll-m-20 text-xl font-semibold text-center">
          {{ $t('auth.resetPasswordDialog.success.header') }}
        </h3>
        <p class="text-sm text-center">{{ $t('auth.resetPasswordDialog.success.text') }}</p>
      </div>
    </ZDialogContent>
  </ZDialog>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { postForgotPassword } from '../api';
import loaderStore from '../stores/loader';
import { ZInput } from '@shadcn/components/ui/input';
import { ZButton } from '@shadcn/components/ui/button';
import { ZLabel } from '@shadcn/components/ui/label';
import FormValidator from '../components/FormValidator.vue';
import FormField from '../components/FormField.vue';
import { HttpStatusCode } from 'axios';
import { ValidationErrors } from '../types/ValidationErrors';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
  ZDialogTrigger,
  ZDialogClose,
} from '@shadcn/components/ui/dialog';

export default defineComponent({
  components: {
    ZInput,
    ZButton,
    ZLabel,
    FormValidator,
    FormField,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
    ZDialogTrigger,
    ZDialogClose,
  },

  data() {
    return {
      success: false,
      errors: null as null | ValidationErrors,
      form: {
        email: '',
      } as App.Data.PasswordResetLinkRequestData,
    };
  },

  methods: {
    resetPassword() {
      const { startLoading, stopLoading } = loaderStore();
      startLoading();
      postForgotPassword(this.form)
        .then(() => {
          this.success = true;
        })
        .catch(error => {
          if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
            this.errors = error.response.data?.errors;
          }
        })
        .finally(() => stopLoading());
    },
  },
});
</script>
