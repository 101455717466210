import type { RouteRecordRaw } from 'vue-router';
import { ROUTE } from './routeNames';
import Inventories from '@views/Inventory/Inventories.vue';
import InventoryDetails from '@views/Inventory/InventoryDetails.vue';
import NewInventoryDialog from '@views/Inventory/NewInventoryDialog.vue';
import {SYMBOL} from "@/types/symbols";

export const inventoryRoutes: RouteRecordRaw[] = [
  {
    path: 'inventory',
    name: 'inventory.layout',
    redirect: () => {
      return { name: ROUTE.INVENTORY_LIST };
    },
    meta: {
      breadcrumbs: true,
      title: 'inventory.label.list_title',
      feature: SYMBOL.FEATURES.INVENTORY,
    },
    children: [
      {
        path: '',
        component: Inventories,
        name: ROUTE.INVENTORY_LIST,
        children: [
          {
            path: 'add',
            component: NewInventoryDialog,
            name: ROUTE.INVENTORY_ADD,
          },
        ],
      },
      {
        path: ':inventoryId',
        component: InventoryDetails,
        name: ROUTE.INVENTORY_DETAILS,
        meta: {
          breadcrumbs: true,
          title: 'inventory.label.details_title',
        },
        props: true,
        children: [],
      },
    ],
  },
];
