<template>
  <transition name="fade">
    <div v-if="status" class="absolute left-0 right-0 top-0 bottom-0 bg-white bg-opacity-75 z-loader flex items-center">
      <img src="@pub/images/loader.gif" title="Logo ZWD" class="max-w-14 my-20 m-auto" />
    </div>
  </transition>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import loaderStore from '../stores/loader';

export default defineComponent({
  data() {
    return {
      status: false,
    };
  },

  mounted() {
    const store = loaderStore();
    store.$subscribe((mutation, state) => {
      this.status = state.status;
    });
  },
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
