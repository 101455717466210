<template>
  <div>
    <div class="flex max-w-[80rem] w-full min-h-[400px] m-auto bg-white md:rounded-xl overflow-hidden mb-20">
      <div class="hidden md:block md:w-1/2 relative">
        <img
          class="absolute object-cover w-full h-full right-0 bottom-0 top-0 left-0"
          src="@pub/images/login-background.jpg"
        />
      </div>
      <div class="w-full md:w-1/2 pt-10 p-8 md:p-16 flex justify-center items-center">
        <div class="w-full max-w-[350px]">
          <div class="flex flex-col gap-4">
            <h3 class="scroll-m-20 text-2xl font-semibold text-center">{{ name }}</h3>
            <p class="mb-5 text-center">{{ message }}</p>
            <ZButton to="/app" @click="goToDashboard">
              {{ $t('messages.button') }}
            </ZButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import router from '../router';
import { ZButton } from '@shadcn/components/ui/button';
import { ROUTE } from '@/router/routeNames';

export default defineComponent({
  components: { ZButton },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      name: '',
      message: '',
    };
  },

  mounted() {
    const message = this.$t('messages.' + this.type);
    if (message) {
      this.name = this.$t(message + '.name');
      this.message = this.$t(message + '.message');
    } else {
      router.push({ name: ROUTE.DASHBOARD });
    }
  },

  methods: {
    goToDashboard() {
      this.$router.replace({ name: ROUTE.DASHBOARD });
    },
  },
});
</script>
