<template>
  <div class="flex items-center">
    <div
      class="flex overflow-hidden items-center gap-4 md:ml-6 hover:bg-gray-50 p-2 rounded-sm cursor-pointer"
      @click="openSelectModal()"
    >
      <div>
        <BuildingOfficeIcon class="icon md hidden md:block" />
      </div>
      <div class="flex flex-col max-w-[70%] md:max-w-full">
        <p class="truncate font-bold">{{ organization.name }}</p>
        <p v-if="branch && branch.name" class="truncate">{{ $t('organization.label.branch') }} {{ branch.name }}</p>
        <p v-else class="truncate">{{ $t('organization.label.select_branch') }}</p>
      </div>
      <div class="p-2">
        <ChevronDownIcon class="w-[15px]" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import { BuildingOfficeIcon, ChevronDownIcon } from '@heroicons/vue/24/outline';
import { Dialogs } from '@/types/Dialogs';
import { getBranch, getOrganization } from '@/api/organization';
import { mapState } from 'pinia';
import useUserStore from '@/stores/auth';
import BranchData = App.Data.BranchData;
import OrganizationData = App.Data.OrganizationData;
import useAccessStore from "@/stores/access";

export default defineComponent({
  components: {
    ChevronDownIcon,
    BuildingOfficeIcon,
    // icons
    // shadcn components
  },

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
    };
  },

  data() {
    return {
      branch: {} as BranchData,
      organization: {} as OrganizationData,
    };
  },

  computed: {
    ...mapState(useUserStore, ['chosenOrganization', 'chosenBranch']),
    access: () => useAccessStore(),
  },

  watch: {
    chosenBranch() {
      if (this.chosenBranch) {
        this.getBranch();
      } else {
        this.branch = {} as BranchData;
      }
    },

    chosenOrganization() {
      if (this.chosenBranch) {
        this.getBranch();
      } else {
        this.branch = {} as BranchData;
      }
      this.getOrganization();
    },
  },

  created() {
    if (this.chosenBranch) {
      this.getBranch();
    }
    this.getOrganization();
  },

  methods: {
    async getBranch() {
      const response = await getBranch(String(this.chosenBranch), String(this.chosenOrganization)).execute();
      this.branch = response.data;
    },

    async getOrganization() {
      if (this.chosenOrganization) {
        const response = await getOrganization(String(this.chosenOrganization)).execute();
        this.organization = response.data;
        this.access.setOrganization(this.organization);
      }
    },

    openSelectModal() {
      this.dialogs.selectBranch(this.organization.id, this.branch.id).then((ids: number[]) => {
        const { saveChosenBranch, saveChosenOrganization } = useUserStore();
        saveChosenOrganization(ids[0]);
        saveChosenBranch(ids[1]);
      });
    },
  },
});
</script>
