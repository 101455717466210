<template>
  <div class="lg:flex md:mt-10">
    <div class="lg:w-1/4 my-4 lg:my-0">
      <div class="flex lg:grid md:mr-8 overflow-auto lg:overflow-hidden p-3 lg:p-0">
        <ZButton variant="ghost" class="justify-start" as-child>
          <router-link to="personal" active-class="bg-muted">{{ $t('account.personal.header') }}</router-link>
        </ZButton>
        <ZButton variant="ghost" class="justify-start" as-child>
          <router-link to="security" active-class="bg-muted">{{ $t('account.security.header') }}</router-link>
        </ZButton>
        <ZButton variant="ghost" class="justify-start" as-child>
          <router-link to="settings" active-class="bg-muted">{{ $t('account.settings.header') }}</router-link>
        </ZButton>
        <ZButton variant="ghost" class="justify-start" as-child>
          <router-link to="password" active-class="bg-muted">{{ $t('account.password.header') }}</router-link>
        </ZButton>
      </div>
    </div>
    <div class="md:w-3/4">
      <div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { ZButton } from '@shadcn/components/ui/button';

export default defineComponent({
  components: {
    ZButton,
  },
});
</script>
