<template>
  <div>
    <template v-if="state == 'loading'">
      <IconAlert type="loading">
        {{ $t('common.action.loading') }}
      </IconAlert>
    </template>
    <template v-else-if="state == 'loaded' && organization && countries && regions && legalForms">
      <div class="py-4">
        <div class="w-full border rounded border-green-600 p-4 mb-4">
          <div class="grid grid-cols-2 lg:grid-cols-5 gap-4 flex-1">
            <div class="i-block col-span-2">
              <span class="i-label">{{ $t('organization.label.address_data') }}</span>
              <span class="i-value">
                {{ organization.postal }} {{ organization.city }}, {{ organization.street }}
                {{ organization.building_number }} {{ organization.apartment_number ?? '' }}
              </span>
            </div>
            <div class="i-block">
              <span class="i-label">{{ $t('organization.label.legal_form') }}</span>
              <span class="i-value">{{ $t('symbols.' + legalForms[organization.legal_form?.toString()]) }}</span>
            </div>
            <div class="i-block">
              <span class="i-label">{{ $t('organization.label.country') }}</span>
              <span class="i-value">{{ countries[organization.country_id?.toString()] }}</span>
            </div>
            <div class="i-block">
              <span class="i-label">{{ $t('organization.label.region') }}</span>
              <span class="i-value">{{ regions[organization.region_id?.toString()] }}</span>
            </div>
            <div class="i-block">
              <span class="i-label">{{ $t('organization.label.tax_number') }}</span>
              <span class="i-value">{{ organization.tax_number }}</span>
            </div>
            <div class="i-block">
              <span class="i-label">{{ $t('organization.label.registration_number') }}</span>
              <span class="i-value">{{ organization.registration_number }}</span>
            </div>
            <div class="i-block">
              <span class="i-label">{{ $t('organization.label.contact_person') }}</span>
              <span class="i-value">{{ organization.contact_person ?? '-' }}</span>
            </div>
            <div class="i-block">
              <span class="i-label">{{ $t('organization.label.email') }}</span>
              <span class="i-value">{{ organization.contact_email ?? '-' }}</span>
            </div>
            <div class="i-block">
              <span class="i-label">{{ $t('organization.label.phone') }}</span>
              <span class="i-value">{{ organization.contact_phone ?? '-' }}</span>
            </div>
          </div>
          <div class="pt-4">
            <span class="text-sm">{{ $t('organization.label.registration_link') }}:</span>
            <div class="flex items-center pt-1">
              <span class="text-sm mr-4">{{ registrationLink }}</span>
              <a
                href="#"
                class="border-l hover:bg-primary hover:text-white border rounded"
                @click.prevent="copyLinkToClipboard()"
              >
                <ClipboardIcon class="icon sm"/>
              </a>
            </div>
            <div class="flex flex-col gap-2 col-span-4">
              <div v-if="access.operator()" class="py-1 text-sm opacity-60">
                {{ $t('userAccount.label.operator_notes') }}:
              </div>
              <div
                v-if="access.operator()"
                class="p-4 align-middle flex flex-row gap-2 items-center bg-gray-100 rounded-lg p-2 text-sm"
              >
                {{ organization.operator_notes }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col lg:flex-row items-center gap-2 col-span-4">
          <div class="pr-4">
            {{ $t('organization.label.user_count') }}: <b>{{ organization.user_count }}</b>
          </div>
          <div
            v-if="organization && organization.curators"
            class="flex flex-col lg:flex-row items-center gap-2 w-full p-2"
          >
            <span class="min-w-max">{{ $t('organization.label.curators') }}:</span>
            <span v-if="organization.curators.length == 0" class="opacity-50">{{
                $t('organization.message.no_curators')
              }}</span>
            <div class="flex justify-between items-center w-full">
              <div class="max-w-[80%]">
                <Popover v-for="curator in organization.curators" :key="curator.id">
                  <PopoverTrigger as-child>
                <span class="inline-block px-2 rounded-sm border text-sm cursor-pointer m-1"
                >{{ curator.first_name }} {{ curator.last_name }}</span
                >
                  </PopoverTrigger>
                  <PopoverContent class="flex gap-2 flex-col text-sm overflow-hidden">
                    <div class="flex flex-row break-words gap-2">
                      <UserCircleIcon class="icon sm"/>
                      {{ curator.first_name }} {{ curator.last_name }}
                    </div>
                    <div class="flex flex-row break-words gap-2">
                      <AtSymbolIcon class="icon sm"/>
                      <span class="break-all">
                    {{ curator.email }}
                  </span>
                    </div>
                    <div class="flex flex-row break-words gap-2">
                      <PhoneIcon class="icon sm"/>
                      {{ curator.phone }}
                    </div>
                    <ZButton v-if="access.operator()" variant="destructive" class="mt-2"
                             @click="removeCurator(curator.id)"
                    >{{ $t('organization.command.remove_curator') }}
                    </ZButton>
                  </PopoverContent>
                </Popover>
              </div>
              <ZButton
                v-if="access.operator()"
                variant="outline"
                @click.prevent="openAddOperatorDialog()"
              >
                <UserPlusIcon class="icon sm mr-2"/>
                {{ $t('organization.label.add_curator') }}
              </ZButton>
            </div>

            <SelectUserDialog
              ref="addOperatorDialog"
              :excludes="organization.curators"
              :prompt="$t('organization.label.add_curator_dialog')"
              @selected="addOperator"
            ></SelectUserDialog>
          </div>
        </div>
      </div>
      <MountedTeleport to="#toolbox" v-if="access.operator()">
        <DropdownMenu>
          <DropdownMenuTrigger as-child>
            <ZButton class="w-full">
              <BuildingOfficeIcon class="icon sm"/>
              <span class="ml-2">
                {{ $t('organization.command.manage') }}
              </span>
              <ChevronDownIcon class="icon xs ml-2"/>
            </ZButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent class="w-56">
            <DropdownMenuGroup>
              <DropdownMenuItem @click="editOrganization()">
                <span>{{ $t('organization.command.edit_organization_data') }}</span>
              </DropdownMenuItem>
              <DropdownMenuSeparator/>
              <DropdownMenuItem @click="manageOrganization()">
                <span>{{ $t('organization.command.manage_organization') }}</span>
              </DropdownMenuItem>
              <DropdownMenuSeparator/>
              <DropdownMenuItem
                v-if="access.operator()"
                :disabled="!organization.can_be_deleted"
                @click="requestOrganizationDelete"
              >
                <span>{{ $t('organization.command.delete_organization') }}</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </MountedTeleport>
      <div class="grid grid-cols-2 lg:grid-cols-5 gap-4 items-stretch">
        <div class="border rounded border-green-600 p-4 col-span-2">
          <h2 class="font-bold mb-4 border-b pb-1 text-md">
            {{ $t('organization.label.organizational_structure') }}
          </h2>
          <IconAlert v-if="branchToTransfer != null" type="info" class="mb-4">
            <div class="flex flex-row gap-4 items-center">
              <div class="flex flex-col">
                <span
                >{{ $t('organization.label.transfer_branch') }} <b>{{ branchToTransfer.name }}</b></span
                >
                {{ $t('organization.message.select_destination_for_branch') }}
              </div>
              <Action @proceed="cancelTransfer()">{{ $t('organization.command.cancel_transfer') }}</Action>
            </div>
          </IconAlert>

          <div
            class="flex justify-between items-center hover:bg-accent p-1"
            :class="{ 'bg-accent': selectedBranch == null }"
            @click.prevent="branchToTransfer == null && selectBranch(-1)"
          >
            <div class="flex gap-2">
              <BuildingOfficeIcon class="icon sm"/>
              <b>{{ organization.name }}</b>
            </div>
            <a
              v-if="branchToTransfer == null && access.operator()"
              href="#"
              class="text-sm rounded border px-1 hover:bg-primary hover:text-white"
              @click.prevent="addBranch(null)"
            >{{ $t('organization.command.add_branch') }}</a
            >

            <a
              v-if="canTransferToDestination(null) && access.operator()"
              href="#"
              class="border p-1 text-xs flex flex-row gap-2"
              @click.prevent="transferBranchTo(null)"
            >
              <ArrowsRightLeftIcon class="icon xs"/>
              {{ $t('organization.command.transfer_here') }}</a
            >
          </div>

          <div
            v-for="(item, index) in branches"
            :key="index"
            class="flex group gap-2 pl-2 cursor-pointer py-1 hover:bg-accent justify-between items-center"
            :class="{
              'bg-accent': selectedBranch && selectedBranch.id == item.id,
              'mt-2': item.branch_type == 'location',
            }"
            @click.prevent="branchToTransfer == null && selectBranch(index)"
          >
            <div
              class="flex gap-2 items-center w-full"
              :style="{ 'padding-left': (item.depth != null ? (item.depth - 1) * 20 : 0) + 'px' }"
            >
              <MapPinIcon v-if="item.branch_type == 'location'" class="icon sm"/>
              <BriefcaseIcon v-else class="icon sm"/>
              <div class="leading-4 flex-1">
                <span class="pr-1">
                  #{{item.id}}
                </span>
                {{ item.name }}
                <div v-if="item.branch_type == 'location'">
                  <span class="text-xs"
                  >{{ item.postal }} {{ item.city }}, {{ item.street }} {{ item.building_number }}
                    {{ item.apartment_number ?? '' }}</span
                  >
                </div>
              </div>
              <a
                v-if="branchToTransfer == null && access.operator()"
                href="#"
                class="justify-self-end text-sm hidden group-hover:block rounded border px-1 hover:bg-primary hover:text-white"
                @click.prevent="addBranch(item.id!)"
              >{{ $t('organization.command.add_sub_branch') }}</a
              >
            </div>
            <a
              v-if="canTransferToDestination(item)"
              href="#"
              class="border p-1 text-xs flex flex-row gap-2"
              @click.prevent="transferBranchTo(item)"
            >
              <ArrowsRightLeftIcon class="icon xs"/>
              {{ $t('organization.command.transfer_here') }}</a
            >
            <span
              v-if="branchToTransfer != null && branchToTransfer.id == item.id"
              class="text-xs flex gap-2 items-center opacity-70"
            ><ArrowsRightLeftIcon class="icon xs"/> {{ $t('organization.label.branch_transferred') }}</span
            >
            <DropdownMenu v-if="branchToTransfer == null && access.operator()">
              <DropdownMenuTrigger as-child class="flex-row gap-1">
                <div>
                  <EllipsisHorizontalCircleIcon
                    class="icon sm cursor-pointer rounded-full mr-2 hover:bg-primary hover:text-white"
                  ></EllipsisHorizontalCircleIcon>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent class="w-56">
                <DropdownMenuGroup>
                  <DropdownMenuItem class="gap-2" @click.prevent="addBranch(item.id!)">
                    <DocumentPlusIcon class="icon sm mr-2"/>
                    {{ $t('organization.command.add_sub_branch') }}
                  </DropdownMenuItem>
                  <DropdownMenuItem :disabled="!canMove(index, 'up')" class="gap-2" @click.prevent="moveUp(item)">
                    <ChevronUpIcon class="icon sm mr-2"/>
                    {{ $t('organization.command.move_up') }}
                  </DropdownMenuItem>
                  <DropdownMenuItem :disabled="!canMove(index, 'down')" class="gap-2" @click.prevent="moveDown(item)">
                    <ChevronDownIcon class="icon sm mr-2"/>
                    {{ $t('organization.command.move_down') }}
                  </DropdownMenuItem>
                  <DropdownMenuSeparator/>
                  <DropdownMenuItem class="gap-2" @click.prevent="moveOut(item)">
                    <ArrowsRightLeftIcon class="icon sm mr-2"/>
                    {{ $t('organization.command.move') }}
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <IconAlert v-if="branches?.length == 0" type="info" class="my-2">
            {{ $t('organization.message.organization_no_children') }}
            <a href="#" class="link" @click.prevent="addBranch(null)">{{
                $t('organization.command.add_first_branch')
              }}</a>
          </IconAlert>
        </div>
        <div class="col-span-2 lg:col-span-3">
          <Tabs v-if="selectedBranch != null" default-value="general" class="w-full">
            <div class="flex flex-row justify-between items-center py-4">
              <h3 class="font-bold text-lg flex flex-row gap-2">
                <MapPinIcon v-if="selectedBranch.branch_type == 'location'" class="icon md"/>
                <BriefcaseIcon v-else class="icon md"/>
                {{ selectedBranch.name }}
              </h3>
              <DropdownMenu v-if="access.operator()">
                <DropdownMenuTrigger as-child>
                  <ZButton class="border p-1 text-sm flex flex-row gap-1 px-4">
                    <MapPinIcon v-if="selectedBranch.branch_type == 'location'" class="icon xs"/>
                    <BriefcaseIcon v-else class="icon sm"/>
                    <span class="ml-2">
                      {{ $t('organization.command.manage') }}
                    </span>
                    <ChevronDownIcon class="icon xs ml-2"/>
                  </ZButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent class="w-56">
                  <DropdownMenuGroup>
                    <DropdownMenuItem @click="editBranch(selectedBranch.id!)">
                      <span>{{ $t('organization.command.edit_branch_data') }}</span>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator/>
                    <DropdownMenuItem :disabled="!selectedBranch.can_be_deleted" @click="requestBranchDelete">
                      <span>{{ $t('organization.command.delete_branch') }}</span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator/>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <div v-if="selectedBranch.branch_type == 'location'" class="flex flex-col gap-1 items-start pb-4">
              <span class="i-label">{{ $t('organization.label.address_data') }}</span>
              <span class="i-value">
                {{ selectedBranch.postal }} {{ selectedBranch.city }}, {{ selectedBranch.street }}
                {{ selectedBranch.building_number }}
              </span>
            </div>
            <TabsList class="grid w-full grid-cols-2">
              <TabsTrigger value="general">
                {{ $t('organization.label.general') }}
              </TabsTrigger>
              <TabsTrigger value="permissions">
                {{ $t('organization.label.permissions') }}
              </TabsTrigger>
            </TabsList>
            <TabsContent v-if="branchStats.usage" value="general" class="border rounded p-4">
              <div class="border rounded p-4 grid grid-cols-2 lg:grid-cols-5 gap-4 mt-2">
                <span class="stat-label col-span-2 lg:col-span-1">{{ $t('organization.label.resources_status') }}</span>
                <div class="stat-box">
                  {{ $t('organization.label.in_use') }}
                  <b>{{ branchStats.usage[0].value ?? 0 }}</b>
                </div>
                <div class="stat-box">
                  {{ $t('organization.label.unused') }}
                  <b>{{ branchStats.usage[1].value ?? 0 }}</b>
                </div>
                <div class="stat-box">
                  {{ $t('organization.label.disposed') }}
                  <b>{{ branchStats.usage[2].value ?? 0 }}</b>
                </div>
                <div class="stat-box">
                  {{ $t('organization.label.sold') }}
                  <b>{{ branchStats.usage[3].value ?? 0 }}</b>
                </div>
              </div>
              <div class="border rounded p-4 grid grid-cols-2 lg:grid-cols-5 gap-4 mt-2">
                <span class="stat-label col-span-2 lg:col-span-1">{{ $t('organization.label.contracts') }}</span>
                <div class="stat-box">
                  {{ $t('organization.label.contracts_waiting') }}
                  <b>{{ branchStats.usage[4].value ?? 0 }}</b>
                </div>
                <div class="stat-box">
                  {{ $t('organization.label.contracts_in_progress') }}
                  <b>{{ branchStats.usage[5].value ?? 0 }}</b>
                </div>
                <div class="stat-box">
                  {{ $t('organization.label.contracts_on_hold') }}
                  <b>{{ branchStats.usage[6].value ?? 0 }}</b>
                </div>
                <div class="stat-box">
                  {{ $t('organization.label.contracts_delayed') }}
                  <b>{{ branchStats.usage[7].value ?? 0 }}</b>
                </div>
              </div>
              <div class="border rounded p-4 flex flex-col lg:grid lg:grid-cols-4 gap-4 mt-2">
                <span class="stat-label lg:mr-9 col-span-2 lg:col-span-1">{{
                    $t('organization.label.carbon_footprint')
                  }}</span>
                <div class="stat-box">
                  {{ $t('organization.label.footprint_total') }}
                  <b>{{ readableCarbonValue(branchStats.usage[8].value ?? 0) }}</b>
                </div>
                <div class="stat-box">
                  {{ $t('organization.label.footprint_year') }}
                  <b>{{ readableCarbonValue(branchStats.usage[9].value ?? 0) }}</b>
                </div>
                <div class="stat-box">
                  {{ $t('organization.label.footprint_month') }}
                  <b>{{ readableCarbonValue(branchStats.usage[10].value ?? 0) }}</b>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="permissions" class="border rounded p-4">
              <ZTable v-if="branchPermissions.length > 0">
                <ZTableRow>
                  <ZTableHead></ZTableHead>
                  <ZTableHead>Użytkownik</ZTableHead>
                  <ZTableHead>Uprawnienia</ZTableHead>
                </ZTableRow>
                <ZTableRow v-for="(item, index) in branchPermissions" :key="index">
                  <ZTableCell class="w-6">
                    <Popover :key="item.id!">
                      <PopoverTrigger as-child>
                        <EllipsisHorizontalIcon class="icon sm cursor-pointer"/>
                      </PopoverTrigger>
                      <PopoverContent class="flex gap-2 flex-col text-sm">
                        <div class="flex flex-row break-words gap-2">
                          <UserCircleIcon class="icon sm"/>
                          {{ item.first_name }} {{ item.last_name }}
                        </div>
                        <div class="flex flex-row break-words gap-2">
                          <AtSymbolIcon class="icon sm"/>
                          {{ item.email }}
                        </div>
                        <div class="flex flex-row break-words gap-2">
                          <PhoneIcon class="icon sm"/>
                          {{ item.phone }}
                        </div>
                        <ZButton variant="secondary" class="mt-2" @click="showUser(item.id!.toString())"
                        >{{ $t('organization.command.user_details') }}
                        </ZButton>
                      </PopoverContent>
                    </Popover>
                  </ZTableCell>
                  <ZTableCell class="w-6">
                    {{ item.email }}
                  </ZTableCell>
                  <ZTableCell class="flex gap-2 flex-row">
                    <div
                      v-for="permission in removeDupes(item.permissions)"
                      :key="permission.id!.toString()"
                      class="inline-block px-2 rounded-sm border flex flex-row gap-1 text-sm"
                    >
                      {{ $t('organization.permission.' + permission.symbol) }}
                    </div>
                  </ZTableCell>
                </ZTableRow>
              </ZTable>
              <div v-else class="p-8 text-center flex flex-col gap-2 items-center">
                <ExclamationTriangleIcon class="icon lg"/>
                {{ $t('userAccount.message.data_not_found') }}
              </div>
            </TabsContent>
          </Tabs>

          <Tabs v-if="selectedBranch == null" default-value="general" class="w-full">
            <div class="flex flex-row justify-between items-center py-4">
              <h3 class="font-bold text-lg flex flex-row gap-2">
                <BuildingOfficeIcon class="icon sm"/>
                {{ organization.name }}
              </h3>
            </div>
            <TabsList class="grid w-full grid-cols-2">
              <TabsTrigger value="general">
                {{ $t('organization.label.general') }}
              </TabsTrigger>
              <TabsTrigger value="permissions">
                {{ $t('organization.label.users') }}
              </TabsTrigger>
            </TabsList>
            <TabsContent v-if="branchStats.usage" value="general" class="border rounded p-4">
              <div class="border rounded p-4 grid grid-cols-2 lg:grid-cols-5 gap-4 mt-2">
                <span class="stat-label col-span-2 lg:col-span-1">{{ $t('organization.label.resources_status') }}</span>
                <div class="stat-box">
                  {{ $t('organization.label.in_use') }}
                  <b>{{ branchStats.usage[0].value ?? 0 }}</b>
                </div>
                <div class="stat-box">
                  {{ $t('organization.label.unused') }}
                  <b>{{ branchStats.usage[1].value ?? 0 }}</b>
                </div>
                <div class="stat-box">
                  {{ $t('organization.label.disposed') }}
                  <b>{{ branchStats.usage[2].value ?? 0 }}</b>
                </div>
                <div class="stat-box">
                  {{ $t('organization.label.sold') }}
                  <b>{{ branchStats.usage[3].value ?? 0 }}</b>
                </div>
              </div>
              <div class="border rounded p-4 grid grid-cols-2 lg:grid-cols-5 gap-4 mt-2">
                <span class="stat-label col-span-2 lg:col-span-1">{{ $t('organization.label.contracts') }}</span>
                <div class="stat-box">
                  {{ $t('organization.label.contracts_waiting') }}
                  <b>{{ branchStats.usage[4].value ?? 0 }}</b>
                </div>
                <div class="stat-box">
                  {{ $t('organization.label.contracts_in_progress') }}
                  <b>{{ branchStats.usage[5].value ?? 0 }}</b>
                </div>
                <div class="stat-box">
                  {{ $t('organization.label.contracts_on_hold') }}
                  <b>{{ branchStats.usage[6].value ?? 0 }}</b>
                </div>
                <div class="stat-box">
                  {{ $t('organization.label.contracts_delayed') }}
                  <b>{{ branchStats.usage[7].value ?? 0 }}</b>
                </div>
              </div>
              <div class="border rounded p-4 flex flex-col lg:grid lg:grid-cols-4 gap-4 mt-2">
                <span class="stat-label lg:mr-9 col-span-2 lg:col-span-1">{{
                    $t('organization.label.carbon_footprint')
                  }}</span>
                <div class="stat-box">
                  {{ $t('organization.label.footprint_total') }}
                  <b>{{ readableCarbonValue(branchStats.usage[8].value ?? 0) }}</b>
                </div>
                <div class="stat-box">
                  {{ $t('organization.label.footprint_year') }}
                  <b>{{ readableCarbonValue(branchStats.usage[9].value ?? 0) }}</b>
                </div>
                <div class="stat-box">
                  {{ $t('organization.label.footprint_month') }}
                  <b>{{ readableCarbonValue(branchStats.usage[10].value ?? 0) }}</b>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="permissions" class="border rounded p-4">
              <ZTable>
                <ZTableRow>
                  <ZTableHead></ZTableHead>
                  <ZTableHead>Email</ZTableHead>
                  <ZTableHead>Imię Nazwisko</ZTableHead>
                  <ZTableHead>Stanowisko</ZTableHead>
                </ZTableRow>
                <ZTableRow v-for="(item, index) in usersDataSet.data" :key="index" class="cursor-pointer">
                  <ZTableCell>
                    <Popover :key="item.id">
                      <PopoverTrigger as-child>
                        <EllipsisHorizontalIcon class="icon sm cursor-pointer"/>
                      </PopoverTrigger>
                      <PopoverContent class="flex gap-2 flex-col text-sm">
                        <div class="flex flex-row break-all overflow-hidden gap-2">
                          <UserCircleIcon class="icon sm"/>
                          {{ item.first_name }} {{ item.last_name }}
                        </div>
                        <div class="flex flex-row break-all overflow-hidden gap-2">
                          <AtSymbolIcon class="icon sm"/>
                          {{ item.email }}
                        </div>
                        <div class="flex flex-row break-all overflow-hidden gap-2">
                          <PhoneIcon class="icon sm"/>
                          {{ item.phone }}
                        </div>
                        <ZButton variant="secondary" class="mt-2" @click="showUser(item.id!.toString())"
                        >{{ $t('organization.command.user_details') }}
                        </ZButton>
                      </PopoverContent>
                    </Popover>
                  </ZTableCell>
                  <ZTableCell>{{ item.email }}</ZTableCell>
                  <ZTableCell>{{ item.first_name + ' ' + item.last_name }}</ZTableCell>
                  <ZTableCell>{{ item.position }}</ZTableCell>
                </ZTableRow>
              </ZTable>
              <div v-if="usersDataSet.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
                <ExclamationTriangleIcon class="icon lg"/>
                {{ $t('userAccount.message.data_not_found') }}
              </div>
              <Paginator :data="usersDataSet"/>
            </TabsContent>
          </Tabs>
        </div>
      </div>
      <router-view></router-view>
    </template>
    <template v-else>
      <IconAlert type="error">
        {{ $t('common.errors.unexpected') }}
      </IconAlert>
    </template>
  </div>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import Navigation from '@/types/Navigation';
import OrganizationData = App.Data.OrganizationData;
import BranchData = App.Data.BranchData;
import CountryWithTranslationData = App.Data.CountryWithTranslationData;
import RegionWithTranslationData = App.Data.RegionWithTranslationData;
import {KeyValue} from '@/types/Common';
import {Dialogs} from '@/types/Dialogs';
import {
  getOrganization,
  getBranches,
  updateBranchOrder,
  updateBranchParent,
  changeBranchParentToRoot,
  deleteOrganization,
  deleteBranch,
  addCurator,
  removeCurator,
  getOrganizationStats,
  getBranchStats,
} from '@/api/organization';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '@shadcn/components/ui/tabs';
import {
  BuildingOfficeIcon,
  MapPinIcon,
  BriefcaseIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  DocumentPlusIcon,
  ArrowsRightLeftIcon,
  EllipsisHorizontalCircleIcon,
  ClipboardIcon,
  UserPlusIcon,
  UserCircleIcon,
  AtSymbolIcon,
  PhoneIcon,
  ExclamationTriangleIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/vue/24/outline';
import {ZButton} from '@shadcn/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@shadcn/components/ui/dropdown-menu';
import {ZTable, ZTableCell, ZTableHead, ZTableRow} from '@shadcn/components/ui/table';
import IconAlert from '@ui/IconAlert.vue';
import Action from '@ui/Action.vue';
import {getCountries, getRegions} from '@/api/dictionaries';
import {ROUTE} from '@/router/routeNames';
import symbolsStore from '@/stores/symbols';
import MountedTeleport from '@ui/MountedTeleport.vue';
import SelectUserDialog from '@ui/UserAccounts/SelectUserDialog.vue';
import {Popover, PopoverContent, PopoverTrigger} from '@shadcn/components/ui/popover';
import UserAccountData = App.Data.UserAccountData;
import {ComponentLoadState} from '@/types/ComponentLoadState';
import useAccessStore from '@/stores/access';
import {DataSet} from '@/utils/DataSet';
import {getPermissionsForBranch, getUserAccounts} from '@/api';
import SimpleSearchData = App.Data.SimpleSearchData;
import Paginator from '@ui/Paginator.vue';
import BranchPermissionData = App.Data.BranchPermissionData;
import UserFilterData = App.Data.UserFilterData;
import PermissionData = App.Data.PermissionData;
import GeneralStatsData = App.Data.GeneralStatsData;

export default defineComponent({
  components: {
    EllipsisHorizontalIcon,
    ExclamationTriangleIcon,
    Paginator,
    UserCircleIcon,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SelectUserDialog,
    IconAlert,
    ZButton,
    Action,
    MountedTeleport,
    // icons
    MapPinIcon,
    BriefcaseIcon,
    BuildingOfficeIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    DocumentPlusIcon,
    ArrowsRightLeftIcon,
    EllipsisHorizontalCircleIcon,
    ClipboardIcon,
    AtSymbolIcon,
    PhoneIcon,
    UserPlusIcon,
    // shadcn components
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    ZTable,
    ZTableRow,
    ZTableCell,
    ZTableHead,
  },

  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      navigation: inject('navigation') as Navigation,
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      organization: null as OrganizationData | null,
      branches: null as null | BranchData[],
      countries: null as KeyValue | null,
      regions: null as KeyValue | null,
      selectedBranch: null as BranchData | null,
      branchToTransfer: null as BranchData | null,
      registrationLink: '',
      legalForms: null as KeyValue | null,
      lastSelectedIndex: null as number | null,
      usersDataSet: {} as DataSet<UserAccountData, SimpleSearchData>,
      branchPermissions: [] as BranchPermissionData[],
      branchStats: {} as GeneralStatsData,

      state: 'loading' as ComponentLoadState,
    };
  },

  computed: {
    access: () => useAccessStore(),
  },

  watch: {
    $route(to: any) {
      if (to.name == ROUTE.ORGANIZATION_DETAILS) {
        this.state = 'loading';
        this.reloadOrganization().then(
          () => (this.state = 'loaded'),
          () => (this.state = 'error'),
        );
      }
    },
  },

  async mounted() {
    try {
      const symbols = symbolsStore();
      this.legalForms = await symbols.get('legal_forms');

      const [countriesResponse, regionsResponse] = await Promise.all([
        getCountries(this.$i18n.locale).execute(),
        getRegions(this.$i18n.locale).execute(),
      ]);

      this.countries = countriesResponse.data.reduce((accumulator: KeyValue, value: CountryWithTranslationData) => {
        return {...accumulator, [value.id]: value.translations[0].translation};
      }, {});
      this.regions = regionsResponse.data.reduce((accumulator: KeyValue, value: RegionWithTranslationData) => {
        return {...accumulator, [value.id]: value.translations[0].translation};
      }, {});

      await this.reloadOrganization();

      this.loadStatsForSelectedBranch();
      this.loadUsersForSelectedBranch();

      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
    }
  },

  methods: {
    async reloadOrganization() {
      const response = await getOrganization(this.organizationId).execute();
      this.organization = response.data;
      this.registrationLink = this.organization.registration_url!;
      (this.navigation as Navigation).setPanelTitle(this.organization.name);

      await this.reloadBranches();
    },

    async reloadBranches() {
      const response = await getBranches(this.organizationId).execute();
      this.branches = response.data;
      if (this.lastSelectedIndex != null) {
        this.selectBranch(this.lastSelectedIndex);
      }
    },

    addBranch(parent: number | null) {
      if (!this.organization) {
        return;
      }

      this.$router.push({
        name: ROUTE.ORGANIZATION_BRANCH_ADD,
        params: {organizationId: this.organization.id, parentBranchId: parent},
      });
    },

    editBranch(id: number) {
      if (!this.organization) {
        return;
      }

      this.$router.push({
        name: ROUTE.ORGANIZATION_BRANCH_EDIT,
        params: {organizationId: this.organization.id, branchId: id},
      });
    },

    editOrganization() {
      if (!this.organization) {
        return;
      }

      this.$router.push({name: ROUTE.ORGANIZATION_EDIT, params: {organizationId: this.organization.id}});
    },

    manageOrganization() {
      if (!this.organization) {
        return;
      }

      this.$router.push({name: ROUTE.ORGANIZATION_MANAGE, params: {organizationId: this.organization.id}});
    },

    async selectBranch(index: number) {
      if (!this.branches) {
        return;
      }

      if (index == -1) {
        this.selectedBranch = null;
        await this.loadStatsForSelectedBranch();
        await this.loadUsersForSelectedBranch();
        return;
      }
      this.selectedBranch = this.branches[index];
      await this.loadStatsForSelectedBranch();
      await this.loadUsersForSelectedBranch();
      this.lastSelectedIndex = index;
    },

    moveOut(branch: BranchData) {
      this.branchToTransfer = branch;
    },

    cancelTransfer() {
      this.branchToTransfer = null;
    },

    canTransferToDestination(destination: BranchData | null) {
      if (this.branchToTransfer == null) return false;

      if (destination == null) {
        if (this.branchToTransfer.parent_branch_id == null) return false;
      } else {
        if (this.branchToTransfer.id == destination.id) return false;
        else if (this.branchToTransfer.parent_branch_id == destination.id) return false;
        else if (
          this.branchToTransfer.hierarchy_path ==
          destination.hierarchy_path!.substring(0, this.branchToTransfer.hierarchy_path!.length)
        )
          return false;
      }
      return true;
    },

    async moveUp(branch: BranchData) {
      if (!this.organization) {
        return;
      }

      try {
        await updateBranchOrder(branch.id!, 'up', this.organization.id!).execute();
      } catch (error: any) {
        throw error;
      }

      try {
        await this.reloadBranches();
      } catch (error: any) {
        this.state = 'error';
      }
    },

    async moveDown(branch: BranchData) {
      if (!this.organization) {
        return;
      }

      try {
        await updateBranchOrder(branch.id!, 'down', this.organization.id!).execute();
      } catch (error: any) {
        throw error;
      }

      try {
        await this.reloadBranches();
      } catch (error: any) {
        this.state = 'error';
      }
    },

    async transferBranchTo(destination: BranchData | null) {
      if (!this.organization) {
        return;
      }

      try {
        if (destination == null && this.branchToTransfer != null)
          await changeBranchParentToRoot(this.branchToTransfer.id!, this.organization.id!).execute();
        else if (this.branchToTransfer != null && this.branchToTransfer.id != null)
          await updateBranchParent(this.branchToTransfer.id, destination!.id!, this.organization.id!).execute();
        this.branchToTransfer = null;
      } catch (error: any) {
        throw error;
      }

      try {
        await this.reloadBranches();
      } catch (error: any) {
        this.state = 'error';
      }
    },

    canMove(index: number, direction: string) {
      if (!this.branches) {
        return false;
      }

      let currentBranch = this.branches[index];

      if (direction == 'down') {
        while (
          this.branches.length > index &&
          currentBranch.depth != null &&
          this.branches[index].depth! >= currentBranch.depth
          ) {
          if (this.branches[index].id != currentBranch.id && this.branches[index].depth == currentBranch.depth)
            return true;
          index++;
        }
      } else {
        while (
          index >= 0 &&
          this.branches.length > index &&
          currentBranch.depth != null &&
          this.branches[index].depth! >= currentBranch.depth
          ) {
          if (this.branches[index].id != currentBranch.id && this.branches[index].depth == currentBranch.depth)
            return true;
          index--;
        }
      }

      return false;
    },

    requestOrganizationDelete() {
      this.dialogs
        .confirm(
          this.$t('organization.label.deleting_organization'),
          this.$t('organization.message.deleting_organization_question'),
        )
        .then(() => {
          if (!this.organization) {
            return;
          }

          deleteOrganization(this.organization.id!)
            .execute()
            .then(() => {
              this.toast({
                title: this.$t('toasts.type.success'),
                description: this.$t('organization.message.organization_deleted'),
              });
            });
          this.$router.replace({name: ROUTE.ORGANIZATION_LIST});
        });
    },

    requestBranchDelete() {
      this.dialogs
        .confirm(
          this.$t('organization.label.deleting_branch'),
          this.$t('organization.message.deleting_branch_question'),
        )
        .then(() => {
          if (!this.organization) {
            return;
          }

          if (this.selectedBranch != null) {
            deleteBranch(this.selectedBranch.id!, this.organization.id!)
              .execute()
              .then(() => {
                this.toast({
                  title: this.$t('toasts.type.success'),
                  description: this.$t('organization.message.branch_deleted'),
                });
                this.reloadOrganization();
              });
          }
        });
    },

    copyLinkToClipboard() {
      navigator.clipboard.writeText(this.registrationLink);
      this.toast({
        title: this.$t('toasts.type.success'),
        description: this.$t('organization.message.registration_link_copied_to_clipboard'),
      });
    },

    openAddOperatorDialog() {
      (this.$refs.addOperatorDialog as typeof SelectUserDialog).open();
    },

    addOperator(item: UserAccountData) {
      addCurator(this.organizationId, item.id!.toString()).execute();
      this.reloadOrganization();
    },

    removeCurator(user_id: number) {
      this.dialogs
        .confirm(this.$t('organization.label.deleting_curator'), this.$t('organization.message.deleting_curator'))
        .then(() => {
          removeCurator(this.organizationId, user_id.toString())
            .execute()
            .then(() => {
              this.reloadOrganization();
            });
        });
    },

    async loadStatsForSelectedBranch() {
      if (this.selectedBranch == null) {
        const statsResponse = await getOrganizationStats(this.organizationId!).execute();
        this.branchStats = statsResponse.data;
      } else {
        const statsResponse = await getBranchStats(this.organizationId!, this.selectedBranch.id!.toString()).execute();
        this.branchStats = statsResponse.data;
      }
    },

    async loadUsersForSelectedBranch() {
      if (this.selectedBranch == null) {
        this.usersDataSet = new DataSet<UserAccountData, UserFilterData>((query: string) =>
          getUserAccounts().execute(query),
        );
        this.usersDataSet.setParams({organizationId: this.organizationId, preset: 'active'} as UserFilterData);
        await this.usersDataSet.load();
      } else {
        const permissionsResponse = await getPermissionsForBranch(this.selectedBranch.id!.toString()).execute();
        this.branchPermissions = permissionsResponse.data;
      }
    },

    showUser(userId: string) {
      this.$router.push({
        name: ROUTE.USER_ACCOUNT_DETAILS,
        params: {userId: userId},
      });
    },

    removeDupes(permissions: PermissionData[]) {
      let dupes = {} as KeyValue;
      let result = [] as PermissionData[];
      for (let i = 0; i < permissions.length; i++) {
        if (dupes[permissions[i].symbol] == undefined) result.push(permissions[i]);
        dupes[permissions[i].symbol] = '';
      }
      return result;
    },
  },
});
</script>
<style>
.stat-label {
  @apply pr-4 py-2 text-right text-sm self-center font-bold;
  @apply border-r-2;
  @apply row-span-2;
  @apply lg:row-span-1;
}

.stat-box {
  @apply flex flex-col-reverse gap-4 py-4;
  @apply text-center text-sm;

  b {
    @apply text-3xl;
  }
}
</style>
