<template>
  <AlertDialog v-if="isDialogOpen" v-model:open="isDialogOpen" :force-mount="true">
    <AlertDialogContent @escape-key-down="cancel">
      <AlertDialogHeader>
        <AlertDialogTitle>{{ $t('userAccount.label.authorize_action') }}</AlertDialogTitle>
        <AlertDialogDescription>
          <div class="flex flex-col gap-2">
            <IconAlert v-if="failed" type="error">{{ $t('userAccount.label.authorize_action_failed') }}</IconAlert>
            <ZLabel for="password">
              {{ $t('userAccount.message.authorize_action') }}
            </ZLabel>
            <ZInput id="password" v-model="password" type="password" />
          </div>
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel @click="cancel">{{ $t('common.label.cancel') }}</AlertDialogCancel>
        <ZButton :disabled="password == ''" @click="confirm">{{ $t('common.label.confirm') }}</ZButton>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@shadcn/components/ui/alert-dialog';
import { authorizeAction } from '@/api';
import { ZLabel } from '@shadcn/components/ui/label';
import { ZInput } from '@shadcn/components/ui/input';
import { ZButton } from '@shadcn/components/ui/button';
import IconAlert from '@ui/IconAlert.vue';

export default defineComponent({
  components: {
    IconAlert,
    ZInput,
    ZLabel,
    ZButton,
    // icons
    // shadcn components
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
  },

  promiseResolve: null,
  promiseReject: null,

  data() {
    return {
      failed: false as boolean,
      isDialogOpen: false,
      password: '' as string,
      promiseResolve: null as any,
      promiseReject: null as any,
      promise: null as null | Promise<void>,
    };
  },

  methods: {
    open(): Promise<void> {
      this.password = '';
      this.failed = false;
      this.isDialogOpen = true;
      this.promise = new Promise((resolve, reject) => {
        this.promiseResolve = resolve;
        this.promiseReject = reject;
      });
      return this.promise;
    },

    cancel() {
      this.isDialogOpen = false;
      if (this.promiseReject != null) this.promiseReject();
    },

    async confirm() {
      const authorizeResponse = await authorizeAction(this.password).execute();
      if (authorizeResponse.data.status == 'authorized') {
        this.isDialogOpen = false;
        if (this.promiseResolve != null) this.promiseResolve();
      } else {
        this.failed = true;
      }
    },
  },
});
</script>
<style></style>
