<template>
  <ZDialog v-model:open="showModal">
    <ZDialogContent class="sm:max-w-[425px]">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('auth.login.2fa.header') }}</ZDialogTitle>
      </ZDialogHeader>
      <FormValidator :errors="errors">
        <div>
          <FormField symbol="code">
            <ZLabel>{{ isRecovery ? $t('auth.login.2fa.recoverText') : $t('auth.login.2fa.text') }}</ZLabel>
            <ZInput v-model="code" type="text" />
          </FormField>
          <ZDialogFooter class="mt-5 flex justify-between">
            <ZButton class="w-full" @click.prevent="login2FA">{{ $t('auth.login.2fa.button') }}</ZButton>
            <ZButton class="w-full" variant="ghost" @click.prevent="isRecovery = !isRecovery">{{
              !isRecovery ? $t('auth.login.2fa.recoverText') : $t('auth.login.2fa.text')
            }}</ZButton>
          </ZDialogFooter>
        </div>
      </FormValidator>
    </ZDialogContent>
  </ZDialog>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { ZInput } from '@shadcn/components/ui/input';
import { ZButton } from '@shadcn/components/ui/button';
import { ZLabel } from '@shadcn/components/ui/label';
import { ZDialog, ZDialogContent, ZDialogFooter, ZDialogHeader, ZDialogTitle } from '@shadcn/components/ui/dialog';
import { useToast } from '@shadcn/components/ui/toast/use-toast';
import { ValidationErrors } from '../types/ValidationErrors';
import FormValidator from '../components/FormValidator.vue';
import FormField from '../components/FormField.vue';
import loaderStore from '../stores/loader';
import useUserStore from '../stores/auth';
import { HttpStatusCode } from 'axios';
import { postChallenge2FA } from '../api';

export default defineComponent({
  components: {
    ZInput,
    ZButton,
    ZLabel,
    ZDialog,
    ZDialogContent,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
    FormValidator,
    FormField,
  },

  data() {
    return {
      showModal: false,
      isRecovery: false,
      errors: null as null | ValidationErrors,
      code: '',
    };
  },

  methods: {
    show: function () {
      this.showModal = !this.showModal;
    },

    login2FA() {
      const { startLoading, stopLoading } = loaderStore();
      startLoading();
      postChallenge2FA(this.isRecovery ? { recovery_code: this.code } : { code: this.code })
        .then(results => {
          this.showModal = false;
          const { loginUser } = useUserStore();
          const { toast } = useToast();
          loginUser(results.data.user, results.data.token);
          toast({
            title: this.$t('common.success.header'),
            description: this.$t('auth.login.form.success'),
          });
        })
        .catch(error => {
          if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
            this.errors = error.response.data.errors;
          }
        })
        .finally(() => stopLoading());
    },
  },
});
</script>
