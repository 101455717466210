<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('dictionary.label.region_add_dialog') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('dictionary.message.region_add_dialog') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <div>
        <FormValidator ref="form" class="grid grid-cols-4 gap-4 py-4">
          <Field symbol="code" class="col-span-5">
            <ZLabel for="code">
              {{ $t('dictionary.label.region_code_field') }}
            </ZLabel>
            <ZInput id="name" v-model="region.code" />
          </Field>
          <Field symbol="name" class="col-span-5">
            <ZLabel for="name">
              {{ $t('dictionary.label.region_name_field') }}
            </ZLabel>
            <ZInput id="name" v-model="region.name" />
          </Field>
        </FormValidator>
      </div>
      <ZDialogFooter>
        <Action @proceed="store">{{ $t('dictionary.command.region_add') }}</Action>
      </ZDialogFooter>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogHeader,
  ZDialogTitle,
  ZDialogFooter,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import { ROUTE } from '@/router/routeNames';
import { ZLabel } from '@shadcn/components/ui/label';
import { ZInput } from '@shadcn/components/ui/input';
import Field from '@ui/FormField.vue';
import { createRegion } from '@/api/dictionaries';
import StoreRegionData = App.Data.StoreRegionData;

export default defineComponent({
  components: {
    Field,
    ZInput,
    ZLabel,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogHeader,
    ZDialogTitle,
    ZDialogFooter,
  },

  props: {
    countryId: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      region: {
        code: '' as string,
        name: '' as string,
      } as StoreRegionData,

      isDialogOpen: true,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  methods: {
    store(action: any) {
      createRegion(this.countryId, this.region)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('action.message.added'),
          });
          this.$router.push({ name: ROUTE.DICTIONARY.COUNTRY_EDIT });
        });
    },
  },
});
</script>
