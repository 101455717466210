<template>
  <div class="py-4 gap-12 dictionaries md:flex">
    <div class="flex md:flex-col gap-2 pt-2 md:border-r md:pr-4">
      <ZButton variant="ghost" class="md:justify-start" as-child>
        <router-link :to="{ name: ROUTE.DICTIONARY.ACTIONS }">{{ $t('dictionary.label.actions') }}</router-link>
      </ZButton>
      <ZButton variant="ghost" class="md:justify-start w-full" as-child>
        <router-link :to="{ name: ROUTE.DICTIONARY.COUNTRIES }">{{
          $t('dictionary.label.countires_regions')
        }}</router-link>
      </ZButton>
    </div>
    <div class="flex-1">
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import { Dialogs } from '@/types/Dialogs';
import { ZButton } from '@shadcn/components/ui/button';

export default defineComponent({
  components: {
    ZButton,
  },

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
    };
  },
});
</script>

<style>
.dictionaries {
  .router-link-active {
    @apply bg-gray-300;
  }
}
</style>
