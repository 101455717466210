<template>
  <DataLoader ref="loader">
    <teleport v-if="componentMounted" to="#toolbox">
      <router-link :to="{ name: ROUTE.TAGS_ADD }">
        <ZButton class="gap-2 w-full md:flex">
          {{ $t('tag.add_tag') }}
          <PlusCircleIcon class="icon md"/>
        </ZButton>
      </router-link>
    </teleport>
    <div class="py-4">
      <div class="flex py-2 gap-4">
        <ZInput v-model="search" :placeholder="$t('common.label.search')"/>
        <div class="">
          <ZSelect v-model="status">
            <ZSelectTrigger class="flex gap-2 justify-start">
              <div class="font-semibold hidden md:block">Status:</div>
              <ZSelectValue :placeholder="$t('notification.placeholder')"/>
            </ZSelectTrigger>
            <ZSelectContent>
              <ZSelectGroup>
                <ZSelectItem value="all">{{ $t('tag.sort.all') }}</ZSelectItem>
                <ZSelectItem value="visible">{{ $t('tag.sort.visible') }}</ZSelectItem>
                <ZSelectItem value="hidden">{{ $t('tag.sort.hidden') }}</ZSelectItem>
              </ZSelectGroup>
            </ZSelectContent>
          </ZSelect>
        </div>
      </div>
      <Paginator :data="tagsDataSet"/>
      <div class="border rounded">
        <ZTable class="w-full">
          <ZTableHeader>
            <ZTableRow>
              <ZTableHead>{{ $t('tag.list.name') }}</ZTableHead>
              <ZTableHead class="text-center">{{ $t('tag.list.used') }}</ZTableHead>
              <ZTableHead class="text-center">{{ $t('tag.list.active') }}</ZTableHead>
              <ZTableHead class="text-center">{{ $t('tag.list.date') }}</ZTableHead>
              <ZTableHead></ZTableHead>
            </ZTableRow>
          </ZTableHeader>
          <ZTableBody>
            <ZTableRow v-for="(item, index) in tagsDataSet.data" :key="index">
              <ZTableCell>
                <div class="table">
                  <TagLabel :name="item.name" :color="item.color"/>
                </div>
              </ZTableCell>
              <ZTableCell class="w-[80px] text-center">{{ item.tagged_count }}</ZTableCell>
              <ZTableCell class="w-[180px] text-center">{{
                  item.active ? $t('tag.dialog.form.visible') : $t('tag.dialog.form.hidden')
                }}
              </ZTableCell>
              <ZTableCell class="w-[120px] text-center"
              ><span class="text-xs text-gray-400">{{ formatDate(item.created_at) }}</span></ZTableCell
              >
              <ZTableCell class="w-[50px] text-right">
                <DropdownMenu>
                  <DropdownMenuTrigger as-child>
                    <ZButton variant="ghost">
                      <EllipsisHorizontalIcon class="icon sm"/>
                    </ZButton>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuGroup>
                      <DropdownMenuItem @click="edit(item.id)">
                        <span>{{ $t('tag.list.edit') }}</span>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator/>
                      <DropdownMenuItem :disabled="!item.can_be_deleted" @click="remove(item.id, item.tagged_count)">
                        <span>{{ $t('tag.list.delete') }}</span>
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              </ZTableCell>
            </ZTableRow>
          </ZTableBody>
        </ZTable>
        <div v-if="tagsDataSet.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
          <ExclamationTriangleIcon class="icon lg"/>
          {{ $t('organization.message.data_not_found') }}
        </div>
      </div>
    </div>
    <Paginator :data="tagsDataSet"/>
    <router-view></router-view>
  </DataLoader>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import {DataSet} from '@/utils/DataSet';
import Paginator from '@ui/Paginator.vue';
import {ZInput} from '@shadcn/components/ui/input';
import {ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow} from '@shadcn/components/ui/table';
import {ExclamationTriangleIcon, PlusCircleIcon} from '@heroicons/vue/24/outline';
import {ZButton} from '@shadcn/components/ui/button';
import {ROUTE} from '@/router/routeNames';
import {deleteTag, getTags} from '@/api';
import TagLabel from '@ui/Tags/TagLabel.vue';
import {Dialogs} from '@/types/Dialogs';
import {
  ZSelect,
  ZSelectContent,
  ZSelectGroup,
  ZSelectItem,
  ZSelectTrigger,
  ZSelectValue,
} from '@shadcn/components/ui/select';
import TagRequestData = App.Data.TagRequestData;
import TagListData = App.Data.TagListData;
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@shadcn/components/ui/dropdown-menu';
import {EllipsisHorizontalIcon} from '@heroicons/vue/24/solid';
import DataLoader from "@ui/DataLoader.vue";

export default defineComponent({
  components: {
    DataLoader,
    EllipsisHorizontalIcon,
    ZSelectTrigger,
    ZSelect,
    ZSelectGroup,
    ZSelectContent,
    ZSelectItem,
    ZSelectValue,
    TagLabel,
    Paginator,
    PlusCircleIcon,
    ExclamationTriangleIcon,
    ZButton,
    ZInput,
    ZTable,
    ZTableRow,
    ZTableBody,
    ZTableCell,
    ZTableHead,
    ZTableHeader,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  },

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      componentMounted: false,
      search: '' as string,
      loader: null as any,
      status: 'all',
      tagsDataSet: {} as DataSet<TagListData, TagRequestData>,
    };
  },

  watch: {
    search() {
      this.load();
    },

    status() {
      this.load();
    },

    $route(to: any) {
      if (to.name == ROUTE.TAGS) {
        this.tagsDataSet.resetToFirstPage();
      }
    },
  },

  mounted() {
    this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;
    this.componentMounted = true;
    this.tagsDataSet = new DataSet<TagListData, TagRequestData>((query: string) => getTags().withLoader(this.loader).execute(query));
    this.tagsDataSet.load();
  },

  methods: {
    load() {
      this.tagsDataSet
        .setParams({page: 1, search: this.search, status: this.status == 'all' ? '' : this.status, perPage: null})
        .resetToFirstPage();
    },

    edit(tag_id: number) {
      this.$router.push({name: ROUTE.TAGS_EDIT, params: {tagId: tag_id}});
    },

    remove(item_id: number, tagged_count: number) {
      if (tagged_count) {
        this.toast({
          variant: 'destructive',
          title: this.$t('tag.tag_is_used_error'),
          description: this.$t('tag.tag_is_used_message'),
        });
        return;
      }
      this.tagsDataSet.load();
      this.dialogs.confirm(this.$t('tag.dialog.delete_title'), this.$t('tag.dialog.delete_message')).then(() => {
        deleteTag(item_id)
          .execute()
          .then(() => {
            this.toast({
              title: this.$t('toasts.type.success'),
              description: this.$t('tag.dialog.tag_deleted'),
            });
            this.tagsDataSet.load();
          });
      });
    },
  },
});
</script>
