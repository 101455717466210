<template>
  <Field symbol="note" class="col-span-4">
    <ZLabel for="note">
      {{ $t('asset.label.note') }}
    </ZLabel>
    <textarea
      id="note"
      v-model="note.note"
      rows="4"
      class="flex w-full resize-none rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
    />
  </Field>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';

interface NoteInterface {
  note: string;
}
export default defineComponent({
  components: {
    ZLabel,
    Field,
  },

  props: {
    note: {
      type: Object as PropType<NoteInterface>,
      required: true,
    },
  },
});
</script>
