<template>
  <div>
    <Field v-if="categories && categories.length" symbol="category_id" class="pt-4">
      <ZLabel for="category_id">
        {{ $t('report.label.category') }}
      </ZLabel>
      <SelectTree v-model="linkData.category_id" :tree="categoriesTree" />
    </Field>
    <Field v-if="linkData.hasOwnProperty('tags') && tags" symbol="validation_rule" class="pt-4">
      <ZLabel>{{ $t('asset.label.filter_tag') }}</ZLabel>
      <SelectMultipleField v-model="linkData.tags" :placeholder="$t('asset.label.all_tags')" :options="tags" />
    </Field>
    <Field v-if="linkData.hasOwnProperty('status') && statuses" symbol="validation_rule" class="pt-4">
      <ZLabel>{{ $t('asset.label.filter_status') }}</ZLabel>
      <SelectField v-model="linkData.status" :options="statuses" :translate="'asset.states.'" />
    </Field>
    <Field symbol="extension" class="pt-4">
      <div class="flex gap-2 items-center">
        <ZLabel for="extension">{{ $t('report.label.extension') }}</ZLabel>
        <a
          v-for="(label, value) in extensions"
          href="#"
          class="border rounded-full px-2 text-sm"
          :class="{ 'bg-primary text-white': linkData.extension == value }"
          @click.prevent="linkData.extension = value"
          >{{ label }}</a
        >
      </div>
    </Field>
    <Field v-if="linkData.hasOwnProperty('with_custom_attributes')" symbol="validation_rule" class="pt-6">
      <div class="flex gap-2 items-center">
        <Checkbox
          id="is_required"
          class="h-6 w-6"
          :checked="linkData.with_custom_attributes == 1"
          @update:checked="toggleChecked()"
        />
        <ZLabel for="is_required" class="peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >{{ $t('report.label.with_custom_attributes') }}
        </ZLabel>
      </div>
    </Field>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';
import { mapState } from '@modules/pinia';
import useUserStore from '@/stores/auth';
import { Checkbox } from '@shadcn/components/ui/checkbox';
import SelectField from '@ui/SelectField.vue';
import SelectMultipleField from '@ui/SelectMultipleField.vue';
import SelectTree from '@ui/SelectTree.vue';

export default defineComponent({
  components: {
    SelectTree,
    SelectMultipleField,
    SelectField,
    Checkbox,
    ZLabel,
    Field,
  },

  props: {
    linkData: {
      type: Object,
      required: true,
    },

    categories: {
      type: Object,
      required: false,
      default: () => {},
    },

    tags: {
      type: Object,
      required: true,
    },

    statuses: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      extensions: {
        csv: 'Csv',
        xlsx: 'Excel',
      },
    };
  },

  computed: {
    ...mapState(useUserStore, ['user']),
    categoriesTree() {
      let tree = new Map<string, object>();

      for (let index in this.categories) {
        const category = this.categories[index];
        const treeKey = category.id.toString();
        tree.set(treeKey, { depth: category.depth, label: category.name });
      }

      return tree;
    },
  },

  methods: {
    toggleChecked() {
      if (this.linkData.with_custom_attributes === 0) {
        this.linkData.with_custom_attributes = 1;
      } else {
        this.linkData.with_custom_attributes = 0;
      }
    },
  },
});
</script>
