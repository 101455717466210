<template>
  <ZSelect v-model:open="show" class="w-full">
    <ZSelectTrigger class="w-full">
      <div class="flex w-full justify-between items-center gap-4 h-10 dotted rounded-md cursor-pointer">
        <span class="truncate text-sm">{{ buttonTitle() }}</span>
      </div>
    </ZSelectTrigger>
    <ZSelectContent align="start">
      <div>
        <ZLabel class="flex gap-2 items-center p-1 py-2 hover:bg-accent rounded-md cursor-pointer" :class="{'opacity-30': model.length == 0 || model == '', 'pointer-events-none': model.length == 0 || model == ''}" @click="unselectAll()">
          <MinusCircleIcon class="icon sm"/>
          {{ $t('common.unselect') }}
        </ZLabel>
      </div>
      <Separator/>
      <div v-for="(item, key) in items" :key="key">
        <ZLabel class="flex items-center py-2 px-1 hover:bg-accent rounded-md cursor-pointer">
          <Checkbox id="consent" :checked="isChecked(item.value)" class="rounded-full border-gray-300 bg-white" @update:checked="selectItem(item.value)">
            <div class="w-full h-full bg-white rounded-full flex items-center justify-center">
              <div class="w-[6px] h-[6px] bg-primary rounded-full"></div>
            </div>
          </Checkbox>
          <div class="ml-2 text-sm">{{ $t(item.name) }}</div>
        </ZLabel>
      </div>
    </ZSelectContent>
  </ZSelect>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {Checkbox} from '@shadcn/components/ui/checkbox';
import {ZLabel} from '@shadcn/components/ui/label';
import {
  ZSelect,
  ZSelectContent,
  ZSelectTrigger,
} from '@shadcn/components/ui/select';
import i18n from "@/utils/i18n";
import {Separator} from "@shadcn/components/ui/separator";
import {MinusCircleIcon} from "@modules/@heroicons/vue/24/outline";

interface ItemType {
  [key: number]: any;
}

export default defineComponent({
  components: {
    MinusCircleIcon, Separator,
    ZLabel,
    Checkbox,
    ZSelect,
    ZSelectContent,
    ZSelectTrigger,
  },

  props: {
    options: {
      type: Object,
      required: false,
      default: null,
    },

    modelValue: {
      type: [String, Number, Object],
      required: false,
      default: '',
    },

    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    shorten: {
      type: Boolean,
      required: false,
      default: false,
    },

    translate: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      show: false as boolean,
      model: this.modelValue,
    };
  },

  computed: {
    items() {
      let result = [] as any;
      let defaultKey = 0;
      for (let key in this.options) {
        let item = this.options[key];
        result[defaultKey.toString()] = {
          name: this.translate == undefined ? item : this.$t(this.translate + item),
          value: key.toString(),
        };
        defaultKey++;
      }
      return result as ItemType;
    },
  },

  watch: {
    modelValue() {
      this.model = this.modelValue;
    },
  },

  mounted() {
    if (!Array.isArray(this.modelValue)) {
      this.model = [];
    }
  },

  methods: {
    buttonTitle() {
      let list = [] as any;
      if (Array.isArray(this.model)) {
        list = this.model;
      }
      let selected = list.length ? list : [];
      const values = [] as string[];
      if (selected) {
        for (let key in selected as ItemType) {
          values[key] = this.translate
            ? i18n.global.t(this.translate + this.options?.[selected[key]])
            : this.options?.[selected[key]];
        }
      }

      if (values.length == 0) {
        return this.placeholder;
      } else if (values.length == 1) {
        return values.join(', ');
      } else if (this.shorten) {
        return this.$t('common.selected') + values.length;
      } else {
        return values.join(', ');
      }
    },

    selectItem(value: any) {
      if (!Array.isArray(this.model)) {
        this.model = [];
      }
      var index = this.model.indexOf(value);
      if (index == -1) {
        this.model.push(value);
      } else {
        this.model.splice(index, 1);
        if (this.model.length == 0) {
          this.model = '';
        }
      }
      this.$emit('update:modelValue', this.model);
    },

    isChecked(value: any) {
      if (typeof this.model == 'object') return this.model?.includes(value);
    },

    closePopover() {
      this.show = !this.show;
    },

    unselectAll() {
      this.model = '';
      this.$emit('update:modelValue', this.model);
    }
  },
});
</script>
