<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ prompt }}</ZDialogTitle>
      </ZDialogHeader>
      <ScrollArea class="h-[200px] w-full rounded-md border p-4">
        <div v-for="item in operatorsDataSet.data" :key="item.id" class="hover:bg-gray-100 p-2">
          <div v-if="disabled[item.id]" class="flex opacity-50 justify-between gap-2">
            <span>
              {{ item.first_name }}
                            {{ item.last_name }}
            </span>
            <span>
              {{ item.email }}
            </span>
          </div>
          <div v-else class="cursor-pointer flex justify-between gap-2" @click="select(item)">
            <span>
              {{ item.first_name }}               {{ item.last_name }}
            </span>
            <span>
              {{ item.email }}
            </span>
          </div>
        </div>
      </ScrollArea>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import {ZDialog, ZDialogContent, ZDialogHeader, ZDialogTitle} from '@shadcn/components/ui/dialog';
import {ScrollArea} from '@shadcn/components/ui/scroll-area';
import {getOperators} from '@/api';
import {Dialogs} from '@/types/Dialogs';
import {DataSet} from '@/utils/DataSet';
import OperatorCompactData = App.Data.OperatorCompactData;
import SimpleSearchData = App.Data.SimpleSearchData;

export default defineComponent({
  components: {
    ZDialog,
    ZDialogContent,
    ZDialogHeader,
    ZDialogTitle,
    ScrollArea,
  },

  props: {
    prompt: {
      type: String,
      required: true,
    },

    excludes: {
      type: Array<OperatorCompactData>,
      required: true,
    },
  },

  emits: ['selected'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
      dialogs: inject('dialogs') as Dialogs,
    };
  },

  data() {
    return {
      isDialogOpen: false,
      operatorsDataSet: {} as DataSet<OperatorCompactData, SimpleSearchData>,
      disabled: {} as any,
    };
  },

  watch: {
    excludes() {
      this.disabled = {};
      for (let i = 0; i < this.excludes?.length; i++) {
        this.disabled[this.excludes[i].id] = true;
      }
    },
  },

  methods: {
    async open() {
      this.operatorsDataSet = new DataSet<OperatorCompactData, SimpleSearchData>((query: string) =>
        getOperators().execute(query),
      );
      this.operatorsDataSet.load();

      for (let i = 0; i < this.excludes?.length; i++) {
        this.disabled[this.excludes[i].id] = true;
      }

      this.isDialogOpen = true;
    },

    select(item: OperatorCompactData) {
      this.$emit('selected', item);
      this.close();
    },

    close() {
      this.isDialogOpen = false;
    },
  },
});
</script>
