<template>
  <div v-if="state == 'loading' && firstLoad" class="py-4">
    <IconAlert type="loading">
      {{ $t('common.action.loading') }}
    </IconAlert>
  </div>
  <div v-if="state == 'error' && errorCode != 403" class="py-4">
    <IconAlert type="error">
      {{ $t('common.errors.unexpected') }}
    </IconAlert>
  </div>
  <div v-else-if="state == 'error' && errorCode == 403" class="py-4">
    <IconAlert type="error">
      {{ $t('common.errors.forbidden') }}
    </IconAlert>
  </div>
  <div
    v-if="state == 'loaded' || (!firstLoad && state != 'error')"
    :class="{ 'component-loading': state == 'loading' }"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { Loader } from '@/types/Loader';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import { KeyValue } from '@/types/Common';
import IconAlert from '@ui/IconAlert.vue';
import { HttpStatusCode } from 'axios';

export default defineComponent({
  components: {
    IconAlert,
    // icons
    // shadcn components
  },

  props: [],

  data() {
    return {
      state: 'loading' as ComponentLoadState,
      errorCode: 0 as number,
      firstLoad: true as boolean,
      loader: null as Loader | null,
      contexts: {} as KeyValue,
    };
  },

  computed: {},

  watch: {},

  mounted() {
    this.loader = {
      setLoading(isLoading: boolean, context: string) {
        this.component.contexts[context] = isLoading;

        if (this.component.state == 'error') return true;

        for (let key in this.component.contexts) {
          if (this.component.contexts[key]) {
            this.component.state = 'loading';
            return true;
          }
        }

        this.component.firstLoad = false;
        this.component.state = 'loaded';
        return true;
      },

      setError(isError: boolean, context: string, code: number) {
        if (code == HttpStatusCode.UnprocessableEntity || code == HttpStatusCode.Forbidden) {
          this.component.contexts[context] = false;
          this.component.state = 'loaded';
          return;
        }
        if (isError) {
          this.component.state = 'error';
          this.component.errorCode = code;
        } else {
          this.component.errorCode = 0;
        }
      },
    } as Loader;

    this.loader.component = this;
  },
});
</script>
<style>
.component-loading {
  @apply cursor-wait pointer-events-none;
}
</style>
