<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('files.label.edit') }}</ZDialogTitle>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded' && file">
        <div>
          <FormValidator ref="form" class="flex flex-col gap-4 py-4">
            <Field symbol="name">
              <ZLabel for="name">
                {{ $t('files.label.name') }}
              </ZLabel>
              <div class="flex justify-between items-stretch">
                <ZInput id="name" v-model="file.name" />
                <div class="text-xs font-bold rounded-md ml-2 bg-gray-100 flex px-4 items-center justify-center">
                  {{ file.ext }}
                </div>
              </div>
            </Field>

            <Field symbol="access">
              <ZLabel for="access">
                {{ $t('files.label.access') }}
              </ZLabel>
              <ZSelect v-model="file!.access!">
                <ZSelectTrigger>
                  <ZSelectValue />
                </ZSelectTrigger>
                <ZSelectContent>
                  <ZSelectGroup>
                    <ZSelectItem value="private">{{ $t('files.access.private') }}</ZSelectItem>
                    <ZSelectItem value="branch">{{ $t('files.access.branch') }}</ZSelectItem>
                    <ZSelectItem value="organization">{{ $t('files.access.organization') }}</ZSelectItem>
                  </ZSelectGroup>
                </ZSelectContent>
              </ZSelect>
            </Field>
            <Field symbol="description">
              <ZLabel for="description">
                {{ $t('files.label.description') }}
              </ZLabel>
              <textarea
                id="name"
                v-model="file.description"
                rows="4"
                class="flex w-full resize-none rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
              />
            </Field>
          </FormValidator>
        </div>
        <ZDialogFooter>
          <div class="flex justify-between w-full">
            <Action variant="destructive" @proceed="remove">{{ $t('files.command.delete') }}</Action>
            <Action @proceed="update">{{ $t('files.command.save') }}</Action>
          </div>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import { ZDialog, ZDialogContent, ZDialogHeader, ZDialogTitle, ZDialogFooter } from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import { deleteFile, getFile, updateFile } from '@/api';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';
import { ZInput } from '@shadcn/components/ui/input';
import { Dialogs } from '@/types/Dialogs';
import { toast } from '@shadcn/components/ui/toast';
import {
  ZSelect,
  ZSelectContent,
  ZSelectGroup,
  ZSelectItem,
  ZSelectTrigger,
  ZSelectValue,
} from '@shadcn/components/ui/select';
import FileData = App.Data.FileData;
import FileUpdateRequestData = App.Data.FileUpdateRequestData;

export default defineComponent({
  components: {
    ZSelectTrigger,
    ZSelect,
    ZSelectItem,
    ZSelectValue,
    ZSelectGroup,
    ZSelectContent,
    ZInput,
    Field,
    ZLabel,
    FormValidator,
    IconAlert,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogHeader,
    ZDialogTitle,
    ZDialogFooter,
  },

  props: {
    fileId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved', 'deleted'],

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as typeof toast,
    };
  },

  data() {
    return {
      file: {} as FileData,
      isDialogOpen: true,
      state: 'loading' as ComponentLoadState,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      const [fileResponse] = await Promise.all([getFile(this.fileId).execute()]);
      this.file = fileResponse.data;
      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
    }
  },

  methods: {
    update(action: any) {
      updateFile(this.fileId, this.file as FileUpdateRequestData)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('organization.message.organization_saved'),
          });
          this.$emit('saved');
        });
    },

    async remove(action: any) {
      try {
        this.dialogs
          .confirm(this.$t('files.label.deleting'), this.$t('files.message.deleting_message'))
          .then(async () => {
            await this.dialogs.authorizeAction();
            deleteFile(parseInt(this.fileId))
              .withLoader(action.loader)
              .execute()
              .then(() => {
                this.isDialogOpen = false;
                this.toast({
                  title: this.$t('common.success.header'),
                  description: this.$t('files.message.deleted'),
                });
                this.$emit('deleted');
              });
          });
      } catch (error: any) {}
    },
  },
});
</script>
