<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('contracts.label.start_processing_contract') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('contracts.message.start_processing_contract') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <FormValidator ref="form">
          <Field symbol="status" class="pt-4">
            <ZLabel for="status">
              {{ $t('contracts.label.change_status') }}
            </ZLabel>
            <SelectField v-model="status" :options="statuses"></SelectField>
          </Field>

          <Field
            v-if="status == SYMBOL.CONTRACT_STATUS.ACCEPTED || status == SYMBOL.CONTRACT_STATUS.DELAYED_EXECUTION"
            symbol="estimated_deadline_at"
            class="pt-4"
          >
            <ZLabel for="estimatedDeadlineAt">
              {{ $t('contracts.label.estimated_deadline_at') }}
            </ZLabel>
            <Popover v-model:open="showDatePicker">
              <PopoverTrigger as-child>
                <ZButton variant="outline" class="w-full font-normal flex items-center justify-start">
                  <div class="flex items-center w-full">
                    <CalendarIcon class="w-[20px] mr-2" />
                    <span v-if="estimatedDeadlineAt">{{ formatDate(estimatedDeadlineAt.toISOString()) }}</span>
                    <span v-else>{{ $t('contracts.label.select_estimated_deadline') }}</span>
                  </div>
                  <ChevronDownIcon class="w-[18px] ml-2 text-gray-400" />
                </ZButton>
              </PopoverTrigger>
              <PopoverContent class="w-auto p-0" align="start">
                <Calendar v-model="estimatedDeadlineAt" timezone="UTC" :columns="1" />
              </PopoverContent>
            </Popover>
          </Field>
          <Field symbol="note" class="pt-4">
            <ZLabel for="note">
              {{ $t('contracts.label.note_content') }}
            </ZLabel>
            <textarea
              v-model="note"
              class="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
            ></textarea>
          </Field>
        </FormValidator>
        <ZDialogFooter>
          <Action :disabled="false" @proceed="update">{{ $t('contracts.command.start_processing_contract') }} </Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import ContractData = App.Data.ContractData;
import { getContract, processContract } from '@/api/contract';
import { KeyValue } from '@/types/Common';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';
import SelectField from '@ui/SelectField.vue';
import { CalendarIcon, ChevronDownIcon } from '@modules/@heroicons/vue/24/outline';
import { ZButton } from '@shadcn/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/components/ui/popover';
import { Calendar } from '@shadcn/components/ui/calendar';
import { datePartISO } from '@/utils';
import ContractDataStatusUpdateRequest = App.Data.ContractDataStatusUpdateRequest;

export default defineComponent({
  components: {
    ZButton,
    CalendarIcon,
    ChevronDownIcon,
    SelectField,
    Field,
    ZLabel,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Calendar,
  },

  props: {
    contractId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved', 'notification-read'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
      stats: inject('stats') as { updateStats: () => Promise<void> },
    };
  },

  data() {
    return {
      isDialogOpen: true,
      showDatePicker: false,
      contract: {} as ContractData,
      state: 'loading' as ComponentLoadState,
      estimatedDeadlineAt: null as Date | null,
      status: '' as string,
      note: '' as string,
      statuses: {} as KeyValue,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    this.statuses[this.SYMBOL.CONTRACT_STATUS.NEW_ORDER] = this.$t('contract.status.new_order');
    this.statuses[this.SYMBOL.CONTRACT_STATUS.ACCEPTED] = this.$t('contract.status.accepted');
    this.statuses[this.SYMBOL.CONTRACT_STATUS.CANCELED] = this.$t('contract.status.canceled');
    this.statuses[this.SYMBOL.CONTRACT_STATUS.IN_PROGRESS] = this.$t('contract.status.in_progress');
    this.statuses[this.SYMBOL.CONTRACT_STATUS.DELAYED_EXECUTION] = this.$t('contract.status.delayed_execution');
    this.statuses[this.SYMBOL.CONTRACT_STATUS.BLOCKED_EXECUTION] = this.$t('contract.status.blocked_execution');
    this.statuses[this.SYMBOL.CONTRACT_STATUS.COMPLETED_EXECUTION] = this.$t('contract.status.completed_execution');

    try {
      this.contract = (await getContract(this.contractId).execute()).data;
      this.status = this.contract.status!;
      this.state = 'loaded';
    } catch (error: any) {
      this.state = error;
    }
  },

  methods: {
    update(action: any) {
      const fields = {
        status: this.status,
        note: this.note ?? '',
      } as ContractDataStatusUpdateRequest;
      if (this.estimatedDeadlineAt) fields.estimated_deadline_at = datePartISO(this.estimatedDeadlineAt);
      processContract(this.contractId, this.SYMBOL.CONTRACT_STATUS.IN_PROGRESS, fields)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.stats.updateStats();
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('contracts.message.contract_saved'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
