<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('report.label.generate_report') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('report.message.generate_report') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <div class="lg:max-h-[70vh] lg:overflow-auto">
          <FormValidator ref="form" :errors="errors">
            <GenerateCarbonReportForm
              :link-data="linkData"
              :branches="branches"
              :export-type="exportType"
            ></GenerateCarbonReportForm>
          </FormValidator>
        </div>
        <ZDialogFooter>
          <div class="w-full flex flex-col items-end">
            <ZButton class="mt-2" @click="generateUrlLink()">
              {{ $t('report.command.generate') }}
            </ZButton>
          </div>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import { ZButton } from '@shadcn/components/ui/button';
import { downloadReport, generateCarbonFootprintReportFileUrl } from '@/api/report';
import CategoryCompactData = App.Data.CategoryCompactData;
import { getBranches } from '@/api';
import { mapState } from '@modules/pinia';
import useUserStore from '@/stores/auth';
import GenerateCarbonReportForm from '@ui/Reports/GenerateCarbonReportForm.vue';
import { HttpStatusCode } from '@modules/axios';
import ExtensionType = App.Enums.ExtensionType;
import { ValidationErrors } from '@/types/ValidationErrors';

interface linkDataInterface {
  comparative_branch_id?: string;
  extension: ExtensionType;
  month_from: string;
  month_to: string;
  year_from: string;
  year_to: string;
}
export default defineComponent({
  components: {
    GenerateCarbonReportForm,
    ZButton,
    IconAlert,
    FormValidator,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    exportType: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      state: 'loading' as ComponentLoadState,
      loader: null as any,
      url: '',
      categories: {} as CategoryCompactData,
      branches: {},
      errors: null as null | ValidationErrors,
      linkData: {
        comparative_branch_id: '',
        extension: 'xlsx' as ExtensionType,
        month_from: '1',
        month_to: '12',
        year_from: new Date().getFullYear().toString(),
        year_to: new Date().getFullYear().toString(),
      } as linkDataInterface,
    };
  },

  computed: {
    ...mapState(useUserStore, ['chosenOrganization']),
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) {
        this.$router.back();
      }
    },
  },

  async mounted() {
    try {
      if (this.exportType !== 'comparative') {
        delete this.linkData.comparative_branch_id;
      }
      if (this.chosenOrganization) {
        await this.getBranches(this.chosenOrganization.toString());
      }
      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
      throw error;
    }
  },

  methods: {
    generateUrlLink() {
      this.errors = null;
      if (this.exportType === 'comparative') {
        generateCarbonFootprintReportFileUrl({
          export_type: this.exportType,
          extension: this.linkData.extension,
          date_from:
            !this.linkData.month_from && !this.linkData.year_from
              ? ''
              : this.linkData.month_from + '-' + this.linkData.year_from,
          date_to:
            !this.linkData.month_to && !this.linkData.year_to
              ? ''
              : this.linkData.month_to + '-' + this.linkData.year_to,
          comparative_branch_id: this.linkData.comparative_branch_id ? parseInt(this.linkData.comparative_branch_id) : null,
        })
          .execute()
          .then(res => {
            downloadReport(res.data.link, this.linkData.extension);
          })
          .catch(error => {
            if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
              this.errors = error.response.data.errors;
            }
          });
      } else if (this.exportType === 'footprint') {
        generateCarbonFootprintReportFileUrl({
          export_type: this.exportType,
          extension: this.linkData.extension,
          date_from:
            !this.linkData.month_from && !this.linkData.year_from
              ? ''
              : this.linkData.month_from + '-' + this.linkData.year_from,
          date_to:
            !this.linkData.month_to && !this.linkData.year_to
              ? ''
              : this.linkData.month_to + '-' + this.linkData.year_to,
        })
          .execute()
          .then(res => {
            downloadReport(res.data.link, this.linkData.extension);
          })
          .catch(error => {
            if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
              this.errors = error.response.data.errors;
            }
          });
      }
    },

    async getBranches(organizationId: string): Promise<void> {
      const branchesResponse = await getBranches(organizationId).execute();
      this.branches = branchesResponse.data;
    },
  },
});
</script>
