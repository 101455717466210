<template>
  <router-view></router-view>
  <div class="my-4">
    <div>
      <div class="border rounded-xl p-6 shadow-md w-full flex flex-col md:flex-row justify-between text-center md:text-left items-center">
        <div class="flex justify-between mb-4 md:mb-0">
          <p class="text-xl font-medium">{{ $t('report.label.assets_export') }}</p>
        </div>
        <div class="w-full md:w-auto">
          <ZButton
            class="w-full"
            @click="$router.push({ name: ROUTE.GENERATE_ASSETS_REPORT, params: { exportType: 'assets' } })"
          >
            {{ $t('report.command.export_data') }}
          </ZButton>
        </div>
      </div>
      <div class="border rounded-xl p-6 shadow-md w-full flex flex-col md:flex-row justify-between text-center md:text-left items-center mt-4">
        <div class="flex justify-between mb-4 md:mb-0">
          <p class="text-xl font-medium">{{ $t('report.label.assets_report') }}</p>
        </div>
        <div class="w-full md:w-auto">
          <ZButton
            variant="outline"
            class="w-full"
            @click="$router.push({ name: ROUTE.GENERATE_ASSETS_REPORT, params: { exportType: 'quantitative' } })"
          >
            {{ $t('report.command.generate_report') }}
          </ZButton>
        </div>
      </div>
      <div class="border rounded-xl p-6 shadow-md w-full flex flex-col md:flex-row justify-between text-center md:text-left items-center mt-4">
        <div class="flex justify-between mb-4 md:mb-0">
          <p class="text-xl font-medium">{{ $t('report.label.models_report') }}</p>
        </div>
        <div class="w-full md:w-auto">
          <ZButton
            variant="outline"
            class="w-full"
            @click="$router.push({ name: ROUTE.GENERATE_ASSETS_REPORT, params: { exportType: 'models' } })"
          >
            {{ $t('report.command.generate_report') }}
          </ZButton>
        </div>
      </div>
      <div class="border rounded-xl p-6 shadow-md mt-4 flex flex-col md:flex-row justify-between text-center md:text-left items-center"
           v-if="access.hasFeature(SYMBOL.FEATURES.CARBON)">
        <div class="flex justify-between mb-4 md:mb-0">
          <p class="text-xl font-medium">{{ $t('report.label.carbon_footprint_report') }}</p>
        </div>
        <div class="w-full md:w-auto">
          <ZButton
            variant="outline"
            class="w-full ml-2"
            @click="$router.push({ name: ROUTE.GENERATE_CARBON_REPORT, params: { exportType: 'footprint' } })"
          >
            {{ $t('report.command.generate_report') }}
          </ZButton>
        </div>
      </div>
      <div class="border rounded-xl p-6 shadow-md flex flex-col md:flex-row justify-between text-center md:text-left items-center mt-4"
           v-if="access.hasFeature(SYMBOL.FEATURES.CARBON)">
        <div class="flex justify-between mb-4 md:mb-0">
          <p class="text-xl font-medium">{{ $t('report.label.comparative_carbon_footprint_report') }}</p>
        </div>
        <div class="w-full md:w-auto">
          <ZButton
            variant="outline"
            class="w-full ml-2"
            @click="$router.push({ name: ROUTE.GENERATE_CARBON_REPORT, params: { exportType: 'comparative' } })"
          >
            {{ $t('report.command.generate_report') }}
          </ZButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue';
import {ZButton} from '@shadcn/components/ui/button';
import useAccessStore from "@/stores/access";

export default defineComponent({
  components: {ZButton},

  computed: {
    access: () => useAccessStore(),
  },

  data() {
    return {};
  },

  methods: {},
});
</script>
