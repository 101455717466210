<template>
  <Field symbol="email" class="col-span-4">
    <ZLabel for="email">
      {{ $t('userAccount.form.email') }}
    </ZLabel>
    <ChangePrompt field="email" :user="user" />
    <ZInput id="first_name" v-model="user.email" />
  </Field>
  <Field symbol="first_name" class="col-span-2">
    <ZLabel for="first_name">
      {{ $t('userAccount.form.first_name') }}
    </ZLabel>
    <ChangePrompt field="first_name" :user="user" />
    <ZInput id="first_name" v-model="user.first_name" />
  </Field>
  <Field symbol="last_name" class="col-span-2">
    <ZLabel for="last_name">
      {{ $t('userAccount.form.last_name') }}
    </ZLabel>
    <ChangePrompt field="last_name" :user="user" />
    <ZInput id="last_name" v-model="user.last_name" />
  </Field>
  <Field symbol="position" class="col-span-2">
    <ZLabel for="position">
      {{ $t('userAccount.form.position') }}
    </ZLabel>
    <ChangePrompt field="position" :user="user" />
    <ZInput id="position" v-model="user.position" />
  </Field>
  <Field symbol="phone" class="col-span-2">
    <ZLabel for="phone">
      {{ $t('userAccount.form.phone') }}
    </ZLabel>
    <ChangePrompt field="phone" :user="user" />
    <ZInput id="phone" v-model="user.phone" />
  </Field>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ZInput } from '@shadcn/components/ui/input';
import { ZLabel } from '@shadcn/components/ui/label';
import ChangePrompt from '@ui/UserAccounts/ChangePrompt.vue';
import Field from '@ui/FormField.vue';
import UserAccountData = App.Data.UserAccountData;

export default defineComponent({
  components: {
    ZInput,
    ZLabel,
    Field,
    ChangePrompt,
  },

  props: {
    user: {
      type: Object as PropType<UserAccountData>,
      required: true,
    },
  },
});
</script>
