<template>
  <DataLoader ref="loader">
  <teleport v-if="componentMounted" to="#toolbox">
    <router-link :to="{ name: ROUTE.DICTIONARY.COUNTRY_ADD }">
      <ZButton class="gap-2 w-full md:flex">
        {{ $t('dictionary.command.country_add') }}
        <PlusCircleIcon class="icon md" />
      </ZButton>
    </router-link>
  </teleport>
  <div class="py-4">
    <Paginator :data="countriesDataSet" />
    <div class="border rounded">
      <ZTable class="w-full">
        <ZTableHeader>
          <ZTableRow>
            <ZTableHead>{{ $t('dictionary.label.country_name') }}</ZTableHead>
            <ZTableHead class="text-center">{{ $t('dictionary.label.country_code') }}</ZTableHead>
            <ZTableHead class="text-center">{{ $t('dictionary.label.region_count') }}</ZTableHead>
            <ZTableHead class="text-center">{{ $t('dictionary.label.organization_count') }}</ZTableHead>
            <ZTableHead class="text-center">{{ $t('dictionary.label.date') }}</ZTableHead>
            <ZTableHead></ZTableHead>
          </ZTableRow>
        </ZTableHeader>
        <ZTableBody>
          <ZTableRow v-for="(item, index) in countriesDataSet.data" :key="index">
            <ZTableCell>
              {{ item.translations![0]!.translation }}
            </ZTableCell>
            <ZTableCell class="w-[80px] text-center">{{ item.code }}</ZTableCell>
            <ZTableCell class="w-[80px] text-center">{{ item.region_count }}</ZTableCell>
            <ZTableCell class="w-[80px] text-center">{{ item.organization_count }}</ZTableCell>
            <ZTableCell class="w-[120px] text-center"
              ><span class="text-xs text-gray-400">{{ formatDate(item.created_at!) }}</span></ZTableCell
            >
            <ZTableCell class="w-[50px] text-right">
              <ZButton variant="ghost" @click="edit(item)">
                <EllipsisHorizontalIcon class="icon sm" />
              </ZButton>
            </ZTableCell>
          </ZTableRow>
        </ZTableBody>
      </ZTable>
      <div v-if="countriesDataSet.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
        <ExclamationTriangleIcon class="icon lg" /> {{ $t('organization.message.data_not_found') }}
      </div>
    </div>
  </div>
  <Paginator :data="countriesDataSet" />
  <router-view></router-view>
  </DataLoader>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import { DataSet } from '@/utils/DataSet';
import Paginator from '@ui/Paginator.vue';
import { ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow } from '@shadcn/components/ui/table';
import { ExclamationTriangleIcon, PlusCircleIcon } from '@heroicons/vue/24/outline';
import { ZButton } from '@shadcn/components/ui/button';
import { ROUTE } from '@/router/routeNames';
import { Dialogs } from '@/types/Dialogs';
import { EllipsisHorizontalIcon } from '@heroicons/vue/24/solid';
import { getCountriesIndex, deleteCountry } from '@/api/dictionaries';
import CountryData = App.Data.CountryData;
import DataLoader from "@ui/DataLoader.vue";

export default defineComponent({
  components: {
    DataLoader,
    EllipsisHorizontalIcon,
    Paginator,
    PlusCircleIcon,
    ExclamationTriangleIcon,
    ZButton,
    ZTable,
    ZTableRow,
    ZTableBody,
    ZTableCell,
    ZTableHead,
    ZTableHeader,
  },

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      componentMounted: false,
      status: 'all',
      countriesDataSet: {} as DataSet<CountryData, null>,
      loader: null as any,
    };
  },

  watch: {
    search() {
      this.load();
    },

    status() {
      this.load();
    },

    $route(to: any) {
      if (to.name == ROUTE.DICTIONARY.COUNTRIES) {
        this.countriesDataSet.resetToFirstPage();
      }
    },
  },

  mounted() {
    this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;
    this.componentMounted = true;
    this.countriesDataSet = new DataSet<CountryData, null>((query: string) => getCountriesIndex().withLoader(this.loader).execute(query));
    this.countriesDataSet.load();
  },

  methods: {
    load() {
      this.countriesDataSet.resetToFirstPage();
    },

    edit(country: CountryData) {
      this.$router.push({ name: ROUTE.DICTIONARY.COUNTRY_EDIT, params: { countryId: country.id } });
    },

    async remove(country: CountryData) {
      if (country.can_be_deleted) {
        this.toast({
          variant: 'destructive',
          title: this.$t('dictionary.label.action_cant_be_deleted'),
          description: this.$t('dictionary.message.action_cant_be_deleted'),
        });
        return;
      }

      this.dialogs
        .confirm(this.$t('dictionary.label.action_delete'), this.$t('dictionary.message.action_delete'))
        .then(() => {
          this.dialogs.authorizeAction().then(() => {
            deleteCountry(country.id!.toString())
              .execute()
              .then(() => {
                this.toast({
                  title: this.$t('toasts.type.success'),
                  description: this.$t('action.message.deleted'),
                });
                this.countriesDataSet.load();
              });
          });
        });
    },
  },
});
</script>
