<template>
  <nav class="text-sm">
    <template v-for="(item, index) in items" :key="index">
      <!-- Params not needed in :to="" because vue-router automatically fills them in based on the current route -->
      <router-link :to="{ name: item.route }">
        {{ $t(item.title) }}
        <template v-if="index < items.length - 1"> / </template>
      </router-link>
    </template>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { BreadcrumbsItem } from '@/types/BreadcrumbsItem';

export default defineComponent({
  components: {
    // icons
    // shadcn components
  },

  data() {
    return {
      items: [] as Array<BreadcrumbsItem>,
    };
  },

  computed: {},

  watch: {},

  mounted() {
    this.empty();
  },

  methods: {
    empty() {
      this.items = [] as any;
    },

    addPathFromRoute() {
      for (let index in this.$route.matched) {
        let route = this.$route.matched[index];

        if (route.meta.breadcrumbs === false || route.meta?.title === undefined) {
          continue;
        }

        this.items.push({
          title: route.meta?.title as string,
          route: route.name as string,
        });
      }
    },
  },
});
</script>
