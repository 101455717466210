<template>
  <div class="py-4">
    <div class="grid sm:grid-cols-2 lg:grid-cols-1 lg:flex sm:gap-4 mt-5">
      <div class="w-full grid gap-2 mb-4">
        <ZLabel>{{ $t('files.label.search') }}</ZLabel>
        <ZInput v-model="filters.search" type="search" />
      </div>
      <div class="lg:w-[300px] grid gap-2 mb-4">
        <ZLabel for="technical_condition">
          {{ $t('files.label.extension') }}
        </ZLabel>
        <ZSelect v-model="filters.ext" class="w-full">
          <ZSelectTrigger class="w-full">
            <div class="flex flex-row gap-2 whitespace-nowrap">
              <ZSelectValue />
            </div>
          </ZSelectTrigger>
          <ZSelectContent>
            <ZSelectGroup>
              <ZSelectItem value="all">{{ $t('files.extensions.all') }}</ZSelectItem>
              <ZSelectItem v-for="(ext, key) in extensions" :key="key" :value="key.toString()">
                {{ $t(ext) }}
              </ZSelectItem>
            </ZSelectGroup>
          </ZSelectContent>
        </ZSelect>
      </div>
      <div class="md:w-[300px] grid gap-2 mb-4">
        <ZLabel for="technical_condition">
          {{ $t('files.label.sort') }}
        </ZLabel>
        <ZSelect v-model="filters.sort" class="w-full">
          <ZSelectTrigger class="w-full">
            <div class="flex flex-row gap-2 whitespace-nowrap">
              <ZSelectValue />
            </div>
          </ZSelectTrigger>
          <ZSelectContent>
            <ZSelectGroup>
              <ZSelectItem value="newest">{{ $t('files.sorting.newest') }}</ZSelectItem>
              <ZSelectItem value="oldest">{{ $t('files.sorting.oldest') }}</ZSelectItem>
              <ZSelectItem value="asc">{{ $t('files.sorting.asc') }}</ZSelectItem>
            </ZSelectGroup>
          </ZSelectContent>
        </ZSelect>
      </div>
      <div class="md:w-[300px] grid gap-2 mb-4">
        <ZLabel for="technical_condition">
          {{ $t('files.label.author') }}
        </ZLabel>
        <ZSelect v-model="filters.user_id as string" class="w-full">
          <ZSelectTrigger class="w-full">
            <div class="flex flex-row gap-2 whitespace-nowrap">
              <ZSelectValue />
            </div>
          </ZSelectTrigger>
          <ZSelectContent>
            <ZSelectGroup>
              <ZSelectItem value="all">{{ $t('files.extensions.all') }}</ZSelectItem>
              <ZSelectItem v-for="(user, key) in users" :key="key" :value="user.id">
                {{ user.first_name }} {{ user.last_name }}
              </ZSelectItem>
            </ZSelectGroup>
          </ZSelectContent>
        </ZSelect>
      </div>
    </div>
  </div>
  <Paginator :data="fileDataSet" />
  <div class="border rounded">
    <ZTable class="w-full">
      <ZTableHeader>
        <ZTableRow>
          <ZTableHead
            ><b>{{ $t('files.label.file') }}</b></ZTableHead
          >
          <ZTableHead class="text-center"
            ><b>{{ $t('files.label.extension') }}</b></ZTableHead
          >
          <ZTableHead class="text-center"
            ><b>{{ $t('files.label.size') }}</b></ZTableHead
          >
          <ZTableHead class="text-center"
            ><b>{{ $t('dictionary.label.used') }}</b></ZTableHead
          >
          <ZTableHead class="text-center"
            ><b>{{ $t('files.label.created') }}</b></ZTableHead
          >
          <ZTableHead class="text-center"
            ><b>{{ $t('files.label.author') }}</b></ZTableHead
          >
          <ZTableHead class="text-center"></ZTableHead>
        </ZTableRow>
      </ZTableHeader>
      <ZTableBody>
        <ZTableRow v-for="(file, index) in fileDataSet.data" :key="index">
          <ZTableCell class="w-full">
            <div class="flex items-center gap-4 justify-start">
              <div v-if="isFileImage(file.mime)">
                <img class="min-w-[50px] w-[50px] h-[50px] rounded-xl object-cover overflow-hidden" :src="file!.url!" />
              </div>
              <div class="font-medium">{{ file.name }}</div>
              <div class="text-xs text-gray-400">{{ file.description }}</div>
            </div>
          </ZTableCell>
          <ZTableCell class="whitespace-nowrap">
            <div class="text-xs text-center">{{ file.ext }}</div>
          </ZTableCell>
          <ZTableCell class="whitespace-nowrap">
            <div class="text-xs text-center">{{ readableFileSize(file.size) }}</div>
          </ZTableCell>
          <ZTableCell class="whitespace-nowrap">
            <div class="text-xs text-center">
              <div>{{ $t('files.label.count_assets') }}: {{ file!.assigns_count!.asset! }}</div>
              <div>{{ $t('files.label.contracts_assets') }}: {{ file!.assigns_count!.contract_asset! }}</div>
            </div>
          </ZTableCell>
          <ZTableCell class="whitespace-nowrap">
            <div class="text-xs text-center">{{ formatDate(file!.created_at!) }}</div>
          </ZTableCell>
          <ZTableCell class="whitespace-nowrap">
            <div class="text-xs text-center">{{ file.author_name }}</div>
          </ZTableCell>
          <ZTableCell class="text-right whitespace-nowrap">
            <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <ZButton variant="ghost">
                  <EllipsisHorizontalIcon class="icon sm" />
                </ZButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuGroup>
                  <DropdownMenuItem @click="getFileDownloadUrl(file!.id!)">
                    <span>{{ $t('files.command.download') }}</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    @click="editFile(file!.id!)"
                    v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"
                  >
                    <span>{{ $t('files.command.edit') }}</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </ZTableCell>
        </ZTableRow>
      </ZTableBody>
    </ZTable>
    <div v-if="fileDataSet.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
      <ExclamationTriangleIcon class="icon lg" />
      {{ $t('files.message.data_not_found') }}
    </div>
  </div>
  <Paginator :data="fileDataSet" />
  <MountedTeleport to="#toolbox">
    <div v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)" class="flex flex-row gap-3">
      <ZButton class="flex gap-2 w-full" @click="addFile">
        {{ $t('files.command.add') }}
        <PlusCircleIcon class="icon md" />
      </ZButton>
    </div>
  </MountedTeleport>
  <FileAttach ref="addFileDialog" :on-update="reload" type="default" />
  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZButton } from '@shadcn/components/ui/button';
import MountedTeleport from '@ui/MountedTeleport.vue';
import { ExclamationTriangleIcon, PlusCircleIcon } from '@heroicons/vue/24/outline';
import {
  ZSelect,
  ZSelectContent,
  ZSelectGroup,
  ZSelectItem,
  ZSelectTrigger,
  ZSelectValue,
} from '@shadcn/components/ui/select';
import { ZLabel } from '@shadcn/components/ui/label';
import { ZInput } from '@shadcn/components/ui/input';
import { ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow } from '@shadcn/components/ui/table';
import Paginator from '@ui/Paginator.vue';
import { DataSet } from '@/utils/DataSet';
import { getFileDownloadUrl, getFiles, getUserAccountsCompact } from '@/api';
import useAccessStore from '@/stores/access';
import FileData = App.Data.FileData;
import FileRequestData = App.Data.FileRequestData;
import { readableFileSize } from '@/utils/readableFileSize';
import symbolsStore from '@/stores/symbols';
import FileAttach from '@ui/File/FileAttach.vue';
import { ROUTE } from '@/router/routeNames';
import { EllipsisHorizontalIcon } from '@modules/@heroicons/vue/24/solid';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@shadcn/components/ui/dropdown-menu';
import { inject } from '@modules/vue';
import { toast } from '@shadcn/components/ui/toast';
import { isFileImage } from '@/utils/isFileImage';

interface FileFiltersInterface {
  ext?: App.Enums.FileExtensions | string;
  sort: App.Enums.FileSortType | string;
  user_id?: string | number;
  search?: string;
  page?: number | null;
  perPage?: number | null;
  mine?: number | null;
  resourceType?: App.Enums.FileDestinationType | string | null;
  resourceId?: number | null;
  contractedAssetId?: number | null;
};

export default defineComponent({
  components: {
    EllipsisHorizontalIcon,
    DropdownMenu,
    FileAttach,
    ZTableHead,
    ZTableBody,
    ZTable,
    Paginator,
    ZTableHeader,
    ZTableRow,
    ZTableCell,
    ExclamationTriangleIcon,
    PlusCircleIcon,
    MountedTeleport,
    ZButton,
    ZSelect,
    ZSelectContent,
    ZSelectGroup,
    ZSelectItem,
    ZSelectTrigger,
    ZSelectValue,
    ZLabel,
    ZInput,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  },

  setup() {
    return {
      toast: inject('toast') as typeof toast,
    };
  },

  data() {
    return {
      fileDataSet: {} as DataSet<FileData, FileRequestData>,
      extensions: [] as any,
      users: [] as any,
      filters: {
        search: '',
        page: 1,
        perPage: 15,
        ext: 'all',
        sort: 'newest',
        user_id: 'all',
      } as FileFiltersInterface,
    };
  },

  computed: {
    access: () => useAccessStore(),
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        this.fileDataSet.setParams(this.resolveFilters() as FileRequestData, true).resetToFirstPage();
      },
    },

    $route(to: any) {
      if (to.name == ROUTE.FILE_LIST) {
        this.fileDataSet.load();
      }
    },
  },

  async mounted() {
    const symbols = symbolsStore();
    this.extensions = await symbols.get('file_extensions');
    await getUserAccountsCompact()
      .execute()
      .then(response => {
        this.users = response.data;
      });

    await this.fetchData();
  },

  methods: {
    isFileImage,
    getFileDownloadUrl,
    readableFileSize,
    router() {
      return this.$router;
    },

    async addFile() {
      (this.$refs['addFileDialog'] as typeof FileAttach).show();
    },

    reload() {
      this.toast({
        title: this.$t('common.success.header'),
        description: this.$t('files.message.added'),
      });
      this.fetchData();
    },

    async fetchData() {
      try {
        this.fileDataSet = new DataSet<FileData, FileRequestData>((query: string) =>
          getFiles(this.resolveFilters() as FileRequestData).execute(query),
        ).setParams(this.resolveFilters() as FileRequestData);
        await this.fileDataSet.load();
      } catch (e) {}
    },

    resolveFilters() {
      if (this.filters.ext === 'all') {
        delete this.filters['ext'];
      }
      if (this.filters.user_id === 'all') {
        delete this.filters['user_id'];
      }
      return this.filters;
    },

    editFile(id: number) {
      this.$router.push({ name: ROUTE.FILE_EDIT, params: { fileId: id } });
    },
  },
});
</script>
