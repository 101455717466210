<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('contracts.label.create_contract') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('contracts.message.create_contract') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <FormValidator ref="form" :errors="errors">
          <ContractForm :contract="contract" :priorities="priorities" />
        </FormValidator>
        <ZDialogFooter>
          <Action :disabled="false" @proceed="update">{{ $t('contracts.command.create_contract') }} </Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import CategoryCompactData = App.Data.CategoryCompactData;
import ContractData = App.Data.ContractData;
import { getContract, updateContract } from '@/api/contract';
import ContractForm from '@ui/Contracts/ContractForm.vue';
import { PRIORITY } from '@/consts';
import { KeyValue } from '@/types/Common';
import {ValidationErrors} from "@/types/ValidationErrors";

export default defineComponent({
  components: {
    ContractForm,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    contractId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      contract: {} as ContractData,
      categories: {} as CategoryCompactData,
      state: 'loading' as ComponentLoadState,
      priorities: {} as KeyValue,
      errors: null as null | ValidationErrors,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      this.contract = (await getContract(this.contractId).execute()).data;
      for (const [key, value] of Object.entries(PRIORITY)) {
        this.priorities[key] = this.$t('contracts.priority.' + value);
      }
      this.state = 'loaded';
    } catch (error: any) {
      this.state = error;
    }
  },

  methods: {
    update(action: any) {
      updateContract(this.contractId, this.contract)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('contracts.message.contract_saved'),
          });
          this.$emit('saved');
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        });
    },
  },
});
</script>
