<template>
  <Field symbol="name" class="col-span-5 lg:col-span-2">
    <ZLabel for="name">
      {{ $t('tag.dialog.form.name') }}
    </ZLabel>
    <ZInput id="name" v-model="tag.name" />
  </Field>
  <Field symbol="color" class="col-span-5 lg:col-span-2">
    <div class="flex items-end">
      <ZLabel for="color" class="w-full">
        {{ $t('tag.dialog.form.color') }}
        <div class="mt-2 border flex items-center rounded-md mr-1">
          <ZInput id="color" v-model="tag.color" class="w-[40px] h-[38px] rounded-full border-0" type="color" />
          {{ tag.color ? $t('tag.dialog.form.change_color') : $t('tag.dialog.form.choose_color') }}
        </div>
      </ZLabel>
      <ZButton v-if="tag.color" variant="outline" @click.prevent="tag.color = null">{{
        $t('tag.dialog.form.delete_color')
      }}</ZButton>
    </div>
  </Field>
  <Field symbol="active" class="col-span-5 lg:col-span-4">
    <div
      class="lg:flex items-center justify-between border rounded-md py-2 px-3"
      :class="tag.active && 'border-green-600 bg-green-50'"
    >
      <div class="mb-4 lg:md-0 lg:mr-4">
        <ZLabel for="active">
          {{ $t('tag.dialog.form.active') }} -
          <b>{{ tag.active ? $t('tag.dialog.form.visible') : $t('tag.dialog.form.hidden') }}</b>
        </ZLabel>
        <div class="text-sm">
          {{ tag.active ? $t('tag.dialog.form.active_text') : $t('tag.dialog.form.unactive_text') }}
        </div>
      </div>
      <ZSwitch id="active" :checked="tag.active" @update:checked="tag.active = !tag.active" />
    </div>
  </Field>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ZInput } from '@shadcn/components/ui/input';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';
import TagData = App.Data.TagData;
import { ZSwitch } from '@shadcn/components/ui/switch';
import { ZButton } from '@shadcn/components/ui/button';

export default defineComponent({
  components: {
    ZButton,
    ZSwitch,
    ZInput,
    ZLabel,
    Field,
  },

  props: {
    tag: {
      type: Object as PropType<TagData>,
      required: true,
    },
  },
});
</script>
