<template>
  <div class="p-4 my-4 border rounded flex gap-2 items-center">
    <ExclamationCircleIcon class="icon lg"/>
    {{ $t('app.text.page_not_found') }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {ExclamationCircleIcon} from "@modules/@heroicons/vue/24/outline";

export default defineComponent({
  components: {
    // icons
    ExclamationCircleIcon,
    // shadcn components
  },

  inject: {
    navigation: { from: 'navigation' },
  },

  data() {
    return {};
  },

  methods: {},
});
</script>
