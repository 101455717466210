<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('assetModels.label.edit_asset_model_data') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('assetModels.message.edit_asset_model_data') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <div>
          <FormValidator ref="form" class="grid grid-cols-4 gap-4 py-4">
            <AssetModelForm :asset-model="assetModel" :categories="categories" />
          </FormValidator>
        </div>
        <ZDialogFooter>
          <Action @proceed="update">{{ $t('assetModels.command.save_changes') }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import { getAssetModel, updateAssetModel } from '@/api/assetModel';
import AssetModelForm from '@ui/AssetModels/AssetModelForm.vue';
import { getCompactCategories } from '@/api/category';
import AssetModelData = App.Data.AssetModelData;
import CategoryCompactData = App.Data.CategoryCompactData;

export default defineComponent({
  components: {
    AssetModelForm,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    modelId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      assetModel: {} as AssetModelData,
      categories: {} as CategoryCompactData,
      state: 'loading' as ComponentLoadState,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      this.assetModel = (await getAssetModel(this.modelId).execute()).data;
      const categoriesResponse = await getCompactCategories().execute();
      this.categories = categoriesResponse.data;
      this.state = 'loaded';
    } catch (error: any) {
      this.state = error;
    }
  },

  methods: {
    update(action: any) {
      updateAssetModel(this.modelId, this.assetModel)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('assetModels.message.model_saved'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
