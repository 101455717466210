import type { RouteRecordRaw } from 'vue-router';
import UserAccounts from '@views/UserAccounts/UserAccounts.vue';
import UserAccountDetails from '@views/UserAccounts/UserAccountDetails.vue';
import NewUserAccount from '@views/UserAccounts/NewUserAccount.vue';
import { ROUTE } from './routeNames';
import EditUserAccount from '@views/UserAccounts/EditUserAccount.vue';
import ChangeAccountPassword from '@views/UserAccounts/ChangeAccountPassword.vue';
import Manage2FA from '@views/UserAccounts/Manage2FA.vue';
import {SYMBOL} from "@/types/symbols";

export const userAccountRoutes: RouteRecordRaw[] = [
  {
    path: 'userAccounts',
    name: 'userAccount.layout',
    redirect: () => {
      return { name: ROUTE.USER_ACCOUNT_LIST };
    },
    meta: {
      breadcrumbs: true,
      title: 'userAccount.label.list_title',
      permission: [{type: SYMBOL.PERMISSION.ORG_ADMIN}],
    },
    children: [
      {
        path: '',
        component: UserAccounts,
        name: ROUTE.USER_ACCOUNT_LIST,
        children: [
          {
            path: 'create/:type',
            component: NewUserAccount,
            name: ROUTE.USER_ACCOUNT_ADD,
            props: true,
          },
        ],
      },
      {
        path: ':userId',
        component: UserAccountDetails,
        name: ROUTE.USER_ACCOUNT_DETAILS,
        meta: {
          breadcrumbs: true,
          title: 'userAccount.label.details_title',
        },
        props: true,
        children: [
          {
            path: 'edit',
            component: EditUserAccount,
            name: ROUTE.USER_ACCOUNT_EDIT,
            props: true,
          },
          {
            path: 'change-password',
            component: ChangeAccountPassword,
            name: ROUTE.USER_ACCOUNT_CHANGE_PASSWORD,
            props: true,
          },
          {
            path: 'manage-2fa',
            component: Manage2FA,
            name: ROUTE.USER_ACCOUNT_MANAGE_2FA,
            props: true,
          },
        ],
      },
    ],
  },
];
