<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[925px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('organization.label.manage_organization') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('organization.message.manage_organization') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded' && features">
        <FormValidator ref="form" class="grid lg:grid-cols-2 gap-4 py-4">
            <fieldset class="border rounded p-4 flex flex-col gap-8">
              <legend class="px-2">{{ $t('organization.label.additional_features') }}</legend>

              <Field symbol="feature_carbonfootprint">
                <div class="flex flex-row gap-2 items-center">
                  <ZSwitch :checked="features.feature_carbonfootprint" @update:checked="features.feature_carbonfootprint = !features.feature_carbonfootprint" />
                  <span class="flex-1">{{ $t('organization.label.feature_carbonfootprint') }}</span>
                </div>
              </Field>

              <Field symbol="feature_finance">
                <div class="flex flex-row gap-2 items-center">
                  <ZSwitch :checked="features.feature_finance" @update:checked="features.feature_finance = !features.feature_finance" />
                  <span class="flex-1">{{ $t('organization.label.feature_finance') }}</span>
                </div>
              </Field>

              <Field symbol="feature_inventory">
                <div class="flex flex-row gap-2 items-center">
                  <ZSwitch :checked="features.feature_inventory" @update:checked="features.feature_inventory = !features.feature_inventory" />
                  <span class="flex-1">{{ $t('organization.label.feature_inventory') }}</span>
                </div>
              </Field>

              <Field symbol="feature_documents">
                <div class="flex flex-row gap-2 items-center">
                  <ZSwitch :checked="features.feature_documents" @update:checked="features.feature_documents= !features.feature_documents" />
                  <span class="flex-1">{{ $t('organization.label.feature_documents') }}</span>
                </div>
              </Field>
            </fieldset>
          <fieldset class="border rounded p-4 flex flex-col gap-8">
            <legend class="px-2">{{ $t('organization.label.security_features') }}</legend>

            <Field symbol="feature_carbonfootprint">
              <div class="flex flex-row gap-2 items-center">
                <ZSwitch :checked="restrict_domains" @update:checked="restrict_domains = !restrict_domains" />
                <span class="flex-1">{{ $t('organization.label.security_restrict_domains') }}</span>
              </div>
              <div class="flex flex-row gap-2 items-stretch w-full">
                <ZInput
                  v-if="restrict_domains"
                  v-model="features.security_restricted_domains"
                  :placeholder="$t('organization.label.security_restrict_domains_ph')"
                />
              </div>
            </Field>

            <Field symbol="feature_carbonfootprint">
              <div class="flex flex-row gap-2 items-center">
                <ZSwitch :checked="features.security_force_2factor" @update:checked="features.security_force_2factor = !features.security_force_2factor" />
                <span class="flex-1">{{ $t('organization.label.security_force_2factor') }}</span>
              </div>
            </Field>

          </fieldset>
        </FormValidator>

        <ZDialogFooter>
          <Action @proceed="update">{{ $t('organization.command.save_changes') }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogHeader,
  ZDialogTitle,
  ZDialogFooter,
} from '@shadcn/components/ui/dialog';
import {getOrganizationFeatures, saveOrganizationFeatures} from '@/api/organization';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import Field from '@ui/FormField.vue';
import access from '@/stores/access';
import {ZInput} from "@shadcn/components/ui/input";
import {ZSwitch} from "@shadcn/components/ui/switch";
import OrganizationFeaturesData = App.Data.OrganizationFeaturesData;

export default defineComponent({
  components: {
    ZSwitch, ZInput,
    Field,
    FormValidator,
    IconAlert,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogHeader,
    ZDialogTitle,
    ZDialogFooter,
  },

  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      features: {} as OrganizationFeaturesData,
      isDialogOpen: true,
      restrict_domains: false as boolean,
      state: 'loading' as ComponentLoadState,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      const [organizationFeaturesResponse] = await Promise.all([
        getOrganizationFeatures(this.organizationId).execute(),
      ]);

      this.features = organizationFeaturesResponse.data;

      this.restrict_domains = this.features?.security_restricted_domains != null;

      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
    }
  },

  methods: {
    access,
    update(action: any) {

      if (this.restrict_domains === false) {
        this.features.security_restricted_domains = null;
      }

      saveOrganizationFeatures(this.organizationId, this.features)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('organization.message.organization_saved'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
