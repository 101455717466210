<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent
      class="lg:max-w-[1300px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto flex flex-col"
    >
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('asset.label.transfer_assets') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('asset.message.transfer_assets') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <h2>
          {{ $t('asset.message.assets_amount') }} <b>{{ assets.length }}</b>
        </h2>
        <div class="flex lg:flex-row gap-6 flex-col mt-0">
          <div class="flex-1 max-h-[50vh] max-w-[100vw] overflow-auto">
            <ZTable class="w-full">
              <ZTableHeader>
                <ZTableRow>
                  <ZTableHead></ZTableHead>
                  <ZTableHead>{{ $t('asset.label.name') }}</ZTableHead>
                  <ZTableHead>{{ $t('asset.label.asset_model') }}</ZTableHead>
                  <ZTableHead>{{ $t('asset.label.inventory_number') }}</ZTableHead>
                  <ZTableHead>{{ $t('asset.label.category') }}</ZTableHead>
                </ZTableRow>
              </ZTableHeader>
              <ZTableBody>
                <ZTableRow v-for="(item, index) in assets" :key="index">
                  <ZTableCell>
                    <img
                      v-if="item.thumb"
                      class="min-w-[50px] w-[50px] h-[50px] object-cover rounded-xl"
                      :src="item.thumb"
                      alt="primaryImage"
                    />
                  </ZTableCell>
                  <ZTableCell>
                    <b>{{ item.name }}</b>
                  </ZTableCell>
                  <ZTableCell>
                    {{ item.asset_model?.name }}
                  </ZTableCell>
                  <ZTableCell>
                    {{ item.asset_number }}
                  </ZTableCell>
                  <ZTableCell>{{ item.category ? item.category.name : $t('asset.label.lack') }}</ZTableCell>
                </ZTableRow>
              </ZTableBody>
            </ZTable>
          </div>
          <div class="lg:w-1/3">
            <Tabs
              v-model="currentTab"
              :default-value="withinOrganization ? 'withinOrganization' : 'betweenOrganizations'"
              class="w-full"
            >
              <TabsList class="grid w-full grid-cols-2" v-if="access.operator()">
                <TabsTrigger value="withinOrganization">
                  {{ $t('asset.label.within_organization') }}
                </TabsTrigger>
                <TabsTrigger value="betweenOrganizations">
                  {{ $t('asset.label.between_organizations') }}
                </TabsTrigger>
              </TabsList>
              <TabsContent value="withinOrganization" class="">
                <FormValidator ref="form" :errors="errors">
                  <AssetTransferForm
                    :selected-branch-id="selectedBranchId"
                    :selected-organization-id="organizationId"
                    :within-organization="true"
                    @update:selected-branch-id="updateSelectedBranchId"
                    @update:selected-organization-id="updateSelectedOrganizationId"
                  />
                </FormValidator>
              </TabsContent>
              <TabsContent value="betweenOrganizations" class="">
                <FormValidator ref="form" :errors="errors">
                  <AssetTransferForm
                    :selected-branch-id="selectedBranchId"
                    :selected-organization-id="selectedOrganizationId"
                    :within-organization="false"
                    @update:selected-branch-id="updateSelectedBranchId"
                    @update:selected-organization-id="updateSelectedOrganizationId"
                  />
                </FormValidator>
              </TabsContent>
            </Tabs>
            <ZDialogFooter class="pt-4">
              <Action class="w-full" :disabled="canSubmit" @proceed="store">{{
                $t('asset.command.transfer_assets')
              }}</Action>
            </ZDialogFooter>
          </div>
        </div>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import AssetTransferForm from '@ui/Assets/AssetTransferForm.vue';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcn/components/ui/tabs';
import MinimalAssetData = App.Data.MinimalAssetData;
import { ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow } from '@shadcn/components/ui/table';
import { transferAssets } from '@/api/asset';
import useUserStore from '@/stores/auth';
import { ValidationErrors } from '@/types/ValidationErrors';
import useAccessStore from "@/stores/access";

export default defineComponent({
  components: {
    ZTableHeader,
    ZTableCell,
    ZTableHead,
    ZTableBody,
    ZTable,
    ZTableRow,
    Tabs,
    TabsTrigger,
    TabsList,
    TabsContent,
    AssetTransferForm,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    assets: {
      type: Array<MinimalAssetData>,
      required: true,
    },
  },

  emits: ['transferDialogClosed', 'saved'],

  setup() {
    const currentTab = ref('withinOrganization');

    return {
      currentTab,
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      state: 'loaded' as ComponentLoadState,
      withinOrganization: true,
      selectedBranchId: '',
      selectedOrganizationId: '',
      organizationId: useUserStore().chosenOrganization?.toString() || '',
      errors: null as null | ValidationErrors,
    };
  },

  computed: {
    access: () => useAccessStore(),

    canSubmit() {
      return !(this.withinOrganization ? this.selectedBranchId : this.selectedBranchId && this.selectedOrganizationId);
    },
  },

  watch: {
    isDialogOpen() {
      this.$emit('transferDialogClosed', true);
    },

    currentTab(newValue) {
      this.withinOrganization = newValue != 'betweenOrganizations';
      this.selectedBranchId = '';
      this.selectedOrganizationId = '';
    },
  },

  methods: {
    useUserStore,
    updateSelectedBranchId(newBranchId: string) {
      this.selectedBranchId = newBranchId;
    },

    updateSelectedOrganizationId(newOrganizationId: string) {
      this.selectedOrganizationId = newOrganizationId;
    },

    async store() {
      await transferAssets({
        asset_ids: this.assets.map(obj => obj.id),
        organization_id: this.selectedOrganizationId,
        branch_id: this.selectedBranchId,
        within_organization: this.withinOrganization,
      })
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('asset.message.transfer_success'),
          });
          this.$emit('saved');
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        });
    },
  },
});
</script>
