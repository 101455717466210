<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('assetModels.label.edit_asset_model_attributes') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('assetModels.message.edit_asset_model_attributes') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <div>
          <FormValidator ref="form" class="py-4">
            <EditAssetModelAttributesForm :asset-model="assetModel"/>
          </FormValidator>
        </div>
        <ZDialogFooter>
          <Action @proceed="update">{{ $t('assetModels.command.save_changes') }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import {ComponentLoadState} from '@/types/ComponentLoadState';
import {getAssetModel, saveAssetModelAttributes} from '@/api/assetModel';
import EditAssetModelAttributesForm from '@ui/AssetModels/EditAssetModelAttributesForm.vue';
import AssetModelData = App.Data.AssetModelData;

export default defineComponent({
  components: {
    EditAssetModelAttributesForm,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    modelId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      assetModel: {} as AssetModelData,
      categories: [],
      state: 'loading' as ComponentLoadState,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  mounted() {
    try {
      getAssetModel(this.modelId)
        .execute()
        .then(response => {
          this.assetModel = response.data;
          this.state = 'loaded';
        });
    } catch (error: any) {
      this.state = error;
    }
  },

  methods: {
    update(action: any) {
      if (!this.assetModel) {
        return;
      }

      const attributes = this.assetModel.assetModelValues!.filter(item => item.category_attribute_id == null);

      saveAssetModelAttributes(this.modelId, attributes)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('assetModels.message.attributes_saved'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
