<template>
  <div
    v-if="data && data.meta && data.meta.total > 0"
    class="w-full flex flex-col md:flex-row justify-between py-2 items-center"
  >
    <div class="hidden md:flex flex-row gap-1 text-sm items-center">
      <SelectField v-if="pageSize && data.meta.total > 10" v-model="perPage" :options="pageOptions"></SelectField>
      {{ $t('pagination.from') }}
      <b>{{ data.meta.from }}</b>
      {{ $t('pagination.to') }}
      <b>{{ data.meta.to }}</b>
      {{ $t('pagination.of') }}
      <b>{{ data.meta.total }}</b>
    </div>
    <div v-if="data.meta.last_page > 1" class="flex gap-4 items-center">
      <a
        href="#"
        class="border rounded-lg text-sm py-1.5 px-2.5 flex gap-2"
        :class="{ 'opacity-50': !hasPrevious, 'pointer-events-none': !hasPrevious }"
        @click.prevent="previousPage"
        ><ChevronDoubleLeftIcon class="icon xs m-1" />
        <span class="hidden lg:block">{{ $t('pagination.previous') }}</span></a
      >
      <span class="flex gap-2 text-sm items-center"
        ><span class="hidden lg:show">{{ $t('pagination.page') }}</span>
        <ZInput v-model="page" class="w-12 h-8 sm text-center" @input="jumpToCurrent" /> {{ $t('pagination.of') }}
        {{ data.meta.last_page }}</span
      >
      <a
        href="#"
        class="border rounded-lg text-sm py-1.5 px-2.5 flex gap-2"
        :class="{ 'opacity-50': !hasNext, 'pointer-events-none': !hasNext }"
        @click.prevent="nextPage"
        ><span class="hidden lg:block">{{ $t('pagination.next') }}</span> <ChevronDoubleRightIcon class="icon xs m-1"
      /></a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon } from '@heroicons/vue/24/outline';
import { ZInput } from '@shadcn/components/ui/input';
import { DataSet } from '@/utils/DataSet';
import SelectField from '@ui/SelectField.vue';

export default defineComponent({
  components: {
    SelectField,
    ZInput,
    ChevronDoubleRightIcon,
    ChevronDoubleLeftIcon,
  },

  props: {
    data: {
      type: Object as PropType<DataSet<any, any> | null>,
      required: false,
      default: null,
    },

    pageSize: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      page: 1,
      perPage: 10,
      pageOptions: {
        '10': '10',
        '20': '20',
        '50': '50',
        '100': '100',
      } as {},
    };
  },

  computed: {
    hasPrevious() {
      return this.page > 1;
    },

    hasNext() {
      return this.page < (this.data?.meta?.last_page ?? 1);
    },
  },

  watch: {
    'data.meta.current_page'() {
      this.page = this.data?.meta?.current_page ?? 1;
    },

    page() {
      const lastPage = this.data?.meta?.last_page ?? 1;
      if (this.page > lastPage) {
        this.$nextTick(() => (this.page = lastPage));
      }
    },

    perPage() {
      if (this.pageSize) {
        this.data?.setPageSize(this.perPage);
        this.jumpToCurrent();
      }
    },
  },

  methods: {
    jumpToCurrent() {
      this.data?.jumpTo(this.page);
    },

    previousPage() {
      if (this.hasPrevious) {
        this.data?.previousPage();
      }
    },

    nextPage() {
      if (this.hasNext) {
        this.data?.nextPage();
      }
    },
  },
});
</script>
