<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('tag.dialog.add_title') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('tag.dialog.add_message') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <div>
        <FormValidator ref="form" class="grid grid-cols-4 gap-4 py-4">
          <TagForm :tag="tag" />
        </FormValidator>
      </div>
      <ZDialogFooter>
        <Action @proceed="store">{{ $t('tag.add_tag') }}</Action>
      </ZDialogFooter>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogHeader,
  ZDialogTitle,
  ZDialogFooter,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import TagForm from '@ui/Tags/TagForm.vue';
import TagData = App.Data.TagData;
import { createTag } from '@/api';
import { ROUTE } from '@/router/routeNames';

export default defineComponent({
  components: {
    TagForm,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogHeader,
    ZDialogTitle,
    ZDialogFooter,
  },

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      tag: {
        name: '',
        color: '',
        active: true,
      } as TagData,

      isDialogOpen: true,
      state: 'loading' as ComponentLoadState,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  methods: {
    store(action: any) {
      createTag(this.tag)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('tag.dialog.message_created'),
          });
          this.$router.push({ name: ROUTE.TAGS });
        });
    },
  },
});
</script>
