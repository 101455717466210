<template>
  <Field symbol="name" class="col-span-5">
    <ZLabel for="name" class="flex flex-row justify-between">
      {{ $t('dictionary.label.action_name_field') }}
      <TranslationPopup v-if="action.id" type="action" :identifier="action.id!"></TranslationPopup>
    </ZLabel>
    <ZInput id="name" v-model="action.name" />
  </Field>
  <Field symbol="hint" class="col-span-5">
    <ZLabel for="hint" class="flex flex-row justify-between">
      {{ $t('dictionary.label.action_hint_field') }}
      <TranslationPopup v-if="action.id" type="action" :identifier="action.id!" symbol="hint"></TranslationPopup>
    </ZLabel>
    <ZInput id="hint" v-model="action.hint" />
  </Field>
  <ZLabel for="hint">
    {{ $t('dictionary.label.action_optional_fields') }}
  </ZLabel>
  <div class="col-span-5 border rounded p-4">
    <Field symbol="location_title">
      <div class="flex flex-row gap-2 items-center">
        <ZSwitch
          :checked="action.is_asset_required"
          @update:checked="action.is_asset_required = !action.is_asset_required"
        />
        <span class="flex-1">{{ $t('dictionary.label.action_is_asset_required') }}</span>
      </div>
    </Field>
  </div>
  <div class="col-span-5 border rounded p-4">
    <Field symbol="location_title">
      <div class="flex flex-row gap-2 items-center">
        <ZSwitch :checked="action.has_location" @update:checked="action.has_location = !action.has_location" />
        <span class="flex-1">{{ $t('dictionary.label.action_has_location') }}</span>
        <div class="flex flex-row gap-2 items-stretch w-1/2">
          <ZInput
            v-if="action.has_location"
            v-model="action.location_title"
            :placeholder="$t('dictionary.label.action_location_field_name')"
          />
          <TranslationPopup
            v-if="action.has_location && action.id"
            type="action"
            :identifier="action.id!"
            symbol="location"
          ></TranslationPopup>
        </div>
      </div>
    </Field>
  </div>
  <div class="col-span-5 border rounded p-4">
    <Field symbol="amount_title">
      <div class="flex flex-row gap-2 items-center">
        <ZSwitch :checked="action.has_amount" @update:checked="action.has_amount = !action.has_amount" />
        <span class="flex-1">{{ $t('dictionary.label.action_has_amount') }}</span>
        <div class="flex flex-row gap-2 items-stretch w-1/2">
          <ZInput
            v-if="action.has_amount"
            v-model="action.amount_title"
            :placeholder="$t('dictionary.label.action_amount_field_name')"
          />
          <TranslationPopup
            v-if="action.has_amount && action.id"
            type="action"
            :identifier="action.id!"
            symbol="amount"
          ></TranslationPopup>
        </div>
      </div>
    </Field>
  </div>
  <div class="col-span-5 border rounded p-4">
    <Field symbol="deadline_title">
      <div class="flex flex-row gap-2 items-center">
        <ZSwitch :checked="action.has_deadline" @update:checked="action.has_deadline = !action.has_deadline" />
        <span class="flex-1">{{ $t('dictionary.label.action_has_deadline') }}</span>
        <div class="flex flex-row gap-2 items-stretch w-1/2">
          <ZInput
            v-if="action.has_deadline"
            v-model="action.deadline_title"
            :placeholder="$t('dictionary.label.action_deadline_field_name')"
          />
          <TranslationPopup
            v-if="action.has_deadline && action.id"
            type="action"
            :identifier="action.id!"
            symbol="deadline"
          ></TranslationPopup>
        </div>
      </div>
    </Field>
  </div>
  <div class="col-span-5 border rounded p-4">
    <Field symbol="checkbox_title">
      <div class="flex flex-row gap-2 items-center">
        <ZSwitch :checked="action.has_checkbox" @update:checked="action.has_checkbox = !action.has_checkbox" />
        <span class="flex-1">{{ $t('dictionary.label.action_has_checkbox') }}</span>
        <div class="flex flex-row gap-2 items-stretch w-1/2">
          <ZInput
            v-if="action.has_checkbox"
            v-model="action.checkbox_title"
            :placeholder="$t('dictionary.label.action_checkbox_field_name')"
          />
          <TranslationPopup
            v-if="action.has_checkbox && action.id"
            type="action"
            :identifier="action.id!"
            symbol="checkbox"
          ></TranslationPopup>
        </div>
      </div>
    </Field>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ZInput } from '@shadcn/components/ui/input';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';
import ActionData = App.Data.ActionData;
import { ZSwitch } from '@shadcn/components/ui/switch';
import { ZButton } from '@shadcn/components/ui/button';
import TranslationPopup from '@ui/TranslationPopup.vue';

export default defineComponent({
  components: {
    TranslationPopup,
    ZButton,
    ZSwitch,
    ZInput,
    ZLabel,
    Field,
  },

  props: {
    action: {
      type: Object as PropType<ActionData>,
      required: true,
    },
  },
});
</script>
