<template>
  <DataLoader ref="loader">
    <div
      v-if="preparingInventory"
      class="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
    >
      <div
        class="fixed left-1/2 top-1/2 z-50 grid w-full max-w-lg -translate-x-1/2 -translate-y-1/2 gap-4 border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg"
      >
        <img src="@pub/images/loader.gif" title="Logo ZWD" class="max-w-14 mb-3 m-auto" />
        <div class="text-center font-bold">{{ $t('inventory.message.preparing_inventory') }}</div>
      </div>
    </div>
    <div class="mb-10">
      <template v-if="inventory.description">
        <MountedTeleport to="#toolbox">
          <DropdownMenu v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)">
            <DropdownMenuTrigger as-child>
              <ZButton class="">
                <CogIcon class="icon sm" />
                <span class="ml-2">
                  {{ $t('inventory.command.manage') }}
                </span>
                <ChevronDownIcon class="icon xs ml-2" />
              </ZButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent class="w-56">
              <DropdownMenuGroup>
                <DropdownMenuItem
                  :disabled="!inventory.can_be_modified"
                  @click.prevent="
                    editInventoryDialog = true;
                    editInventory = { ...inventory };
                  "
                >
                  <span>{{ $t('inventory.command.edit_inventory') }}</span>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem :disabled="!inventory.can_be_deleted" @click.prevent="requestDelete">
                  <span>{{ $t('inventory.command.delete_inventory') }}</span>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  :disabled="inventory.status !== 'finished' && inventory.status !== 'completed'"
                  @click.prevent="downloadReportUrl"
                >
                  <span>{{ $t('inventory.command.download_report') }}</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </MountedTeleport>

        <div v-if="inventory.description?.length" class="i-block mt-8">
          <span class="i-label">{{ $t('inventory.label.description') }}</span>
          <span class="i-value">{{ inventory.description }}</span>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-8">
          <div class="col-span-4 xl:col-span-2">
            <div class="border rounded-lg grid grid-cols-1 divide-y overflow-hidden">
              <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 px-4 py-2 grid-flow-row">
                <div class="i-block">
                  <span class="i-label">{{ $t('inventory.label.number') }}</span>
                  <span class="i-value">#{{ inventory.id }}</span>
                </div>
                <div class="i-block">
                  <span class="i-label">{{ $t('inventory.label.status') }}</span>
                  <span class="i-value"
                    ><PrettyStatus :status="inventory.status" :translate="'inventory.status.'"
                  /></span>
                </div>
                <div class="i-block">
                  <span class="i-label">{{ $t('inventory.label.started_at') }}</span>
                  <span class="i-value">{{ inventory.started_at ? formatDate(inventory.started_at) : '-' }}</span>
                </div>
                <div class="i-block">
                  <span class="i-label">{{ $t('inventory.label.completed_at') }}</span>
                  <span class="i-value">{{ inventory.completed_at ? formatDate(inventory.completed_at) : '-' }}</span>
                </div>
              </div>
              <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 px-4 py-2 grid-flow-row">
                <div class="i-block">
                  <span class="i-label">{{ $t('inventory.label.organization') }}</span>
                  <span class="i-value">{{ inventory!.organization!.name }}</span>
                </div>
                <div class="i-block">
                  <span class="i-label">{{ $t('inventory.label.branch') }}</span>
                  <span class="i-value">{{ inventory!.branch!.name }}</span>
                </div>
                <div class="i-block">
                  <span class="i-label">{{ $t('inventory.label.user_name') }}</span>
                  <span class="i-value">{{ inventory.user_name }}</span>
                </div>
              </div>
              <div
                :class="{ 'opacity-40': inventory.status === 'new' }"
                class="grid bg-gra-50 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 px-4 py-2 grid-flow-row"
              >
                <div class="i-block">
                  <span class="i-label">{{ $t('inventory.label.assets_count') }}</span>
                  <span class="i-value">{{ inventory.inventory_assets_count }}</span>
                </div>
                <div class="i-block">
                  <span class="i-label">{{ $t('inventory.label.assets_good_count') }}</span>
                  <span class="i-value">{{ inventory.inventory_assets_good_count }}</span>
                </div>
                <div class="i-block">
                  <span class="i-label">{{ $t('inventory.label.assets_damaged_count') }}</span>
                  <span class="i-value">{{ inventory.inventory_assets_damaged_count }}</span>
                </div>
                <div class="i-block">
                  <span class="i-label">{{ $t('inventory.label.assets_missing_count') }}</span>
                  <span class="i-value">{{ inventory.inventory_assets_missing_count }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-span-3 xl:col-span-1 border rounded-xl flex flex-col overflow-hidden"
            :class="{ 'border-primary': inventory.status == 'finished' }"
          >
            <div class="h-full w-full flex items-center justify-center">
              <div class="text-center p-4">
                <div v-if="inventory.status == 'new'">
                  <div class="mt-2 font-bold text-sm">{{ $t('inventory.state_manage.new.title') }}</div>
                  <Action
                    v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"
                    class="mt-5"
                    @proceed="requestPrepareInventory"
                    >{{ $t('inventory.state_manage.new.button') }}
                  </Action>
                </div>
                <div v-else-if="inventory.status == 'in_preparation'">
                  <div class="mt-2 font-bold text-sm">{{ $t('inventory.state_manage.in_preparation.title') }}</div>
                </div>
                <div v-else-if="inventory.status == 'in_progress'">
                  <div class="mt-2 font-bold text-sm">{{ $t('inventory.state_manage.in_progress.title') }}</div>
                  <Action
                    v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"
                    class="mt-5"
                    @proceed="requestCompleteInventory"
                    >{{ $t('inventory.state_manage.in_progress.button') }}
                  </Action>
                </div>
                <div v-else-if="inventory.status == 'completed'">
                  <div class="mt-2 font-bold text-sm">{{ $t('inventory.state_manage.completed.title') }}</div>
                  <Action
                    v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"
                    class="mt-5"
                    @proceed="requestFinalizeInventory"
                    >{{ $t('inventory.state_manage.completed.button') }}
                  </Action>
                </div>
                <div v-else-if="inventory.status == 'finished'">
                  <CheckCircleIcon class="icon w-20 m-auto text-primary" />
                  <div class="mt-2 font-bold text-sm">{{ $t('inventory.state_manage.finished.title') }}</div>
                  <div class="font-bold text-sm">
                    {{ inventory.completed_at ? formatDate(inventory.completed_at) : '-' }}
                  </div>
                  <Action
                    v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"
                    class="mt-5"
                    @proceed="downloadReportUrl"
                    >{{ $t('inventory.state_manage.finished.button') }}
                  </Action>
                </div>
              </div>
            </div>
          </div>
          <div v-if="inventory.inventory_assets_count" class="col-span-3 mt-4">
            <h1 class="text-xl font-bold">{{ $t('inventory.label.assets_list') }}</h1>
            <div class="grid sm:grid-cols-2 lg:grid-cols-1 lg:flex sm:gap-4 mt-5">
              <div class="w-full grid gap-2 mb-4">
                <ZLabel>{{ $t('inventory.label.inventory_asset_search') }}</ZLabel>
                <ZInput
                  v-model="filters.search"
                  type="search"
                  :placeholder="$t('inventory.label.inventory_asset_search_placeholder')"
                />
              </div>
              <div class="w-full lg:max-w-[200px] grid gap-2 mb-4">
                <ZLabel for="technical_condition">
                  {{ $t('inventory.inventory_assets.label.status') }}
                </ZLabel>
                <ZSelect v-model="filters.status!" class="w-full">
                  <ZSelectTrigger class="w-full">
                    <div class="flex flex-row gap-2 whitespace-nowrap">
                      <ZSelectValue :placeholder="$t('inventory.inventory_assets.label.status')" />
                    </div>
                  </ZSelectTrigger>
                  <ZSelectContent>
                    <ZSelectGroup>
                      <ZSelectItem :key="'all'" :value="'all'">{{ $t('inventory.status.all') }}</ZSelectItem>
                      <ZSelectItem
                        v-for="(key, inventoryStatus) in inventoryAssetStatuses"
                        :key="inventoryStatus"
                        :value="inventoryStatus"
                        >{{ $t('inventory.inventory_assets.states.' + inventoryStatus) }}
                      </ZSelectItem>
                    </ZSelectGroup>
                  </ZSelectContent>
                </ZSelect>
              </div>
            </div>
            <div v-if="inventoryAssetsDataSet.data?.length" class="border rounded">
              <ZTable class="w-full">
                <ZTableHeader>
                  <ZTableRow>
                    <ZTableHead class="font-bold text-left"></ZTableHead>
                    <ZTableHead class="font-bold text-left"
                      >{{ $t('inventory.inventory_assets.label.name') }}
                    </ZTableHead>
                    <ZTableHead class="font-bold text-left"
                      >{{ $t('inventory.inventory_assets.label.asset_number') }}
                    </ZTableHead>
                    <ZTableHead class="font-bold text-left"
                      >{{ $t('inventory.inventory_assets.label.technical_condition') }}
                    </ZTableHead>
                    <ZTableHead class="font-bold text-left"
                      >{{ $t('inventory.inventory_assets.label.status') }}
                    </ZTableHead>
                    <ZTableHead class="font-bold text-left"
                      >{{ $t('inventory.inventory_assets.label.note') }}
                    </ZTableHead>
                    <ZTableHead v-if="inventory.can_be_modified" class="font-bold text-left"></ZTableHead>
                  </ZTableRow>
                </ZTableHeader>
                <ZTableBody>
                  <ZTableRow v-for="(inventoryAsset, index) in inventoryAssetsDataSet.data" :key="index">
                    <ZTableCell>
                      <img
                        v-if="inventoryAsset!.asset!.thumb"
                        class="w-[50px] h-[50px] object-cover rounded-xl"
                        :src="inventoryAsset!.asset!.thumb"
                        alt="primaryImage"
                      />
                    </ZTableCell>
                    <ZTableCell>
                      {{ inventoryAsset!.asset!.name }}
                    </ZTableCell>
                    <ZTableCell>
                      <b>{{ inventoryAsset!.asset!.asset_number }}</b>
                    </ZTableCell>
                    <ZTableCell>
                      <PrettyStatus
                        :status="inventoryAsset!.asset!.technical_condition!"
                        :translate="'asset.technical_conditions.'"
                      />
                    </ZTableCell>
                    <ZTableCell>
                      <PrettyStatus
                        :status="inventoryAsset.status!"
                        :translate="'inventory.inventory_assets.states.'"
                      />
                    </ZTableCell>
                    <ZTableCell>
                      <span>{{ inventoryAsset!.description ? inventoryAsset!.description : '-' }}</span>
                    </ZTableCell>
                    <ZTableCell>
                      <ZButton
                        v-if="inventory.can_be_modified && access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"
                        variant="ghost"
                        @click.prevent="
                          editedAssetDialog = true;
                          editedAsset = { ...inventoryAsset };
                        "
                      >
                        <PencilSquareIcon class="icon sm" />
                      </ZButton>
                    </ZTableCell>
                  </ZTableRow>
                </ZTableBody>
              </ZTable>
            </div>
            <div
              v-if="inventoryAssetsDataSet.data?.length == 0"
              class="p-8 text-center flex flex-col gap-2 items-center"
            >
              <ExclamationTriangleIcon class="icon lg" />
              {{ $t('inventory.inventory_assets.message.data_not_found') }}
            </div>
            <Paginator :data="inventoryAssetsDataSet" />
          </div>
        </div>
        <ZDialog :open="editedAssetDialog" @update:open="openState => (editedAssetDialog = openState)">
          <ZDialogContent class="sm:max-w-[425px]">
            <ZDialogHeader>
              <ZDialogTitle>{{ $t('inventory.inventory_assets.editAssetDialog.title') }}</ZDialogTitle>
              <ZDialogDescription>
                {{ $t('inventory.inventory_assets.editAssetDialog.text') }}
              </ZDialogDescription>
            </ZDialogHeader>
            <div class="py-4">
              <FormValidator ref="form" class="grid gap-4">
                <FormField symbol="status">
                  <ZLabel> {{ $t('inventory.inventory_assets.label.status') }}</ZLabel>
                  <ZSelect v-model="editedAsset!.status!" class="w-full">
                    <ZSelectTrigger class="w-full">
                      <div class="flex flex-row gap-2 whitespace-nowrap">
                        <ZSelectValue :placeholder="$t('inventory.inventory_assets.label.status')" />
                      </div>
                    </ZSelectTrigger>
                    <ZSelectContent>
                      <ZSelectGroup>
                        <ZSelectItem
                          v-for="(key, inventoryStatus) in inventoryAssetStatuses"
                          :key="key"
                          :value="inventoryStatus"
                          >{{ $t('inventory.inventory_assets.states.' + inventoryStatus) }}
                        </ZSelectItem>
                      </ZSelectGroup>
                    </ZSelectContent>
                  </ZSelect>
                </FormField>
                <FormField symbol="description">
                  <ZLabel>{{ $t('inventory.inventory_assets.label.description') }}</ZLabel>
                  <textarea
                    id="name"
                    v-model="editedAsset!.description"
                    rows="4"
                    class="flex w-full resize-none rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
                  />
                </FormField>
              </FormValidator>
            </div>
            <ZDialogFooter>
              <Action @proceed="saveAsset">{{ $t('asset.command.save_changes') }}</Action>
            </ZDialogFooter>
          </ZDialogContent>
        </ZDialog>
        <ZDialog :open="editInventoryDialog" @update:open="openState => (editInventoryDialog = openState)">
          <ZDialogContent class="sm:max-w-[425px]">
            <ZDialogHeader>
              <ZDialogTitle>{{ $t('inventory.command.edit_inventory') }}</ZDialogTitle>
            </ZDialogHeader>
            <div class="py-4">
              <FormValidator ref="inventoryForm" class="grid gap-4">
                <FormField symbol="description">
                  <ZLabel>{{ $t('inventory.inventory_assets.label.description') }}</ZLabel>
                  <textarea
                    id="name"
                    v-model="editInventory!.description"
                    rows="4"
                    class="flex w-full resize-none rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
                  />
                </FormField>
              </FormValidator>
            </div>
            <ZDialogFooter>
              <Action @proceed="saveInventory">{{ $t('asset.command.save_changes') }}</Action>
            </ZDialogFooter>
          </ZDialogContent>
        </ZDialog>
      </template>
    </div>
  </DataLoader>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import {Dialogs} from '@/types/Dialogs';
import Navigation from '@/types/Navigation';
import { ZButton } from '@shadcn/components/ui/button';
import { ChevronDownIcon, CogIcon, CheckCircleIcon } from '@heroicons/vue/24/outline';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@shadcn/components/ui/dropdown-menu';
import InventoryData = App.Data.InventoryData;
import {
  completeInventory,
  deleteInventory,
  finalizeInventory,
  getInventory,
  getInventoryAssets,
  getInventoryReportUrl,
  prepareInventory,
  updateAsset,
  updateInventory,
} from '@/api/inventory';
import PrettyStatus from '@ui/PrettyStatus.vue';
import InventoryAssetData = App.Data.InventoryAssetData;
import {ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow} from '@shadcn/components/ui/table';
import Paginator from '@ui/Paginator.vue';
import {DataSet} from '@/utils/DataSet';
import InventoryAssetFilterRequestData = App.Data.InventoryAssetFilterRequestData;
import {ExclamationTriangleIcon, PencilSquareIcon} from '@modules/@heroicons/vue/24/outline';
import {ZInput} from '@shadcn/components/ui/input';
import {ZLabel} from '@shadcn/components/ui/label';
import {
  ZDialog,
  ZDialogHeader,
  ZDialogTitle,
  ZDialogDescription,
  ZDialogContent,
  ZDialogFooter,
} from '@shadcn/components/ui/dialog';
import {
  ZSelect,
  ZSelectContent,
  ZSelectGroup,
  ZSelectItem,
  ZSelectTrigger,
  ZSelectValue,
} from '@shadcn/components/ui/select';
import symbolsStore from '@/stores/symbols';
import Action from '@ui/Action.vue';
import FormValidator from '@ui/FormValidator.vue';
import FormField from '@ui/FormField.vue';
import {ROUTE} from '@/router/routeNames';
import {formatDate} from '@/utils';
import MountedTeleport from '@ui/MountedTeleport.vue';
import useAccessStore from '@/stores/access';
import DataLoader from '@ui/DataLoader.vue';

export default defineComponent({
  components: {
    DataLoader,
    MountedTeleport,
    CheckCircleIcon,
    PencilSquareIcon,
    FormField,
    FormValidator,
    Action,
    ZSelectValue,
    ZSelectContent,
    ZSelectItem,
    ZSelectGroup,
    ZSelectTrigger,
    ZSelect,
    ZLabel,
    ZInput,
    ExclamationTriangleIcon,
    Paginator,
    ZTable,
    ZTableHeader,
    ZTableRow,
    ZTableHead,
    ZTableBody,
    ZTableCell,
    PrettyStatus,
    DropdownMenuSeparator,
    DropdownMenuItem,
    DropdownMenuGroup,
    DropdownMenuContent,
    DropdownMenuTrigger,
    DropdownMenu,
    CogIcon,
    ChevronDownIcon,
    ZButton,
    ZDialog,
    ZDialogHeader,
    ZDialogTitle,
    ZDialogDescription,
    ZDialogContent,
    ZDialogFooter,
  },

  props: {
    inventoryId: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
      navigation: inject('navigation') as Navigation,
    };
  },

  data() {
    return {
      inventory: {} as InventoryData,
      inventoryAssetsDataSet: {} as DataSet<InventoryAssetData, InventoryAssetFilterRequestData>,
      inventoryAssetStatuses: {},
      editedAsset: null as InventoryAssetData | null,
      editedAssetDialog: false,
      editInventory: null as InventoryData | null,
      editInventoryDialog: false,
      canManage: false,
      preparingInventory: false,
      loader: null as any,
      filters: {
        status: 'all',
        search: '',
        page: 1,
        perPage: 15,
      },
    };
  },

  computed: {
    access: () => useAccessStore(),
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        this.inventoryAssetsDataSet.setParams(this.filters, true).resetToFirstPage();
      },
    },
  },

  async mounted() {
    try {
      this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;
      const symbols = symbolsStore();
      this.inventoryAssetStatuses = await symbols.get('inventory_assets_states');
      await this.getInventoryData();
    } catch (error: any) {
      throw error;
    }
  },

  methods: {
    async getInventoryData() {
      this.inventory = (await getInventory(this.inventoryId).withLoader(this.loader).execute()).data;
      this.getInventoryAssets();
    },

    getInventoryAssets() {
      this.inventoryAssetsDataSet = new DataSet<InventoryAssetData, InventoryAssetFilterRequestData>((query: string) =>
        getInventoryAssets(this.inventoryId).execute(query),
      );
      this.inventoryAssetsDataSet.load();
    },

    saveAsset(action: any) {
      updateAsset(this.inventory!.id!.toString(), this.editedAsset!.id!.toString(), {
        status: this.editedAsset!.status,
        description: this.editedAsset!.description,
      })
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.toast({
            title: this.$t('common.success.header'),
            description: this.$t('common.success.saved'),
          });
          this.editedAssetDialog = false;
          this.getInventoryData();
        })
        .catch(() => {
        });
    },

    saveInventory(action: any) {
      updateInventory(this.inventory!.id!.toString(), {description: this.editInventory!.description!})
        .withForm(this.$refs.inventoryForm as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.toast({
            title: this.$t('common.success.header'),
            description: this.$t('common.success.saved'),
          });
          this.editInventoryDialog = false;
          this.getInventoryData();
        })
        .catch(() => {
        });
    },

    requestDelete() {
      this.dialogs
        .confirm(this.$t('inventory.command.delete_inventory'), this.$t('inventory.message.deleting_inventory'))
        .then(() => {
          deleteInventory(this.inventory.id!)
            .execute()
            .then(() => {
              this.toast({
                title: this.$t('toasts.type.success'),
                description: this.$t('inventory.message.deleted_inventory'),
              });
              this.$router.replace({name: ROUTE.INVENTORY_LIST});
            });
        });
    },

    preparingInventoryStatus() {
      this.preparingInventory = true;
      setTimeout(async () => {
        const inventory = (await getInventory(this.inventoryId).execute()).data;
        if (inventory.status === 'in_progress') {
          this.getInventoryAssets();
          this.inventory = inventory;
          this.toast({
            title: this.$t('toasts.type.success'),
            description: this.$t('inventory.message.prepared_inventory'),
          });
          this.preparingInventory = false;
        } else {
          this.preparingInventoryStatus();
        }
      }, 2500);
    },

    requestPrepareInventory() {
      this.dialogs
        .confirm(
          this.$t('inventory.state_manage.new.confirm.title'),
          this.$t('inventory.state_manage.new.confirm.message', {date: formatDate(new Date().toString())}),
        )
        .then(() => {
          prepareInventory(this.inventory.id!)
            .execute()
            .then(() => {
              this.preparingInventoryStatus();
            });
        });
    },

    requestCompleteInventory() {
      this.dialogs
        .confirm(
          this.$t('inventory.state_manage.in_progress.confirm.title'),
          this.$t('inventory.state_manage.in_progress.confirm.message', {date: formatDate(new Date().toString())}),
        )
        .then(() => {
          completeInventory(this.inventory!.id!)
            .execute()
            .then(() => {
              this.toast({
                title: this.$t('toasts.type.success'),
                description: this.$t('inventory.message.completed_inventory'),
              });
              this.getInventoryData();
            });
        });
    },

    requestFinalizeInventory() {
      this.dialogs
        .confirm(
          this.$t('inventory.state_manage.completed.confirm.title'),
          this.$t('inventory.state_manage.completed.confirm.message'),
        )
        .then(() => {
          finalizeInventory(this.inventory!.id!)
            .execute()
            .then(() => {
              this.toast({
                title: this.$t('toasts.type.success'),
                description: this.$t('inventory.message.finalized_inventory'),
              });
              this.getInventoryData();
            });
        });
    },

    downloadReportUrl() {
      getInventoryReportUrl(this.inventory!.id!);
    },
  },
});
</script>
