import { defineStore } from 'pinia';
import { GroupedKeyValue } from '@/types/Common';
import { getSymbols } from '@/api/commonApis';

const symbolsStore = defineStore('symbolsStore', {
  state: () => ({
    symbols: {} as GroupedKeyValue,
    loaded: false as boolean,
  }),

  actions: {
    async get(group: string) {
      if (this.loaded) return this.symbols[group];
      await this.load();
      return this.symbols[group];
    },

    async load() {
      const response = await getSymbols().execute();
      this.symbols = response.data;
      this.loaded = true;
    },
  },
});
export default symbolsStore;
