import { createI18n } from 'vue-i18n';
import PL_LOCALE from '../locales/pl.json';
import EN_LOCALE from '../locales/en.json';

const defaultBrowserLanguage = window.navigator.language.toUpperCase().split('-')[0];
const messages = {
  PL: PL_LOCALE,
  EN: EN_LOCALE,
};
const locale = createI18n({
  locale: defaultBrowserLanguage,
  fallbackLocale: 'PL',
  legacy: true,
  globalInjection: true,
  messages,
});
export default locale;
