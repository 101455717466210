import { http } from './http';
import { ApiCall } from '@/api/ApiCall';
import { PaginatedResponse } from '@/utils/DataSet';
import FileRequestData = App.Data.FileRequestData;
import FileData = App.Data.FileData;
import FileAttachRequestData = App.Data.FileAttachRequestData;
import FileDetachRequestData = App.Data.FileDetachRequestData;
import { KeyValue } from '@/types/Common';
import FileUpdateRequestData = App.Data.FileUpdateRequestData;
import SavedRecordResponse = App.Data.SavedRecordResponse;

export const getFiles = (fields: FileRequestData) => {
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<FileData>>(`files` + query);
    },
    {},
    '',
    fields,
  );
};
export const getFile = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get<FileData>(`/files/${options.id}`);
  }, options);
};
export const storeNewFile = (fields: FormData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post(`/files`, fields);
    },
    {},
    '',
    fields,
  );
};
export const attachFile = (fields: FileAttachRequestData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post(`/files/attach`, fields);
    },
    {},
    '',
    fields,
  );
};
export const detachFile = (fields: FileDetachRequestData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post(`/files/detach`, fields);
    },
    {},
    '',
    fields,
  );
};
export const updateFile = (id: string, fields: FileUpdateRequestData) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(`/files/${options.id}/update`, fields);
    },
    options,
    '',
    fields,
  );
};

export const deleteFile = (id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  return ApiCall.create(options => {
    return http.delete(`/files/${options.id}/delete`);
  }, options);
};

export const getFileDownloadUrl = (id: number) => {
  http
    .get(`files/${id}/download`, {
      responseType: 'blob',
    })
    .then(response => {
      const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        }),
        url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = response.headers['x-filename'];
      downloadLink.click();
    });
};
