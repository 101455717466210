<template>
  <div>
    <input
      :disabled="disabled"
      class="flex h-10 w-full rounded-md border bg-background px-3 py-2 ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:border-ring disabled:cursor-not-allowed disabled:opacity-50"
      :class="[alignment, ...(important ? ['font-bold', 'border-primary', 'text-lg'] : ['text-sm', 'border-input'])]"
      :value="displayedValue"
      :placeholder="placeholder"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
      @keydown="onKeyDown"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { PropType } from '@modules/vue';

export default defineComponent({
  props: {
    modelValue: {
      type: [Number, null] as PropType<number | null>,
      required: true,
    },

    placeholder: {
      type: String,
      required: false,
      default: ''
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    align: {
      type: String,
      required: false,
      default: 'right',
    },

    important: {
      type: Boolean,
      required: false,
      default: false,
    },

    digits: {
      type: Number,
      required: false,
      default: 2,
    }
  },

  emits: ['update:modelValue'],

  data() {
    return {
      formatedValue: '' as string,
      displayedValue: '' as string,
      formatter: null as any,
      decimalSeparator: '' as string,
      previouslySanitized: '' as string,
    };
  },

  computed: {
    alignment(): string {
      return 'text-' + this.align;
    }
  },

  mounted() {
    const locale = navigator.language || 'en-US';
    this.decimalSeparator = new Intl.NumberFormat(locale).format(1.1).charAt(1);
    this.formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: this.digits,
      maximumFractionDigits: this.digits
    });
    this.display(this.modelValue);
  },

  methods: {
    update(val: string) {
      if (val == '') {
        this.$emit('update:modelValue', null);
        return;
      }
      val = val.toString().replace(this.decimalSeparator, '.');
      let returns = Number(parseFloat(val).toFixed(this.digits));
      this.$emit('update:modelValue', returns);
    },

    checkSanity(val: number | string) {
      let testString = val.toString();

      if (testString.indexOf(this.decimalSeparator) == 0) return false;
      if (testString.split(this.decimalSeparator).length > 2) return false;

      let parts = testString.split(this.decimalSeparator);
      if (parts.length > 0 && !/^\d+$/.test(parts[0])) return false;
      if (parts.length > 1 && parts[1] != '' && !/^\d+$/.test(parts[1])) return false;

      return true;
    },

    sanitize(val: number | string | null) {
      if (val == null || val == '') return '';
      val = val.toString().replace('.', this.decimalSeparator);

      if (!this.checkSanity(val)) {
        return false;
      }

      this.previouslySanitized = val;
      return val;
    },

    display(val: number | string | null) {
      if (val == null || val == '') return '';

      this.displayedValue = this.formatter.format(val).toString();
    },

    onKeyDown(event: KeyboardEvent) {
      let stop = false;

      if (event.key.length == 1 && !event.ctrlKey) {
        if (!/^\d+$/.test(event.key)) stop = true;
        if (event.key == this.decimalSeparator) {
          const input = event.target as HTMLInputElement;
          if (input.value.indexOf(this.decimalSeparator) > -1) stop = true;
          else stop = false;
        }
      }

      if (stop) {
        event.preventDefault();
        event.stopPropagation();
      }
    },

    onInput(event: Event) {
      const input = event.target as HTMLInputElement;
      let saneVal = this.sanitize(input.value);
      if (saneVal === false) {
        this.displayedValue = this.previouslySanitized;
        input.value = this.previouslySanitized;
        return;
      }

      this.displayedValue = saneVal;
      this.update(this.displayedValue);
    },

    onBlur() {
      this.update(this.displayedValue);
      this.display(this.modelValue);
    },

    onFocus() {
      if (this.sanitize(this.modelValue)) {
        this.displayedValue = this.sanitize(this.modelValue) as string;
      }
    },
  }
});
</script>
