<template>
  <div :class="validClass" class="w-full flex flex-col gap-2">
    <slot></slot>
    <div v-if="!isValid" :class="errorClass" class="field-error-message">{{ errorMessage }}</div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['validationError'],

  props: {
    valid: {
      type: [String],
      required: false,
      default: null,
    },

    error: {
      type: [String],
      required: false,
      default: '',
    },

    errorClass: {
      type: [String],
      required: false,
      default: '',
    },

    symbol: {
      type: [String],
      required: true,
    },
  },

  data() {
    return {
      validClass: '' as string,
      isValid: true as boolean,
      errorMessage: '' as unknown,
    };
  },

  watch: {
    valid() {
      this.checkValidity();
    },

    validationError: {
      handler() {
        this.checkValidity();
      },

      deep: true,
    },
  },

  mounted() {
    this.checkValidity();
  },

  methods: {
    checkValidity() {
      this.validClass = '';
      this.isValid = true;
      this.errorMessage = '';
      if (this.valid === 'false') {
        this.validClass = 'field-has-error';
        this.isValid = false;
        this.errorMessage = this.error;
      } else if (this.validationError != undefined) {
        if (this.validationError[this.symbol as keyof typeof this.validationError] != undefined) {
          this.validClass = 'field-has-error';
          this.isValid = false;
          let messageName = this.validationError[this.symbol as keyof typeof this.validationError][0];
          let parts = String(messageName!).split(':');
          let template = this.$t(String(parts.shift()));
          for (let i in parts) {
            template = template.replace('$' + (Number(i) + 1), parts[i]);
          }
          this.errorMessage = template;
        }
      }
    },
  },
});
</script>
