<template>
  <ZSelect v-model="selected" class="w-full">
    <ZSelectTrigger class="w-full">
      <div class="flex flex-row gap-2 whitespace-nowrap text-xs">
        <ZSelectValue placeholder="" />
      </div>
    </ZSelectTrigger>
    <ZSelectContent>
      <ZSelectGroup>
        <ZSelectItem v-for="item in metaTree" :key="item.value" :value="item.value" class="py-0">
          <span class="flex items-center">
            <span v-for="(indent, index) in item.path" :key="index" class="flex items-center">
              <span v-if="indent == 1" class="inline-block w-[15px] h-[28px] border-r-2"></span>
              <span v-if="indent == 2" class="inline-block w-[15px] h-[28px]">
                <span class="inline-block w-[15px] h-[14px] border-r-2"></span>
              </span>
              <span v-if="indent == 0" class="inline-block w-[15px] h-[28px]"></span>
            </span>
            <span class="inline-block w-[6px] border-b-2 mr-1"></span>
            {{ item.meta.label }}
          </span>
        </ZSelectItem>
      </ZSelectGroup>
    </ZSelectContent>
  </ZSelect>
</template>

<script lang="ts">
interface MetaInterface {
  depth: number,
  label: string
}
interface MetaTreeItem {
  value: string,
  path?: number[],
  meta: MetaInterface,
}
import {
  ZSelect,
  ZSelectContent,
  ZSelectGroup,
  ZSelectItem,
  ZSelectTrigger,
  ZSelectValue,
} from '@shadcn/components/ui/select';
import { defineComponent } from '@modules/vue';

export default defineComponent({
  components: {
    ZSelectGroup,
    ZSelectContent,
    ZSelectItem,
    ZSelectValue,
    ZSelect,
    ZSelectTrigger,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    tree: {
      type: Map,
      required: false,
      default: [],
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      selected: this.modelValue,
    };
  },

  computed: {
    metaTree() {
      let mtree = [] as Array<MetaTreeItem>;
      for (let [value, meta] of this.tree) {
        mtree.push({ value: value as string, meta: meta as MetaInterface });
      }

      mtree.reverse();

      let path = [];
      for (let leaf in mtree) {
        let node = mtree[leaf].meta;

        for (let d = 0; d < node.depth; d++) {
          if (path.length < d) path.push(0);
          else if (path[d] == 2) path[d] = 1;
        }

        if (path.length < node.depth) path.push(2);
        else if (path.length > node.depth) {
          path.length = node.depth;
        }

        if (path.length == node.depth && path[node.depth - 1] == 0) path[node.depth - 1] = 2;

        mtree[leaf].path = [...path];
      }

      mtree.reverse();

      return mtree;
    },
  },

  watch: {
    modelValue() {
      this.selected = this.modelValue;
    },

    selected() {
      this.$emit('update:modelValue', this.selected);
    },
  },
});
</script>
