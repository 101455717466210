<template>
  <div v-if="stats && stats.usage" class="grid grid-cols-4 gap-4 pt-10">
    <div class="col-span-4 lg:col-span-3">
      <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-7 gap-4 text-center p-10 border mb-4 rounded">
        <div class="font-bold pb-4 col-span-2 md:col-span-1 md:border-r md:py-2 align-middle flex self-center md:pr-2">
          {{ $t('dashboard.label.completed_contracts') }}
        </div>
        <div>
          <p>
            <span class="text-3xl font-bold">{{ stats.usage[11].value }}</span>
          </p>
          <p class="text-sm">{{ $t('common.months.' + dates[0].month) }} {{ dates[0].year }}</p>
        </div>
        <div>
          <p>
            <span class="text-3xl font-bold">{{ stats.usage[12].value }}</span>
          </p>
          <p class="text-sm">{{ $t('common.months.' + dates[1].month) }} {{ dates[1].year }}</p>
        </div>
        <div>
          <p>
            <span class="text-3xl font-bold">{{ stats.usage[13].value }}</span>
          </p>
          <p class="text-sm">{{ $t('common.months.' + dates[2].month) }} {{ dates[2].year }}</p>
        </div>
        <div>
          <p>
            <span class="text-3xl font-bold">{{ stats.usage[14].value }}</span>
          </p>
          <p class="text-sm">{{ $t('common.months.' + dates[3].month) }} {{ dates[3].year }}</p>
        </div>
        <div>
          <p>
            <span class="text-3xl font-bold">{{ stats.usage[15].value }}</span>
          </p>
          <p class="text-sm">{{ $t('common.months.' + dates[4].month) }} {{ dates[4].year }}</p>
        </div>
        <div>
          <p>
            <span class="text-3xl font-bold">{{ stats.usage[16].value }}</span>
          </p>
          <p class="text-sm">{{ $t('common.months.' + dates[5].month) }} {{ dates[5].year }}</p>
        </div>
      </div>

      <div class="grid grid-cols-2 lg:grid-cols-7 gap-4 text-center p-10 border rounded">
        <div class="font-bold pb-4 col-span-2 md:col-span-1 md:border-r md:py-2 align-middle flex self-center md:pr-2">
          {{ $t('dashboard.label.new_contracts') }}
        </div>
        <div>
          <p>
            <span class="text-3xl font-bold">{{ stats.usage[17].value }}</span>
          </p>
          <p class="text-sm">{{ $t('common.months.' + dates[0].month) }} {{ dates[0].year }}</p>
        </div>
        <div>
          <p>
            <span class="text-3xl font-bold">{{ stats.usage[18].value }}</span>
          </p>
          <p class="text-sm">{{ $t('common.months.' + dates[1].month) }} {{ dates[1].year }}</p>
        </div>
        <div>
          <p>
            <span class="text-3xl font-bold">{{ stats.usage[19].value }}</span>
          </p>
          <p class="text-sm">{{ $t('common.months.' + dates[2].month) }} {{ dates[2].year }}</p>
        </div>
        <div>
          <p>
            <span class="text-3xl font-bold">{{ stats.usage[20].value }}</span>
          </p>
          <p class="text-sm">{{ $t('common.months.' + dates[3].month) }} {{ dates[3].year }}</p>
        </div>
        <div>
          <p>
            <span class="text-3xl font-bold">{{ stats.usage[21].value }}</span>
          </p>
          <p class="text-sm">{{ $t('common.months.' + dates[4].month) }} {{ dates[4].year }}</p>
        </div>
        <div>
          <p>
            <span class="text-3xl font-bold">{{ stats.usage[22].value }}</span>
          </p>
          <p class="text-sm">{{ $t('common.months.' + dates[5].month) }} {{ dates[5].year }}</p>
        </div>
      </div>
    </div>

    <div
      class="col-span-4 md:col-span-1 border p-6 text-center flex flex-col items-center justify-center flex-1 rounded"
    >
      <span class="text-3xl font-bold">{{ stats.usage[23].value }} {{ $t('dashboard.label.days') }}</span>
      <p class="text-center text-sm">{{ $t('dashboard.label.average_contract_realization_time') }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import GeneralStatsData = App.Data.GeneralStatsData;
import useAccessStore from '@/stores/access';

export default defineComponent({
  components: {},

  props: {
    dataset: {
      required: true,
      type: Object as PropType<GeneralStatsData>,
    },

    dates: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      stats: {} as GeneralStatsData,
    };
  },

  mounted() {
    this.stats = this.dataset;
  },

  computed: {
    access: () => useAccessStore(),
  },

  watch: {
    dataset() {
      this.stats = this.dataset;
    },
  },
});
</script>
