<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('userAccount.label.create_user_account') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('userAccount.message.create_user_account') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <DataLoader ref="loader">
        <FormValidator ref="form" class="grid grid-cols-4 gap-4 py-4" default-action="($refs.update as any).proceed()">
          <Field
            v-if="accountType == SYMBOL.ACCOUNT_TYPE.CUSTOMER && access.operator()"
            class="col-span-4"
            symbol="organization_id"
          >
            <ZLabel for="legal_form">
              {{ $t('userAccount.form.select_organization') }}
            </ZLabel>
            <SelectField
              id="organization_id"
              v-model="user.organization_id"
              :placeholder="$t('userAccount.form.select_organization')"
              :options="organizations"
              searchable
              sorted
            />
          </Field>

          <UserAccountForm :user="user" />
        </FormValidator>
        <ZDialogFooter>
          <Action ref="update" @proceed="store">{{ $t('userAccount.command.create_user_account') }}</Action>
        </ZDialogFooter>
      </DataLoader>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import UserAccountForm from '@ui/UserAccounts/UserAccountForm.vue';
import { createOperatorAccount, createUserAccount } from '@/api/userAccount';
import SelectField from '@ui/SelectField.vue';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';
import { KeyValue } from '@/types/Common';
import { getOrganizationsCompact } from '@/api/organization';
import UserAccountData = App.Data.UserAccountData;
import OrganizationCompactData = App.Data.OrganizationCompactData;
import DataLoader from '@ui/DataLoader.vue';
import useAccessStore from '@/stores/access';

export default defineComponent({
  components: {
    DataLoader,
    Field,
    ZLabel,
    SelectField,
    UserAccountForm,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      organizations: {} as KeyValue,
      accountType: this.type as string,
      user: {} as UserAccountData,
      isDialogOpen: true,

      loader: null as any,
    };
  },

  computed: {
    access: () => useAccessStore(),
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },

  methods: {
    async init() {
      try {
        this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;
        const organizationsResponse = await getOrganizationsCompact().withLoader(this.loader).execute();
        this.organizations = organizationsResponse.data.reduce(
          (accumulator: KeyValue, value: OrganizationCompactData) => {
            return { ...accumulator, [value.id]: value.name };
          },
          {},
        );
      } catch (error: any) {}
    },

    store(action: any) {
      this.user.account_type = this.type;

      if (this.access.currentUser() == null) return;

      if (this.accountType == this.SYMBOL.ACCOUNT_TYPE.CUSTOMER) {
        if (!this.access.operator()) {
          this.user.organization_id = this.access.currentOrganizationId()!.toString();
        }

        createUserAccount(this.user)
          .withForm(this.$refs.form as typeof FormValidator)
          .withLoader(action.loader)
          .execute()
          .then(response => {
            this.toast({
              title: this.$t('toasts.type.saved'),
              description: this.$t('userAccount.message.user_account_created'),
            });
            this.$router.push({ name: 'userAccount.details', params: { userId: response.data.new_id } });
          });
      } else {
        createOperatorAccount(this.user)
          .withForm(this.$refs.form as typeof FormValidator)
          .withLoader(action.loader)
          .execute()
          .then(response => {
            this.toast({
              title: this.$t('toasts.type.saved'),
              description: this.$t('userAccount.message.user_account_created'),
            });
            this.$router.push({ name: 'userAccount.details', params: { userId: response.data.new_id } });
          });
      }
    },
  },
});
</script>
