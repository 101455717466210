import type { RouteRecordRaw } from 'vue-router';
import { ROUTE } from './routeNames';
import Notifications from '@views/Notifications/Notifications.vue';

export const notificationsRoutes: RouteRecordRaw[] = [
  {
    path: 'notifications',
    name: ROUTE.NOTIFICATIONS,
    component: Notifications,
    meta: {
      breadcrumbs: true,
      title: 'notification.title',
    },
  },
];
