import { http } from './http';
import { ApiCall } from '@/api/ApiCall';
import { PaginatedResponse } from '@/utils/DataSet';
import InventoryFilterRequestData = App.Data.InventoryFilterRequestData;
import InventoryData = App.Data.InventoryData;
import { KeyValue } from '@/types/Common';
import InventoryAssetData = App.Data.InventoryAssetData;
import InventoryDataStoreRequest = App.Data.InventoryDataStoreRequest;
import InventoryAssetUpdateStatusRequest = App.Data.InventoryAssetUpdateStatusRequest;
import InventoryUpdateRequest = App.Data.InventoryUpdateRequest;
import SavedRecordResponse = App.Data.SavedRecordResponse;

export const getInventories = (fields: InventoryFilterRequestData) => {
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<InventoryData>>(`inventory` + query);
    },
    {},
    '',
    fields,
  );
};

export const getInventory = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get<InventoryData>(`/inventory/${options.id}`);
  }, options);
};
export const updateInventory = (inventory_id: string, fields: InventoryUpdateRequest) => {
  const options = {} as KeyValue;
  options.inventory_id = inventory_id;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(`/inventory/${options.inventory_id}`, fields);
    },
    options,
    '',
    fields,
  );
};
export const getInventoryAssets = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<InventoryAssetData>>(`/inventory/${options.id}/inventoryAssets` + query);
    },
    options,
    '',
  );
};

export const createInventory = (fields: InventoryDataStoreRequest) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<SavedRecordResponse>('/inventory', fields);
    },
    {},
    '',
    fields,
  );
};

export const updateAsset = (
  inventory_id: string,
  inventory_asset_id: string,
  fields: InventoryAssetUpdateStatusRequest,
) => {
  const options = {} as KeyValue;
  options.inventory_id = inventory_id;
  options.inventory_asset_id = inventory_asset_id;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(
        `/inventory/${options.inventory_id}/updateStatus/${options.inventory_asset_id}`,
        fields,
      );
    },
    options,
    '',
    fields,
  );
};

export const deleteInventory = (id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  return ApiCall.create(options => {
    return http.delete(`/inventory/${options.id}`);
  }, options);
};
export const prepareInventory = (id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  return ApiCall.create(options => {
    return http.patch(`/inventory/${options.id}/prepare`);
  }, options);
};
export const completeInventory = (id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  return ApiCall.create(options => {
    return http.patch(`/inventory/${options.id}/complete`);
  }, options);
};
export const finalizeInventory = (id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  return ApiCall.create(options => {
    return http.patch(`/inventory/${options.id}/finalize`);
  }, options);
};

export const getInventoryReportUrl = (id: number) => {
  http
    .get(`inventory/${id}/report`, {
      responseType: 'blob',
    })
    .then(response => {
      const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = response.headers['x-filename'];
      downloadLink.click();
    });
};
