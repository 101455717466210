<template>
  <div class="mb-5">
    <ZDialog v-model:open="showRecoveryCodes">
      <ZDialogContent class="sm:max-w-[425px]">
        <ZDialogHeader>
          <ZDialogTitle>{{ $t('account.security.2fa.recoveryHeader') }}</ZDialogTitle>
        </ZDialogHeader>
        <div>
          <div v-for="(code, index) in showRecoveryCodes" :key="index">{{ code }}</div>
        </div>
      </ZDialogContent>
    </ZDialog>
    <h3 class="text-2xl font-semibold">{{ $t('account.security.header') }}</h3>
    <div class="border rounded-xl mt-8" :class="{ 'border-primary': enabled }">
      <div class="p-8 flex justify-between items-center">
        <div class="mr-4">
          <h3 class="scroll-m-20 text-lg font-semibold tracking-tight">{{ $t('account.security.2fa.header') }}</h3>
          <p>{{ $t('account.security.2fa.text') }}</p>
        </div>
        <ZSwitch :checked="enabled" @update:checked="update()" />
      </div>
      <div v-if="enabled">
        <Separator />
        <div class="flex md:items-center p-5">
          <div
            class="w-[35px] h-[35px] min-w-[35px] min-h-[35px] font-semibold bg-primary rounded-full flex items-center justify-center text-white"
          >
            1
          </div>
          <div class="md:flex justify-between items-center ml-8">
            <div>
              <h3 class="scroll-m-20 text-lg font-semibold tracking-tight">
                {{ $t('account.security.2fa.qrHeader') }}
              </h3>
              <p class="text-gray-500">{{ $t('account.security.2fa.qrText') }}</p>
            </div>
            <div>
              <div class="w-[120px] h-[120px] bg-gray-100 flex items-center justify-center rounded-md mt-4 md:mt-0">
                <ZButton v-if="!qr" variant="ghost" @click.prevent="showQRcode()">{{
                  $t('account.security.2fa.qrButton')
                }}</ZButton>
                <!-- eslint-disable vue/no-v-html -->
                <div class="qr-code" v-html="qr"></div>
                <!--eslint-enable-->
              </div>
            </div>
          </div>
        </div>
        <div v-if="requiresVerification">
          <Separator />
          <div class="flex md:items-center p-5">
            <div
              class="w-[35px] h-[35px] min-w-[35px] min-h-[35px] font-semibold bg-primary rounded-full flex items-center justify-center text-white"
            >
              2
            </div>
            <div class="ml-8">
              <h3 class="scroll-m-20 text-lg font-semibold tracking-tight">{{ $t('account.security.2fa.confirm') }}</h3>
              <p>{{ $t('account.security.2fa.confirm_text') }}</p>
            </div>
            <div class="flex gap-2">
              <ZInput v-model="verificationCode" :placeholder="$t('account.security.2fa.confirm_placeholder')"/> <ZButton @click="testCode">{{ $t('account.security.2fa.confirm_verification') }}</ZButton>
            </div>
          </div>
          <div v-if="verificationError" class="text-red-400 px-4 text-right pb-4">
            {{ $t('account.security.2fa.verification_error') }}
          </div>
        </div>
        <Separator />
        <div class="flex items-center p-5" v-if="!requiresVerification">
          <div
            class="w-[35px] h-[35px] min-w-[35px] min-h-[35px] font-semibold bg-primary rounded-full flex items-center justify-center text-white"
          >
            2
          </div>
          <div class="ml-8">
            <h3 class="scroll-m-20 text-lg font-semibold tracking-tight">
              {{ $t('account.security.2fa.recoveryHeader') }}
            </h3>
            <p class="text-gray-500">{{ $t('account.security.2fa.recoveryText') }}</p>
            <div class="md:flex gap-5 mt-3">
              <ZButton variant="outline" @click.prevent="getRecoveryCodes">{{
                $t('account.security.2fa.recoveryButtonShow')
              }}</ZButton>
              <ZButton variant="ghost" @click.prevent="newRecoveryCodes">{{
                $t('account.security.2fa.recoveryButtonGenerate')
              }}</ZButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { ZButton } from '@shadcn/components/ui/button';
import { ZInput } from "@shadcn/components/ui/input";
import { ZSwitch } from '@shadcn/components/ui/switch';
import { Separator } from '@shadcn/components/ui/separator';
import {
  postEnable2FA,
  getCurrentUser,
  postDisable2FA,
  getQR2FA,
  getRecoveryCodes2FA,
  postRecoveryCodes2FA, postVerify2FA,
} from '../../api';
import { ZDialog, ZDialogContent, ZDialogHeader, ZDialogTitle } from '@shadcn/components/ui/dialog';
import loaderStore from '../../stores/loader';
import access from "@/stores/access";

export default defineComponent({
  components: {
    ZInput,
    ZButton,
    ZSwitch,
    Separator,
    ZDialog,
    ZDialogContent,
    ZDialogHeader,
    ZDialogTitle,
  },

  data() {
    return {
      loader: loaderStore(),
      showRecoveryCodes: <boolean | undefined>false,
      enabled: false,
      requiresVerification: false,
      verificationCode: '' as String,
      verificationError: false,
      qr: null,
    };
  },

  mounted() {
    this.checkUserEnabled();
  },

  methods: {
    update() {
      if (this.enabled) {
        this.disable2FA();
      } else {
        this.enable2FA();
        this.requiresVerification = true;
      }
      this.enabled = !this.enabled;
    },

    async testCode() {
      this.loader.startLoading();
      try {
        this.verificationError = false;
        const verifyResponse: any = await postVerify2FA(this.verificationCode);
        this.requiresVerification = false;
        this.enabled = true;
        access().twoFactorWarning = false;
      } catch(e) {
        this.verificationError = true;
      }
      this.loader.stopLoading();
    },

    async checkUserEnabled() {
      this.loader.startLoading();
      const response: any = await getCurrentUser();
      this.enabled = response.data.two_factor_status != 'disabled';
      this.requiresVerification = response.data.two_factor_status == 'awaits_verification';
      this.loader.stopLoading();
    },

    async enable2FA() {
      this.loader.startLoading();
      await postEnable2FA();
      this.enabled = true;
      this.loader.stopLoading();
    },

    async disable2FA() {
      this.loader.startLoading();
      await postDisable2FA();
      this.enabled = false;
      this.loader.stopLoading();
    },

    async showQRcode() {
      this.loader.startLoading();
      const response = await getQR2FA();
      this.qr = response.data.svg;
      this.loader.stopLoading();
    },

    async getRecoveryCodes() {
      this.loader.startLoading();
      const response = await getRecoveryCodes2FA();
      this.showRecoveryCodes = response.data;
      this.loader.stopLoading();
    },

    async newRecoveryCodes() {
      this.loader.startLoading();
      await postRecoveryCodes2FA();
      await this.getRecoveryCodes();
      this.loader.stopLoading();
    },
  },
});
</script>
