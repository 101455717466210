<template>
  <Teleport v-if="isMounted" :to="to"><slot></slot></Teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    to: {
      type: String,
      required: true,
    },
  },

  data() {
    return { isMounted: false };
  },

  mounted() {
    this.isMounted = true;
  },
});
</script>
