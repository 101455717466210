import axios, { HttpStatusCode, AxiosResponse, AxiosError } from 'axios';
import useUserStore from '../stores/auth';
import access from "@/stores/access";
import { useToast } from '@shadcn/components/ui/toast/use-toast';
import useI18n from '../utils/i18n';
import router from '@/router';
import { ROUTE } from '@/router/routeNames';

const { toast } = useToast();
export const http = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

http.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  error => {
    if (!(error instanceof AxiosError)) {
      toast({
        title: useI18n.global.t('common.errors.header'),
        description: useI18n.global.t('common.errors.unexpected'),
        variant: 'destructive',
      });
    } else if (!error.response) {
      toast({
        title: useI18n.global.t('common.errors.header'),
        description: useI18n.global.t('common.errors.network'),
        variant: 'destructive',
      });
    } else {
      const status = error.response.status;

      if (status >= 500) {
        toast({
          title: useI18n.global.t('common.errors.header'),
          description: useI18n.global.t('common.errors.unexpected'),
          variant: 'destructive',
        });
      } else {
        const { logoutUser, user } = useUserStore();
        switch (status) {
          case HttpStatusCode.Unauthorized:
            const accessCode = error.response.data?.policyAccessCode as App.Enums.UserAccessPolicyType;
            if (accessCode) {
              if (accessCode == 'passwordReset') {
                router.push({ name: ROUTE.CHANGE_PASSWORD });
                return Promise.reject(error);
              } else if (accessCode == 'ipNotMatch') {
                toast({
                  title: useI18n.global.t('common.errors.header'),
                  description: useI18n.global.t('common.errors.ipNotFound'),
                  variant: 'destructive',
                });
              } else if (accessCode == 'twoFactorDisabled') {
                access().twoFactorWarning = true;
                router.push({ name: ROUTE.ACCOUNT_SECURITY });
                return Promise.reject(error);
              }
            }
            logoutUser();
            return Promise.reject(error);
          case HttpStatusCode.Forbidden:
            toast({
              title: useI18n.global.t('common.errors.header'),
              description: useI18n.global.t('common.errors.forbidden'),
              variant: 'destructive',
            });
            break;
        }
      }
    }

    return Promise.reject(error);
  },
);
