<template>
  <div>
    <div :class="{ 'flex justify-between gap-2': linkData.hasOwnProperty('comparative_branch_id') }">
      <Field v-if="exportType == 'comparative'" symbol="comparative_branch_id" class="pt-4">
        <ZLabel for="comparative_branch_id">
          {{ $t('report.label.comparative_branch') }}
        </ZLabel>
        <div v-if="comparedBranch != null" class="border rounded p-2 flex justify-between items-center">
          <div>
            <p class="font-bold">
              {{ comparedBranch.name }}
            </p>
            <template v-if="comparedBranch.branch_type == 'location'">
              <p class="text-xs">
                {{ comparedBranch.postal }} {{ comparedBranch.city }}, {{ comparedBranch.street }}
                {{ comparedBranch.building_number }}
                {{ comparedBranch.apartment_number ?? '' }}
              </p>
            </template>
            <template v-if="comparedBranch.parents!.length">
              <div class="text-xs">
                <span v-for="(parent, branchIndex) in comparedBranch.parents" :key="`parent-${branchIndex}`">
                  {{ branchIndex > 0 ? ' /' : '' }} {{ parent.name }}
                </span>
              </div>
            </template>
          </div>
          <a href="#" class="text-sm text-primary" @click.prevent="openBranchDialog()">{{
            $t('report.command.change_comparative_branch')
          }}</a>
        </div>
        <div v-else class="border rounded-md p-2 px-4">
          <a href="#" class="text-sm text-primary" @click.prevent="openBranchDialog()">{{
            $t('report.command.select_comparative_branch')
          }}</a>
        </div>
        <SelectBranchToCompareDialog ref="compareBranchDialog" />
      </Field>
    </div>
    <div class="flex flex-col md:flex-row gap-2 md:gap-8">
      <Field symbol="date_from" class="pt-4">
        <ZLabel for="date_from">
          {{ $t('report.label.date_from') }}
        </ZLabel>
        <div class="flex gap-2">
          <SelectField
            v-model="linkData.month_from"
            :placeholder="$t('report.label.month')"
            :options="SYMBOL.MONTHS"
            :translate="'report.months.'"
          ></SelectField>
          <SelectField
            v-model="linkData.year_from"
            :placeholder="$t('report.label.year')"
            :options="years"
          ></SelectField>
        </div>
      </Field>
      <Field symbol="date_to" class="pt-4">
        <ZLabel for="date_to" class="justify-center">
          {{ $t('report.label.date_to') }}
        </ZLabel>
        <div class="flex gap-2">
          <SelectField
            v-model="linkData.month_to"
            :placeholder="$t('report.label.month')"
            :options="SYMBOL.MONTHS"
            :translate="'report.months.'"
          ></SelectField>
          <SelectField v-model="linkData.year_to" :placeholder="$t('report.label.year')" :options="years"></SelectField>
        </div>
      </Field>
    </div>
    <Field symbol="extension" class="pt-4">
      <div class="flex gap-2 items-center">
        <ZLabel for="extension">{{ $t('report.label.extension') }}</ZLabel>
        <a
          v-for="(label, value) in extensions"
          href="#"
          class="border rounded-full px-2 text-sm"
          :class="{ 'bg-primary text-white': linkData.extension == value }"
          @click.prevent="linkData.extension = value"
          >{{ label }}</a
        >
      </div>
    </Field>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';
import { mapState } from '@modules/pinia';
import useUserStore from '@/stores/auth';
import SelectField from '@ui/SelectField.vue';
import SelectBranchToCompareDialog from '@ui/Reports/SelectBranchToCompareDialog.vue';
import OrganizationBranchData = App.Data.OrganizationBranchData;

export default defineComponent({
  components: {
    SelectBranchToCompareDialog,
    SelectField,
    ZLabel,
    Field,
  },

  props: {
    linkData: {
      type: Object,
      required: true,
    },

    exportType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      comparedBranch: null as OrganizationBranchData | null,
      extensions: {
        csv: 'Csv',
        xlsx: 'Excel',
      },
    };
  },

  computed: {
    ...mapState(useUserStore, ['user']),

    years() {
      let currentYear = new Date().getFullYear();
      const years = [];
      let counts = 0;
      while (counts < 6) {
        years[currentYear] = currentYear;
        currentYear -= 1;
        counts++;
      }
      return years;
    },
  },

  watch: {
    linkData() {},
  },

  methods: {
    openBranchDialog() {
      this.getBranchCompareDialogComponent()
        .open()
        .then(response => {
          this.comparedBranch = response;
          this.linkData.comparative_branch_id = response.id;
        });
    },

    getBranchCompareDialogComponent() {
      return this.$refs.compareBranchDialog as InstanceType<typeof SelectBranchToCompareDialog>;
    },
  },
});
</script>
