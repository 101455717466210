import type { RouteRecordRaw } from 'vue-router';
import Reports from '@views/Reports/Reports.vue';
import { ROUTE } from './routeNames';
import GenerateAssetsReportDialog from '@views/Reports/GenerateAssetsReportDialog.vue';
import GenerateCarbonFootprintReportDialog from '@views/Reports/GenerateCarbonFootprintReportDialog.vue';
import {SYMBOL} from "@/types/symbols";

export const reportRoutes: RouteRecordRaw[] = [
  {
    path: 'reports',
    name: 'report.layout',
    redirect: () => {
      return { name: ROUTE.REPORT_LIST };
    },
    meta: {
      breadcrumbs: true,
      title: 'report.label.list_title',
      permission: [{type: SYMBOL.PERMISSION.BRANCH_MANAGE, branch: true}]
    },
    children: [
      {
        path: '',
        component: Reports,
        name: ROUTE.REPORT_LIST,
        children: [
          {
            path: 'assetsReport/:exportType',
            component: GenerateAssetsReportDialog,
            name: ROUTE.GENERATE_ASSETS_REPORT,
            props: true,
          },
          {
            path: 'carbonFootprintReport/:exportType',
            component: GenerateCarbonFootprintReportDialog,
            name: ROUTE.GENERATE_CARBON_REPORT,
            props: true,
          },
        ],
      },
    ],
  },
];
