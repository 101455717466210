import { acceptHMRUpdate, defineStore } from 'pinia';

const useUserStore = defineStore('loader', {
  state: () => ({
    status: false,
  }),
  actions: {
    startLoading() {
      this.status = true;
    },
    stopLoading() {
      this.status = false;
    },
  },
});
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
export default useUserStore;
