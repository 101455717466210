<template>
  <div class="my-4">
    <template v-if="state == 'loading'">
      <IconAlert type="loading">
        {{ $t('common.action.loading') }}
      </IconAlert>
    </template>
    <template v-else-if="state == 'loaded' && categories?.length == 0">
      <IconAlert v-if="categories?.length == 0" type="info" class="my-2">
        {{ $t('category.message.category_no_children') }}
        <router-link class="link" :to="{ name: ROUTE.CATEGORY_ADD }">
          {{ $t('category.command.add_first_category') }}
        </router-link>
      </IconAlert>
    </template>
    <template v-else-if="state == 'loaded' && categories && attributeTypes && selectedCategory">
      <div class="grid grid-cols-2 lg:grid-cols-6 gap-4 items-stretch">
        <div class="border border-green-600 rounded p-4 col-span-3">
          <div class="mb-4 border-b pb-4 flex justify-between items-center">
            <h2 class="font-bold text-md">
              {{ $t('category.label.category_structure') }}
            </h2>
            <router-link :to="{ name: ROUTE.CATEGORY_ADD }">
              <ZButton class="flex gap-2">
                <span class="hidden lg:block">{{ $t('category.command.add_category') }}</span>
                <PlusCircleIcon class="icon md" />
              </ZButton>
            </router-link>
          </div>
          <IconAlert v-if="categoryToTransfer != null" type="info" class="mb-4">
            <div class="flex flex-row gap-4 items-center">
              <div class="flex flex-col">
                <span
                  >{{ $t('category.label.transfer_category') }} <b>{{ categoryToTransfer.name }}</b></span
                >
                {{ $t('category.message.select_destination_for_category') }}
              </div>
              <Action @proceed="cancelTransfer()">{{ $t('category.command.cancel_transfer') }}</Action>
            </div>
          </IconAlert>
          <a
            v-if="canTransferToDestination(null)"
            href="#"
            class="border p-1 text-xs flex flex-row gap-2"
            @click.prevent="transferCategoryTo(null)"
          >
            <ArrowsRightLeftIcon class="icon xs" />
            {{ $t('category.command.transfer_here') }}</a
          >
          <div
            v-for="(item, index) in filteredCategories"
            :key="index"
            class="flex group gap-2 pl-2 cursor-pointer py-2 hover:bg-accent justify-between items-center"
            :class="{
              'bg-accent': selectedCategory && selectedCategory.id == item.id,
            }"
          >
            <div
              class="flex w-full gap-2"
              :style="{ 'padding-left': (item.depth != null ? (item.depth - 1) * 20 : 0) + 'px' }"
              @click.prevent="categoryToTransfer == null && selectCategory(index)"
            >
              <div v-if="hasChildren[item.hierarchy_path!]">
                <MinusCircleIcon
                  v-if="!folded[item.hierarchy_path!]"
                  class="icon sm opacity-30"
                  @click="fold(item.hierarchy_path!, item.depth!)"
                />
                <PlusCircleIcon v-else class="icon sm opacity-30" @click="unfold(item.hierarchy_path!)" />
              </div>
              <div v-else class="w-[20px]"></div>

              <Square3Stack3DIcon class="icon sm" />
              <div class="leading-4">
                <span class="pr-1">#{{item.id}}</span>
                {{ item.name }}
              </div>
            </div>
            <a
              v-if="canTransferToDestination(item)"
              href="#"
              class="border p-1 text-xs flex flex-row gap-2"
              @click.prevent="transferCategoryTo(item)"
            >
              <ArrowsRightLeftIcon class="icon xs" />
              {{ $t('category.command.transfer_here') }}</a
            >
            <span
              v-if="categoryToTransfer != null && categoryToTransfer.id == item.id"
              class="text-xs flex gap-2 items-center opacity-70"
              ><ArrowsRightLeftIcon class="icon xs" /> {{ $t('category.label.category_transferred') }}</span
            >
            <DropdownMenu v-if="categoryToTransfer == null">
              <DropdownMenuTrigger as-child>
                <div>
                  <EllipsisHorizontalCircleIcon
                    class="icon sm cursor-pointer rounded-full mr-2 hover:bg-primary hover:text-white"
                  ></EllipsisHorizontalCircleIcon>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent class="w-56">
                <DropdownMenuGroup>
                  <DropdownMenuItem class="gap-2" @click.prevent="addChild(item.id!)">
                    <DocumentPlusIcon class="icon sm mr-2" />
                    {{ $t('category.command.add_sub_category') }}
                  </DropdownMenuItem>
                  <DropdownMenuItem :disabled="!canMove(index, 'up')" class="gap-2" @click.prevent="moveUp(item)">
                    <ChevronUpIcon class="icon sm mr-2" />
                    {{ $t('category.command.move_up') }}
                  </DropdownMenuItem>
                  <DropdownMenuItem :disabled="!canMove(index, 'down')" class="gap-2" @click.prevent="moveDown(item)">
                    <ChevronDownIcon class="icon sm mr-2" />
                    {{ $t('category.command.move_down') }}
                  </DropdownMenuItem>
                  <DropdownMenuItem class="gap-2" @click.prevent="moveOut(item)">
                    <ArrowsRightLeftIcon class="icon sm mr-2" />
                    {{ $t('category.command.move') }}
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem class="gap-2" @click.prevent="editCategory(item!.id.toString())">
                    {{ $t('category.command.edit_category_data') }}
                  </DropdownMenuItem>
                  <TooltipProvider class="gap-2">
                    <Tooltip>
                      <TooltipTrigger as-child class="w-full">
                        <div class="w-full">
                          <DropdownMenuItem
                            class="w-full"
                            :disabled="!item.can_be_deleted"
                            @click.prevent="requestCategoryDelete"
                          >
                            <span class="text-red-500">{{ $t('category.command.delete_category') }}</span>
                          </DropdownMenuItem>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent v-if="!item.can_be_deleted" :side="'bottom'">
                        <span>{{ $t('category.message.cannot_be_deleted') }}</span>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <IconAlert v-if="categories?.length == 0" type="info" class="my-2">
            {{ $t('category.message.category_no_children') }}
            <router-link class="link" :to="{ name: ROUTE.CATEGORY_ADD }">
              {{ $t('category.command.add_first_category') }}
            </router-link>
          </IconAlert>
        </div>

        <Tabs v-if="categories?.length" default-value="attributes" class="col-span-2 lg:col-span-3">
          <div class="flex flex-row justify-between items-center py-4">
            <h3 class="font-bold text-lg flex flex-row gap-2">
              <Square3Stack3DIcon class="icon md" />
              {{ selectedCategory.name }}
            </h3>
            <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <ZButton class="border p-1 text-sm flex flex-row gap-1 px-4">
                  <Square3Stack3DIcon class="icon sm" />
                  <span class="ml-2">
                    {{ $t('category.command.manage') }}
                  </span>
                  <ChevronDownIcon class="icon xs ml-2" />
                </ZButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent class="w-56">
                <DropdownMenuGroup>
                  <DropdownMenuItem @click="editCategory(selectedCategory!.id?.toString())">
                    <span>{{ $t('category.command.edit_category_data') }}</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem @click="addCategoryAttribute">
                    <span>{{ $t('category.command.add_category_attribute') }}</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem :disabled="!selectedCategory.can_be_deleted" @click="requestCategoryDelete">
                    <span>{{ $t('category.command.delete_category') }}</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div class="my-4 p-4 rounded border flex flex-col lg:flex-row gap-6">
            <div>{{ $t('category.label.contains') }}:</div>
            <div class="flex gap-2">
              <span class="opacity-50">{{ $t('category.label.contains_models') }}:</span>
              <b>{{ categoryStats.models }}</b>
            </div>
            <div class="flex gap-2">
              <span class="opacity-50">{{ $t('category.label.contains_assets') }}:</span>
              <b>{{ categoryStats.assets }}</b>
            </div>
            <div class="flex gap-2">
              <span class="opacity-50">{{ $t('category.label.contains_categories') }}:</span>
              <b>{{ categoryStats.directSubCategories }} ({{ categoryStats.allSubCategories }})</b>
            </div>
          </div>
          <TabsList class="grid w-full grid-cols-2">
            <TabsTrigger value="attributes">{{ $t('category.label.category_attributes') }}</TabsTrigger>
            <TabsTrigger value="carbon">{{ $t('category.label.carbon_footprint') }}</TabsTrigger>
          </TabsList>
          <TabsContent value="attributes" class="border rounded p-4">
            <div v-if="selectedCategory">
              <CategoryAttributes
                :category="selectedCategory!"
                @add-category-attribute="addCategoryAttribute"
                @delete-attribute="requestCategoryAttributeDelete($event)"
              ></CategoryAttributes>
            </div>
          </TabsContent>
          <TabsContent value="carbon">
            <CarbonDefaults type="category" :identifier="selectedCategory.id!"></CarbonDefaults>
          </TabsContent>
        </Tabs>
      </div>
    </template>
    <template v-else>
      <IconAlert type="error">
        {{ $t('common.errors.unexpected') }}
      </IconAlert>
    </template>
  </div>
  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import Action from '@ui/Action.vue';
import {
  ArrowsRightLeftIcon,
  Square3Stack3DIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentPlusIcon,
  EllipsisHorizontalCircleIcon,
  PlusCircleIcon,
  MinusCircleIcon,
} from '@heroicons/vue/24/outline';
import { ZButton } from '@shadcn/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@shadcn/components/ui/dropdown-menu';
import {
  changeCategoryParentToRoot,
  deleteCategory,
  deleteCategoryAttribute,
  getCategories,
  getCategoryStats,
  updateCategoryOrder,
  updateCategoryParent,
} from '@/api/category';
import { ROUTE } from '@/router/routeNames';
import { Dialogs } from '@/types/Dialogs';
import attributeTypesStore from '@/stores/attributeTypes';
import { KeyValue } from '@/types/Common';
import CategoryAttributes from '@ui/Categories/CategoryAttributes.vue';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcn/components/ui/tabs';
import CategoryData = App.Data.CategoryData;
import CategoryStatsData = App.Data.CategoryStatsData;
import CarbonDefaults from '@ui/Dictionaries/CarbonDefaults.vue';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/components/ui/tooltip';

export default defineComponent({
  components: {
    TooltipContent,
    TooltipTrigger,
    Tooltip,
    TooltipProvider,
    PlusCircleIcon,
    MinusCircleIcon,
    TabsContent,
    TabsTrigger,
    TabsList,
    Tabs,
    CategoryAttributes,
    Square3Stack3DIcon,
    ZButton,
    DocumentPlusIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    EllipsisHorizontalCircleIcon,
    ArrowsRightLeftIcon,
    Action,
    IconAlert,
    // shadcn components
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    CarbonDefaults,
  },

  props: {
    categoryId: {
      type: String,
      default: undefined,
      required: false,
    },
  },

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      categories: [] as CategoryData[] | null,
      selectedCategory: undefined as CategoryData | undefined,
      categoryToTransfer: null as CategoryData | null,
      attributeTypes: {} as KeyValue,
      lastSelectedIndex: null as number | null,
      state: 'loading' as ComponentLoadState,
      folded: {} as Record<string, number>,
      hasChildren: {} as Record<string, number>,
      categoryStats: {} as CategoryStatsData,
    };
  },

  computed: {
    filteredCategories() {
      const filtered = [] as CategoryData[];
      let folds = [] as number[];

      if (!this.categories) return [];

      for (let index = 0; index < this.categories!.length; index++) {
        const category = this.categories![index];

        while (folds[folds.length - 1] >= category.depth!) folds.pop();

        if (folds.length == 0) {
          filtered.push(category);
        }

        if (this.folded[category.hierarchy_path!]) {
          folds.push(this.folded[category.hierarchy_path!]);
        }
      }

      return filtered;
    },
  },

  watch: {
    $route(to: any, from: any) {
      if (to.name == ROUTE.CATEGORY_LIST && from.name != ROUTE.CATEGORY_LIST) {
        this.state = 'loading';
        this.getCategories().then(
          () => {
            this.state = 'loaded';
            if (to.query.categoryId) {
              this.selectedCategory = this.categories?.filter(attr => attr.id == to.query.categoryId)[0];
            }
          },
          () => (this.state = 'error'),
        );
      }
    },

    selectedCategory() {
      this.$router.replace({
        path: this.$route.path,
        query: { categoryId: this.selectedCategory?.id },
      });
    },
  },

  async mounted() {
    const attributeTypes = attributeTypesStore();
    this.attributeTypes = await attributeTypes.get();
    await this.getCategories();
    if (this.$route.query.categoryId) {
      this.selectedCategory = this.categories?.filter(
        attr => attr.id!.toString() == this.$route.query.categoryId!.toString(),
      )[0];
    } else {
      this.selectedCategory = this.categories![0];
    }

    if (this.selectedCategory) {
      const categoryStatsResponse = await getCategoryStats(this.selectedCategory.id!.toString()).execute();
      this.categoryStats = categoryStatsResponse.data;
    }

    this.state = 'loaded';
  },

  methods: {
    async getCategories() {
      const response = await getCategories().execute();
      this.categories = response.data;

      this.hasChildren = {};
      let lastCategory = null as CategoryData | null;

      if (this.categories!.length > 0) {
        for (let index = 0; index < this.categories!.length; index++) {
          const category = this.categories![index];

          if (lastCategory && category.depth! > lastCategory!.depth!) {
            this.hasChildren[lastCategory!.hierarchy_path!] = 1;
          }

          lastCategory = category;
        }
      }
    },

    addChild(parent: number | null) {
      if (!this.categories) {
        return;
      }

      this.$router.push({
        name: ROUTE.CATEGORY_SUB_ADD,
        params: { parentCategoryId: parent },
      });
    },

    editCategory(categoryId: string | null = null) {
      if (!this.categories) {
        return;
      }

      this.$router.push({
        name: ROUTE.CATEGORY_EDIT,
        params: { categoryId: categoryId },
      });
    },

    addCategoryAttribute() {
      this.$router.push({
        name: ROUTE.CATEGORY_ATTRIBUTE_ADD,
        params: { categoryId: this.selectedCategory!.id },
      });
    },

    async selectCategory(index: number) {
      if (!this.categories) {
        return;
      }

      if (index == -1) {
        this.selectedCategory = undefined;
        return;
      }

      this.selectedCategory = this.categories[index];

      const categoryStatsResponse = await getCategoryStats(this.selectedCategory.id!.toString()).execute();
      this.categoryStats = categoryStatsResponse.data;

      this.lastSelectedIndex = index;
    },

    moveOut(category: CategoryData) {
      this.categoryToTransfer = category;
    },

    cancelTransfer() {
      this.categoryToTransfer = null;
    },

    canTransferToDestination(destination: CategoryData | null) {
      if (this.categoryToTransfer == null) return false;

      if (destination == null) {
        if (this.categoryToTransfer.parent_category_id == null) return false;
      } else {
        if (this.categoryToTransfer.id == destination.id) return false;
        else if (this.categoryToTransfer.parent_category_id == destination.id) return false;
        else if (
          this.categoryToTransfer.hierarchy_path ==
          destination.hierarchy_path!.substring(0, this.categoryToTransfer.hierarchy_path!.length)
        )
          return false;
      }
      return true;
    },

    async moveUp(category: CategoryData) {
      if (!this.categories) {
        return;
      }

      try {
        await updateCategoryOrder(category.id!, 'up').execute();
      } catch (error: any) {
        throw error;
      }

      try {
        await this.getCategories();
      } catch (error: any) {
        this.state = 'error';
      }
    },

    async moveDown(category: CategoryData) {
      if (!this.categories) {
        return;
      }

      try {
        await updateCategoryOrder(category.id!, 'down').execute();
      } catch (error: any) {
        throw error;
      }

      try {
        await this.getCategories();
      } catch (error: any) {
        this.state = 'error';
      }
    },

    async transferCategoryTo(destination: CategoryData | null) {
      if (!this.categories) {
        return;
      }

      try {
        if (destination == null && this.categoryToTransfer != null)
          await changeCategoryParentToRoot(this.categoryToTransfer.id!).execute();
        else if (this.categoryToTransfer != null && this.categoryToTransfer.id != null)
          await updateCategoryParent(this.categoryToTransfer.id, destination!.id!).execute();
        this.categoryToTransfer = null;
      } catch (error: any) {
        throw error;
      }

      try {
        await this.getCategories();
      } catch (error: any) {
        this.state = 'error';
      }
    },

    canMove(index: number, direction: string) {
      if (!this.categories) {
        return false;
      }

      let currentCategory = this.categories[index];

      if (direction == 'down') {
        while (
          this.categories.length > index &&
          currentCategory.depth != null &&
          this.categories[index].depth! >= currentCategory.depth
        ) {
          if (this.categories[index].id != currentCategory.id && this.categories[index].depth == currentCategory.depth)
            return true;
          index++;
        }
      } else {
        while (
          index >= 0 &&
          this.categories.length > index &&
          currentCategory.depth != null &&
          this.categories[index].depth! >= currentCategory.depth
        ) {
          if (this.categories[index].id != currentCategory.id && this.categories[index].depth == currentCategory.depth)
            return true;
          index--;
        }
      }

      return false;
    },

    fold(path: string, depth: number) {
      this.folded[path] = depth;
    },

    unfold(path: string) {
      delete this.folded[path];
    },

    requestCategoryDelete() {
      this.dialogs
        .confirm(this.$t('category.label.deleting_category'), this.$t('category.message.deleting_category_question'))
        .then(() => {
          deleteCategory(this.selectedCategory!.id!)
            .execute()
            .then(() => {
              this.toast({
                title: this.$t('toasts.type.success'),
                description: this.$t('category.message.category_deleted'),
              });
              if (this.selectedCategory) {
                this.getCategories().then(() => {
                  this.selectedCategory = this.categories![0];
                });
              } else {
                this.$router.push({ name: ROUTE.CATEGORY_LIST });
              }
            });
        });
    },

    requestCategoryAttributeDelete(attributeId: number) {
      this.dialogs
        .confirm(
          this.$t('category.label.deleting_category_attribute'),
          this.$t('category.message.deleting_category_attribute_question'),
        )
        .then(() => {
          deleteCategoryAttribute(attributeId)
            .execute()
            .then(() => {
              this.toast({
                title: this.$t('toasts.type.success'),
                description: this.$t('category.message.category_attribute_deleted'),
              });
              this.getCategories().then(() => {
                this.selectedCategory = this.categories?.filter(attr => attr.id == this.selectedCategory?.id)[0];
              });
            });
        });
    },
  },
});
</script>
