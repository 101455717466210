<template>
  <Field symbol="category_id" class="col-span-4">
    <ZLabel for="category_id">
      {{ $t('assetModels.form.category') }}
    </ZLabel>
    <SelectTree v-model="selectedCategory" :tree="categoriesTree" />
  </Field>
  <Field symbol="code" class="col-span-2">
    <ZLabel for="code">
      {{ $t('assetModels.form.code') }}
    </ZLabel>
    <ZInput id="code" v-model="assetModel.code" />
  </Field>
  <Field symbol="manufacturer" class="col-span-2">
    <ZLabel for="manufacturer">
      {{ $t('assetModels.form.manufacturer') }}
    </ZLabel>
    <ZInput id="manufacturer" v-model="assetModel.manufacturer" />
  </Field>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ZInput } from '@shadcn/components/ui/input';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';
import AssetModelData = App.Data.AssetModelData;
import SelectTree from '@ui/SelectTree.vue';

export default defineComponent({
  components: {
    SelectTree,
    ZInput,
    ZLabel,
    Field,
  },

  props: {
    assetModel: {
      type: Object as PropType<AssetModelData>,
      required: true,
    },

    categories: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedCategory: '' as string,
    };
  },

  computed: {
    categoriesTree() {
      let tree = new Map<string, object>();

      for (let index in this.categories) {
        const category = this.categories[index];
        const treeKey = category.id.toString();
        tree.set(treeKey, { depth: category.depth, label: category.name });
      }

      return tree;
    },
  },

  watch: {
    selectedCategory(val: string) {
      this.assetModel.category_id = parseInt(val);
    },
  },

  mounted() {
    if (this.assetModel.category_id) this.selectedCategory = this.assetModel.category_id?.toString();
  },
});
</script>
