<template>
  <div class="p-4 my-4 border rounded">Treść w przygotowaniu.</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    // icons
    // shadcn components
  },

  inject: {
    navigation: { from: 'navigation' },
  },

  data() {
    return {};
  },

  methods: {},
});
</script>
