<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('organization.label.create_branch') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('organization.message.create_branch') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded' && branch && branchTypes">
        <FormValidator ref="form" class="grid grid-cols-4 gap-4 py-4">
          <BranchForm :branch="branch" :branch-types="branchTypes" />
        </FormValidator>
        <ZDialogFooter>
          <Action :disabled="branch.branch_type == undefined" @proceed="store">
            {{ $t('organization.command.create_branch') }}
          </Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import { createBranch } from '@/api/organization';
import BranchData = App.Data.BranchData;
import FormValidator from '@ui/FormValidator.vue';
import type { KeyValue } from '@/types/Common';
import symbolsStore from '@/stores/symbols';
import BranchForm from '@ui/Organizations/BranchForm.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';

export default defineComponent({
  components: {
    IconAlert,
    BranchForm,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    organizationId: {
      type: String,
      required: true,
    },

    parentBranchId: {
      type: String,
      default: undefined,
      required: false,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      branch: {} as BranchData,
      branchTypes: {} as KeyValue,
      isDialogOpen: true,

      state: 'loading' as ComponentLoadState,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      const symbols = symbolsStore();
      this.branchTypes = await symbols.get('branch_types');

      this.branch = {} as BranchData;

      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
    }
  },

  methods: {
    store(action: any) {
      createBranch(this.organizationId, this.parentBranchId, this.branch)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('organization.message.branch_saved'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
