<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('asset.label.assign_tags') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('asset.message.assign_tags') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <div class="lg:max-h-[70vh] lg:overflow-auto">
          <FormValidator ref="form">
            <AssetTagsForm :asset="asset" :tags="tagsDataSet" :asset-tags="assetTags" @update="modifiedTags = $event" />
          </FormValidator>
        </div>
        <ZDialogFooter>
          <Action :disabled="!tagsDataSet.data || !tagsDataSet.data.length" @proceed="save">{{ $t('asset.command.save_changes') }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import AssetData = App.Data.AssetData;
import { getAsset, getAssetTags, manageAssetTags } from '@/api/asset';
import AssetTagsForm from '@ui/Assets/AssetTagsForm.vue';
import { getTags } from '@/api';
import { DataSet } from '@/utils/DataSet';
import TagListData = App.Data.TagListData;
import TagRequestData = App.Data.TagRequestData;
import AssetTagData = App.Data.AssetTagData;

export default defineComponent({
  components: {
    AssetTagsForm,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    assetId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      asset: {} as AssetData,
      state: 'loading' as ComponentLoadState,
      tagsDataSet: {} as DataSet<TagListData, TagRequestData>,
      assetTags: [] as AssetTagData[],
      modifiedTags: [],
      loader: null as any,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      this.asset = (await getAsset(this.assetId).execute()).data;
      this.tagsDataSet = new DataSet<TagListData, TagRequestData>((query: string) => getTags().execute(query)).setParams({ search: '', page: 1, status: 'visible', perPage: 30 });
      this.tagsDataSet.load();
      const response = await getAssetTags(this.assetId).execute();
      this.assetTags = response.data;
      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
      throw error;
    }
  },

  methods: {
    save(action: any) {
      if (!this.asset) {
        return;
      }

      manageAssetTags(this.assetId, this.modifiedTags)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('assetModels.message.attributes_saved'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
