import { http } from './http';
import { ApiCall } from '@/api/ApiCall';
import {PaginatedResponse} from "@/utils/DataSet";
export const getImportedAssets = () => {
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<any>>('/imports/asset' + query);
    },
    {},
    '',
  );
};
export const storeImportAsset = (fields: FormData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post(`/imports/asset`, fields);
    },
    {},
    '',
    fields,
  );
};
export const storeImagesImportedAssets = (fields: FormData) => {
  return http.post(`/imports/asset/images`, fields);
};
export const confirmImportAsset = (fields: FormData, withWarnings: Boolean) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post(`/imports/asset/confirm/${options.mode}`, fields);
    },
    {mode: withWarnings ? 'withWarnings' : 'strict'},
    '',
    fields,
  );
};

export const getImportAssetConfig = () => {
  return ApiCall.create(
    () => {
      return http.get<PaginatedResponse<any>>('/imports/asset/config');
    },
    {},
    '',
  );
};
