import FormValidator from '@ui/FormValidator.vue';
import { KeyValue } from '@/types/Common';
import { Loader } from '@/types/Loader';

type ApiCallCallback<RequestType, ResponseType> = (
  options: KeyValue,
  query: string | null,
  fields: RequestType | null,
) => Promise<ResponseType>;

export class ApiCall<RequestType, ResponseType> {
  private callback: ApiCallCallback<RequestType, ResponseType>;
  private loader: any | null;
  private form: any | null;
  private options: KeyValue;
  private query: string | null;
  private fields: RequestType | null;
  private loadingContext: string;

  private constructor(
    callback: ApiCallCallback<RequestType, ResponseType>,
    options: KeyValue,
    query = null as null | string,
    fields = null as RequestType | null,
  ) {
    this.callback = callback;
    this.loader = null;
    this.form = null;
    this.options = options;
    this.query = query;
    this.fields = fields;
    this.loadingContext = crypto.randomUUID();
  }

  static create<RequestType, ResponseType>(
    callback: ApiCallCallback<RequestType, ResponseType>,
    options: KeyValue,
    query = null as null | string,
    fields = null as RequestType | null,
  ) {
    return new ApiCall<RequestType, ResponseType>(callback, options, query, fields);
  }

  public setOption(optionName: string, optionValue: any) {
    if (this.options == null) this.options = {};
    this.options[optionName] = optionValue;
  }

  public withForm(form: typeof FormValidator) {
    this.form = form;
    return this;
  }

  public withLoader(loader: Loader) {
    this.loader = loader;
    return this;
  }

  public execute(query = null as string | null) {
    if (query == undefined) query = this.query;

    if (query == undefined) query = '';
    if (this.loader != null) this.loader.setLoading(true, this.loadingContext);

    return this.callback(this.options, query, this.fields).then(
      response => {
        if (this.loader != null) this.loader.setLoading(false, this.loadingContext);
        return response;
      },
      error => {
        if (this.loader != null) {
          this.loader.setError(true, this.loadingContext, error.response.status);
        }
        if (this.form != null) this.form.setErrors(error.response.data.errors);

        throw error;
      },
    );
  }
}
