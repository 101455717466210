<template>
  <Field symbol="description" class="mt-4">
    <ZLabel for="description">
      {{ $t('inventory.form.description') }}
    </ZLabel>
    <textarea
      id="description"
      v-model="localDescription"
      class="flex w-full min-h-24 resize-none rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
    >
    </textarea>
  </Field>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';

export default defineComponent({
  components: {
    ZLabel,
    Field,
  },

  props: {
    description: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['update:description'],

  data() {
    return {
      localDescription: this.description,
    };
  },

  watch: {
    localDescription(newVal: string) {
      this.$emit('update:description', newVal);
    },
  },
});
</script>
