<template>
  <div class="layout">
    <div class="layout-top-bar">
      <div class="flex max-w-[70%] md:max-w-full">
        <div class="w-[70px] md:hidden self-center">
          <a
            class="flex md:hidden items-center gap-4 ml-4 hover:bg-gray-50 p-2 pr-8 cursor-pointer border-r whitespace-nowrap"
            @click="toggleMenu"
          >
            <ListBulletIcon class="icon md"/>
          </a>
        </div>
        <div class="overflow-hidden w-full">
          <OrganizationMenu/>
        </div>
      </div>
      <div class="sm:flex">
        <div
          class="hidden lg:flex items-center gap-4 ml-6 hover:bg-gray-50 p-2 pr-8 cursor-pointer border-r whitespace-nowrap"
        >
          <DevicePhoneMobileIcon class="icon md"/>
          {{ $t('app.mobile_app_download') }}
        </div>
        <div class="hidden lg:flex items-center px-2 rounded-sm cursor-pointer border-r whitespace-nowrap">
          <ChangeLanguage/>
        </div>
        <UserMenu/>
      </div>
    </div>
    <div class="layout-sidebar overflow-y-auto flex flex-col justify-between h-full" :class="showMenu && 'show-menu'">
      <a class="absolute right-0 p-3 md:hidden cursor-pointer" @click="toggleMenu">
        <XCircleIcon class="icon md"/>
      </a>
      <div>
        <div class="flex w-full justify-center">
          <router-link :to="{ name: ROUTE.DASHBOARD }">
            <img src="@pub/images/logo.png" title="Logo ZWD" class="max-w-32 pt-12 pb-4"/>
          </router-link>
        </div>
        <h3>{{ $t('menu.general') }}</h3>
        <div class="v-menu side">
          <router-link :to="{ name: ROUTE.DASHBOARD }" class="menu-item">
            <LightBulbIcon class="icon sm"/>
            {{ $t('menu.dashboard') }}
          </router-link>
          <router-link :to="{ name: ROUTE.NOTIFICATIONS }" class="menu-item">
            <BellIcon class="icon sm"/>
            <span class="flex items-center gap-2">
            {{ $t('menu.notifications') }}
              <span
                v-if="menuStats && menuStats.new_notifications"
                class="bg-red-600 text-white h-[1.25rem] font-medium w-[1.285rem] text-[10px] rounded-full flex items-center justify-center">{{
                  menuStats.new_notifications
                }}
              </span>
            </span>
          </router-link>
          <router-link :to="{ name: ROUTE.ABOUT }" class="menu-item disabled">
            <BellIcon class="icon sm"/>
            {{ $t('menu.about') }}
          </router-link>
        </div>
        <h3 v-if="access.allowed(SYMBOL.PERMISSION.ORG_ADMIN)">{{ $t('menu.admin') }}</h3>
        <div class="v-menu side">
          <router-link v-if="access.operator()" :to="{ name: ROUTE.ORGANIZATION_LIST }" class="menu-item">
            <BriefcaseIcon class="icon sm"/>
            {{ $t('menu.organizations') }}
          </router-link>
          <router-link
            v-if="!access.operator() && access.allowed(SYMBOL.PERMISSION.ORG_ADMIN) && access.currentOrganizationId()"
            :to="{ name: ROUTE.ORGANIZATION_DETAILS, params: { organizationId: access.currentOrganizationId() } }"
            class="menu-item"
          >
            <BriefcaseIcon class="icon sm"/>
            {{ $t('menu.branches') }}
          </router-link>
          <router-link
            v-if="access.allowed(SYMBOL.PERMISSION.ORG_ADMIN)"
            :to="{ name: ROUTE.USER_ACCOUNT_LIST }"
            class="menu-item"
          >
            <UserGroupIcon class="icon sm"/>
            {{ $t('menu.users') }}
          </router-link>
          <router-link
            v-if="access.operator(SYMBOL.PERMISSION.SYS_CATEGORY_MANAGER)"
            :to="{ name: ROUTE.CATEGORY_LIST }"
            class="menu-item"
          >
            <Square3Stack3DIcon class="icon sm"/>
            {{ $t('menu.categories') }}
          </router-link>
          <router-link
            v-if="access.operator(SYMBOL.PERMISSION.SYS_DICTIONARY_MANAGER)"
            :to="{ name: ROUTE.ASSET_MODELS }"
            class="menu-item"
          >
            <CubeIcon class="icon sm"/>
            {{ $t('menu.assetModels') }}
          </router-link>
          <router-link v-if="access.operator()" :to="{ name: ROUTE.MANAGED_CONTRACTS_LIST }" class="menu-item">
            <TruckIcon class="icon sm"/>
            <span class="flex gap-2 items-center">
            {{ $t('menu.contracts') }}
              <span
                v-if="menuStats && menuStats.new_contracts"
                class="bg-red-600 text-white h-[1.25rem] font-medium w-[1.285rem] flex items-center text-[10px] rounded-full justify-center">{{
                  menuStats.new_contracts
                }}
              </span>
            </span>
          </router-link>
          <router-link
            v-if="access.operator(SYMBOL.PERMISSION.SYS_DICTIONARY_MANAGER)"
            :to="{ name: ROUTE.DICTIONARY.ACTIONS }"
            class="menu-item"
          >
            <BookOpenIcon class="icon sm"/>
            {{ $t('menu.dictionaries') }}
          </router-link>
        </div>
        <h3>{{ $t('menu.organization') }}</h3>
        <div class="v-menu side">
          <router-link
            :to="{ name: ROUTE.ASSET_LIST }"
            class="menu-item"
            :class="{ disabled: !access.hasBranchSelected() }"
          >
            <CubeIcon class="icon sm"/>
            {{ $t('menu.assets') }}
          </router-link>
          <router-link
            :to="{ name: ROUTE.CONTRACTS_LIST }"
            class="menu-item"
            :class="{ disabled: !access.hasBranchSelected() }"
          >
            <TruckIcon class="icon sm"/>
            {{ $t('menu.contracts') }}
          </router-link>
          <router-link
            :to="{ name: ROUTE.INVENTORY_LIST }"
            class="menu-item"
            :class="{ disabled: !access.hasBranchSelected() }"
            v-if="access.hasFeature(SYMBOL.FEATURES.INVENTORY)"
          >
            <BriefcaseIcon class="icon sm"/>
            {{ $t('menu.inventory') }}
          </router-link>
          <router-link
            v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_REPORT, true)"
            :to="{ name: ROUTE.REPORT_LIST }"
            class="menu-item"
            :class="{ disabled: !access.currentOrganizationId() }"
          >
            <DocumentChartBarIcon class="icon sm"/>
            {{ $t('menu.reports') }}
          </router-link>
          <router-link
            :class="{ disabled: !access.hasBranchSelected() }"
            :to="{ name: ROUTE.FILE_LIST }"
            class="menu-item"
            v-if="access.hasFeature(SYMBOL.FEATURES.DOCUMENTS)"
          >
            <LinkIcon class="icon sm"/>
            {{ $t('menu.files') }}
          </router-link>
          <router-link
            v-if="access.allowed(SYMBOL.PERMISSION.ORG_ADMIN)"
            :to="{ name: ROUTE.TAGS }"
            class="menu-item"
            :class="{ disabled: !access.currentOrganizationId() }"
          >
            <TagIcon class="icon sm"/>
            {{ $t('menu.tags') }}
          </router-link>
        </div>
      </div>

      <div class="flex justify-center p-4 pt-8 w-full self-end">
        <a
          href="https://outlet.zerowaste.design/pl/page/regulamin"
          class="text-xs mr-3 text-gray-400"
          target="_blank"
        >{{ $t('auth.login.rules') }}</a
        >
        <a
          href="https://outlet.zerowaste.design/pl/page/polityka-prywatnosci"
          target="_blank"
          class="text-xs text-gray-400"
        >{{ $t('auth.login.policy') }}</a
        >
      </div>
    </div>
    <div
      v-if="showMenu"
      class="absolute z-20 left-0 right-0 top-0 bottom-0 bg-black bg-opacity-50 mh:hidden"
      @click="toggleMenu"
    ></div>
    <div class="layout-body pt-8">
      <div class="container">
        <div v-if="access.twoFactorWarning" class="mb-4 w-full p-6 bg-orange-400 text-white font-bold rounded-sm flex items-center gap-4">
          <LockClosedIcon class="icon md"></LockClosedIcon>
          {{ $t('common.errors.twoFactorDisabled') }}
        </div>
        <div class="lg:flex gap-4 justify-between">
          <div class="w-full">
            <Breadcrumbs v-if="hasBreadcrumbs" ref="breadcrumbs"></Breadcrumbs>
            <h1 class="text-2xl font-bold">{{ panelTitle }}</h1>
          </div>
          <div id="toolbox" class="mt-2"></div>
        </div>
        <router-view></router-view>
      </div>
    </div>
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
    <SelectBranchDialog ref="selectBranchDialog"></SelectBranchDialog>
    <AuthorizeDialog ref="authorizeActionDialog"></AuthorizeDialog>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {
  BellIcon,
  BriefcaseIcon,
  UserGroupIcon,
  TruckIcon,
  BookOpenIcon,
  CubeIcon,
  DocumentChartBarIcon,
  LightBulbIcon,
  DevicePhoneMobileIcon,
  ListBulletIcon,
  XCircleIcon,
  Square3Stack3DIcon,
  TagIcon,
  LinkIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
} from '@heroicons/vue/24/outline';
import Breadcrumbs from '@ui/Breadcrumbs.vue';
import OrganizationMenu from '@ui/OrganizationMenu.vue';
import UserMenu from '@ui/UserMenu.vue';
import ChangeLanguage from '@ui/ChangeLanguage.vue';
import Navigation from '@/types/Navigation';
import ConfirmDialog from '@ui/ConfirmDialog.vue';
import SelectBranchDialog from '@ui/SelectBranchDialog.vue';
import {Dialogs} from '@/types/Dialogs';
import AuthorizeDialog from '@ui/AuthorizeDialog.vue';
import useAccessStore from '@/stores/access';
import MenuCounterData = App.Data.MenuCounterData;
import statsStore from '@/stores/stats';


export default defineComponent({
  components: {
    LinkIcon,
    LockClosedIcon,
    AuthorizeDialog,
    TagIcon,
    UserMenu,
    OrganizationMenu,
    Breadcrumbs,
    BellIcon,
    BriefcaseIcon,
    UserGroupIcon,
    TruckIcon,
    BookOpenIcon,
    DocumentChartBarIcon,
    CloudArrowUpIcon,
    CubeIcon,
    LightBulbIcon,
    DevicePhoneMobileIcon,
    ListBulletIcon,
    XCircleIcon,
    ConfirmDialog,
    Square3Stack3DIcon,
    SelectBranchDialog,
    ChangeLanguage,
  },

  provide() {
    let nav = {
      breadcrumbs: this.getBreadcrumbsComponent,
      setPanelTitle: this.setPanelTitle,
      showBreadcrumbs: this.showBreadcrumbs,
    } as Navigation;

    let dialogs = {
      confirm: this.openConfirmDialog as (title: string, message: string) => Promise<void>,
      selectBranch: this.openSelectBranchDialog as (
        organizationId: number | undefined,
        branchId: number | undefined,
      ) => Promise<number[]>,

      authorizeAction: this.openAuthorizeActionDialog as () => Promise<void>,
    };

    let stats = {
      updateStats: this.updateStats as () => Promise<void>,
    };

    return {
      navigation: nav,
      dialogs: dialogs as Dialogs,
      stats
    };
  },

  data() {
    return {
      panelTitle: 'Panel' as string,
      hasBreadcrumbs: true,
      showMenu: false,
      originalStyles: {
        display: '',
      },
      menuStats: {} as MenuCounterData
    };
  },

  computed: {
    access: () => useAccessStore(),
  },

  watch: {
    $route(to: any) {
      this.showBreadcrumbs(true);
      if (to.meta?.title != undefined && to.meta?.breadcrumbs !== false) {
        this.setPanelTitle(this.$t(to.meta.title));
      }
      this.getBreadcrumbsComponent().empty();
      this.getBreadcrumbsComponent().addPathFromRoute();
      this.closeMenu();
    },
  },

  mounted() {
    const recaptchaBadge = document.querySelector('.grecaptcha-badge') as HTMLElement;
    const recaptchaError = document.querySelector('.grecaptcha-error') as HTMLElement;

    setInterval(() => {
      this.updateStats();
    }, 60000)

    if (this.$route.meta?.title != undefined && this.$route.meta?.breadcrumbs !== false) {
      this.setPanelTitle(this.$t(this.$route.meta.title as string));
    }
    this.updateStats();
    (this.$refs.breadcrumbs as typeof Breadcrumbs).empty();
    (this.$refs.breadcrumbs as typeof Breadcrumbs).addPathFromRoute();

    if (recaptchaBadge) {
      this.originalStyles.display = recaptchaBadge.style.display;
      recaptchaBadge.style.display = 'none';
    } else if (recaptchaError) {
      this.originalStyles.display = recaptchaError.style.display;
      recaptchaError.style.display = 'none';
    }
  },

  unmounted() {
    const recaptchaBadge = document.querySelector('.grecaptcha-badge') as HTMLElement;
    const recaptchaError = document.querySelector('.grecaptcha-error') as HTMLElement;

    if (recaptchaBadge) {
      recaptchaBadge.style.display = this.originalStyles.display || '';
    } else if (recaptchaError) {
      recaptchaError.style.display = this.originalStyles.display || '';
    }
  },

  methods: {
    async updateStats() {
      const stats = statsStore();
      await stats.load();
      this.menuStats['new_notifications'] = await stats.get('new_notifications');
      this.menuStats['new_contracts'] = await stats.get('new_contracts');
    },

    toggleMenu() {
      this.showMenu = !this.showMenu;
    },

    closeMenu() {
      this.showMenu = false;
    },

    setPanelTitle(t: string) {
      this.panelTitle = t;
    },

    showBreadcrumbs(t: boolean) {
      this.hasBreadcrumbs = t;
    },

    getBreadcrumbsComponent(): any {
      return this.$refs.breadcrumbs;
    },

    getConfirmDialogComponent() {
      return this.$refs.confirmDialog as InstanceType<typeof ConfirmDialog>;
    },

    openConfirmDialog(title: string, message: string): Promise<void> {
      return this.getConfirmDialogComponent().open(title, message);
    },

    getSelectBranchComponent() {
      return this.$refs.selectBranchDialog as InstanceType<typeof SelectBranchDialog>;
    },

    openSelectBranchDialog(organizationId: number | undefined, branchId: number | undefined): Promise<number[]> {
      return this.getSelectBranchComponent().open(organizationId, branchId);
    },

    getAuthorizeActionComponent() {
      return this.$refs.authorizeActionDialog as InstanceType<typeof AuthorizeDialog>;
    },

    openAuthorizeActionDialog(): Promise<void> {
      return this.getAuthorizeActionComponent().open();
    },
  },
});
</script>

<style>
.layout {
  @apply flex;
  @apply items-stretch;

  .layout-top-bar {
    @apply fixed w-full h-20 flex;
    @apply drop-shadow-pane;
    @apply items-center justify-between gap-4;
    @apply bg-white;
    @apply md:pl-[300px];
    @apply z-10;
  }

  .layout-sidebar {
    &.show-menu {
      @apply ml-0;
    }

    @apply fixed h-full drop-shadow-pane;
    @apply bg-white z-30;

    @apply w-[300px] -ml-[300px] md:ml-0;

    h3 {
      @apply pl-6 pt-4 pb-2;
      @apply font-bold uppercase text-black text-[11px];
    }

    .feature:hover {
      @apply bg-black bg-opacity-5;
    }
  }

  .layout-body {
    @apply w-full;
    @apply bg-white;
    @apply mt-20;
    @apply md:ml-[300px];
    @apply md:w-[calc(100%-300px)];
  }
}
</style>
