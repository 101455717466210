export const ROUTE = {
  ROOT: 'root',

  // Login routes
  LOGIN: 'guest.login',
  REGISTER: 'guest.register',
  REGISTER_EMPLOYEE: 'guest.register.employee',
  RESET_PASSWORD: 'guest.reset-password',
  CHANGE_PASSWORD: 'guest.change-password',

  // App top routes
  DASHBOARD: 'user.dashboard',
  NOTIFICATIONS: 'user.notifications',
  ABOUT: 'app.about',

  ACCOUNT_PERSONAL_INFORMATION: 'account.personal',
  ACCOUNT_SECURITY: 'account.security',
  ACCOUNT_PASSWORD: 'account.password',
  ACCOUNT_SETTINGS: 'account.settings',

  // Organization routes
  ORGANIZATION_LIST: 'organization.list',
  ORGANIZATION_DETAILS: 'organization.details',
  ORGANIZATION_ADD: 'organization.add',
  ORGANIZATION_EDIT: 'organization.edit',
  ORGANIZATION_MANAGE: 'organization.manage',
  ORGANIZATION_BRANCH_ADD: 'organization.branch.add',
  ORGANIZATION_BRANCH_EDIT: 'organization.branch.edit',

  // User account routes
  USER_ACCOUNT_LIST: 'userAccount.list',
  USER_ACCOUNT_DETAILS: 'userAccount.details',
  USER_ACCOUNT_ADD: 'userAccount.add',
  USER_ACCOUNT_EDIT: 'userAccount.edit',
  USER_ACCOUNT_CHANGE_PASSWORD: 'userAccount.changePassword',
  USER_ACCOUNT_MANAGE_2FA: 'userAccount.manage2fa',

  CATEGORY_LIST: 'category.list',
  CATEGORY_ADD: 'category.add',
  CATEGORY_EDIT: 'category.edit',
  CATEGORY_SUB_ADD: 'category.child.add',

  CATEGORY_ATTRIBUTE_ADD: 'category.attribute.add',
  CATEGORY_ATTRIBUTE_EDIT: 'category.attribute.edit',

  // Model routes
  ASSET_MODELS: 'models',
  ASSET_MODELS_DETAILS: 'assetModels.details',
  ASSET_MODELS_DETAILS_EDIT: 'assetModels.details.editDetails',
  ASSET_MODELS_DETAILS_EDIT_ATTRIBUTES: 'assetModels.details.editAttributes',
  ASSET_MODELS_DETAILS_EDIT_IMAGES: 'assetModels.details.editImages',
  ASSET_MODEL_ADD: 'assetModels.add',

  // Tag routes
  TAGS: 'tags',
  TAGS_EDIT: 'tags.edit',
  TAGS_ADD: 'tags.add',

  DICTIONARY: {
    INDEX: 'dictionary.index',

    // Action routes
    ACTIONS: 'action.index',
    ACTION_EDIT: 'action.edit',
    ACTION_ADD: 'action.add',
    ACTION_SHOW: 'action.show',
    ACTION_DELETE: 'action.delete',
    COUNTRIES: 'countries',
    COUNTRY_INDEX: 'country.index',
    COUNTRY_EDIT: 'country.edit',
    COUNTRY_ADD: 'country.add',
    REGION_ADD: 'region.add',
  },

  // Asset routes
  ASSET_LIST: 'asset.list',
  ASSET_DETAILS: 'asset.details',
  ASSET_DETAILS_EDIT: 'asset.details.editDetails',
  ASSET_ASSIGN_TAGS: 'asset.details.assignTags',
  ASSET_CATEGORY_AND_MODEL_EDIT: 'asset.details.editCategoryAndModel',
  ASSET_DETAILS_EDIT_ATTRIBUTES: 'asset.details.editAttributes',
  ASSET_DETAILS_UPDATE_STATUS: 'asset.details.updateStatus',
  ASSET_DETAILS_EDIT_IMAGES: 'asset.details.editImages',
  ASSET_DUPLICATE: 'asset.duplicate',
  ASSET_ADD: 'asset.add',
  ASSET_TRANSFER: 'asset.transfer',

  // Inventory routes
  INVENTORY_LIST: 'inventory.list',
  INVENTORY_DETAILS: 'inventory.details',
  INVENTORY_ADD: 'inventory.add',
  ASSET_ADD_NOTE: 'asset.addNote',
  ASSET_EDIT_NOTE: 'asset.editNote',
  ASSET_EDIT_CARBON: 'asset.editCarbonFootprint',

  // Contract routes
  CONTRACTS_LIST: 'contracts.list',
  CONTRACT_DETAILS: 'contracts.details',
  CONTRACT_EDIT: 'contracts.details.edit',
  CONTRACT_ACTION_EDIT: 'contracts.actions.editAction',
  CONTRACTS_ADD: 'contracts.add',
  CONTRACT_ADD_ACTIONS: 'contracts.addActions',
  CONTRACT_ACTION_ASSIGN_ASSETS: 'contracts.actions.assignAssets',
  CONTRACT_ACTION_ASSET_ADD_NOTE: 'contracts.actions.assets.addNote',

  // Report routes
  REPORT_LIST: 'report.list',
  GENERATE_ASSETS_REPORT: 'report.generateAssetsReport',
  GENERATE_CARBON_REPORT: 'report.generateCarbonReport',

  MANAGED_CONTRACTS_LIST: 'managed.contracts.list',
  MANAGED_CONTRACT_DETAILS: 'managed.contracts.details',
  MANAGED_CONTRACT_STATUS_UPDATE: 'managed.contracts.status_update',
  MANAGED_CONTRACT_ACTION_EDIT: 'managed.contracts.actions.editAction',
  MANAGED_CONTRACT_ACTION_STATUS: 'managed.contracts.actions.changeStatus',
  MANAGED_CONTRACT_ADD_NOTE: 'managed.contracts.add_note',
  MANAGED_CONTRACT_ADD_NOTE_TO_ACTION: 'managed.contracts.add_note_to_action',
  MANAGED_CONTRACT_ADD_NOTE_TO_ASSET: 'managed.contracts.add_note_to_asset',
  MANAGED_CONTRACT_ASSET_CARBON: 'managed.contracts.asset_carbon',

  FILE_LIST: 'file.list',
  FILE_ATTACH: 'file.attach',
  FILE_EDIT: 'file.edit',

  IMPORT: 'import',
  IMPORT_ASSETS: 'import.assets',

  PAGE_NOT_FOUND: 'page_not_found'
};
