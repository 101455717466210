export const AUTH_COOKIE_KEY = 'auth';

export const PRIORITY = {
  1: 'trivial',
  2: 'very_low',
  3: 'low',
  4: 'normal',
  5: 'high',
  6: 'very_high',
  7: 'critical',
};
