<template>
  <div v-if="newAssetMode">
    <Field symbol="category_id" class="pt-4">
      <ZLabel for="category_id">
        {{ $t('asset.form.category') }}
      </ZLabel>
      <SelectTree v-model="selectedCategory" :tree="categoriesTree"/>
    </Field>
    <Field class="py-4" symbol="asset_model_code">
      <ZLabel for="code">
        {{ $t('asset.form.model_number') }}
      </ZLabel>
      <ZInput
        id="code"
        v-model="asset.code"
        :placeholder="$t('asset.label.model_search')"
      />
    </Field>
    <Field symbol="manufacturer" class="pt-4">
      <ZLabel for="manufacturer">
        {{ $t('asset.form.manufacturer') }}
      </ZLabel>
      <ZInput id="name" v-model="asset.manufacturer"/>
    </Field>
  </div>
  <div v-else>
    <Field class="py-4" symbol="model_search">
      <ZLabel for="model_search">
        {{ $t('asset.form.model') }}
      </ZLabel>
      <ZInput
        id="model_search"
        v-model="filters.search"
        :placeholder="$t('asset.label.model_search')"
      />
    </Field>
    <Field symbol="category_id" class="pb-4">
      <ZLabel for="category_id">
        {{ $t('asset.form.category') }}
      </ZLabel>
      <SelectTree v-model="selectedCategory" :tree="categoriesTree"/>
    </Field>
    <span class="text-sm">Wybierz model:</span>
    <div class="border rounded h-[29rem] mt-1">
      <ZTable>
        <ZTableHeader>
          <ZTableRow>
            <ZTableHead class="w-[50px]"></ZTableHead>
            <ZTableHead class="w-[80px]"></ZTableHead>
            <ZTableHead>Nazwa</ZTableHead>
            <ZTableHead>Kod modelu</ZTableHead>
            <ZTableHead>Producent</ZTableHead>
          </ZTableRow>
        </ZTableHeader>
        <ZTableBody>
          <ZTableRow v-for="(model, index) in models.data" :key="index">
            <ZTableCell>
              <Checkbox
                id="is_required"
                class="h-6 w-6"
                :checked="selectedModel == model.id"
                @update:checked="toggleSelected(model.id)"
              />
            </ZTableCell>
            <ZTableCell>
              <img
                v-if="model.thumb"
                class="w-[50px] h-[50px] object-contain rounded-xl"
                :src="model.thumb"
                alt="primaryImage"
              />
            </ZTableCell>
            <ZTableCell>{{ model.name }}</ZTableCell>
            <ZTableCell>{{ model.code }}</ZTableCell>
            <ZTableCell>{{ model.manufacturer ? model.manufacturer : '-' }}</ZTableCell>
          </ZTableRow>
        </ZTableBody>
      </ZTable>
      <div v-if="models.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
        <ExclamationTriangleIcon class="icon lg"/>
        {{ $t('asset.label.create_new_model') }}
        <ZButton @click="newAssetMode = true" v-if="newAsset">{{ $t('asset.command.create_new_model') }}</ZButton>
      </div>
    </div>
    <div class="min-h-[5vh]">
      <Paginator :data="models"/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {ZInput} from '@shadcn/components/ui/input';
import {ZLabel} from '@shadcn/components/ui/label';
import {ZButton} from "@shadcn/components/ui/button";
import Field from '@ui/FormField.vue';
import Paginator from '@ui/Paginator.vue';
import {PropType} from 'vue';
import {DataSet} from '@/utils/DataSet';
import SelectTree from '@ui/SelectTree.vue';
import {ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow} from "@shadcn/components/ui/table";
import {Checkbox} from "@shadcn/components/ui/checkbox";
import {ExclamationTriangleIcon} from "@modules/@heroicons/vue/24/outline";

export default defineComponent({
  components: {
    ExclamationTriangleIcon,
    Checkbox,
    ZTableHead,
    ZTableBody,
    ZTableCell,
    ZTableHeader,
    ZTableRow,
    ZTable,
    SelectTree,
    Paginator,
    ZInput,
    ZLabel,
    ZButton,
    Field,
  },

  props: {
    asset: {
      type: Object,
      required: true,
    },

    filters: {
      type: Object,
      required: true,
    },

    categories: {
      type: Object,
      required: true,
    },

    models: {
      type: Object as PropType<DataSet<any, any>>,
      required: true,
    },

    newAsset: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      selectedCategory: '' as string,
      selectedModel: '' as string,
      newAssetMode: false as boolean,
    };
  },

  computed: {
    categoriesTree() {
      let tree = new Map<string, object>();

      for (let index in this.categories) {
        const category = this.categories[index];
        const treeKey = category.id.toString();
        tree.set(treeKey, {depth: category.depth, label: category.name});
      }

      return tree;
    },
  },

  watch: {
    selectedCategory(val: string) {
      this.asset.asset_model_id = null;
      this.asset.category_id = parseInt(val);
    },

    selectedModel(val: string) {
      this.asset.asset_model_id = parseInt(val);
    },

    'filters.search'() {
      this.selectedModel = '';
      this.asset.asset_model_id = null;
      this.asset.code = this.filters.search;
    },
  },

  mounted() {
    if (this.asset.category_id) this.selectedCategory = this.asset.category_id?.toString() ?? '';
    if (this.asset.asset_model_id) this.selectedModel = this.asset.asset_model_id?.toString() ?? '';
  },

  methods: {
    toggleSelected(id: string) {
      if (this.selectedModel !== id) {
        this.selectedModel = id
      } else {
        this.selectedModel = ''
      }
    }
  }
});
</script>
