import { http } from './http';
import UserAccountData = App.Data.UserAccountData;
import NewRecordResponse = App.Data.NewRecordResponse;
import SavedRecordResponse = App.Data.SavedRecordResponse;
import { ApiCall } from '@/api/ApiCall';
import { KeyValue } from '@/types/Common';
import ActionableAccountsStatsData = App.Data.ActionableAccountsStatsData;
import AdminUpdatesUserPasswordRequestData = App.Data.AdminUpdatesUserPasswordRequestData;
import OperatorCompactData = App.Data.OperatorCompactData;
import PermissionData = App.Data.PermissionData;
import { PaginatedResponse } from '@/utils/DataSet';
import UserTrackedActivityData = App.Data.UserTrackedActivityData;
import BranchPermissionData = App.Data.BranchPermissionData;
import UpdateUserAccountData = App.Data.UpdateUserAccountData;

export const createUserAccount = (fields: UserAccountData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<NewRecordResponse>('/userAccounts/user', fields);
    },
    {},
    '',
    fields,
  );
};

export const createOperatorAccount = (fields: UserAccountData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<NewRecordResponse>('/userAccounts/operator', fields);
    },
    {},
    '',
    fields,
  );
};

export const saveUserAccount = (id: string, fields: UpdateUserAccountData) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(`/userAccounts/${options.id}`, fields);
    },
    options,
    '',
    fields,
  );
};

export const getActionableAccountsStats = () => {
  return ApiCall.create(
    (options, query) => {
      return http.get<ActionableAccountsStatsData>(`userAccounts/actionable-stats` + query);
    },
    {},
    '',
  );
};

export const getUserAccounts = () => {
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<UserAccountData>>(`userAccounts` + query);
    },
    {},
    '',
  );
};

export const getUserAccount = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get<UserAccountData>(`/userAccounts/${options.id}`);
  }, options);
};

export const deleteUserAccount = (id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  return ApiCall.create(options => {
    return http.delete(`/userAccounts/${options.id}`);
  }, options);
};

export const getUserActivities = (userId: string) => {
  const options = {} as KeyValue;
  options.id = userId;
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<UserTrackedActivityData>>(`/userAccounts/${options.id}/activity` + query);
    },
    options,
    '',
  );
};

export const setUserState = (userId: string, state: string) => {
  const options = {} as KeyValue;
  options.id = userId;
  options.state = state;
  return ApiCall.create(options => {
    return http.get<SavedRecordResponse>(`/userAccounts/${options.id}/set-state/${options.state}`);
  }, options);
};

export const setUserOrganization = (userId: number, organizationId: string) => {
  const options = {} as KeyValue;
  options.id = userId.toString();
  options.organizationId = organizationId;
  return ApiCall.create(
    (options) => {
      return http.post(`/userAccounts/${options.id}/organizations/${options.organizationId}`);
    },
    options,
  );
};

export const removeUserOrganization = (userId: number, organizationId: string) => {
  const options = {} as KeyValue;
  options.id = userId.toString();
  options.organizationId = organizationId;
  return ApiCall.create(
    options => {
      return http.delete(`/userAccounts/${options.id}/organizations/${options.organizationId}`);
    },
    options,
  );
};

export const updateUserPassword = (userId: string, fields: AdminUpdatesUserPasswordRequestData) => {
  const options = {} as KeyValue;
  options.id = userId;
  return ApiCall.create(options => {
    return http.patch<SavedRecordResponse>(`/userAccounts/${options.id}/set-password`, fields);
  }, options);
};

export const sendPasswordResetLink = (userId: string) => {
  const options = {} as KeyValue;
  options.id = userId;
  return ApiCall.create(options => {
    return http.patch<SavedRecordResponse>(`/userAccounts/${options.id}/send-password-reset-link`);
  }, options);
};

export const postDisable2FAForUser = (userId: string) => {
  const options = {} as KeyValue;
  options.id = userId;
  return ApiCall.create(options => {
    return http.delete(`/userAccounts/${options.id}/disable-two-factor-auth`);
  }, options);
};

export const getRecoveryCodesForUser = (userId: string) => {
  const options = {} as KeyValue;
  options.id = userId;
  return ApiCall.create(options => {
    return http.get(`/userAccounts/${options.id}/two-factor-recovery-codes`);
  }, options);
};

export const resetRecoveryCodesForUser = (userId: string) => {
  const options = {} as KeyValue;
  options.id = userId;
  return ApiCall.create(options => {
    return http.post(`/userAccounts/${options.id}/two-factor-recovery-codes`);
  }, options);
};

export const loginAsTemporaryUser = (userId: string) => {
  const options = {} as KeyValue;
  options.id = userId;
  return ApiCall.create(options => {
    return http.post(`/userAccounts/${options.id}/login-as-temporary-user`);
  }, options);
};

export const logoutTemporaryUser = (token: string, saved: string) => {
  const options = {} as KeyValue;
  options.token = token;
  options.saved = saved;
  return ApiCall.create(options => {
    return http.get(`/userAccounts/logout-temporary-user/${options.token}/${options.saved}`);
  }, options);
};

export const getOperators = () => {
  return ApiCall.create(() => {
    return http.get<PaginatedResponse<OperatorCompactData>>(`userAccounts/operators`);
  }, {});
};

export const getPermissions = (userId: string, organizationId: string) => {
  const options = {} as KeyValue;
  options.id = userId;
  if (organizationId != null) {
    options.organizationId = organizationId;
    return ApiCall.create(options => {
      return http.get<PermissionData[]>(`userAccounts/${options.id}/permissions/${options.organizationId}`);
    }, options);
  } else {
    return ApiCall.create(options => {
      return http.get<PermissionData[]>(`userAccounts/${options.id}/permissions`);
    }, options);
  }
};

export const getPermissionsForBranch = (branchId: string) => {
  const options = {} as KeyValue;
  options.id = branchId;
  return ApiCall.create(options => {
    return http.get<BranchPermissionData[]>(`userAccounts/branches/${options.id}/permissions`);
  }, options);
};

export const grantPermission = (
  userId: string,
  symbol: string,
  organizationId: string | null,
  branchId: string | null,
) => {
  const fields = {
    symbol: symbol,
    organization_id: organizationId,
    branch_id: branchId,
  } as PermissionData;

  const options = {} as KeyValue;
  options.id = userId;

  return ApiCall.create(
    (options, query, fields) => {
      return http.post(`/userAccounts/${options.id}/permissions/grant`, fields);
    },
    options,
    '',
    fields,
  );
};

export const revokePermission = (userId: string, permissionId: string) => {
  const options = {} as KeyValue;
  options.id = userId;
  options.permission = permissionId;
  const fields = {} as PermissionData;
  return ApiCall.create(
    options => {
      return http.delete(`/userAccounts/${options.id}/permissions/revoke/${options.permission}`);
    },
    options,
    '',
    fields,
  );
};

export const authorizeAction = (password: string) => {
  const fields = {
    password: password,
  } as KeyValue;

  return ApiCall.create(
    (options, query, fields) => {
      return http.post(`/authorize-action`, fields);
    },
    {},
    '',
    fields,
  );
};

export const getUserAccountsCompact = () => {
  return ApiCall.create(
    () => {
      return http.get<PaginatedResponse<UserAccountData>>(`userAccountsCompact`);
    },
    {},
    '',
  );
};
