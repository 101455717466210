import { ApiCall } from '@/api/ApiCall';
import { http } from '@/api/http';
import { GroupedKeyValue, KeyValue } from '@/types/Common';
import TranslationData = App.Data.TranslationData;
import SavedRecordResponse = App.Data.SavedRecordResponse;
import LanguageData = App.Data.LanguageData;

export const getSymbols = () => {
  return ApiCall.create(
    () => {
      return http.post<GroupedKeyValue>('symbols');
    },
    {},
    '',
  );
};

export const getLanguages = () => {
  return ApiCall.create(() => {
    return http.get<LanguageData[]>('translatables/languages');
  }, {});
};

export const getTranslations = (translatableType: string, translatableId: number, symbol?: string | undefined) => {
  const options = {} as KeyValue;
  options.type = translatableType;
  options.id = translatableId.toString();
  if (symbol) options.symbol = symbol;

  return ApiCall.create(
    (options, query) => {
      if (options.symbol == undefined)
        return http.get<TranslationData[]>(`translatables/${options.type}/${options.id}` + query);
      else return http.get<TranslationData[]>(`translatables/${options.type}/${options.id}/${options.symbol}` + query);
    },
    options,
    '',
  );
};

export const storeTranslation = (
  translation: string,
  language: string,
  translatableType: string,
  translatableId: number,
  symbol: string | undefined,
) => {
  const fields = {} as KeyValue;
  fields.translation = translation;
  fields.type = translatableType;
  fields.id = translatableId.toString();
  if (symbol) fields.symbol = symbol;
  fields.language = language;

  return ApiCall.create(
    (options, query, fields) => {
      return http.post<SavedRecordResponse>(`translatables`, fields);
    },
    {},
    '',
    fields,
  );
};

export const getTracking = (trackingId: string) => {
  const options = {} as KeyValue;
  options.id = trackingId;

  return ApiCall.create(options => {
    return http.get(`/tracking/${options.id}`);
  }, options);
};
