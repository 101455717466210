<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('tag.dialog.edit_title') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('tag.dialog.edit_message') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded' && tag">
        <div>
          <FormValidator ref="form" class="grid grid-cols-4 gap-4 py-4">
            <TagForm :tag="tag" />
          </FormValidator>
        </div>
        <ZDialogFooter>
          <Action @proceed="update">{{ $t('organization.command.save_changes') }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogHeader,
  ZDialogTitle,
  ZDialogFooter,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import { getTag, saveTag } from '@/api';
import TagData = App.Data.TagData;
import TagForm from '@ui/Tags/TagForm.vue';

export default defineComponent({
  components: {
    TagForm,
    FormValidator,
    IconAlert,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogHeader,
    ZDialogTitle,
    ZDialogFooter,
  },

  props: {
    tagId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      tag: {} as TagData,
      isDialogOpen: true,
      state: 'loading' as ComponentLoadState,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      const [tagResponse] = await Promise.all([getTag(this.tagId).execute()]);
      this.tag = tagResponse.data;
      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
    }
  },

  methods: {
    update(action: any) {
      saveTag(this.tagId, this.tag)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('organization.message.organization_saved'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
