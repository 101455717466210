<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('inventory.label.create_inventory') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('inventory.message.create_inventory') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <FormValidator ref="form">
          <InventoryForm :description="description" @update:description="updateDescription" />
        </FormValidator>
        <ZDialogFooter>
          <Action :disabled="!description" @proceed="store">{{ $t('inventory.command.create_inventory') }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import InventoryForm from '@ui/Inventory/InventoryForm.vue';
import useUserStore from '@/stores/auth';
import { createInventory } from '@/api/inventory';
import { ValidationErrors } from '@/types/ValidationErrors';

export default defineComponent({
  components: {
    InventoryForm,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      state: 'loaded' as ComponentLoadState,
      description: '',
      errors: null as null | ValidationErrors,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  methods: {
    useUserStore,

    updateDescription(description: string) {
      this.description = description;
    },

    async store(action: any) {
      await createInventory({
        description: this.description,
      })
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(response => {
          this.toast({
            title: this.$t('common.success.header'),
            description: this.$t('inventory.message.create_success'),
          });
          this.$router.push({ name: this.ROUTE.INVENTORY_DETAILS, params: { inventoryId: response.data.saved_id } });
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        });
    },
  },
});
</script>
