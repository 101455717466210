<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('asset.label.edit_asset_attributes') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('asset.message.edit_asset_attributes') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <div>
          <FormValidator ref="form" class="py-4">
            <EditAssetAttributesForm :asset="asset" />
          </FormValidator>
        </div>
        <ZDialogFooter>
          <Action @proceed="update">{{ $t('asset.command.save_changes') }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import AssetData = App.Data.AssetData;
import { getAsset, saveAssetAttributes } from '@/api/asset';
import EditAssetAttributesForm from '@ui/Assets/EditAssetAttributesForm.vue';

export default defineComponent({
  components: {
    EditAssetAttributesForm,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    assetId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      asset: {} as AssetData,
      categories: [],
      state: 'loading' as ComponentLoadState,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  mounted() {
    try {
      getAsset(this.assetId)
        .execute()
        .then(response => {
          this.asset = response.data;
          this.state = 'loaded';
        });
    } catch (error: any) {
      this.state = error;
    }
  },

  methods: {
    update(action: any) {
      if (!this.asset) {
        return;
      }

      const attributes = this.asset.assetValues!.filter(item => item.category_attribute_id == null);

      saveAssetAttributes(this.assetId, attributes)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('asset.message.attributes_saved'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
