<template>
  <FormField symbol="action_id">
    <ZLabel for="action_id">
      {{ $t('contracts.label.action') }}
    </ZLabel>
    <SelectField v-model="action.action_id" class="w-full" :options="actionList"></SelectField>
  </FormField>
  <FormField v-if="full" symbol="contractor">
    <ZLabel for="contractor">
      {{ $t('contracts.label.contractor') }}
    </ZLabel>
    <ZInput v-model="action.contractor" class="w-full" />
  </FormField>
  <FormField symbol="notes">
    <ZLabel for="action_id">
      {{ $t('contracts.label.note') }}
    </ZLabel>
    <textarea
      id="hint"
      v-model="action.notes"
      rows="3"
      class="flex w-full resize-none rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
    />
  </FormField>
  <FormField v-if="selectedAction && selectedAction.has_amount" symbol="amount_value">
    <ZLabel for="deadline_value">
      {{ selectedAction.amount_title }}
    </ZLabel>
    <ZInput v-model="action.amount_value" />
  </FormField>
  <FormField v-if="selectedAction && selectedAction.has_location" symbol="location_value">
    <ZLabel for="deadline_value">
      {{ selectedAction.location_title }}
    </ZLabel>
    <ZInput v-model="action.location_value" />
  </FormField>
  <FormField v-if="selectedAction && selectedAction.has_deadline" symbol="deadline_value">
    <ZLabel for="deadline_value">
      {{ selectedAction.deadline_title }}
    </ZLabel>
    <div class="flex w-full items-center">
      <Popover v-model:open="showDatePicker">
        <PopoverTrigger as-child>
          <ZButton variant="outline" class="w-full font-normal flex items-center justify-start">
            <div class="flex items-center w-full">
              <CalendarIcon class="w-[20px] mr-2" />
              <span v-if="action.deadline_value" class="leading-3">
                {{ formatDate(action.deadline_value) }}
              </span>
              <span v-else>{{ $t('contracts.form.date.button') }}</span>
            </div>
            <ChevronDownIcon class="w-[18px] ml-2 text-gray-400" />
          </ZButton>
        </PopoverTrigger>
        <PopoverContent class="w-auto p-0" align="start">
          <Calendar v-model="action.deadline_value" timezone="UTC" :columns="1" />
        </PopoverContent>
      </Popover>
      <XCircleIcon v-if="action.deadline_value" class="w-[25px] ml-2" @click.prevent="action.deadline_value = ''" />
    </div>
  </FormField>
  <FormField v-if="selectedAction && selectedAction.has_checkbox" symbol="checkbox_value">
    <ZLabel class="flex items-center">
      <Checkbox
        id="checkbox"
        class="h-6 w-6"
        :checked="action.checkbox_value == true"
        @update:checked="toggleCheckbox()"
      />
      <span class="pl-2">
        {{ selectedAction.checkbox_title }}
      </span>
    </ZLabel>
  </FormField>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import SelectField from '@ui/SelectField.vue';
import symbolsStore from '@/stores/symbols';
import { KeyValue } from '@/types/Common';
import { PropType } from '@modules/vue';
import ActionData = App.Data.ActionData;
import { ZInput } from '@shadcn/components/ui/input';
import ContractActionStoreRequest = App.Data.ContractActionStoreRequest;
import { Checkbox } from '@shadcn/components/ui/checkbox';
import { ZLabel } from '@shadcn/components/ui/label';
import FormField from '@ui/FormField.vue';
import { CalendarIcon, ChevronDownIcon, XCircleIcon } from '@modules/@heroicons/vue/24/outline';
import { ZButton } from '@shadcn/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/components/ui/popover';
import { Calendar } from '@shadcn/components/ui/calendar';

export default defineComponent({
  components: {
    ZButton,
    ChevronDownIcon,
    XCircleIcon,
    CalendarIcon,
    FormField,
    Checkbox,
    SelectField,
    ZInput,
    ZLabel,
    PopoverTrigger,
    Popover,
    PopoverContent,
    Calendar,
  },

  props: {
    action: {
      type: Object as PropType<ContractActionStoreRequest>,
      required: true,
    },

    full: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      actionList: {},
      actions: [] as Array<ActionData>,
      selectedAction: {} as ActionData,
      showDatePicker: false,
    };
  },

  watch: {
    'action.action_id'() {
      if (this.action.action_id) {
        const foundAction = this.actions.find((action: ActionData) => action.id == this.action.action_id);
        if (!foundAction) {
          return;
        }
        this.selectedAction = foundAction;
        if (this.action.location_value) delete this.action.location_value;
        if (this.action.amount_value) delete this.action.amount_value;
        if (this.selectedAction.has_deadline) this.action.deadline_value = '';
        if (!this.selectedAction.has_deadline) delete this.action.deadline_value;
        if (this.selectedAction.has_checkbox) this.action.checkbox_value = false;
        if (!this.selectedAction.has_checkbox) delete this.action.checkbox_value;
      }
    },
  },

  async mounted() {
    const symbols = symbolsStore();
    const rawContractActions = await symbols.get('contract_actions');
    this.actions = Object.values(rawContractActions);
    this.actionList = this.actions.reduce((accumulator: KeyValue, value: ActionData) => {
      return { ...accumulator, [value.id as number]: value.name };
    }, {});
    if (this.action && Object.keys(this.action).length) {
      const foundAction = this.actions.find((action: ActionData) => action.id == this.action.action_id);
      if (!foundAction) {
        return;
      }
      this.selectedAction = foundAction;
    }
  },

  methods: {
    toggleCheckbox() {
      this.action.checkbox_value = !this.action.checkbox_value;
    },

    clearDate() {
      this.action.deadline_value = '';
    },
  },
});
</script>
