import { ApiCall } from '@/api/ApiCall';
import { http } from '@/api/http';
import ExportLinkData = App.Data.ExportLinkData;
import AssetsExportRequestData = App.Data.AssetsExportRequestData;
import CarbonFootprintReportRequestData = App.Data.CarbonFootprintReportRequestData;

export const generateAssetsReportFileUrl = (fields: AssetsExportRequestData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<ExportLinkData>('reports/generateAssetsReportFileUrl', fields);
    },
    {},
    '',
    fields,
  );
};
export const generateCarbonFootprintReportFileUrl = (fields: CarbonFootprintReportRequestData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<ExportLinkData>('reports/generateCarbonFootprintReportFileUrl', fields);
    },
    {},
    '',
    fields,
  );
};

export const downloadReport = (url: string, type: string) => {
  let contentType = '' as string;
  switch (type) {
    case 'xlsx':
      contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    case 'csv':
      contentType = 'text/csv';
      break;
  }

  http
    .get(url, {
      responseType: 'blob',
    })
    .then(response => {
      const blob = new Blob([response.data], {
          type: contentType,
        }),
        url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = response.headers['x-filename'];
      downloadLink.click();
    });
};
