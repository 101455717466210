import { ApiCall } from '@/api/ApiCall';
import { http } from '@/api/http';
import RegionWithTranslationData = App.Data.RegionWithTranslationData;
import CountryWithTranslationData = App.Data.CountryWithTranslationData;
import { PaginatedResponse } from '@/utils/DataSet';
import { KeyValue } from '@/types/Common';
import CountryData = App.Data.CountryData;
import ActionData = App.Data.ActionData;
import StoreCountryData = App.Data.StoreCountryData;
import RegionData = App.Data.RegionData;
import StoreRegionData = App.Data.StoreRegionData;
import NewRecordResponse = App.Data.NewRecordResponse;

export const getRegions = (language: string) => {
  const options = {} as any;
  options.language = language;
  return ApiCall.create(
    (o, query) => {
      return http.get<RegionWithTranslationData[]>(`/dictionaries/${o?.language}/regions` + query);
    },
    options,
    '',
  );
};

export const getCountries = (language: string) => {
  const options = {} as any;
  options.language = language;
  return ApiCall.create(
    (o, query) => {
      return http.get<CountryWithTranslationData[]>(`/dictionaries/${o?.language}/countries` + query);
    },
    options,
    '',
  );
};

export const getLanguages = () => {
  return http.get<App.Data.LanguageData>('/dictionaries/languages');
};

export const getCountriesIndex = () => {
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<CountryData>>('/dictionaries/countries' + query);
    },
    {},
    '',
  );
};

export const getCountry = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get<CountryData>(`/dictionaries/countries/${options.id}`);
  }, options);
};

export const saveCountry = (id: string, fields: CountryData) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<ActionData>(`/dictionaries/countries/${options.id}`, fields);
    },
    options,
    '',
    fields,
  );
};

export const createCountry = (fields: StoreCountryData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<NewRecordResponse>('/dictionaries/countries', fields);
    },
    {},
    '',
    fields,
  );
};

export const deleteCountry = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.delete(`/dictionaries/countries/${options.id}`);
  }, options);
};

export const getRegionsIndex = (countryId: string) => {
  const options = {} as any;
  options.id = countryId;
  return ApiCall.create(
    (o, query) => {
      return http.get<PaginatedResponse<RegionData>>(`/dictionaries/countries/${options.id}/regions` + query);
    },
    options,
    '',
  );
};

export const createRegion = (countryId: string, fields: StoreRegionData) => {
  const options = {} as any;
  options.id = countryId;
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<StoreRegionData>(`/dictionaries/countries/${options.id}/regions`, fields);
    },
    options,
    '',
    fields,
  );
};

export const deleteRegion = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.delete(`/dictionaries/regions/${options.id}`);
  }, options);
};
