<template>
  <DataLoader ref="loader">
    <div class="py-4">
      <div class="flex flex-col md:flex-row py-4 gap-4">
        <div class="w-full grid gap-2">
          <ZLabel>{{ $t('assetModels.label.search') }}</ZLabel>
          <ZInput v-model="filters.search" :placeholder="$t('common.label.search')"/>
        </div>
        <div class="w-full grid gap-2">
          <ZLabel>{{ $t('assetModels.label.type') }}</ZLabel>
          <ZSelect v-model="filters.type">
            <ZSelectTrigger>
              <ZSelectValue :placeholder="$t('assetModels.placeholder')"/>
            </ZSelectTrigger>
            <ZSelectContent>
              <ZSelectGroup>
                <ZSelectItem value="all">{{ $t('assetModels.form.sort.all') }}</ZSelectItem>
                <ZSelectItem value="global">{{ $t('assetModels.form.sort.global') }}</ZSelectItem>
                <ZSelectItem value="organization">{{ $t('assetModels.form.sort.organization') }}</ZSelectItem>
              </ZSelectGroup>
            </ZSelectContent>
          </ZSelect>
        </div>
        <div class="w-full grid gap-2">
          <ZLabel>{{ $t('assetModels.label.category') }}</ZLabel>
          <SelectTree v-model="filters.category_id" :tree="categoriesTree"
                      :placeholder="$t('assetModels.placeholder')"/>
        </div>
        <div class="flex flex-col gap-2">
          <ZLabel>{{ $t('asset.label.reset_filter') }}</ZLabel>
          <ZButton variant="secondary" @click="resetFilters"><TrashIcon class="icon sm"></TrashIcon>{{ $t('asset.command.reset_filter') }}</ZButton>
        </div>
        <MountedTeleport to="#toolbox">
          <router-link :to="{ name: ROUTE.ASSET_MODEL_ADD }">
            <ZButton class="flex w-full gap-2">
             {{ $t('assetModels.command.add_model') }}
              <PlusCircleIcon class="icon md"/>
            </ZButton>
          </router-link>
        </MountedTeleport>
      </div>
      <Paginator :data="assetModelDataSet" page-size/>
      <div class="border rounded">
        <ZTable class="w-full">
          <ZTableHeader>
            <ZTableRow>
              <ZTableHead></ZTableHead>
              <ZTableHead>{{ $t('assetModels.label.state') }}</ZTableHead>
              <ZTableHead>{{ $t('assetModels.label.category') }}</ZTableHead>
              <ZTableHead>{{ $t('assetModels.label.manufacturer') }}</ZTableHead>
              <ZTableHead>{{ $t('assetModels.label.code') }}</ZTableHead>
              <ZTableHead>{{ $t('assetModels.label.organization') }}</ZTableHead>
            </ZTableRow>
          </ZTableHeader>
          <ZTableBody>
            <ZTableRow
              v-for="(item, index) in assetModelDataSet.data"
              :key="index"
              class="cursor-pointer"
              @click="details(item.id ?? 0)"
            >
              <ZTableCell>
                <img
                  v-if="item.thumb"
                  class="min-w-[50px] w-[50px] h-[50px] object-contain rounded-xl"
                  :src="item.thumb"
                  alt="primaryImage"
                />
              </ZTableCell>
              <ZTableCell>
              <span
                class="rounded text-xs py-1 px-2 uppercase font-bold"
                :class="{
                  'bg-gray-200': item.state == SYMBOL.ASSET_MODEL_STATE.DRAFT,
                  'bg-green-200': item.state == SYMBOL.ASSET_MODEL_STATE.ACTIVE,
                }"
              >{{ $t('assetModels.states.' + item.state) }}</span
              >
              </ZTableCell>
              <ZTableCell>{{ item.category ? item.category.name : $t('assetModels.label.lack') }}</ZTableCell>
              <ZTableCell>{{ item.manufacturer }}</ZTableCell>
              <ZTableCell>
                <b>{{ item.code }}</b>
              </ZTableCell>
              <ZTableCell>
              <span v-if="item.organization">
                {{ item.organization.name }}
              </span>
                <span v-else class="text-xs">
                {{ $t('assetModels.label.lack') }}
                <span class="ml-2 rounded text-xs bg-gray-200 py-1 px-1.5">{{
                    $t('assetModels.label.global_model')
                  }}</span>
              </span>
              </ZTableCell>
            </ZTableRow>
          </ZTableBody>
        </ZTable>
        <div v-if="assetModelDataSet.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
          <ExclamationTriangleIcon class="icon lg"/>
          {{ $t('organization.message.data_not_found') }}
        </div>
      </div>
    </div>
    <Paginator :data="assetModelDataSet" page-size/>
    <router-view></router-view>
  </DataLoader>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {DataSet} from '@/utils/DataSet';
import Paginator from '@ui/Paginator.vue';
import {ZInput} from '@shadcn/components/ui/input';
import {ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow} from '@shadcn/components/ui/table';
import {ExclamationTriangleIcon, PlusCircleIcon} from '@heroicons/vue/24/outline';
import {ZButton} from '@shadcn/components/ui/button';
import {ROUTE} from '@/router/routeNames';
import {
  ZSelect,
  ZSelectContent,
  ZSelectGroup,
  ZSelectItem,
  ZSelectTrigger,
  ZSelectValue,
} from '@shadcn/components/ui/select';
import {getAssetModels} from '@/api/assetModel';
import AssetModelData = App.Data.AssetModelData;
import AssetModelRequestData = App.Data.AssetModelRequestData;
import {getCompactCategories} from '@/api/category';
import CategoryCompactData = App.Data.CategoryCompactData;
import {ZLabel} from '@shadcn/components/ui/label';
import MountedTeleport from '@ui/MountedTeleport.vue';
import SelectTree from '@ui/SelectTree.vue';
import DataLoader from "@ui/DataLoader.vue";
import rememberStore from "@/stores/remember";
import {TrashIcon} from "@modules/@heroicons/vue/24/outline";

interface filtersInterface {
  search: string;
  page: number;
  perPage: number;
  type: any;
  category_id?: number;
  with_drafts: boolean;
}

export default defineComponent({
  components: {
    TrashIcon,
    DataLoader,
    SelectTree,
    ZLabel,
    MountedTeleport,
    ZSelectContent,
    ZSelectGroup,
    ZSelect,
    ZSelectTrigger,
    ZSelectValue,
    ZSelectItem,
    Paginator,
    // icons
    PlusCircleIcon,
    ExclamationTriangleIcon,
    // shadcn components
    ZButton,
    ZInput,
    ZTable,
    ZTableRow,
    ZTableBody,
    ZTableCell,
    ZTableHead,
    ZTableHeader,
  },

  data() {
    return {
      assetModelDataSet: {} as DataSet<AssetModelData, AssetModelRequestData>,
      categories: [] as CategoryCompactData[],
      loader: null as any,
      resetPages: true,
      filters: rememberStore().remind('model.filters', {
        search: '',
        category_id: '0',
        perPage: 15,
        page: 1,
        type: 'all',
        with_drafts: true,
      }),
    };
  },

  computed: {
    categoriesTree() {
      let tree = new Map<string, object>();

      this.categories.forEach(category => {
        const treeKey = category!.id!.toString();
        tree.set(treeKey, { depth: category.depth, label: category.name });
      });

      return tree;
    },
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        const finalFilters: filtersInterface = {
          ...this.filters,
          category_id: parseInt(this.filters!.category_id!, 10),
        };
        if (this.filters.category_id === '0') {
          finalFilters['category_id'] = undefined;
        }

        if (this.resetPages) this.assetModelDataSet.setParams(finalFilters).resetToFirstPage();
        else this.assetModelDataSet.setParams(finalFilters).load(this.filters.page ?? 1);

        this.resetPages = true;
      },
    },

    $route(to: any) {
      if (to.name == ROUTE.ASSET_MODELS) {
        this.resetPages = false;
        this.filters = rememberStore().remind('model.filters');
      }
    },
  },

  mounted() {
    this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;

    const finalFilters: filtersInterface = {
      ...this.filters,
      category_id: parseInt(this.filters!.category_id!, 10),
      with_drafts: true,
    };
    if (this.filters.category_id === '0') {
      delete finalFilters['category_id'];
    }

    this.assetModelDataSet = new DataSet<AssetModelData, AssetModelRequestData>(
      (query: string) => getAssetModels().withLoader(this.loader).execute(query),
      () => rememberStore().memorize('model.filters', {...this.filters, page: this.assetModelDataSet.meta?.current_page}),
    );

    this.assetModelDataSet.setParams(finalFilters);
    this.assetModelDataSet.load(this.filters.page ?? 1);
    this.getCategories();
  },

  methods: {
    async getCategories() {
      const categoriesResponse = await getCompactCategories().execute();
      this.categories = Array.isArray(categoriesResponse.data) ? categoriesResponse.data : [categoriesResponse.data];
    },

    details(id: number) {
      this.$router.push({name: ROUTE.ASSET_MODELS_DETAILS, params: {modelId: id}});
    },

    resetFilters() {
      this.filters = {
        search: '',
        category_id: '0',
        perPage: 15,
        page: 1,
        type: 'all',
        with_drafts: true,
      };
    }
  },
});
</script>
