<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('userAccount.label.manage_2fa') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('userAccount.message.manage_2fa') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <div v-if="recoveryCodes != null" class="flex flex-col border py-2 text-center rounded">
        <span v-for="code in recoveryCodes.codes" :key="code" class="text-sm">{{ code }}</span>
      </div>
      <div v-else class="border py-5 text-center rounded">
        <a href="#" @click.prevent="getRecoveryCodes">{{ $t('userAccount.command.show_recovery_codes') }}</a>
      </div>

      <ZDialogFooter>
        <div class="w-full grid grid-cols-2 gap-2">
          <ZButton variant="secondary" @click.prevent="resetRecoveryCodes">{{
            $t('userAccount.command.reset_codes')
          }}</ZButton>
          <ZButton variant="destructive" @click.prevent="disableTwoFactor">{{
            $t('userAccount.command.disable_2fa')
          }}</ZButton>
        </div>
      </ZDialogFooter>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogHeader,
  ZDialogTitle,
  ZDialogFooter,
} from '@shadcn/components/ui/dialog';
import { getRecoveryCodesForUser, postDisable2FAForUser, resetRecoveryCodesForUser } from '@/api';
import { ZButton } from '@shadcn/components/ui/button';
import UserAccountData = App.Data.UserAccountData;
import { Dialogs } from '@/types/Dialogs';
import RecoveryCodesData = App.Data.RecoveryCodesData;

export default defineComponent({
  components: {
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogHeader,
    ZDialogTitle,
    ZDialogFooter,
    ZButton,
  },

  props: {
    userId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
      dialogs: inject('dialogs') as Dialogs,
    };
  },

  data() {
    return {
      recoveryCodes: null as RecoveryCodesData | null,
      isDialogOpen: true,
      fields: {} as UserAccountData,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {},

  methods: {
    async getRecoveryCodes() {
      const recoveryCodesResponse = await getRecoveryCodesForUser(this.userId).execute();
      this.recoveryCodes = recoveryCodesResponse.data;
    },

    async resetRecoveryCodes() {
      const recoveryCodesResponse = await resetRecoveryCodesForUser(this.userId).execute();
      this.recoveryCodes = recoveryCodesResponse.data;
    },

    disableTwoFactor() {
      this.dialogs
        .confirm(
          this.$t('userAccount.label.disable_2fa_confirmation'),
          this.$t('userAccount.message.disable_2fa_confirmation'),
        )
        .then(() => {
          postDisable2FAForUser(this.userId).execute();
          this.isDialogOpen = false;
        });
    },

    close() {
      this.isDialogOpen = false;
    },
  },
});
</script>
