<template>
  <Avatar>
    {{ firstLetters }}
  </Avatar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Avatar } from '@shadcn/components/ui/avatar';

export default defineComponent({
  components: {
    Avatar,
    // icons
    // shadcn components
  },

  props: {
    modelValue: {
      type: null,
      required: true,
    },
  },

  computed: {
    firstLetters() {
      if (this.modelValue != null && this.modelValue.first_name && this.modelValue.last_name)
        return this.modelValue.first_name!.substring(0, 1) + this.modelValue.last_name!.substring(0, 1);
      return '';
    },
  },
});
</script>
