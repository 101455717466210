import { ApiCall } from '@/api/ApiCall';
import { http } from './http';
import { KeyValue } from '@/types/Common';
import CategoryData = App.Data.CategoryData;
import NewRecordResponse = App.Data.NewRecordResponse;
import SavedRecordResponse = App.Data.SavedRecordResponse;
import CategoryAttributeData = App.Data.CategoryAttributeData;
import CategoryCompactData = App.Data.CategoryCompactData;

export const getCategories = () => {
  return ApiCall.create(
    () => {
      return http.get(`categories`);
    },
    {},
    '',
  );
};
export const getCategory = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get(`/categories/${options.id}`);
  }, options);
};

export const getCategoryStats = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get(`/categories/${options.id}/stats`);
  }, options);
};

export const getCategoryAttribute = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;

  return ApiCall.create(options => {
    return http.get(`/categoryAttribute/${options.id}`);
  }, options);
};

export const getCategoryAttributeTypes = () => {
  return ApiCall.create(
    () => {
      return http.post<KeyValue>('attributeTypes');
    },
    {},
    '',
  );
};

export const getCompactCategories = () => {
  return ApiCall.create(
    (options, query) => {
      return http.get<CategoryCompactData>(`/categories/compact` + query);
    },
    {},
    '',
  );
};

export const createCategory = (fields: CategoryData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<NewRecordResponse>('/categories', fields);
    },
    {},
    '',
    fields,
  );
};

export const createSubCategory = (fields: CategoryData, parent: string | undefined) => {
  const options = {} as KeyValue;
  options.parent = parent ?? '';

  return ApiCall.create(
    (options, query, fields) => {
      return http.post<NewRecordResponse>(`/categories/${options.parent}/sub`, fields);
    },
    options,
    '',
    fields,
  );
};

export const createCategoryAttribute = (fields: CategoryAttributeData, categoryId: string) => {
  const options = {} as KeyValue;
  options.categoryId = categoryId;

  return ApiCall.create(
    (options, query, fields) => {
      return http.post<NewRecordResponse>(`/categoryAttribute/${options.categoryId}`, fields);
    },
    options,
    '',
    fields,
  );
};

export const getAllCategoryAttributes = (categoryId: string) => {
  const options = {} as KeyValue;
  options.id = categoryId;

  return ApiCall.create(
    (options, query) => {
      return http.get<any>(`/categories/${options.id}/all-attributes` + query);
    },
    options,
    '',
  );
};
export const saveCategory = (fields: CategoryData, category: string) => {
  const options = {} as KeyValue;
  options.category = category;

  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<SavedRecordResponse>(`/categories/${options.category}`, fields);
    },
    options,
    '',
    fields,
  );
};

export const saveCategoryAttribute = (fields: CategoryAttributeData, categoryAttributeId: number) => {
  const options = {} as KeyValue;
  options.categoryAttributeId = categoryAttributeId.toString();

  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<NewRecordResponse>(`/categoryAttribute/${options.categoryAttributeId}`, fields);
    },
    options,
    '',
    fields,
  );
};

export const changeCategoryParentToRoot = (category_id: number) => {
  const options = {} as KeyValue;
  options.category_id = category_id.toString();
  return ApiCall.create(options => {
    return http.get<SavedRecordResponse>(`/categories/${options.category_id}/transfer-to-root`);
  }, options);
};

export const updateCategoryParent = (category_id: number, destination: number) => {
  const options = {} as KeyValue;
  options.category_id = category_id.toString();
  options.destination = destination.toString();
  return ApiCall.create(options => {
    return http.get<SavedRecordResponse>(`/categories/${options.category_id}/transfer/${options.destination}`);
  }, options);
};

export const updateCategoryOrder = (id: number, direction: string) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  options.direction = direction.toString();
  return ApiCall.create(options => {
    return http.get<SavedRecordResponse>(`/categories/${options.id}/move/${options.direction}`);
  }, options);
};

export const deleteCategory = (id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  return ApiCall.create(options => {
    return http.delete(`/categories/${options.id}`);
  }, options);
};

export const deleteCategoryAttribute = (id: number) => {
  const options = {} as KeyValue;
  options.id = id.toString();
  return ApiCall.create(options => {
    return http.delete(`/categoryAttribute/${options.id}`);
  }, options);
};
