<template>
  <ZDialog ref="dialog" v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('userAccount.label.change_user_data') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('userAccount.message.change_user_data') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <DataLoader ref="loader">
        <template v-if="user">
          <IconAlert v-if="user.changes_requested_status" class="my-2" type="warning">
            <div class="flex flex-row justify-between items-center">
              <div class="flex flex-col gap-2">
                <span>{{ $t('userAccount.label.user_requested_changes') }}</span>
                {{ $t('userAccount.label.accept_or_decline_changes') }}
              </div>
            </div>
          </IconAlert>
          <FormValidator
            ref="form"
            class="grid grid-cols-4 gap-4 py-4"
            @default-action="($refs.update as any).proceed()"
          >
            <UserAccountForm :user="user" />
            <Field v-if="access().operator()" symbol="operator_notes" class="col-span-4">
              <ZLabel for="operator_notes">
                {{ $t('userAccount.label.operator_notes') }}
              </ZLabel>
              <textarea
                id="operator_notes"
                v-model="user.operator_notes"
                class="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
              />
            </Field>
            <Field symbol="organization_notes" class="col-span-4">
              <ZLabel for="organization_notes">
                {{ $t('userAccount.label.organization_notes') }}
              </ZLabel>
              <textarea
                id="organization_notes"
                v-model="user.organization_notes"
                class="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
              />
            </Field>
            <Field symbol="allowed_ips" class="col-span-4">
              <ZLabel for="allowed_ips">
                {{ $t('userAccount.label.allowed_ips') }}
              </ZLabel>
              <ZInput id="last_name" v-model="user.allowed_ips" />
            </Field>
          </FormValidator>
          <ZDialogFooter>
            <Action ref="update" :disabled="hasChanges" @proceed="update">{{
              $t('userAccount.command.save_changes')
            }}</Action>
          </ZDialogFooter>
        </template>
      </DataLoader>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogHeader,
  ZDialogTitle,
  ZDialogFooter,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { getUserAccount, saveUserAccount } from '@/api/userAccount';
import UserAccountForm from '@ui/UserAccounts/UserAccountForm.vue';
import Field from '@ui/FormField.vue';
import { ZLabel } from '@shadcn/components/ui/label';
import { ZInput } from '@shadcn/components/ui/input';
import UserAccountData = App.Data.UserAccountData;
import DataLoader from '@ui/DataLoader.vue';
import access from '@/stores/access';
import UpdateUserAccountData = App.Data.UpdateUserAccountData;

export default defineComponent({
  components: {
    DataLoader,
    ZInput,
    ZLabel,
    Field,
    UserAccountForm,
    FormValidator,
    IconAlert,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogHeader,
    ZDialogTitle,
    ZDialogFooter,
  },

  props: {
    userId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      user: null as UserAccountData | null,
      isDialogOpen: true,

      loader: null as any,
    };
  },

  computed: {
    hasChanges() {
      if (this.user == null || this.user.changes == null) return false;

      let counter = 0 as number;
      counter = Object.keys(this.user.changes).length;

      return counter > 0;
    },
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },

  methods: {
    access,
    async init() {
      this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;

      try {
        const [userResponse] = await Promise.all([getUserAccount(this.userId).withLoader(this.loader).execute()]);

        this.user = userResponse.data;
      } catch (error: any) {}
    },

    update(action: any) {
      if (!this.user) {
        return;
      }

      let updatedUserData = {
        email: this.user.email,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        position: this.user.position,
        phone: this.user.phone,
        organization_notes: this.user.organization_notes,
        allowed_ips: this.user.allowed_ips,
      } as UpdateUserAccountData;

      if (this.access().operator()) {
        updatedUserData.operator_notes = this.user.operator_notes;
      }

      saveUserAccount(this.userId, updatedUserData)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('userAccount.message.user_account_saved'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
