<template>
  <teleport v-if="componentMounted" to="#toolbox">
    <ZButton variant="outline" @click="markAllAsSeen">{{ $t('notification.buttonMarkAsSeen') }}</ZButton>
  </teleport>
  <ZButton
    class="w-full flex items-center justify-between my-4 lg:hidden"
    variant="outline"
    @click.prevent="showFilters = !showFilters"
  >
    <div class="flex items-center">
      <AdjustmentsHorizontalIcon class="w-[20px] mr-2"/>
      {{ $t('notification.showFilters') }}
    </div>
    <div>
      <ChevronDownIcon v-if="!showFilters" class="w-[20px]"/>
      <ChevronUpIcon v-if="showFilters" class="w-[20px]"/>
    </div>
  </ZButton>
  <div class="grid sm:grid-cols-2 lg:grid-cols-1 lg:flex sm:gap-4 mt-5" :class="{ hidden: !showFilters }">
    <div class="w-full lg:max-w-[200px] grid gap-2 mb-4">
      <ZLabel>{{ $t('notification.form.search.label') }}</ZLabel>
      <ZInput v-model="filters.search" type="search" :placeholder="$t('notification.form.search.placeholder')"/>
    </div>
    <div class="w-full lg:w-[160px] grid gap-2 mb-4">
      <ZLabel>{{ $t('notification.form.sort.label') }}</ZLabel>
      <ZSelect v-model="filters.sort">
        <ZSelectTrigger>
          <ZSelectValue :placeholder="$t('notification.placeholder')"/>
        </ZSelectTrigger>
        <ZSelectContent>
          <ZSelectGroup>
            <ZSelectItem value="newest">{{ $t('notification.form.sort.newest') }}</ZSelectItem>
            <ZSelectItem value="oldest">{{ $t('notification.form.sort.oldest') }}</ZSelectItem>
            <ZSelectItem value="unread">{{ $t('notification.form.sort.unread') }}</ZSelectItem>
          </ZSelectGroup>
        </ZSelectContent>
      </ZSelect>
    </div>
    <div class="w-full lg:w-[160px] grid gap-2 mb-4">
      <ZLabel>{{ $t('notification.form.type.label') }}</ZLabel>
      <ZSelect v-model="filters.type">
        <ZSelectTrigger>
          <ZSelectValue :placeholder="$t('notification.placeholder')"/>
        </ZSelectTrigger>
        <ZSelectContent>
          <ZSelectGroup>
            <ZSelectItem value="all">{{ $t('notification.form.type.all') }}</ZSelectItem>
            <ZSelectItem value="organization">{{ $t('notification.form.type.organization') }}</ZSelectItem>
            <ZSelectItem value="resource">{{ $t('notification.form.type.resource') }}</ZSelectItem>
            <ZSelectItem value="contract">{{ $t('notification.form.type.contract') }}</ZSelectItem>
            <ZSelectItem value="user">{{ $t('notification.form.type.user') }}</ZSelectItem>
          </ZSelectGroup>
        </ZSelectContent>
      </ZSelect>
    </div>
    <div class="w-full lg:max-w-[219px] grid gap-2 mb-4">
      <ZLabel>{{ $t('notification.form.date.label') }}</ZLabel>
      <div class="flex w-full items-center">
        <Popover v-model:open="showDatePicker">
          <PopoverTrigger as-child>
            <ZButton variant="outline" class="w-full font-normal flex items-center justify-start">
              <div class="flex items-center w-full">
                <CalendarIcon class="w-[20px] mr-2"/>
                <span v-if="filters.dateRange.start && filters.dateRange.end" class="leading-3 text-xs">
                  Od: {{ formatDateTime(filters.dateRange.start) }} <br/>
                  Do: {{ formatDateTime(filters.dateRange.end) }}
                </span>
                <span v-else>{{ $t('notification.form.date.button') }}</span>
              </div>
              <ChevronDownIcon class="w-[18px] ml-2 text-gray-400"/>
            </ZButton>
          </PopoverTrigger>
          <PopoverContent class="w-auto p-0" align="start">
            <Calendar v-model.range="filters.dateRange" timezone="UTC" :columns="1"/>
          </PopoverContent>
        </Popover>
        <ZButton v-if="filters.dateRange.start && filters.dateRange.end" variant="ghost" @click.prevent="clearDate()">
          <XCircleIcon class="w-[23px]"/>
        </ZButton>
      </div>
    </div>
  </div>
  <div>
    <div v-if="notificationDataSet.data?.length != 0">
      <div v-for="notify in notificationDataSet.data" :key="notify.id">
        <div
          class="relative rounded-md my-5 border shadow-sm"
          :class="[!notify.read_at ? ' border-green-500' : 'border-gray-200']"
        >
          <div
            v-if="!notify.read_at"
            class="absolute -top-3 left-3 bg-green-500 rounded-full text-xs font-semibold py-1 px-2 text-white"
          >
            {{ $t('notification.new') }}
          </div>
          <div class="p-2 md:flex items-center justify-between">
            <p class="ml-2 py-2">{{ notify.message }}</p>
            <router-link v-if="notify.notify_link" :to="notify.notify_link">
              <ZButton class="w-full mt-4 md:mt-0 md:w-auto">{{ $t('notification.button') }}</ZButton>
            </router-link>
          </div>
          <Separator/>
          <div
            class="px-3 py-2 bg-gray-50 rounded-b-md text-xs overflow-hidden grid md:flex md:gap-4 items-center justify-between"
          >
            <span v-if="notify.author"
            ><UserAvatar :name="notify.author" class="bg-white w-[24px] h-[24px] text-xs mr-2"/><b
              class="font-semibold"
            >{{ notify.author }}</b
            ></span
            >
            <span v-else>{{ $t('notification.authorSystem') }}</span>
            <span class="text-gray-400">
              <ZButton variant="link" class="h-[28px] text-xs" @click="markAsSeen(notify.id)">
                {{ notify.read_at ? $t('notification.buttonMarkAsNotSeen') : $t('notification.buttonMarkAsSeen') }}
              </ZButton>
              {{ formatDateTime(notify.created_at) }}
            </span>
          </div>
        </div>
      </div>
      <Paginator :data="notificationDataSet"/>
    </div>
    <div v-else class="my-16">
      <InformationCircleIcon class="icon w-[80px] mb-5 m-auto"/>
      <h3 class="scroll-m-20 text-xl font-semibold text-center">
        <Translation keypath="notification.empty.header"/>
      </h3>
      <p class="scroll-m-20 text-center">
        <Translation keypath="notification.empty.text"/>
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent, inject} from 'vue';
import {DataSet} from '@/utils/DataSet';
import loaderStore from '../../stores/loader';
import {
  InformationCircleIcon,
  CalendarIcon,
  XCircleIcon,
  AdjustmentsHorizontalIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/vue/24/outline';
import {
  ZSelect,
  ZSelectContent,
  ZSelectItem,
  ZSelectTrigger,
  ZSelectValue,
  ZSelectGroup,
} from '@shadcn/components/ui/select';
import {Popover, PopoverContent, PopoverTrigger} from '@shadcn/components/ui/popover';
import {Separator} from '@shadcn/components/ui/separator';
import {ZButton} from '@shadcn/components/ui/button';
import {ZLabel} from '@shadcn/components/ui/label';
import {ZInput} from '@shadcn/components/ui/input';
import {Calendar} from '@shadcn/components/ui/calendar';
import UserAvatar from '@/components/UserAvatar.vue';
import {Translation} from 'vue-i18n';
import {getNotifications, markNotificationAsSeen, markAllNotificationAsSeen} from '@/api/notification';
import NotificationData = App.Data.NotificationData;
import Paginator from '@ui/Paginator.vue';
import {Dialogs} from '@/types/Dialogs';
import NotificationRequestData = App.Data.NotificationRequestData;

export default defineComponent({
  components: {
    Paginator,
    UserAvatar,
    Translation,
    ZSelect,
    ZSelectContent,
    ZSelectGroup,
    ZSelectItem,
    ZSelectTrigger,
    XCircleIcon,
    AdjustmentsHorizontalIcon,
    ZSelectValue,
    Separator,
    ZButton,
    ZLabel,
    ZInput,
    InformationCircleIcon,
    CalendarIcon,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Calendar,
    ChevronDownIcon,
    ChevronUpIcon,
  },

  emits: ['notification-read'],

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
      stats: inject('stats') as { updateStats: () => Promise<void> },
    };
  },

  data() {
    return {
      componentMounted: false,
      loader: loaderStore(),
      notificationDataSet: {} as DataSet<NotificationData, NotificationRequestData>,
      showDatePicker: false,
      showFilters: false,
      filters: {
        page: 1,
        search: '',
        sort: 'newest',
        type: 'all',
        dateRange: {
          start: '',
          end: '',
        },
      },
    };
  },

  watch: {
    filters: {
      handler() {
        this.showDatePicker = false;
        this.reload();
      },

      deep: true,
    },
  },

  mounted() {
    this.componentMounted = true;
    this.loader.startLoading();
    this.notificationDataSet = new DataSet<NotificationData, NotificationRequestData>((query: string) =>
      getNotifications(this.filters).execute(query),
    );
    this.notificationDataSet.load().finally(() => {
      this.loader.stopLoading();
    });

    this.componentMounted = true;
  },

  methods: {
    reload() {
      this.notificationDataSet
        .setParams({
          ...this.filters,
          type: this.filters.type == 'all' ? '' : this.filters.type,
          dateRange: {
            start: this.filters.dateRange.start && new Date(this.filters.dateRange.start).toISOString(),
            end: this.filters.dateRange.end && new Date(this.filters.dateRange.end).toISOString(),
          },
        })
        .resetToFirstPage();
    },

    async markAsSeen(id: string) {
      await markNotificationAsSeen(id);
      await this.notificationDataSet.load();
      await this.stats.updateStats();
    },

    markAllAsSeen() {
      this.dialogs
        .confirm(this.$t('notification.markAllAsSeenConfirm.title'), this.$t('notification.markAllAsSeenConfirm.text'))
        .then(async () => {
          await markAllNotificationAsSeen();
          await this.notificationDataSet.load();
          await this.stats.updateStats();
          this.toast({
            title: this.$t('toasts.type.success'),
            description: this.$t('notification.markAllAsSeenConfirm.success'),
          });
        });
    },

    clearDate() {
      this.filters.dateRange = {
        start: '',
        end: '',
      };
    },
  },
});
</script>
