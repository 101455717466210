<template>
  <Field symbol="name" class="col-span-7">
    <ZLabel for="name">
      {{ $t('category.label.category_name') }}
    </ZLabel>
    <ZInput id="name" v-model="category.name" />
  </Field>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ZInput } from '@shadcn/components/ui/input';
import { ZLabel } from '@shadcn/components/ui/label';
import Field from '@ui/FormField.vue';
import CategoryData = App.Data.CategoryData;

export default defineComponent({
  components: {
    ZInput,
    ZLabel,
    Field,
  },

  props: {
    category: {
      type: Object as PropType<CategoryData>,
      required: true,
    },
  },
});
</script>
