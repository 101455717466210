<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent
      class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto gap-0"
    >
      <ZDialogHeader class="gap-2 mb-4">
        <ZDialogTitle>{{ $t('report.label.select_comparable_branch') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('report.message.select_comparable_branch') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <ZInput v-model="searchQuery" :placeholder="$t('organization.label.branch_search')" class="my-4" />
      <div v-if="!branchDataSet || !branchDataSet.data || !branchDataSet.data.length" class="pt-6">
        {{ $t('organization.label.no_branches_found') }}
      </div>
      <div
        v-for="(branch, index) in branchDataSet.data"
        :key="`branch-${index}`"
        :class="{ 'font-medium text-gray-800 bg-gray-100': selectedBranch && branch.id === selectedBranch.id }"
        class="cursor-pointer min-h-16 py-3 border-b flex justify-between items-center hover:font-medium px-2"
        @click="selectBranch(branch)"
      >
        <div>
          <p>
            {{ branch.name }}
          </p>
          <template v-if="branch.branch_type == 'location'">
            <p class="text-xs">
              {{ branch.postal }} {{ branch.city }}, {{ branch.street }} {{ branch.building_number }}
              {{ branch.apartment_number ?? '' }}
            </p>
          </template>
          <template v-if="branch.parents!.length">
            <div class="text-xs">
              <span v-for="(parent, branchIndex) in branch.parents" :key="`parent-${branchIndex}`">
                {{ branchIndex > 0 ? ' /' : '' }} {{ parent.name }}
              </span>
            </div>
          </template>
        </div>
        <ArrowRightIcon class="icon sm" />
      </div>
      <Paginator :data="branchDataSet" />
      <ZDialogFooter class="mt-5">
        <ZButton class="w-full" :disabled="!selectedBranch" @click="save()">{{
          $t('report.command.select_branch_to_compare')
        }}</ZButton>
      </ZDialogFooter>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import { getOrganizationBranches } from '@/api/organization';
import { DataSet } from '@/utils/DataSet';
import { mapState } from 'pinia';
import useUserStore from '@/stores/auth';
import { ZButton } from '@shadcn/components/ui/button';
import { ZInput } from '@shadcn/components/ui/input';
import { ArrowRightIcon } from '@heroicons/vue/24/outline';
import Paginator from '@ui/Paginator.vue';
import OrganizationBranchData = App.Data.OrganizationBranchData;
import SimpleSearchData = App.Data.SimpleSearchData;
import useAccessStore from '@/stores/access';

export default defineComponent({
  components: {
    Paginator,
    ArrowRightIcon,
    ZInput,
    ZButton,
    ZDialogFooter,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogHeader,
    ZDialogTitle,
  },

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: false,
      selectedOrganizationId: null as number | null,
      selectedBranch: null as OrganizationBranchData | null,
      selectedBranchId: undefined as number | undefined,
      branchDataSet: {} as DataSet<OrganizationBranchData, SimpleSearchData>,
      searchQuery: '' as string,
      promiseResolve: null as any,
      promiseReject: null as any,
    };
  },

  computed: {
    ...mapState(useUserStore, ['user']),
    access: () => useAccessStore(),
  },

  watch: {
    searchQuery() {
      this.branchDataSet.loadDebounced();
    },

    isDialogOpen() {
      if (!this.isDialogOpen) {
        this.selectedBranch = null;
        this.searchQuery = '';
      }
    },
  },

  mounted() {
    const { chosenOrganization } = useUserStore();
    this.selectedOrganizationId = chosenOrganization;
  },

  methods: {
    open() {
      this.getBranches();

      const promise = new Promise<number[]>((resolve, reject) => {
        this.promiseResolve = resolve;
        this.promiseReject = reject;
      });

      this.isDialogOpen = true;
      return promise;
    },

    getBranches() {
      this.branchDataSet = new DataSet<OrganizationBranchData, SimpleSearchData>((query: string) =>
        getOrganizationBranches(this.selectedOrganizationId ?? 0, this.searchQuery).execute(query),
      );
      this.branchDataSet.load();
    },

    selectBranch(branch: OrganizationBranchData) {
      this.selectedBranch = branch;
    },

    save() {
      this.promiseResolve(this.selectedBranch);
      this.isDialogOpen = false;
    },
  },
});
</script>
