<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent
      :class="{ 'lg:max-w-[625px]': !zoomMode, 'lg:max-w-[1024px]': zoomMode }"
      class="lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto lg:h-max-[650px]"
    >
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('assetModels.label.asset_model_images') }}</ZDialogTitle>
      </ZDialogHeader>
      <Carousel
        :opts="{
          align: 'start',
          loop: false,
        }"
        :class="{ 'h-96': !zoomMode, 'max-h-[750px]': zoomMode }"
        class="w-full"
        @init-api="setApi"
      >
        <CarouselContent>
          <CarouselItem v-for="(image, i) in imageUrls" :key="i">
            <div class="relative group">
              <div :class="{ 'h-96': !zoomMode, 'max-h-[650px]': zoomMode }" class="w-full overflow-hidden relative">
                <img
                  :class="{ 'h-96': !zoomMode, 'max-h-[650px]': zoomMode }"
                  class="w-full object-contain transition-transform duration-300 ease-in-out cursor-zoom-in"
                  :src="image as string"
                  :alt="image as string"
                  @mousedown.stop="toggleZoomMode"
                  @touchstart.stop="toggleZoomModeTouch"
                  @mousemove.stop="moveZoomOrigin"
                  @touchmove.stop="moveZoomOriginTouch"
                  @mouseleave.stop="zoomOut"
                  @touchend.stop="zoomOutTouch"
                />
              </div>
              <div class="absolute bottom-0 right-0 bg-white p-2 font-light w-16 text-center">
                {{ i + 1 }} / {{ totalCount }}
              </div>
            </div>
          </CarouselItem>
        </CarouselContent>
        <CarouselPrevious class="ml-14" />
        <CarouselNext class="mr-14" />
      </Carousel>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { ZDialog, ZDialogContent, ZDialogHeader, ZDialogTitle } from '@shadcn/components/ui/dialog';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@shadcn/components/ui/carousel';

export default defineComponent({
  components: {
    CarouselNext,
    CarouselPrevious,
    CarouselItem,
    Carousel,
    CarouselContent,
    ZDialog,
    ZDialogContent,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    imageUrls: {
      type: Array,
      required: true,
    },
  },

  emits: ['imagePreviewClosed'],

  setup() {
    const api = ref<CarouselApi | null>(null);
    const totalCount = ref(0);
    const images = ref<HTMLElement[]>([]);
    const zoomMode = ref(false);

    function setApi(val: CarouselApi) {
      api.value = val;
      totalCount.value = 0;
      if (api.value) {
        totalCount.value = api.value.scrollSnapList().length;
      }
    }

    onMounted(() => {
      images.value = (Array.isArray(images.value) ? images.value : [images.value]).filter(
        (ref: any) => ref instanceof HTMLElement,
      );
    });

    function moveZoomOrigin(event: MouseEvent) {
      const img = event.target as HTMLElement;
      if (!img) return;

      const { clientX, clientY } = event;
      const { left, top, width, height } = img.getBoundingClientRect();

      const posX = ((clientX - left) / width) * 100;
      const posY = ((clientY - top) / height) * 100;

      img.style.transformOrigin = `${posX}% ${posY}%`;

      event.preventDefault();
    }

    function moveZoomOriginTouch(event: TouchEvent) {
      const img = event.target as HTMLElement;
      if (!img) return;

      const touch = event.touches[0];
      const { clientX, clientY } = touch;
      const { left, top, width, height } = img.getBoundingClientRect();

      const posX = ((clientX - left) / width) * 100;
      const posY = ((clientY - top) / height) * 100;

      img.style.transformOrigin = `${posX}% ${posY}%`;

      event.preventDefault();
    }

    function zoomOut(event: MouseEvent) {
      const img = event.target as HTMLElement;
      if (!img) return;

      const { left, top, width, height } = img.getBoundingClientRect();
      const isNotInImageBounds =
        event.clientX >= left && event.clientX <= left + width && event.clientY >= top && event.clientY <= top + height;

      if (isNotInImageBounds) {
        img.style.transform = `scale(1)`;
        img.style.cursor = 'zoom-in';
        event.preventDefault();
      }
    }

    function zoomOutTouch(event: TouchEvent) {
      const img = event.target as HTMLElement;
      if (!img) return;

      const touch = event.changedTouches[0];
      const { clientX, clientY } = touch;
      const { left, top, width, height } = img.getBoundingClientRect();
      const isNotInImageBounds =
        clientX >= left && clientX <= left + width && clientY >= top && clientY <= top + height;

      if (isNotInImageBounds) {
        img.style.transform = `scale(1)`;
        img.style.cursor = 'zoom-in';
        event.preventDefault();
      }
    }

    function toggleZoomMode(event: MouseEvent) {
      if (event.button !== 0) return;

      const img = event.target as HTMLElement;
      if (!img) return;

      if (img.style.transform === 'scale(2)') {
        img.style.transform = `scale(1)`;
        img.style.cursor = 'zoom-in';
        zoomMode.value = false;
      } else {
        zoomMode.value = true;
        const { clientX, clientY } = event;
        const { left, top, width, height } = img.getBoundingClientRect();

        const posX = ((clientX - left) / width) * 100;
        const posY = ((clientY - top) / height) * 100;

        img.style.transform = `scale(2)`;
        img.style.cursor = 'zoom-out';
        img.style.transformOrigin = `${posX}% ${posY}%`;
      }

      event.preventDefault();
    }

    function toggleZoomModeTouch(event: TouchEvent) {
      const img = event.target as HTMLElement;
      if (!img) return;

      if (img.style.transform === 'scale(2)') {
        img.style.transform = `scale(1)`;
        img.style.cursor = 'zoom-in';
        zoomMode.value = false;
      } else {
        zoomMode.value = true;
        const touch = event.touches[0];
        const { clientX, clientY } = touch;
        const { left, top, width, height } = img.getBoundingClientRect();

        const posX = ((clientX - left) / width) * 100;
        const posY = ((clientY - top) / height) * 100;

        img.style.transform = `scale(2)`;
        img.style.cursor = 'zoom-out';
        img.style.transformOrigin = `${posX}% ${posY}%`;
      }

      event.preventDefault();
    }

    return {
      setApi,
      totalCount,
      images,
      zoomMode,
      zoomOut,
      moveZoomOrigin,
      moveZoomOriginTouch,
      zoomOutTouch,
      toggleZoomMode,
      toggleZoomModeTouch,
    };
  },

  data() {
    return {
      isDialogOpen: true,
    };
  },

  watch: {
    isDialogOpen(val) {
      if (!val) this.$emit('imagePreviewClosed');
    },
  },
});
</script>
