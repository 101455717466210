<template>
  <DataLoader ref="loader">
    <div class="mb-10">
      <router-view></router-view>
      <template v-if="contract.priority">
        <Tabs v-model="currentTab" class="py-5">
          <TabsList
            class="lg:mt-4 no-scrollbar !flex justify-start overflow-x-auto lg:overflow-visible items-center gap-4 lg:gap-0 grid py-5 w-full"
          >
            <TabsTrigger class="w-full" value="details">
              {{ $t('contracts.label.navigation_details') }}
            </TabsTrigger>
            <TabsTrigger class="w-full" value="actions">
              <div class="relative flex items-center gap-2">
                {{ $t('contracts.label.navigation_actions') }}
                <div
                  class="lg:absolute -top-4 -right-5 w-[18px] h-[18px] bg-primary rounded-full text-white text-[9px] flex items-center justify-center"
                >
                  {{ contract!.contracted_actions!.length }}
                </div>
              </div>
            </TabsTrigger>
            <TabsTrigger class="w-full" value="histories">
              {{ $t('contracts.label.navigation_histories') }}
            </TabsTrigger>
          </TabsList>
          <TabsContent value="details">
            <div class="flex flex-col gap-5 mt-4">
              <div class="flex flex-col gap-3 rounded-xl border bg-card text-card-foreground">
                <div class="flex p-6 flex-col lg:flex-row gap-4 w-full">
                  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 flex-1">
                    <div class="i-block">
                      <span class="i-label">{{ $t('contracts.label.contract_number') }}</span>
                      <span class="i-value">
                      {{ contract!.contract_full_number! }}
                    </span>
                    </div>
                    <div class="i-block">
                      <span class="i-label">{{ $t('contracts.label.status') }}</span>
                      <span class="i-value">
                      <PrettyStatus :status="contract!.status!" :translate="'contract.status.'"/>
                    </span>
                    </div>
                    <div class="i-block">
                      <span class="i-label">{{ $t('contracts.label.priority') }}</span>
                      <span class="i-value">
                      {{ $t('contracts.priority.' + priorities[contract!.priority!]) }}
                    </span>
                    </div>
                    <div class="i-block">
                      <span class="i-label">{{ $t('contracts.label.author') }}</span>
                      <span class="i-value"> {{ contract?.user_name! }} </span>
                    </div>
                    <div class="i-block">
                      <span class="i-label">{{ $t('common.carbon_value') }}</span>
                      <span class="i-value"
                      ><b class="text-lg font-bold">{{
                          contract?.carbon_footprint ? readableCarbonValue(contract?.carbon_footprint) : '-'
                        }}</b></span
                      >
                    </div>
                    <div class="i-block">
                      <span class="i-label">{{ $t('contracts.label.assets_count') }}</span>
                      <span class="i-value"> {{ contract?.contracted_assets_count }} </span>
                    </div>
                    <div class="i-block">
                      <span class="i-label">{{ $t('contracts.label.actions_count') }}</span>
                      <span class="i-value"> {{ contract?.contracted_actions_count! }} </span>
                    </div>
                    <div class="i-block">
                      <span class="i-label">{{ $t('contracts.label.contract_created_at') }}</span>
                      <span class="i-value">{{ formatDate(contract?.created_at) }}</span>
                    </div>
                    <div class="i-block">
                      <span class="i-label">{{ $t('contracts.label.estimated_deadline_at') }}</span>
                      <span class="i-value">{{
                          contract?.estimated_deadline_at ? formatDate(contract?.estimated_deadline_at) : '-'
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="contract && contract.description" class="rounded-xl border bg-card text-card-foreground">
                <div class="m-5 flex items-center justify-between">
                  <h3 class="font-semibold leading-none tracking-tight">{{ $t('contracts.label.contract_text') }}</h3>
                </div>
                <div class="m-5">
                  {{ contract?.description ? contract.description : '' }}
                </div>
              </div>
            </div>
          </TabsContent>
          <TabsContent value="actions">
            <div v-if="contract.contracted_actions?.length" class="mt-4">
              <div class="lg:flex border rounded-lg">
                <div class="lg:w-2/5">
                  <div class="flex p-4 h-[57px] justify-between items-center pb-3 border-b">
                    <div class="font-bold">{{ $t('contracts.label.actions') }}</div>
                  </div>
                  <div
                    v-for="(action, index) in contract.contracted_actions"
                    :key="index"
                    class="flex justify-between items-center bg-gray-50 py-3 px-4 m-4 rounded-lg hover:bg-accent cursor-pointer"
                    :class="{ 'bg-primary/20': selectedAction && selectedAction.id == action.id }"
                    @click="selectContractedAction(index)"
                  >
                    <div class="w-full flex flex-col gap-1">
                      <div class="font-semibold flex gap-2 items-center justify-between">
                        <div>
                          <span class="text-xs inline-block align-middle mr-1">
                            <span class="inline-block p-1 bg-white border px-2 rounded border-gray-100">#{{ action!.ref_number }}</span>
                          </span>
                          {{ getActionData(action!.action!.id!).name }}
                        </div>
                        <PrettyStatus :status="action?.status" :translate="'contract.status.'"/>
                      </div>
                      <div class="text-xs">{{ getActionData(action!.action!.id!).hint }}</div>
                      <div class="text-xs w-full">
                        <div class="pb-2">{{ action!.notes }}</div>
                        {{ $t('contracts.label.assigned_contractor') }}:
                        <b class="font-bold text-green-600">{{ action!.contractor ?? '-' }}</b>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="selectedAction" class="w-full lg:w-3/5 lg:border-l lg:shadow-[-10px_0px_20px_-15px_rgba(0,0,0,0.15)]">
                  <div class="flex flex-col lg:flex-row gap-2 p-4 justify-between items-center pb-3 border-b">
                    <div class="font-bold flex gap-2 items-center">
                      <span class="text-xs inline-block align-middle mr-1">
                        <span class="inline-block p-1 bg-gray-500 text-white border px-2 rounded border-gray-100">#{{ selectedAction!.ref_number }}</span>
                      </span>
                      <PrettyStatus :status="selectedAction?.status" :translate="'contract.status.'"/>
                      {{ getActionData(selectedAction!.action!.id!)!.name }}
                    </div>
                    <div class="w-full lg:max-w-36">
                      <DropdownMenu>
                        <DropdownMenuTrigger as-child>
                          <ZButton variant="outline" class="h-7 w-full">
                          <span class="ml-2">
                            {{ $t('contracts.label.action_options') }}
                          </span>
                            <ChevronDownIcon class="icon xs ml-2"/>
                          </ZButton>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent class="w-56">
                          <DropdownMenuGroup>
                            <DropdownMenuItem as-child>
                              <router-link
                                :to="{
                                name: ROUTE.MANAGED_CONTRACT_ACTION_EDIT,
                                params: { actionId: selectedAction!.id! },
                              }"
                              >{{ $t('contracts.command.edit_action') }}
                              </router-link
                              >
                            </DropdownMenuItem>
                            <DropdownMenuSeparator/>
                            <DropdownMenuItem @click="addStatusToAction(selectedAction!.id!)">
                              <span>{{ $t('contracts.command.add_action_status') }}</span>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator/>
                            <DropdownMenuItem @click="addNoteToAction(selectedAction!.id!)">
                              <span>{{ $t('contracts.command.add_admin_note') }}</span>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator/>
                            <DropdownMenuItem @click="exportAssetsCsv(selectedAction!.id!)">
                              {{ $t('contracts.command.export_assets_to_csv') }}
                            </DropdownMenuItem>
                          </DropdownMenuGroup>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </div>
                  <div>
                    <div class="grid grid-cols-1 divide-y">
                      <div class="p-4">
                        <div class="text-xs font-semibold">{{ $t('contracts.label.assigned_contractor') }}</div>
                        <div>{{ selectedAction!.contractor ?? '-' }}</div>
                      </div>
                      <div v-if="selectedAction!.notes != '' && selectedAction!.notes != undefined" class="p-4">
                        <div class="text-xs">{{ $t('contracts.label.note') }}</div>
                        <div>{{ selectedAction!.notes! }}</div>
                      </div>
                      <div v-if="selectedAction!.admin_notes != '' && selectedAction!.admin_notes != undefined"
                           class="p-4">
                        <div class="text-xs">{{ $t('contracts.label.admin_notes') }}</div>
                        <div v-for="(note, index) in selectedAction!.admin_notes!" :key="index">{{ index + 1 }}.
                          {{ note.note }}
                        </div>
                      </div>
                      <div v-if="selectedAction.amount_value" class="p-4">
                        <div class="text-xs">{{ getActionData(selectedAction!.action!.id!)!.amount_title }}</div>
                        <div>{{ selectedAction!.amount_value }}</div>
                      </div>
                      <div v-if="selectedAction.location_value" class="p-4">
                        <div class="text-xs">{{ getActionData(selectedAction!.action!.id!)!.location_title }}</div>
                        <div>{{ selectedAction!.location_value }}</div>
                      </div>
                      <div v-if="selectedAction.checkbox_value" class="p-4">
                        <div class="text-xs">{{ getActionData(selectedAction!.action!.id!)!.checkbox_title }}</div>
                        <div>{{ selectedAction.checkbox_value ? 'Tak' : 'Nie' }}</div>
                      </div>
                      <div v-if="selectedAction.deadline_value" class="p-4">
                        <div class="text-xs">{{ getActionData(selectedAction!.action!.id!)!.deadline_title }}</div>
                        <div>{{ formatDate(selectedAction.deadline_value) }}</div>
                      </div>
                    </div>
                    <div v-if="actionAssetDataStatus == 'loading' || (selectedAction && actionAssetDataSet && actionAssetDataSet.data)"
                         class="font-bold p-4 border-y">{{ $t('contracts.label.contracted_assets') }}</div>
                    <div v-if="actionAssetDataStatus == 'loading'">
                      <IconAlert type="loading">
                        {{ $t('common.action.loading') }}
                      </IconAlert>
                    </div>
                    <div v-else-if="selectedAction && actionAssetDataSet && actionAssetDataSet.data">
                      <div
                        v-for="(item, index) in actionAssetDataSet.data"
                        :key="index"
                        class="shadow-sm border m-5 relative rounded-lg grid grid-cols-1 divide-y"
                        :class="{ 'border-primary': selectedAssets.includes(item.id) }"
                      >
                        <div class="lg:flex lg:pb-0 pb-8 items-center justify-between">
                          <div class="absolute flex justify-center -bottom-2.5 right-0 left-0">
                            <ZButton
                              class="h-5 text-xs font-semibold"
                              variant="outline"
                              @click="toggleVisibleDetails(item.id)"
                            >
                              {{
                                visibleDetails.indexOf(item.id) ? $t('common.show_details') : $t('common.hide_details')
                              }}
                            </ZButton>
                          </div>
                          <div class="flex items-center gap-3 p-4">
                            <div>
                              <img
                                v-if="item!.asset!.thumb"
                                class="w-[85px] h-[85px] object-cover rounded-xl hidden md:block"
                                :src="item!.asset!.thumb"
                                alt="primaryImage"
                              />
                            </div>
                            <div>
                              <div class="flex flex-col text-sm">
                                <b
                                >{{ item!.asset!.name }} /
                                  {{
                                    item!.asset!.asset_model ? item!.asset!.asset_model?.name : $t('asset.label.lack')
                                  }}</b
                                >
                                {{ item!.asset!.asset_number }}
                                <div class="text-xs mt-2">
                                  <b>{{ $t('contracts.label.localization') }}</b>
                                  <div class="flex flex-col">
                                  <span>{{
                                      item!.asset!.branch!.address ? item!.asset!.branch!.address : $t('asset.label.lack')
                                    }}</span>
                                    {{ item!.asset!.branch!.path ? item!.asset!.branch!.path : $t('asset.label.lack') }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="flex items-center gap-4 mx-4 justify-center">
                            <div>
                              <div class="flex items-center justify-center rounded-full font-bold">
                                {{ item!.carbon_value ? readableCarbonValue(item!.carbon_value) : '-' }}
                              </div>
                              <div class="text-xs">{{ $t('common.carbon_value') }}</div>
                            </div>
                            <div>
                              <ZButton class="rounded-full w-[40px] h-[40px]">
                                <router-link
                                  :to="{
                                  name: ROUTE.MANAGED_CONTRACT_ASSET_CARBON,
                                  params: { contractedAssetId: item.id },
                                }"
                                >
                                  <CalculatorIcon class="icon sm"/>
                                </router-link>
                              </ZButton>
                            </div>
                            <div>
                              <ZButton class="rounded-full w-[40px] h-[40px]">
                                <router-link
                                  :to="{
                                  name: ROUTE.MANAGED_CONTRACT_ADD_NOTE_TO_ASSET,
                                  params: { contractedAssetId: item.id },
                                }"
                                >
                                  <ChatBubbleOvalLeftEllipsisIcon class="icon sm"/>
                                </router-link>
                              </ZButton>
                            </div>
                          </div>
                        </div>
                        <div v-if="visibleDetails.indexOf(item.id) !== -1" class="p-2">
                          <div class="flex items-center justify-between text-sm">
                            <b>{{ $t('contracts.label.note') }}</b>
                          </div>
                          <div class="whitespace-pre-wrap">{{ item.notes ? item.notes : '-' }}</div>
                        </div>
                        <div v-if="visibleDetails.indexOf(item.id) !== -1" class="p-2">
                          <div class="flex items-center justify-between text-sm">
                            <b>{{ $t('files.title') }}</b>
                          </div>
                          <div
                            v-if="item.files && item.files.length"
                               class="grid gap-4 grid-cols-2 md:grid-cols-4 m-2 lg:grid-cols-5">
                            <div v-for="(file, index) in item.files" :key="index">
                              <DropdownMenu :key="index">
                                <DropdownMenuTrigger :key="index" class="cursor-pointer" as-child>
                                  <div>
                                    <div
                                      class="bg-gray-100 h-[100px] rounded-lg relative overflow-hidden flex items-center justify-center"
                                    >
                                      <img
                                        v-if="isFileImage(file.mime)"
                                        class="absolute w-full h-full object-cover overflow-hidden"
                                        :src="file.url"
                                      />
                                      <div v-else>
                                        <div class="text-center p-1">
                                          <b class="text-xs">{{ file.ext }}</b>
                                          <div class="text-xs line-clamp-2">{{ file.name }}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent class="w-56">
                                  <DropdownMenuGroup>
                                    <DropdownMenuItem @click="getFileDownloadUrl(file.id!)">
                                      <span>{{ $t('files.command.download') }}</span>
                                    </DropdownMenuItem>
                                  </DropdownMenuGroup>
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </div>
                          </div>
                          <div v-else>-</div>
                        </div>
                        <div v-if="visibleDetails.indexOf(item.id) !== -1" class="p-2">
                          <div class="flex items-center justify-between text-sm">
                            <b>{{ $t('contracts.label.comments') }}</b>
                          </div>
                          <div v-if="item.comments.length">
                            <p v-for="(comment, index) in item.comments" :key="index" class="my-1">
                              {{ index + 1 }}. {{ comment.note }}
                            </p>
                          </div>
                          <div v-else>-</div>
                        </div>
                      </div>
                      <div>
                        <div
                          v-if="actionAssetDataSet.data?.length == 0"
                          class="p-8 text-center flex flex-col gap-2 items-center"
                        >
                          <ExclamationTriangleIcon class="icon lg"/>
                          {{ $t('asset.message.data_not_found') }}
                        </div>
                        <Paginator class="border-t px-4" :data="actionAssetDataSet"></Paginator>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabsContent>
          <TabsContent value="histories">
            <ContractChanges class="mt-4" :contract-id="contract!.id"/>
          </TabsContent>
          <TabsContent value="realization"></TabsContent>
        </Tabs>
      </template>

      <MountedTeleport to="#toolbox">
        <DropdownMenu>
          <DropdownMenuTrigger as-child>
            <ZButton class="w-full">
              <TruckIcon class="icon sm"/>
              <span class="ml-2">
              {{ $t('contracts.command.manage') }}
            </span>
              <ChevronDownIcon class="icon xs ml-2"/>
            </ZButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent class="w-56">
            <DropdownMenuGroup>
              <DropdownMenuItem as-child>
                <router-link :to="{ name: ROUTE.MANAGED_CONTRACT_STATUS_UPDATE }">{{
                    $t('contracts.command.proceed')
                  }}
                </router-link>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem as-child>
                <router-link :to="{ name: ROUTE.MANAGED_CONTRACT_ADD_NOTE }">{{
                    $t('contracts.command.add_note')
                  }}
                </router-link>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </MountedTeleport>
    </div>
  </DataLoader>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import {Dialogs} from '@/types/Dialogs';
import Navigation from '@/types/Navigation';
import {ZButton} from '@shadcn/components/ui/button';
import {ChevronDownIcon, CalculatorIcon} from '@heroicons/vue/24/outline';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@shadcn/components/ui/dropdown-menu';
import {ROUTE} from '@/router/routeNames';
import {downloadActionAssetsCSV, getContract, getContractChanges, toggleContractStatus} from '@/api/contract';
import {getContractedActionAssets} from '@/api/contractAsset';
import {getContractedActions} from '@/api/contractAction';
import ContractData = App.Data.ContractData;
import {PRIORITY} from '@/consts';
import symbolsStore from '@/stores/symbols';
import ActionData = App.Data.ActionData;
import ContractActionData = App.Data.ContractActionData;
import {DataSet} from '@/utils/DataSet';
import Paginator from '@ui/Paginator.vue';
import PrettyStatus from '@ui/PrettyStatus.vue';
import {TruckIcon, ExclamationTriangleIcon, ChatBubbleOvalLeftEllipsisIcon} from '@modules/@heroicons/vue/24/outline';
import {HttpStatusCode} from '@modules/axios';
import {toast} from '@shadcn/components/ui/toast';
import ContractAssetData = App.Data.ContractAssetData;
import ContractActionAssetRequestData = App.Data.ContractActionAssetRequestData;
import MountedTeleport from '@ui/MountedTeleport.vue';
import ContractChangeData = App.Data.ContractChangeData;
import ContractChanges from '@ui/Contracts/ContractChanges.vue';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '@shadcn/components/ui/tabs';
import DataLoader from "@ui/DataLoader.vue";
import {isFileImage} from '@/utils/isFileImage';
import {getFileDownloadUrl} from '@/api';
import {KeyValue} from "@/types/Common";
import IconAlert from "@ui/IconAlert.vue";
import {ComponentLoadState} from "@/types/ComponentLoadState";

export default defineComponent({
  components: {
    DataLoader,
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
    ContractChanges,
    TruckIcon,
    MountedTeleport,
    ExclamationTriangleIcon,
    CalculatorIcon,
    ChatBubbleOvalLeftEllipsisIcon,
    PrettyStatus,
    Paginator,
    DropdownMenuSeparator,
    DropdownMenuItem,
    DropdownMenuGroup,
    DropdownMenuContent,
    DropdownMenuTrigger,
    DropdownMenu,
    ChevronDownIcon,
    ZButton,
    IconAlert,
  },

  props: {
    contractId: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
      navigation: inject('navigation') as Navigation,
    };
  },

  data() {
    return {
      contract: {} as ContractData,
      contract_actions: [] as Array<ActionData>,
      actionAssetDataStatus: 'loading' as ComponentLoadState,
      priorities: {} as KeyValue,
      selectedAction: null as ContractActionData | null,
      selectedIndex: null as number | null,
      actionAssetDataSet: {} as DataSet<ContractAssetData, ContractActionAssetRequestData>,
      changesDataSet: {} as DataSet<ContractChangeData, null>,
      selectedAssets: [] as Array<number>,
      displayAllNotes: false as boolean,
      loader: null as any,
      visibleDetails: [] as number[],
      filters: {
        search: '',
        page: 1,
        perPage: 8,
      },

      currentTab: 'details',
    };
  },

  watch: {
    currentTab() {
      this.$router.push({hash: '#' + this.currentTab});
    },

    async $route(to: any, from: any) {
      if (to.name == ROUTE.MANAGED_CONTRACT_DETAILS && from.name != ROUTE.MANAGED_CONTRACT_ASSET_CARBON && from.name != ROUTE.MANAGED_CONTRACT_DETAILS) {
        try {
          await this.getContractData();
          if (this.selectedAction && this.selectedAction.id) {
            const foundAction = this.contract!.contracted_actions!.find(item => item.id == this.selectedAction!.id!);
            if (!foundAction) {
              return;
            }
            this.selectedAction = foundAction;
            await this.getActionAssets(this.selectedAction!.id!);
          } else {
            if (this.contract && this.contract.contracted_actions)
              this.selectedAction = this.contract!.contracted_actions[0];
          }
        } catch (error: any) {
          throw error;
        }
      } else if (to.name == ROUTE.MANAGED_CONTRACT_DETAILS && from.name == ROUTE.MANAGED_CONTRACT_ASSET_CARBON) {
        try {
          await this.getActionAssets(this.selectedAction!.id!);
        } catch (error: any) {
          throw error;
        }
      }
    },

    async selectedAction() {
      if (this.selectedAction && this.selectedAction.id) await this.getActionAssets(this.selectedAction!.id);
    },

    filters: {
      deep: true,
      handler() {
        this.actionAssetDataSet.setParams(this.filters).resetToFirstPage();
      },
    },
  },

  async mounted() {
    try {
      this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;
      if (this.$route.hash) {
        this.currentTab = this.$route.hash.replace('#', '');
      }
      await this.getContractData();
      await this.getActions();
      await this.getChanges();
      this.priorities = PRIORITY;
      this.selectedAction =
        this.contract && this.contract.contracted_actions ? this.contract.contracted_actions[0] : null;
    } catch (error: any) {
      throw error;
    }
  },

  methods: {
    getFileDownloadUrl,
    isFileImage,
    async getContractData() {
      this.contract = (await getContract(this.contractId).withLoader(this.loader).execute()).data;
    },

    async getActions() {
      const symbols = symbolsStore();
      const rawContractActions = await symbols.get('contract_actions');
      this.contract_actions = Object.values(rawContractActions);
    },

    async getChanges() {
      this.changesDataSet = new DataSet<ContractChangeData, null>((query: string) =>
        getContractChanges(this.contract!.id!.toString()).execute(query),
      );
      await this.changesDataSet.load();
    },

    async getActionAssets(actionId: number) {
      this.actionAssetDataStatus = 'loading';
      if (this.contract && this.contract.id) {
        this.actionAssetDataSet = new DataSet<ContractAssetData, ContractActionAssetRequestData>((query: string) =>
          getContractedActionAssets(this.contract!.id!.toString(), actionId.toString(), this.filters).execute(query),
        ).setParams(this.filters);
        await this.actionAssetDataSet.load().then(() => (this.actionAssetDataStatus = 'loaded'));
      }
    },

    async getContractActions() {
      if (this.contract && this.contract.id) {
        await getContractedActions(this.contract!.id?.toString()).execute();
      }
    },

    addNoteToAction(contractedActionId: number) {
      if (!this.contract || !contractedActionId) {
        return;
      }
      this.$router.push({
        name: ROUTE.MANAGED_CONTRACT_ADD_NOTE_TO_ACTION,
        params: { contractedActionId: contractedActionId },
      });
    },

    addStatusToAction(contractedActionId: number) {
      if (!this.contract || !contractedActionId) {
        return;
      }
      this.$router.push({
        name: ROUTE.MANAGED_CONTRACT_ACTION_STATUS,
        params: { contractedActionId: contractedActionId },
      });
    },

    displayNotes() {
      this.displayAllNotes = !this.displayAllNotes;
    },

    getActionData(actionId: number): ActionData {
      const foundItem = this.contract_actions.find(item => item.id == actionId);
      if (!foundItem) return {} as ActionData;
      return foundItem;
    },

    toggleStatus() {
      this.dialogs
        .confirm(
          this.$t('contracts.label.activating_contract'),
          this.$t('contracts.message.activating_contract_question'),
        )
        .then(() => {
          if (!this.contract) {
            return;
          }
          toggleContractStatus(this.contractId)
            .execute()
            .then(async () => {
              this.toast({
                title: this.$t('toasts.type.saved'),
                description: this.$t('contracts.message.contract_saved'),
              });
              this.selectedAssets = [];
              await this.getContractData();
            })
            .catch(error => {
              if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
                toast({
                  title: this.$t('common.errors.header'),
                  description: this.$t(error.response.data.message),
                  variant: 'destructive',
                });
              }
            });
        });
    },

    toggleSelectedAsset(id: number) {
      const assetChosen = this.selectedAssets.find(assetId => assetId == id);
      if (assetChosen) {
        this.selectedAssets.splice(this.selectedAssets.indexOf(assetChosen), 1);
      } else {
        this.selectedAssets.push(id);
      }
    },

    selectContractedAction(index: number) {
      this.selectedAction = this.contract!.contracted_actions![index];
      this.selectedIndex = index;
    },

    exportAssetsCsv(id: number) {
      downloadActionAssetsCSV(this.contract!.id!, id);
    },

    toggleVisibleDetails(id: any) {
      const exist = this.visibleDetails.indexOf(id) !== -1;
      if (exist) {
        this.visibleDetails.splice(this.visibleDetails.indexOf(id), 1);
      } else {
        this.visibleDetails.push(id);
      }
    },
  },
});
</script>
