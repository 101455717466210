<template>
  <ZDialog v-model:open="isDialogOpen" class="md:max-h-[90vh]">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('asset.label.edit_asset_data') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('asset.message.edit_asset_data') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <div class="lg:max-h-[70vh] lg:overflow-auto">
          <FormValidator ref="form" class="grid grid-cols-4 gap-4 py-4">
            <AssetNotesForm :note="note" />
          </FormValidator>
        </div>
        <ZDialogFooter>
          <Action @proceed="update">{{ $t('asset.command.save_changes') }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import AssetModelData = App.Data.AssetModelData;
import AssetData = App.Data.AssetData;
import { getAsset, updateAsset } from '@/api/asset';
import CategoryCompactData = App.Data.CategoryCompactData;
import { DataSet } from '@/utils/DataSet';
import AssetModelRequestData = App.Data.AssetModelRequestData;
import AssetNotesForm from '@ui/Assets/AssetNotesForm.vue';
import { getAssetNote, updateAssetNote } from '@/api/assetNote';
import AssetNotesData = App.Data.AssetNotesData;

export default defineComponent({
  components: {
    AssetNotesForm,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    assetId: {
      type: String,
      required: true,
    },

    noteId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      categories: {} as CategoryCompactData,
      asset: {} as AssetData,
      note: {} as AssetNotesData,
      state: 'loading' as ComponentLoadState,
      assetModelDataSet: {} as DataSet<AssetModelData, AssetModelRequestData>,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      this.asset = (await getAsset(this.assetId).execute()).data;
      this.note = (await getAssetNote(this.assetId, this.noteId).execute()).data;
      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
      throw error;
    }
  },

  methods: {
    update(action: any) {
      updateAssetNote(this.assetId, this.noteId, { note: this.note.note })
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.isDialogOpen = false;
          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('asset.message.asset_saved'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
