<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('assetModels.label.create_asset_model') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('assetModels.message.create_asset_model') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <FormValidator ref="form" class="grid grid-cols-4 gap-4 py-4">
          <AssetModelForm :asset-model="assetModel" :categories="categories" />
        </FormValidator>
        <ZDialogFooter>
          <Action @proceed="store">{{ $t('assetModels.command.add_model') }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import AssetModelForm from '@ui/AssetModels/AssetModelForm.vue';
import AssetModelData = App.Data.AssetModelData;
import CategoryCompactData = App.Data.CategoryCompactData;
import { createAssetModel } from '@/api/assetModel';
import { getCompactCategories } from '@/api/category';

export default defineComponent({
  components: {
    AssetModelForm,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      assetModel: {} as AssetModelData,
      categories: {} as CategoryCompactData,
      state: 'loaded' as ComponentLoadState,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  mounted() {
    try {
      this.getCategories();
    } catch (error: any) {
      this.state = error;
    }
  },

  methods: {
    store(action: any) {
      createAssetModel(this.assetModel)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(response => {
          this.$router.push({ name: this.ROUTE.ASSET_MODELS_DETAILS, params: { modelId: response.data.new_id } });

          this.toast({
            title: this.$t('toasts.type.success'),
            description: this.$t('assetModels.message.model_created'),
          });
          this.$emit('saved');
        });
    },

    async getCategories() {
      const categoriesResponse = await getCompactCategories().execute();
      this.categories = categoriesResponse.data;
    },
  },
});
</script>
