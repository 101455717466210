<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>
          {{ $t('category.label.edit_category') }}
        </ZDialogTitle>
        <ZDialogDescription>
          {{ $t('category.message.change_category_data') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded' && category">
        <FormValidator ref="form" class="grid grid-cols-7 gap-4 py-4">
          <Field symbol="name" class="col-span-7">
            <ZLabel for="name">
              {{ $t('category.label.category_name') }}
            </ZLabel>
            <ZInput id="name" v-model="category.name" />
          </Field>
        </FormValidator>
        <ZDialogFooter>
          <Action @proceed="update">
            {{ $t('category.command.edit_category_data') }}
          </Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import { getCategory, saveCategory } from '@/api/category';
import { ROUTE } from '@/router/routeNames';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import Field from '@ui/FormField.vue';
import { ZLabel } from '@shadcn/components/ui/label';
import { ZInput } from '@shadcn/components/ui/input';
import CategoryData = App.Data.CategoryData;

export default defineComponent({
  components: {
    ZInput,
    ZLabel,
    Field,
    ZDialogFooter,
    ZDialogTitle,
    ZDialogDescription,
    ZDialogHeader,
    ZDialogContent,
    ZDialog,
    IconAlert,
    Action,
    FormValidator,
  },

  props: {
    categoryId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      category: {} as CategoryData,
      isDialogOpen: true,

      state: 'loading' as ComponentLoadState,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      const response = await getCategory(this.categoryId).execute();
      this.category = response.data;
      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
    }
  },

  methods: {
    update(action: typeof Action) {
      saveCategory(this.category, this.categoryId)
        .withForm(this.$refs.form as typeof FormValidator)
        .withLoader(action.loader)
        .execute()
        .then(response => {
          this.$router.push({ name: ROUTE.CATEGORY_LIST, query: { categoryId: response.data.saved_id } });

          this.toast({
            title: this.$t('toasts.type.saved'),
            description: this.$t('category.message.category_saved'),
          });
          this.$emit('saved');
        });
    },
  },
});
</script>
