<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[425px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{ $t('asset.label.update_status') }}</ZDialogTitle>
        <ZDialogDescription>
          {{ $t('asset.message.update_status') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded' && !duplicatedRecords">
        <div>
          <FormValidator ref="form" class="my-4">
            <ZLabel>{{ $t('asset.label.status') }}</ZLabel>
            <SelectField v-model="status" :options="assetStatuses" :translate="'asset.states.'" />
          </FormValidator>
        </div>
        <ZDialogFooter>
          <Action :disabled="!status" @proceed="updateAssetStatus">{{ $t('asset.command.save_changes') }}</Action>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Action from '@ui/Action.vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import DuplicatedData = App.Data.DuplicatedData;
import AssetStatus = App.Enums.AssetStatus;
import { KeyValue } from '@/types/Common';
import symbolsStore from '@/stores/symbols';
import { updateAssetStatus } from '@/api/asset';
import SelectField from '@ui/SelectField.vue';
import { ZLabel } from '@shadcn/components/ui/label';

export default defineComponent({
  components: {
    ZLabel,
    SelectField,
    IconAlert,
    FormValidator,
    Action,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    assetId: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      state: 'loading' as ComponentLoadState,
      status: null as null | AssetStatus,
      duplicatedRecords: null as Array<DuplicatedData> | null,
      assetStatuses: {} as KeyValue,
    };
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) this.$router.back();
    },
  },

  async mounted() {
    try {
      const symbols = symbolsStore();
      const allStatuses = await symbols.get('asset_statuses');
      this.assetStatuses = Object.fromEntries(Object.entries(allStatuses).filter(([key]) => key !== 'all'));
      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
      throw error;
    }
  },

  methods: {
    async updateAssetStatus() {
      this.state = 'loading';
      await updateAssetStatus(this.assetId, this.status!)
        .execute()
        .then(response => {
          this.$router.back();
        });
    },
  },
});
</script>
