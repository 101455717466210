import type { RouteRecordRaw } from 'vue-router';
import GuestLayout from '../views/GuestLayout.vue';
import Login from '../views/Auth/Login.vue';
import Register from '../views/Auth/Register.vue';
import RegisterEmployee from '../views/Auth/RegisterEmployee.vue';
import ResetPassword from '../views/Auth/ResetPassword.vue';
import ChangePassword from '../views/Auth/ChangePassword.vue';
import { ROUTE } from './routeNames';
import useUserStore from '@/stores/auth';

export const authRoutes: RouteRecordRaw[] = [
  {
    path: '',
    component: GuestLayout,
    redirect: () => {
      const { loggedIn } = useUserStore();
      if (loggedIn) {
        return { name: ROUTE.DASHBOARD };
      } else {
        return { name: ROUTE.LOGIN };
      }
    },
    children: [
      {
        path: 'login',
        component: Login,
        name: ROUTE.LOGIN,
      },
      {
        path: 'register',
        component: Register,
        name: ROUTE.REGISTER,
      },
      {
        path: 'register/:token',
        component: RegisterEmployee,
        name: ROUTE.REGISTER_EMPLOYEE,
        props: true,
      },
      {
        path: 'reset-password/:token?',
        component: ResetPassword,
        name: ROUTE.RESET_PASSWORD,
        props: true,
      },
      {
        path: 'change-password',
        component: ChangePassword,
        name: ROUTE.CHANGE_PASSWORD,
        props: true,
      },
    ],
  },
];
