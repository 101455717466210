<template>
  <div class="flex flex-col lg:flex-row gap-4 w-full">
    <div
      class="row-span-2 lg:mr-4 lg:p-2 relative lg:border rounded-md w-full h-[160px] md:h-[220px] lg:max-w-[130px] lg:max-h-[130px] cursor-pointer"
    >
      <img
        class="w-full h-full object-cover rounded-md"
        :src="asset.thumb!"
        alt="primaryImage"
        @click="previewImages()"
      />
      <div
        v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"
        class="text-xs absolute top-0 left-0 -m-3 bg-muted w-[30px] h-[30px] flex justify-center rounded-full"
        @click="editAssetImages()"
      >
        <PlusCircleIcon class="icon sm"/>
      </div>
    </div>
    <div class="grid grid-cols-2 lg:grid-cols-5 gap-4 flex-1">
      <div class="i-block">
        <span class="i-label">Nazwa modelu / kod modelu</span>
        <span class="i-value">
          {{ asset?.assetModel?.name }}{{asset?.assetModel?.name && asset?.assetModel?.code ? ' / ' : ''}}{{ asset?.assetModel?.code }}
        </span>
      </div>
      <div class="i-block">
        <span class="i-label">{{ $t('asset.label.status') }}</span>
        <PrettyStatus class="mt-1" :status="asset!.status!" :translate="'asset.states.'"/>
      </div>
      <div class="i-block">
        <span class="i-label">{{ $t('asset.label.technical_condition') }}</span>
        <span class="i-value !font-semibold">{{ $t('asset.technical_conditions.' + asset.technical_condition) }}</span>
      </div>
      <div class="i-block">
        <span class="i-label">{{ $t('asset.label.inventory_number') }}</span>
        <span class="i-value">
          {{ asset?.asset_number }}
        </span>
      </div>
      <div class="i-block">
        <span class="i-label">{{ $t('asset.label.serial_number') }}</span>
        <span class="i-value">
          {{ asset?.serial_number ? asset?.serial_number : '-' }}
        </span>
      </div>
      <div class="i-block">
        <span class="i-label">{{ $t('asset.label.lastInventoryAt') }}</span>
        <span class="i-value">
          {{ asset.last_inventory_at ? formatDate(asset.last_inventory_at) : '-' }}
        </span>
      </div>
      <div class="i-block">
        <span class="i-label">{{ $t('asset.label.category') }}</span>
        <span class="i-value">
          {{ asset?.category?.name }}
        </span>
      </div>
      <div class="i-block">
        <span class="i-label">{{ $t('asset.label.model_code') }}</span>
        <span class="i-value">
          {{ asset?.assetModel?.code }}
        </span>
      </div>
      <div v-if="asset.branch?.branch_type !== 'other'" class="i-block">
        <span class="i-label">{{ $t('asset.label.address_data') }}</span>
        <span class="i-value">
          {{ asset?.branch!.postal }} {{ asset?.branch!.city }}, {{ asset?.branch!.street }}
          {{ asset?.branch!.building_number }} {{ asset?.branch!.apartment_number ?? '' }}
        </span>
      </div>
      <div v-else class="i-block">
        <span class="i-label">{{ $t('asset.label.branch_name') }}</span>
        <span class="i-value">
          {{ asset?.branch!.name }}
        </span>
      </div>
      <div class="i-block">
        <span class="i-label">{{ $t('asset.label.location') }}</span>
        <span class="i-value">
          {{ asset?.location }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import AssetData = App.Data.AssetData;
import {PlusCircleIcon} from '@modules/@heroicons/vue/24/outline';
import PrettyStatus from '@ui/PrettyStatus.vue';
import useAccessStore from '@/stores/access';

export default defineComponent({
  components: {
    PrettyStatus,
    PlusCircleIcon,
  },

  props: {
    asset: {
      type: Object as PropType<AssetData>,
      required: true,
    },

    previewImages: {
      type: Function,
      required: true,
    },

    editAssetImages: {
      type: Function,
      required: true,
    },
  },

  computed: {
    access: () => useAccessStore(),
  },
});
</script>
