import { http } from './http';
import { ApiCall } from '@/api/ApiCall';
import { PaginatedResponse } from '@/utils/DataSet';
import { KeyValue } from '@/types/Common';
import CarbonFootprintDefaultValueData = App.Data.CarbonFootprintDefaultValueData;
import ActionData = App.Data.ActionData;

export const getCompactedActions = () => {
  return ApiCall.create(
    (options, query) => {
      return http.get<ActionData[]>('/dictionaries/actions/compact' + query);
    },
    {},
    '',
  );
};

export const getActions = () => {
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<ActionData>>('/dictionaries/actions' + query);
    },
    {},
    '',
  );
};

export const getAction = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.get<ActionData>(`/dictionaries/actions/${options.id}`);
  }, options);
};

export const saveAction = (id: string, fields: ActionData) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<ActionData>(`/dictionaries/actions/${options.id}`, fields);
    },
    options,
    '',
    fields,
  );
};

export const createAction = (fields: ActionData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<ActionData>('/dictionaries/actions', fields);
    },
    {},
    '',
    fields,
  );
};

export const deleteAction = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.delete(`/dictionaries/actions/${options.id}`);
  }, options);
};

export const getDefaultCarbonValuesForCategory = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query) => {
      return http.get<CarbonFootprintDefaultValueData[]>(
        `/dictionaries/category/${options.id}/carbon-values` + query,
      );
    },
    options,
    '',
  );
};

export const getDefaultCarbonValuesForAssetModel = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query) => {
      return http.get<CarbonFootprintDefaultValueData[]>(
        `/dictionaries/asset-model/${options.id}/carbon-values` + query,
      );
    },
    options,
    '',
  );
};

export const saveDefaultCarbonValue = (id: string, fields: CarbonFootprintDefaultValueData) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(
    (options, query, fields) => {
      return http.patch<ActionData>(`/dictionaries/carbon-values/${options.id}`, fields);
    },
    options,
    '',
    fields,
  );
};
export const createDefaultCarbonValue = (fields: CarbonFootprintDefaultValueData) => {
  return ApiCall.create(
    (options, query, fields) => {
      return http.post<ActionData>('/dictionaries/carbon-values', fields);
    },
    {},
    '',
    fields,
  );
};
export const deleteDefaultCarbonValue = (id: string) => {
  const options = {} as KeyValue;
  options.id = id;
  return ApiCall.create(options => {
    return http.delete(`/dictionaries/carbon-values/${options.id}`);
  }, options);
};
