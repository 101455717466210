import type { RouteRecordRaw } from 'vue-router';
import Organizations from '@views/Organizations/Organizations.vue';
import OrganizationDetails from '@views/Organizations/OrganizationDetails.vue';
import EditOrganizationDialog from '@views/Organizations/EditOrganizationDialog.vue';
import EditBranchDialog from '@views/Organizations/EditBranchDialog.vue';
import NewBranchDialog from '@views/Organizations/NewBranchDialog.vue';
import NewOrganizationDialog from '@views/Organizations/NewOrganizationDialog.vue';
import ManageOrganizationDialog from "@views/Organizations/ManageOrganizationDialog.vue";
import { ROUTE } from './routeNames';
import {SYMBOL} from "@/types/symbols";

export const organizationRoutes: RouteRecordRaw[] = [
  {
    path: 'organizations',
    name: 'organization.layout',
    redirect: () => {
      return { name: ROUTE.ORGANIZATION_LIST };
    },
    meta: {
      breadcrumbs: true,
      title: 'organization.label.list_title',
      permission: [{type: SYMBOL.PERMISSION.ORG_ADMIN}],
    },
    children: [
      {
        path: '',
        component: Organizations,
        name: ROUTE.ORGANIZATION_LIST,
        children: [
          {
            path: 'add',
            component: NewOrganizationDialog,
            name: ROUTE.ORGANIZATION_ADD,
          },
        ],
      },

      {
        path: ':organizationId',
        component: OrganizationDetails,
        name: ROUTE.ORGANIZATION_DETAILS,
        meta: {
          breadcrumbs: true,
          title: 'organization.label.details_title',
        },
        props: true,
        children: [
          {
            path: 'edit',
            component: EditOrganizationDialog,
            name: ROUTE.ORGANIZATION_EDIT,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'manage',
            component: ManageOrganizationDialog,
            name: ROUTE.ORGANIZATION_MANAGE,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'branch-edit/:branchId',
            component: EditBranchDialog,
            name: ROUTE.ORGANIZATION_BRANCH_EDIT,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'branch-add/:parentBranchId?',
            component: NewBranchDialog,
            name: ROUTE.ORGANIZATION_BRANCH_ADD,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
        ],
      },
    ],
  },
];
