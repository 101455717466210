<template>
  <Popover>
    <PopoverTrigger as-child>
      <span
        class="border px-2 rounded text-xs cursor-pointer flex items-center border-green-600"
        :class="{ 'opacity-30': isDisabled, 'pointer-events-none': isDisabled }"
        @click.prevent="load"
      >
        <div class="flex items-center p-1 gap-1"><FlagIcon class="icon xs opacity-30" /> PL</div>
      </span>
    </PopoverTrigger>
    <PopoverContent class="w-[420px] flex gap-2" align="end">
      <div class="w-[80px]"><SelectField v-model="selectedLang" :options="languages"></SelectField></div>
      <div class="flex flex-col">
        <ZInput v-model="translation" :class="error ? 'border-red-500' : ''"></ZInput>
        <template v-if="error">
          <div v-for="(err, index) in error" :key="index" class="text-center text-sm w-full whitespace-nowrap">
            <span class="text-red-500">{{ $t(err[0]) }}</span>
          </div>
        </template>
        <template v-else-if="success">
          <div class="text-center text-sm w-full whitespace-nowrap">
            <span class="text-green-500">{{ $t('dictionary.message.saved') }}</span>
          </div>
        </template>
      </div>
      <Action @proceed="save">Ok</Action>
    </PopoverContent>
  </Popover>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZInput } from '@shadcn/components/ui/input';
import { FlagIcon } from '@heroicons/vue/24/solid';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/components/ui/popover';
import SelectField from '@ui/SelectField.vue';
import { getTranslations, storeTranslation } from '@/api/commonApis';
import type { KeyValue } from '@/types/Common';
import Action from '@ui/Action.vue';
import languagesStore from '@/stores/languages';
import TranslationData = App.Data.TranslationData;
import { ValidationErrors } from '@/types/ValidationErrors';

export default defineComponent({
  components: {
    Action,
    FlagIcon,
    Popover,
    PopoverContent,
    PopoverTrigger,
    ZInput,
    SelectField,
  },

  props: {
    type: {
      type: String,
      required: true,
    },

    disabled: {
      type: String,
      required: false,
      default: '',
    },

    identifier: {
      type: Number,
      required: true,
    },

    symbol: {
      type: String,
      default: null,
      required: false,
    },

    exclude: {
      type: String,
      default: 'PL',
      required: false,
    },
  },

  data() {
    return {
      translations: [] as TranslationData[],
      translation: '' as string,
      languages: {} as KeyValue,
      selectedLang: '' as string,
      error: null as null | ValidationErrors,
      success: false as boolean,
    };
  },

  computed: {
    isDisabled() {
      return this.disabled == 'true' || this.disabled == 'disabled';
    },
  },

  watch: {
    selectedLang() {
      this.translation = '';
      for (let index in this.translations) {
        let entry = this.translations[index];
        if (entry.language_code == this.selectedLang) {
          this.translation = entry.translation;
          return;
        }
      }
    },
  },

  methods: {
    async load() {
      this.error = null;
      this.success = false;
      const languages = languagesStore();

      const excludeQuery = '?exclude=' + this.exclude;

      const translationsResponse = await getTranslations(this.type, this.identifier, this.symbol).execute(excludeQuery);
      this.translations = translationsResponse.data;
      this.languages = await languages.get(this.exclude);

      if (this.exclude == 'PL') this.selectedLang = 'EN';
      else this.selectedLang = 'PL';

      for (let index = 0; index < this.translations.length; index++) {
        let entry = this.translations[index];
        if (entry.language_code == this.selectedLang) {
          this.translation = entry.translation;
          return;
        }
      }
    },

    async save(action: any) {
      await storeTranslation(this.translation, this.selectedLang, this.type, this.identifier, this.symbol)
        .withLoader(action.loader)
        .execute()
        .then(() => {
          this.$emit('updated');
          this.error = null;
          this.success = true;
        })
        .catch(error => {
          this.error = error.response.data.errors;
        });

    },
  },
});
</script>
