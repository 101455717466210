import '../css/app.css';

import { createApp } from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import pinia from './store';
import App from './App.vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import type { BugsnagPluginVueResult } from '@bugsnag/plugin-vue';
import router from './router';
import i18n from './utils/i18n';
import 'vue-toastification/dist/index.css';
import useUserStore from './stores/auth';
import { ROUTE } from '@/router/routeNames';
import { SYMBOL } from '@/types/symbols';
import { formatDate, formatDateTime, datePartISO } from '@/utils';
import {formatCurrency, readableCarbonValue} from '@/utils/readableValues';
import access from "@/stores/access";
import {isNavigationFailure, NavigationFailureType} from "@modules/vue-router";

let bugsnagVue: BugsnagPluginVueResult | undefined;
if (import.meta.env.VITE_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    onError: function (event) {
      return !(event.originalError.response && event.originalError.response.status);
    },
  });

  bugsnagVue = Bugsnag.getPlugin('vue');
}
const app = createApp(App).use(pinia).use(i18n);
if (bugsnagVue) {
  app.use(bugsnagVue);
}

app.use(VueReCaptcha, { siteKey: import.meta.env.VITE_CAPTCHA_KEY, loaderOptions: {} });

const { initAuth } = useUserStore();
await initAuth();
app.use(router).mount('#app');

router.beforeEach((to, from) => {
  if (to.meta.feature) {
    if (!access().hasFeature(to.meta.feature)) return false;
  }

  if (to.meta.permission) {
    for(let perm : any of to.meta.permission) {
      if (perm.operator === true && !access().operator()) return false;
      if (!access().allowed(perm.type, perm.branch ?? false)) return false;
    }
    return true;
  }

  return true;
})

router.afterEach((to, from, failure) => {
  if (isNavigationFailure(failure, NavigationFailureType.aborted)) {
    router.push({name: ROUTE.PAGE_NOT_FOUND})
  }
})

app.config.globalProperties.ROUTE = ROUTE;
app.config.globalProperties.SYMBOL = SYMBOL;
app.config.globalProperties.formatDate = formatDate;
app.config.globalProperties.formatDateTime = formatDateTime;
app.config.globalProperties.readableCarbonValue = readableCarbonValue;
app.config.globalProperties.formatCurrency = formatCurrency;
app.config.globalProperties.datePartIso = datePartISO;
