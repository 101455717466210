import Models from '@views/AssetModels/Models.vue';
import { ROUTE } from '@/router/routeNames';
import { RouteRecordRaw } from 'vue-router';
import ModelDetails from '@views/AssetModels/ModelDetails.vue';
import NewAssetModelDialog from '@views/AssetModels/NewAssetModelDialog.vue';
import EditAssetModelDataDialog from '@views/AssetModels/EditAssetModelDataDialog.vue';
import EditAssetModelImagesDialog from '@views/AssetModels/EditAssetModelImagesDialog.vue';
import EditAssetModelAttributesDialog from '@views/AssetModels/EditAssetModelAttributesDialog.vue';
import {SYMBOL} from "@/types/symbols";

export const model_routes: RouteRecordRaw[] = [
  {
    path: 'models',
    name: 'models.layout',
    redirect: () => {
      return { name: ROUTE.ASSET_MODELS };
    },
    meta: {
      breadcrumbs: true,
      title: 'assetModels.label.models_title',
      permission: [{operator: true}],
    },
    children: [
      {
        path: '',
        component: Models,
        name: ROUTE.ASSET_MODELS,
        props: true,
        children: [
          {
            path: 'add',
            component: NewAssetModelDialog,
            name: ROUTE.ASSET_MODEL_ADD,
          },
        ],
      },
      {
        path: ':modelId',
        component: ModelDetails,
        name: ROUTE.ASSET_MODELS_DETAILS,
        props: true,
        meta: {
          breadcrumbs: true,
          title: 'assetModels.label.details',
        },
        children: [
          {
            path: 'editDetails',
            component: EditAssetModelDataDialog,
            name: ROUTE.ASSET_MODELS_DETAILS_EDIT,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'editImages',
            component: EditAssetModelImagesDialog,
            name: ROUTE.ASSET_MODELS_DETAILS_EDIT_IMAGES,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
          {
            path: 'editAttributes',
            component: EditAssetModelAttributesDialog,
            name: ROUTE.ASSET_MODELS_DETAILS_EDIT_ATTRIBUTES,
            props: true,
            meta: {
              breadcrumbs: false,
            },
          },
        ],
      },
    ],
  },
];
