<template>
  <div>
    <h3 class="text-2xl font-semibold mb-5">{{ $t('account.password.header') }}</h3>
    <div class="max-w-[500px]">
      <FormValidator :errors="errors">
        <div class="grid gap-4">
          <FormField symbol="current_password">
            <ZLabel>{{ $t('account.password.form.currentPassword') }}</ZLabel>
            <ZInput v-model="form.current_password" type="password" />
          </FormField>
          <FormField symbol="password">
            <ZLabel>{{ $t('account.password.form.password') }}</ZLabel>
            <ZInput v-model="form.password" type="password" />
          </FormField>
          <FormField symbol="password_confirmation">
            <ZLabel>{{ $t('account.password.form.passwordConfirmation') }}</ZLabel>
            <ZInput v-model="form.password_confirmation" type="password" />
          </FormField>
        </div>
        <div class="flex justify-end">
          <ZButton class="mt-5" @click.prevent="update">{{ $t('common.save') }}</ZButton>
        </div>
      </FormValidator>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { ZButton } from '@shadcn/components/ui/button';
import { ZInput } from '@shadcn/components/ui/input';
import { ZLabel } from '@shadcn/components/ui/label';
import { useToast } from '@shadcn/components/ui/toast/use-toast';
import { postUpdatePassword } from '../../api/account';
import loaderStore from '../../stores/loader';
import FormValidator from '../../components/FormValidator.vue';
import FormField from '../../components/FormField.vue';
import { ValidationErrors } from '../../types/ValidationErrors';
import { HttpStatusCode } from 'axios';

export default defineComponent({
  components: {
    ZButton,
    ZInput,
    ZLabel,
    FormValidator,
    FormField,
  },

  data() {
    return {
      errors: null as null | ValidationErrors,
      form: {
        current_password: '',
        password: '',
        password_confirmation: '',
      } as App.Data.UserUpdatePasswordRequestData,
    };
  },

  computed: {},
  watch: {},
  mounted() {},
  methods: {
    update() {
      const { startLoading, stopLoading } = loaderStore();
      startLoading();
      postUpdatePassword(this.form)
        .then(() => {
          const { toast } = useToast();
          toast({
            title: this.$t('common.success.header'),
            description: this.$t('common.success.saved'),
          });
          this.form = { current_password: '', password: '', password_confirmation: '' };
          this.errors = null;
        })
        .catch(error => {
          if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
            this.errors = error.response.data.errors;
          }
        })
        .finally(() => stopLoading());
    },
  },
});
</script>
