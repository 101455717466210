import { http } from './http';
import NotificationData = App.Data.NotificationData;
import NotificationRequestData = App.Data.NotificationRequestData;
import { ApiCall } from '@/api/ApiCall';
import { PaginatedResponse } from '@/utils/DataSet';

export const getNotifications = (fields: NotificationRequestData) => {
  return ApiCall.create(
    (options, query) => {
      return http.get<PaginatedResponse<NotificationData>>('/notifications' + query);
    },
    {},
    '',
    fields,
  );
};
export const markNotificationAsSeen = (id: string) => {
  return http.post(`/notifications/${id}/markAsSeen`);
};
export const markAllNotificationAsSeen = () => {
  return http.post(`/notifications/markAllAsSeen`);
};
