<template>
  <ZDialog v-model:open="isDialogOpen">
    <ZDialogContent class="lg:max-w-[625px] lg:overflow-y-hidden max-w-screen-lg overflow-y-scroll h-full lg:h-auto">
      <ZDialogHeader>
        <ZDialogTitle>{{
          exportType === 'assets' ? $t('report.label.generate_export') : $t('report.label.generate_report')
        }}</ZDialogTitle>
        <ZDialogDescription>
          {{ exportType === 'assets' ? $t('report.message.generate_export') : $t('report.message.generate_report') }}
        </ZDialogDescription>
      </ZDialogHeader>
      <template v-if="state == 'loading'">
        <IconAlert type="loading">
          {{ $t('common.action.loading') }}
        </IconAlert>
      </template>
      <template v-else-if="state == 'loaded'">
        <div class="lg:max-h-[70vh] lg:overflow-auto">
          <FormValidator ref="form" :errors="errors">
            <GenerateReportForm
              :link-data="linkData"
              :categories="categories"
              :tags="tags"
              :statuses="assetStatuses"
            ></GenerateReportForm>
          </FormValidator>
        </div>
        <ZDialogFooter>
          <div class="w-full flex flex-col items-end">
            <ZButton class="mt-2" @click="generateUrlLink()">
              {{ exportType === 'assets' ? $t('report.command.export_data') : $t('report.command.generate') }}
            </ZButton>
          </div>
        </ZDialogFooter>
      </template>
      <template v-else-if="state == 'error'">
        <IconAlert type="error">
          {{ $t('common.errors.unexpected') }}
        </IconAlert>
      </template>
    </ZDialogContent>
  </ZDialog>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import {
  ZDialog,
  ZDialogContent,
  ZDialogDescription,
  ZDialogFooter,
  ZDialogHeader,
  ZDialogTitle,
} from '@shadcn/components/ui/dialog';
import FormValidator from '@ui/FormValidator.vue';
import IconAlert from '@ui/IconAlert.vue';
import { ComponentLoadState } from '@/types/ComponentLoadState';
import { ZButton } from '@shadcn/components/ui/button';
import { downloadReport, generateAssetsReportFileUrl } from '@/api/report';
import { getCompactCategories } from '@/api/category';
import CategoryCompactData = App.Data.CategoryCompactData;
import GenerateReportForm from '@ui/Reports/GenerateAssetsReportForm.vue';
import { getTagsCompact } from '@/api';
import { mapState } from '@modules/pinia';
import useUserStore from '@/stores/auth';
import ExtensionType = App.Enums.ExtensionType;
import { HttpStatusCode } from '@modules/axios';
import { ValidationErrors } from '@/types/ValidationErrors';
import { KeyValue } from '@/types/Common';
import TagsCompactData = App.Data.TagsCompactData;
import symbolsStore from '@/stores/symbols';

interface linkDataInterface {
  category_id?: string;
  with_custom_attributes?: number;
  extension: ExtensionType;
  tags?: any;
  status?: string;
}

export default defineComponent({
  components: {
    GenerateReportForm,
    ZButton,
    IconAlert,
    FormValidator,
    ZDialog,
    ZDialogContent,
    ZDialogDescription,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
  },

  props: {
    exportType: {
      type: String,
      required: true,
    },
  },

  emits: ['saved'],

  setup() {
    return {
      toast: inject('toast') as (props: any) => void,
    };
  },

  data() {
    return {
      isDialogOpen: true,
      state: 'loading' as ComponentLoadState,
      loader: null as any,
      url: '',
      categories: {} as CategoryCompactData,
      errors: null as null | ValidationErrors,
      tags: {},
      assetStatuses: {} as KeyValue,
      linkData: {
        category_id: '',
        with_custom_attributes: 0 as number,
        extension: 'xlsx' as ExtensionType,
        tags: [],
        status: 'all',
      } as linkDataInterface,
    };
  },

  computed: {
    ...mapState(useUserStore, ['user']),
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) {
        this.$router.back();
      }
    },
  },

  async mounted() {
    try {
      if (this.exportType === 'models') {
        delete this.linkData.category_id;
        delete this.linkData.with_custom_attributes;
        delete this.linkData.tags;
      } else if (this.exportType === 'quantitative') {
        delete this.linkData.category_id;
        delete this.linkData.with_custom_attributes;
        delete this.linkData.tags;
        delete this.linkData.status;
      } else {
        this.getCategories();
      }
      const symbols = symbolsStore();
      this.assetStatuses = await symbols.get('asset_statuses');
      const tagsResponse = await getTagsCompact().execute();
      this.tags = tagsResponse.data.reduce((accumulator: KeyValue, value: TagsCompactData) => {
        return { ...accumulator, [value.id]: value.name };
      }, {});
      this.state = 'loaded';
    } catch (error: any) {
      this.state = 'error';
      throw error;
    }
  },

  methods: {
    generateUrlLink() {
      this.errors = null;
      if (this.exportType === 'models') {
        generateAssetsReportFileUrl({
          export_type: this.exportType,
          status: this.linkData.status,
          extension: this.linkData.extension,
        })
          .execute()
          .then(res => {
            downloadReport(res.data.link, this.linkData.extension);
          })
          .catch(error => {
            if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
              this.errors = error.response.data.errors;
            }
          });
      } else if (this.exportType === 'quantitative') {
        generateAssetsReportFileUrl({
          export_type: this.exportType,
          extension: this.linkData.extension,
        })
          .execute()
          .then(res => {
            downloadReport(res.data.link, this.linkData.extension);
          })
          .catch(error => {
            if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
              this.errors = error.response.data.errors;
            }
          });
      } else if (this.exportType === 'assets') {
        generateAssetsReportFileUrl({
          export_type: this.exportType,
          extension: this.linkData.extension,
          category_id: this.linkData.category_id,
          with_custom_attributes: this.linkData.with_custom_attributes,
          tags: this.linkData.tags,
          status: this.linkData.status,
        })
          .execute()
          .then(res => {
            downloadReport(res.data.link, this.linkData.extension);
          })
          .catch(error => {
            if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
              this.errors = error.response.data.errors;
            }
          });
      }
    },

    async getCategories() {
      const categoriesResponse = await getCompactCategories().execute();
      this.categories = categoriesResponse.data;
    },
  },
});
</script>
